<div class="box p-3 mt-4 full-width">
  <div class="box-header font-weight-bold">Sự kiện</div>
  <div class="box-body mt-4">
    <table class="table table-striped">
      <thead>
        <tr class="table-secondary">
          <th scope="col">
            <div class="sortable-col">
              <div class="col-label">Ngày</div>
              <div class="sort-icons-container">
                <i
                  class="fa fa-lg fa-caret-up handle"
                  (click)="sortEventTable('cameragroup', 'ASC')"
                ></i>
                <i
                  class="fa fa-lg fa-caret-down handle"
                  (click)="sortEventTable('cameragroup', 'DESC')"
                ></i>
              </div>
            </div>
          </th>
          <th scope="col">
            <div class="sortable-col">
              <div class="col-label">Khu vực</div>
              <div class="sort-icons-container">
                <i
                  class="fa fa-lg fa-caret-up handle"
                  (click)="sortEventTable('cameragroup', 'ASC')"
                ></i>
                <i
                  class="fa fa-lg fa-caret-down handle"
                  (click)="sortEventTable('cameragroup', 'DESC')"
                ></i>
              </div>
            </div>
          </th>
          <th scope="col">
            <div class="sortable-col">
              <div class="col-label">Đối tượng</div>
              <div class="sort-icons-container">
                <i
                  class="fa fa-lg fa-caret-up handle"
                  (click)="sortEventTable('custno', 'ASC')"
                ></i>
                <i
                  class="fa fa-lg fa-caret-down handle"
                  (click)="sortEventTable('custno', 'DESC')"
                ></i>
              </div>
            </div>
          </th>
          <th scope="col">
            <div class="sortable-col">
              <div class="col-label">Nam</div>
              <div class="sort-icons-container">
                <i
                  class="fa fa-lg fa-caret-up handle"
                  (click)="sortEventTable('nam', 'ASC')"
                ></i>
                <i
                  class="fa fa-lg fa-caret-down handle"
                  (click)="sortEventTable('nam', 'DESC')"
                ></i>
              </div>
            </div>
          </th>
          <th scope="col">
            <div class="sortable-col">
              <div class="col-label">Nữ</div>
              <div class="sort-icons-container">
                <i
                  class="fa fa-lg fa-caret-up handle"
                  (click)="sortEventTable('nu', 'ASC')"
                ></i>
                <i
                  class="fa fa-lg fa-caret-down handle"
                  (click)="sortEventTable('nu', 'DESC')"
                ></i>
              </div>
            </div>
          </th>
          <th scope="col">
            <div class="sortable-col">
              <div class="col-label">Trẻ em</div>
              <div class="sort-icons-container">
                <i
                  class="fa fa-lg fa-caret-up handle"
                  (click)="sortEventTable('tre_em', 'ASC')"
                ></i>
                <i
                  class="fa fa-lg fa-caret-down handle"
                  (click)="sortEventTable('tre_em', 'DESC')"
                ></i>
              </div>
            </div>
          </th>
          <th scope="col">
            <div class="sortable-col">
              <div class="col-label">Thanh niên</div>
              <div class="sort-icons-container">
                <i
                  class="fa fa-lg fa-caret-up handle"
                  (click)="sortEventTable('thanh_nien', 'ASC')"
                ></i>
                <i
                  class="fa fa-lg fa-caret-down handle"
                  (click)="sortEventTable('thanh_nien', 'DESC')"
                ></i>
              </div>
            </div>
          </th>
          <th scope="col">
            <div class="sortable-col">
              <div class="col-label">Trung niên</div>
              <div class="sort-icons-container">
                <i
                  class="fa fa-lg fa-caret-up handle"
                  (click)="sortEventTable('trung_nien', 'ASC')"
                ></i>
                <i
                  class="fa fa-lg fa-caret-down handle"
                  (click)="sortEventTable('trung_nien', 'DESC')"
                ></i>
              </div>
            </div>
          </th>
          <th scope="col">
            <div class="sortable-col">
              <div class="col-label">Người cao tuổi</div>
              <div class="sort-icons-container">
                <i
                  class="fa fa-lg fa-caret-up handle"
                  (click)="sortEventTable('cao_tuoi', 'ASC')"
                ></i>
                <i
                  class="fa fa-lg fa-caret-down handle"
                  (click)="sortEventTable('cao_tuoi', 'DESC')"
                ></i>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of eventShowList">
          <td>{{ row.daytime | date: "dd-MM-YYYY" }}</td>
          <td>{{ row.cameragroup ? row.cameragroup : noText }}</td>
          <td>{{ row.custno ? row.custno : noStatistic }}</td>
          <td>{{ row.nam ? row.nam : noStatistic }}</td>
          <td>{{ row.nu ? row.nu : noStatistic }}</td>
          <td>{{ row.tre_em ? row.tre_em : noStatistic }}</td>
          <td>{{ row.thanh_nien ? row.thanh_nien : noStatistic }}</td>
          <td>{{ row.trung_nien ? row.trung_nien : noStatistic }}</td>
          <td>{{ row.cao_tuoi ? row.cao_tuoi : noStatistic }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <app-pagination
    [page]="currentPage"
    [itemsPerPage]="eventsPerPage"
    [total]="noEvent"
    (clickedPage)="changePage($event)"
  ></app-pagination>
</div>
