import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-sidebar',
  templateUrl: './nav-sidebar.component.html',
  styleUrls: ['./nav-sidebar.component.scss']
})
export class NavSidebarComponent implements OnInit {
  @Input() startTab:string;
  @Output() clickedItem = new EventEmitter<any>();
  @Input() sideBarItem = [];
  @Input() name: string;
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    let currentUrl = this.router.url.split('/')[2];
    let currentRoute = currentUrl.split('?')[0];
    let currentItem = this.sideBarItem.find(item => item.value == currentRoute);
    this.startTab = currentItem ? currentItem.title : null;
    if (!this.startTab) {
      this.activateClass(this.sideBarItem[0]);
    }
    else {
      let sideBarTab = this.sideBarItem.find(sideBarTab => sideBarTab.title == this.startTab);
      this.activateClass(sideBarTab);
    }
  }

  activateClass(item) {
    this.clickedItem.emit(item.value);
    this.sideBarItem.map(check => {
      if (item.value === check.value) {
        check.active = true;
      } else {
        check.active = false;
      }
    })
  }
}
