import {Component, Input, OnInit} from '@angular/core';
import { Branch } from 'src/app/models/branch';
import { Cameragroup } from 'src/app/models/cameragroup';
import { Ward } from 'src/app/models/ward';
import {GlobalVariablesService} from '../../services/variables/global-variables.service'
@Component({
  selector: 'app-realtime',
  templateUrl: './realtime.component.html',
  styleUrls: ['./realtime.component.scss']
})
export class RealtimeComponent implements OnInit {
  @Input() active: string;

  public selectedWard: Ward;
  public selectedBranch: Branch;
  public selectedCameraGroup: Cameragroup;
  constructor() { }

  ngOnInit(): void {
  }

  public handleClickedWard(ward: Ward) {
    this.selectedWard = ward;
  }

  public handleClickedBranch(branch: Branch) {
    this.selectedBranch = branch;
  }

  public handleClickedCameragroup(cameraGroup: Cameragroup) {
    this.selectedCameraGroup = cameraGroup;
  }

}
