import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import {PagedItem} from 'src/app/models/paged-item';
import {ApiService} from 'src/app/services/api.service';
import {API, DEVICE_IMG_HOST, FUNC, REPLACEMENT} from 'src/environments/environment';
import {EventCustomer} from 'src/app/models/event-customer';
import {Option} from 'src/app/models/option';
import {Branch} from 'src/app/models/branch';
import {SettingOption} from 'src/app/models/setting-option';

import * as moment from 'moment';
import {IdentificationInfoComponent} from "../identification-info/identification-info.component";
import {IdentificationSearchComponent} from "../identification-search/identification-search.component";

@Component({
  selector: 'app-identification-list',
  templateUrl: './identification-list.component.html',
  styleUrls: ['./identification-list.component.scss']
})
export class IdentificationListComponent implements OnInit, OnChanges {
  @Input() selectedFilterTab: Option;
  @Input() selectedFilterBranch: Branch;
  @Input() selectedFilterTime: SettingOption;
  @Input() filterText: string;
  @Output() clickedEvent: EventEmitter<EventCustomer> = new EventEmitter<EventCustomer>();
  @Output()handleElm = new EventEmitter<boolean>();
  @Output()handleModal = new EventEmitter<boolean>();
  public eventCustomerList: EventCustomer[] = [];
  public currentPage: number = 1;
  public noEvents: number = 0;
  public eventsPerPage: number = 20;
  public deviceHost = DEVICE_IMG_HOST;
  public noText = REPLACEMENT.noText;
  public selectedBranchChildrenId: number[] = [];
  public changeImg = false;
  public elm = false;
  public modal = false;

  constructor(
    private apiService: ApiService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes['selectedFilterTab'] && changes['selectedFilterTab'].currentValue && changes['selectedFilterTab'].previousValue && changes['selectedFilterTab'].currentValue.id != changes['selectedFilterTab'].previousValue.id) {
      this.getEventCustomers();
    }
    if (changes['selectedFilterBranch'] && changes['selectedFilterBranch'].currentValue) {
      this.filterCustomerWithBranch();
    }
    if (changes['selectedFilterTime'] && changes['selectedFilterTime'].currentValue && changes['selectedFilterTime'].previousValue && changes['selectedFilterTime'].currentValue.value != changes['selectedFilterTime'].previousValue.value) {
      this.getEventCustomers();
    }
    if (changes['filterText'] && changes['filterText'].currentValue && changes['filterText'].previousValue && changes['filterText'].currentValue.name != changes['filterText'].previousValue.name) {
      this.getEventCustomers();
    }
  }

  public getEventCustomers() {
    let eventInclude = [
      {
        model: 't_cameragroup',
        include: [
          {
            model: 't_branch',
            include: []
          }
        ]
      },
      {
        model: 't_customer',
        include: [
          {
            model: 't_option',
            as: 'type',
            include: []
          },
          {
            model: 't_option',
            as: 'cust_gender',
            include: []
          }
        ]
      },
      {
        model: 't_option',
        include: []
      }
    ]
    let eventQuery = {
      op: 'AND',
      val: []
    }
    if (this.selectedFilterTab && this.selectedFilterTab.id != 0) {
      eventQuery.val.push(
        {
          model: 't_event_customer',
          col: 'customertypeoptid',
          op: '=',
          val: this.selectedFilterTab.id
        })
    }
    if (this.selectedFilterBranch && this.selectedFilterBranch.id != 0) {
      eventQuery.val.push(
        {
          model: 't_cameragroup',
          col: 'branchid',
          op: 'in',
          val: this.selectedBranchChildrenId
        })
    }
    if (this.selectedFilterTime) {
      eventQuery.val.push(
        {
          model: 't_event_customer',
          col: 'createddate',
          op: '>=',
          val: moment().subtract(this.selectedFilterTime.value, 'days')
        })
    }
    if (this.filterText) {
      eventQuery.val.push(
        {
          model: 't_customer',
          col: 'name',
          op: 'ilike',
          val: '%' + this.filterText + '%'
        })
    }
    this.apiService.get<PagedItem<EventCustomer>>(API.eventCustomers, {
      include: JSON.stringify(eventInclude),
      query: JSON.stringify(eventQuery),
      page: this.currentPage,
      itemsPerPage: this.eventsPerPage
    }).subscribe(events => {
      if (events) {
        this.noEvents = events.count;
        let eventList = events.rows.map(event => {
          let eventNote = JSON.parse(event.note);
          if (eventNote.frequent_info) {
            let now = moment();
            let eventTime = moment(eventNote.frequent_info.since_date);
            eventNote.frequent_info.nrof_day = now.diff(eventTime, 'days');
          }
          event.note = eventNote
          event.active = false;
          return event;
        });
        this.eventCustomerList = eventList;
      }
    })
  }

  public handleClickEvent(e: EventCustomer) {
    this.eventCustomerList.map(event => {
      if (event.id === e.id) {
        event.active = true;
        this.clickedEvent.emit(e);
      } else {
        event.active = false;
      }
    })
  }

  public changePage(page: number) {
    this.currentPage = page;
    this.getEventCustomers();
  }

  public filterCustomerWithBranch() {
    this.getBranchChildren();
  }

  public getBranchChildren() {
    this.apiService.get<any>(API.view + FUNC.getBranchChildren, {parent: this.selectedFilterBranch.id}).subscribe(branches => {
      if (branches) {
        this.selectedBranchChildrenId = branches.map(branch => branch.id);
        this.getEventCustomers();
      }
    })
  }

  public drag() {
    this.elm = true;
    this.handleElm.emit(this.elm);
  }

  dragLeaveEle() {
    this.elm = false;
    this.handleElm.emit(this.elm);
  }
  dragLeaveModal() {
    this.modal = false;
    this.handleModal.emit(this.modal)
  }
  dragStart(e) {
    // console.log(e)
  }
}
