<div class="slim-scroll hidden-scrollbar" *ngIf="active === 'Khu vực'">
  <div class="row">
    <app-area-search
      class="full-width"
      (clickedBranch)="handleClickedBranch($event)"
      (clickedTimeOption)="handleClickedTimeOption($event)"
      (clickedTimeTab)="handleClickedTimeTab($event)"
      (clickedStall)="handleClickedStall($event)"
      [showFromToDate]="true"
      (changedFromDate)="handleFromDate($event)"
      (changedToDate)="handleToDate($event)"
    ></app-area-search>
  </div>
  <div class="row full-width mt-4">
    <div class="col-3 p-0 d-flex flex-column">
      <!-- <div class="row pb-4">
        <div class="box full-width">
          <div class="title-header p-4">{{chosenBranch ? chosenBranch.name : noText}}</div>
          <div class="pl-4 pb-4"><img src="assets/image/member.PNG" alt=""> <span
            class="title-group p-1">22 thành viên</span></div>
        </div>
      </div> -->
      <div class="row pb-4">
        <div class="box full-width">
          <div class="full-width d-flex flex-row" style="padding: 2rem">
            <div>
              <img class="mr-2 pt-1" src="assets/image/7232.png" alt="" />
            </div>
            <div class="pl-2">
              <div class="l-title font-weight-bold">
                {{ summaryStatistics.custno }}
              </div>
              <div class="sub-title sub-style">Khách hàng</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-4">
        <div class="box full-width">
          <div class="full-width d-flex flex-row" style="padding: 2rem">
            <div>
              <img class="mr-2 pt-1" src="assets/image/7231.png" alt="" />
            </div>
            <div class="pl-2">
              <div class="l-title font-weight-bold">
                {{ summaryStatistics.interestedcustno }}
              </div>
              <div class="sub-title sub-style">Quan tâm</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="box full-width">
          <div class="full-width d-flex flex-row" style="padding: 2rem">
            <div class="icon-clock background-primary-style mr-2">
              <span><i class="fa fa-clock-o default-style"></i></span>
            </div>
            <div class="pl-2">
              <div class="l-title font-weight-bold">
                {{
                  summaryStatistics.custno
                    ? (summaryStatistics.sumwatchtime / summaryStatistics.custno
                      | number: "1.0-0")
                    : noStatistic
                }}
                giây
              </div>
              <div class="sub-title sub-style">Thời gian xem TB</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-9 pr-0">
      <app-map [minimize]="true" [selectedBranch]="chosenBranch"></app-map>
    </div>
  </div>
  <div class="row mt-4">
    <app-area-chart
      class="full-width"
      [rawData]="eventData"
      [selectedTab]="chosenTab"
      [selectedTimeOption]="chosenTimeOption"
      [fromTime]="fromTime"
      [toTime]="toTime"
    ></app-area-chart>
  </div>

  <div class="row full-width">
    <app-table-events
      class="full-width mb-5"
      [chosenBranch]="chosenBranch"
      [chosenStall]="chosenStall"
      [chosenTimeOption]="chosenTimeOption"
      [fromTime]="fromTime"
      [toTime]="toTime"
    ></app-table-events>
  </div>
</div>
