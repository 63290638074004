import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
} from '@angular/core';
import * as moment from 'moment';
import { SettingOption } from 'src/app/models/setting-option';
import { REPLACEMENT } from 'src/environments/environment';

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss'],
})
export class AreaChartComponent implements OnInit, OnChanges {
  @Input() rawData = [];
  @Input() selectedTab = 'day';
  @Input() selectedTimeOption: SettingOption;
  @Input() fromTime;
  @Input() toTime;
  public custnoData = [];
  public watchtimeData = [];
  public custnoColorScheme = {
    domain: ['#00dcff', '#FF00D5'],
  };
  public watchtimeColorScheme = {
    domain: ['#FF00D5'],
  };
  public custnoLabel = REPLACEMENT.custno;
  public interestedcustnoLabel = REPLACEMENT.interestedcustno;
  public watchtime = REPLACEMENT.watchtime;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes['rawData'] && changes['rawData'].currentValue) {
      this.custnoData = this.formatCustnoData();
      this.watchtimeData = this.formatAvgWatchtimeData();
    }
    if (changes['selectedTab'] && changes['selectedTab'].currentValue) {
      if (this.selectedTimeOption[this.selectedTab] && this.rawData) {
        this.custnoData = this.formatCustnoData();
        this.watchtimeData = this.formatAvgWatchtimeData();
      }
    }
    if (
      changes['fromTime'] &&
      changes['fromTime'].currentValue &&
      this.selectedTimeOption
    ) {
      if (this.selectedTimeOption[this.selectedTab] && this.rawData) {
        this.custnoData = this.formatCustnoData();
        this.watchtimeData = this.formatAvgWatchtimeData();
      }
    }
    if (
      changes['toTime'] &&
      changes['toTime'].currentValue &&
      this.selectedTimeOption
    ) {
      if (this.selectedTimeOption[this.selectedTab] && this.rawData) {
        this.custnoData = this.formatCustnoData();
        this.watchtimeData = this.formatAvgWatchtimeData();
      }
    }
  }

  public formatCustnoData() {
    let result = [
      {
        name: 'custno',
        series: [],
      },
      {
        name: 'interestedcustno',
        series: [],
      },
    ];
    let endVal;
    let startVal;
    switch (this.selectedTimeOption[this.selectedTab].dataBasedOn) {
      case 'hour':
        startVal = 0;
        endVal = this.rawData.length - 1;
        for (let i = 0; i <= endVal; i++) {
          let colName = startVal + i;
          let value = this.rawData.find(
            (row) =>
              row[
                this.selectedTimeOption[this.selectedTab].dataBasedOn
              ]?.substring(0, 2) == colName
          );
          result.map((line) => {
            line.series.push({
              name: colName + ' giờ',
              value: value && value[line.name] ? +value[line.name] : 0,
            });
          });
        }
        break;
      case 'day':
        startVal = moment(this.rawData[0].day, 'DD-MM');
        endVal = this.rawData.length - 1;
        for (let i = 0; i <= endVal; i++) {
          let colName = startVal.format('DD-MM');
          let value = this.rawData.find(
            (row) =>
              row[this.selectedTimeOption[this.selectedTab].dataBasedOn] ==
              colName
          );
          result.map((line) => {
            line.series.push({
              name: colName,
              value: value && value[line.name] ? +value[line.name] : 0,
            });
          });
          startVal.add(
            1,
            this.selectedTimeOption[this.selectedTab].dataBasedOn
          );
        }
        break;
      case 'month':
        startVal = moment(this.rawData[0].day);
        endVal = this.rawData.length - 1;
        for (let i = 0; i <= endVal; i++) {
          let colName = startVal.format('MM-yyyy');
          let value = this.rawData.find(
            (row) =>
              row[this.selectedTimeOption[this.selectedTab].dataBasedOn] ==
              colName
          );
          result.map((line) => {
            line.series.push({
              name: colName,
              value: value && value[line.name] ? +value[line.name] : 0,
            });
          });
          startVal.add(
            1,
            this.selectedTimeOption[this.selectedTab].dataBasedOn
          );
        }
        break;
      case 'week':
        startVal = 1;
        endVal = moment().isoWeeksInYear();
        for (let i = 0; i <= endVal; i++) {
          let colName = startVal + i;
          let value = this.rawData.find(
            (row) =>
              row[this.selectedTimeOption[this.selectedTab].dataBasedOn] ==
              colName
          );
          result.map((line) => {
            line.series.push({
              name: colName,
              value: value && value[line.name] ? +value[line.name] : 0,
            });
          });
        }
        break;
    }
    return result;
  }

  public formatAvgWatchtimeData() {
    let result = [
      {
        name: 'watchtime',
        series: [],
      },
    ];

    let endVal;
    let startVal;
    switch (this.selectedTimeOption[this.selectedTab].dataBasedOn) {
      case 'hour':
        startVal = 0;
        endVal = this.rawData.length - 1;
        for (let i = 0; i <= endVal; i++) {
          let colName = startVal + i;
          let value = this.rawData.find(
            (row) =>
              row[
                this.selectedTimeOption[this.selectedTab].dataBasedOn
              ]?.substring(0, 2) == colName
          );
          result.map((line) => {
            line.series.push({
              name: colName + ' giờ',
              value:
                value && value.custno && value.custno > 0
                  ? +value.sumwatchtime / +value.custno
                  : 0,
            });
          });
        }
        break;
      case 'day':
        startVal = moment(this.rawData[0].day, 'DD-MM');
        endVal = this.rawData.length - 1;
        for (let i = 0; i <= endVal; i++) {
          let colName = startVal.format('DD-MM');
          let value = this.rawData.find(
            (row) =>
              row[this.selectedTimeOption[this.selectedTab].dataBasedOn] ==
              colName
          );
          result.map((line) => {
            line.series.push({
              name: colName,
              value:
                value && value.custno && value.custno > 0
                  ? +value.sumwatchtime / +value.custno
                  : 0,
            });
          });
          startVal.add(
            1,
            this.selectedTimeOption[this.selectedTab].dataBasedOn
          );
        }
        break;
      case 'month':
        startVal = moment().subtract(
          +this.selectedTimeOption.value + 1,
          'days'
        );
        endVal = moment().diff(startVal, 'months');
        for (let i = 0; i <= endVal; i++) {
          let colName = startVal.format('MM-yyyy');
          let value = this.rawData.find(
            (row) =>
              row[this.selectedTimeOption[this.selectedTab].dataBasedOn] ==
              colName
          );
          result.map((line) => {
            line.series.push({
              name: colName,
              value:
                value && value.custno && value.custno > 0
                  ? +value.sumwatchtime / +value.custno
                  : 0,
            });
          });
          startVal.add(
            1,
            this.selectedTimeOption[this.selectedTab].dataBasedOn
          );
        }
        break;
      case 'week':
        startVal = 1;
        endVal = moment().isoWeeksInYear();
        for (let i = 0; i <= endVal; i++) {
          let colName = startVal + i;
          let value = this.rawData.find(
            (row) =>
              row[this.selectedTimeOption[this.selectedTab].dataBasedOn] ==
              colName
          );
          result.map((line) => {
            line.series.push({
              name: colName,
              value:
                value && value.custno && value.custno > 0
                  ? +value.sumwatchtime / +value.custno
                  : 0,
            });
          });
        }
        break;
    }
    return result;
  }
}
