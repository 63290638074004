<div *ngIf="active === 'Nhận dạng'" class="full-height">
  <div class="row full-height">
    <div class="d-flex flex-column flex-grow-1 p-0 full-height"
         [ngClass]="activeSideBar?'col-9 transition':'col-12 transition'">
      <app-identification-search
      (changeTab)="handleFilterTab($event)"
      (clickedBranch)="handleChangeBranch($event)"
      (clickedTimeOption)="handleChangeTimeOption($event)"
      (textSearchChange)="handleTextSearch($event)" [showEle]="showElement" [showModal]="showModalVar">
    </app-identification-search>
      <app-identification-list class="slim-scroll hidden-scrollbar"
      [selectedFilterTab]="selectedTab"
      [selectedFilterBranch]="selectedBranch"
      [selectedFilterTime]="selectedTimeOption"
      [filterText]="textSearch"
      (clickedEvent)="handleClickedEvent($event)" (handleElm)="showElementFunc($event)" (handleModal)="showModalFunc($event)"
      ></app-identification-list>
    </div>
    <div  class="full-width full-height p-0" style="padding-left: 1.5rem !important"
         [ngClass]="activeSideBar?'display-block col':'display-none'">
      <app-identification-info (close)="onClose()"
                               (showPersonForm)="handleShowPersonForm($event)" [eventInfo]="selectedEvent"></app-identification-info>
    </div>
    <div class="bg-white full-width full-height" style="position: absolute; top: 0; left: 0; z-index: 1000;" [ngClass]="{'display-none': !showAddForm, 'display-block': showAddForm}">
      <app-person-detail [personType]="'customer'" [isShow]="showAddForm" [forcedAvatar]="selectedAvatar" [faceuid]="selectedFaceuid" (closeForm)="hideDetailForm($event)"></app-person-detail>
    </div>
  </div>
</div>
