import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import {
  API,
  API_IMG_HOST,
  DEVICE_IMG_HOST,
  FUNC,
  REPLACEMENT,
} from 'src/environments/environment';
import { Option } from 'src/app/models/option';
import { Subscription } from 'rxjs';
import { SocketService } from 'src/app/services/socket.service';
import { Cameragroup } from 'src/app/models/cameragroup';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  @Input() changeICon: any;
  @Output() showSideBar = new EventEmitter<boolean>();
  public userInfo;
  public isShowUserMenu: boolean = false;
  public defaultAvatar = 'assets/image/no-avatar.png';
  public avatarSrc;
  public isShowNotifies: boolean = false;
  public notifyList = [];
  public typeList = [];
  public deviceHost = DEVICE_IMG_HOST;
  public noNewNotifies = 0;
  public eventSubscription: Subscription;
  public userBranchChildren = [];
  public userCameraGroups = [];
  public maxNotification = REPLACEMENT.maxNotification;
  array = [];
  public page = 1;
  sum = 100;
  throttle = 1;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = '';
  modalOpen = false;
  allpost = [];
  notEmptyPost = true;
  notscrolly = true;

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private socketService: SocketService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getUserInfo();
    if (this.userInfo.branchid) this.getUserBranchChildren();
    else this.getBranchCameragroups();
    this.getTypes();
    this.getNotifications();
    this.eventSubscription = this.socketService
      .onNewEvent()
      .subscribe((data) => {
        if (data && this.authService.getToken()) {
          if (
            !this.userInfo.branchid ||
            (this.userInfo.branchid &&
              this.userCameraGroups.includes(Number(data.cameragroupid)))
          ) {
            if (!this.isShowNotifies) {
              this.noNewNotifies += 1;
            } else {
              let content = JSON.parse(data.note);
              let personType = this.typeList.find(
                (type) => type.code == content.person_type
              );
              if (personType) content.personType = personType.name;
              this.notifyList = [content, ...this.notifyList];
            }
          }
        }
      });
  }

  public onShow() {
    this.showSideBar.emit();
  }

  public clickedUserMenu() {
    this.isShowUserMenu = !this.isShowUserMenu;
    this.isShowNotifies = false;
  }

  public clickedNotifyMenu() {
    this.isShowNotifies = !this.isShowNotifies;
    if (this.isShowNotifies == true) {
      this.page = 1;
      this.notifyList.length = 0;
      this.getNotifications();
      this.noNewNotifies = 0;
    }
    this.isShowUserMenu = false;
  }

  public getUserInfo() {
    this.userInfo = this.authService.getUserInfo();
    if (this.userInfo.img) this.avatarSrc = API_IMG_HOST + this.userInfo.img;
    else this.avatarSrc = this.defaultAvatar;
  }

  public getNotifications() {
    this.apiService
      .get<any>(API.notifications, {
        page: this.page,
        itemsPerPage: this.maxNotification + 1,
      })
      .subscribe((notifications) => {
        if (notifications) {
          notifications.rows.map((notify) => {
            let message = JSON.parse(notify.detail);
            if (message) {
              message.id = notify.id;
              message.eventid = notify.eventid;
              message.branchid = notify.branchid;
              message.isread = JSON.parse(notify.isread);
              if (message.isread && message.isread.includes(this.userInfo.id))
                message.isNew = false;
              else {
                message.isNew = true;
                if (this.isShowNotifies == false) this.noNewNotifies += 1;
              }
              let personType = this.typeList.find(
                (type) => type.code == message.person_type
              );
              if (personType) message.personType = personType.name;
              this.notifyList.push(message);
            }
            this.spinner.hide();
          });
        }
      });
  }

  public updateNotifyState(notify) {
    if (!notify.isread) {
      notify.isread = [this.userInfo.id];
    } else if (notify.isread && !notify.isread.includes(this.userInfo.id)) {
      notify.isread.push(this.userInfo.id);
    } else {
      this.isShowNotifies = false;
      return null;
    }
    this.apiService
      .put<any>(API.notifications + '/' + notify.id, {
        isread: JSON.stringify(notify.isread),
      })
      .subscribe((result) => {
        if (result) {
          this.isShowNotifies = false;
        }
      });
  }

  public getUserBranchChildren() {
    this.apiService
      .get<any[]>(API.view + FUNC.getBranchChildren, {
        parent: this.userInfo.branchid,
      })
      .subscribe((branches) => {
        if (branches) {
          this.userBranchChildren = branches.map((branch) => branch.id);
          this.getBranchCameragroups();
        }
      });
  }

  public getBranchCameragroups() {
    let cameragroupQuery = {
      op: 'AND',
      val: [
        {
          model: 't_cameragroup',
          col: 'branchid',
          op: 'in',
          val: this.userBranchChildren,
        },
      ],
    };
    this.apiService
      .get<Cameragroup[]>(API.cameraGroup, {
        query: JSON.stringify(cameragroupQuery),
      })
      .subscribe((cameragroups) => {
        if (cameragroups) {
          this.userCameraGroups = cameragroups.map(
            (cameragroup) => cameragroup.id
          );
        }
      });
  }

  public getTypes() {
    let typeInclude = [
      {
        model: 't_optiongroup',
        include: [],
      },
    ];
    let typeQuery = {
      op: 'AND',
      val: [
        {
          model: 't_optiongroup',
          col: 'code',
          op: '=',
          val: 'customertypeoptionid',
        },
      ],
    };
    this.apiService
      .get<Option[]>(API.options, {
        include: JSON.stringify(typeInclude),
        query: JSON.stringify(typeQuery),
      })
      .subscribe((types) => {
        if (types) {
          this.typeList = types;
        }
      });
  }

  public logout() {
    this.authService.logout();
  }

  onScroll() {
    this.spinner.show();
    this.notscrolly = false;
    this.loadNextPost();
  }

  // load th next 6 posts
  loadNextPost() {
    this.page = this.page + 1;
    this.getNotifications();
  }
}
