<div class="box h-auto" (click)="hideModalFunc($event)">
  <div style="display: grid; grid-template-columns: 2fr 2fr 4fr; grid-gap: 2px">
    <!-- <div class="position-relative search-main" style="display: grid; grid-template-columns: 1fr 8fr 1fr" [ngClass]="
    isFocus? 'background-shadow' : ''
          ">
      <div class="size-icon handle" style="margin: auto" (click)="search()"><i class="fa fa-search"></i></div>
      <div class="">
        <div class="parent full-height full-width position-relative d-flex">
          <ng-container *ngFor="let image of imageUrl; let i = index">
            <div class="parent-img position-relative" *ngIf="showImg === true">
              <img class="mini-img" [src]="image.url || image" alt="">
              <span class="position-relative handle" (click)="closeImg($event,i)"><i
                class="fa fa-close pt-2 position-absolute"
                style="right: 5px;top: 2px"></i></span>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="handle line-height-50" (click)="resetSearch()" [hidden]="textSearch == ''"><i
        class="fa fa-sm fa-close"></i></div>
      <div class="borderLeft pr-3">
        <span class="handle" (click)="showModalFunc($event)"><i class="fa fa-camera"></i></span>
      </div>
      <div class="position-absolute custom-modal" *ngIf="showModal === true">
        <h5 class="pl-3 pr-3 pt-3">Tìm kiếm bằng hình ảnh</h5>
        <div class="pl-3 pr-3 pb-3">Tìm kiếm bằng hình ảnh thay vì văn bản. Thử kéo một hình ảnh vào đây.</div>
        <div *ngIf="showEle === false">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item custom-nav">
              <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
                 aria-selected="true">Dán URL của hình ảnh</a>
            </li>
            <li class="nav-item custom-nav">
              <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile"
                 aria-selected="false">Tải ảnh lên</a>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent" style="background: #f5f5f5">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <div class="p-4" style="display: grid;grid-template-columns: 3fr 1fr">
                <input [(ngModel)]="srcImage" class="full-width" style="outline: none; border: none" type='text'
                       placeholder="Tìm kiếm"
                />
                <button class="bg-primary ml-3" style="border: none !important;" (click)="findImage()">
                  Tìm kiếm
                </button>
              </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab" appImgSearch
                 (files)="dropImage($event)" (drag)="onDrag($event)">
              <form class="p-4">
                <input #file type="file" accept='image/*' name="file" id="file" class="inputfile"
                       (change)="chooseFile(file.files)"/>
              </form>
            </div>
          </div>
        </div>
        <div style="background: #d3ceca ;height: 150px;text-align: center;vertical-align: middle"
             *ngIf="showEle === true"
             appImgSearch (files)="dropImage($event)">
          <h3 class="pt-5" style="color:rgba(0,0,0,.5)">Thả ảnh vào đây</h3>
        </div>
      </div>
    </div> -->
    <!-- <div class="d-flex flex-row"> -->
    <div class="handle position-relative w-100">
      <div
        class="parent w-100"
        [ngClass]="isShowBranchOption ? 'background-shadow' : ''"
        (click)="handleSelectList('isShowBranchOption')"
      >
        <div class="line-height-50" style="display: flex">
          <div class="showing-select">
            {{ selectedBranch ? selectedBranch.name : noText }}
          </div>
          <div class="ml-3">
            <i
              class="fa"
              [ngClass]="{
                'fa-caret-down': !isShowBranchOption,
                'fa-caret-up': isShowBranchOption
              }"
            ></i>
          </div>
        </div>
      </div>
      <!--      <ul class="top-level-menu" [hidden]="!isShowBranchOption">-->
      <!--        <li class="title-menu" *ngFor="let branch of branchList" [ngClass]="{'select-active': selectedBranch && branch.name == selectedBranch.name}">-->
      <!--          <a (click)="handleClickedBranch(branch)">{{branch.name}}</a>-->
      <!--        </li>-->
      <!--      </ul>-->
      <app-dropdown-list
        [dataList]="branchList"
        [saveBranch]="true"
        [isShow]="isShowBranchOption"
        [selectedBranch]="selectedBranch"
        (clickedOutside)="closePopup()"
        (handleSelect)="handleClickedBranch($event)"
      ></app-dropdown-list>
    </div>
    <div
      class="handle position-relative w-100"
      (click)="handleSelectList('isShowTimeOption')"
    >
      <div
        class="parent w-100"
        [ngClass]="isShowTimeOption ? 'background-shadow' : ''"
      >
        <div class="line-height-50" style="display: flex">
          <div class="showing-select">
            {{ selectedTime ? selectedTime.name : noText }}
          </div>
          <div class="ml-3">
            <i
              class="fa"
              [ngClass]="{
                'fa-caret-down': !isShowTimeOption,
                'fa-caret-up': isShowTimeOption
              }"
            ></i>
          </div>
        </div>
      </div>
      <ul class="top-level-menu" [hidden]="!isShowTimeOption">
        <li
          class="title-menu"
          *ngFor="let option of timeOptions"
          [ngClass]="{
            'select-active': selectedTime && option.name == selectedTime.name
          }"
        >
          <a (click)="handleSelectTime(option)">{{ option.name }}</a>
        </li>
      </ul>
      <app-dropdown-list
        [dataList]="timeOptions"
        [saveBranch]="true"
        [isShow]="isShowTimeOption"
        [selectedBranch]="selectedTime"
        (clickedOutside)="closePopup()"
        (handleSelect)="handleSelectTime($event)"
      ></app-dropdown-list>
    </div>
    <div class="box-header row">
      <ul
        class="nav justify-content-end align-items-center col"
        id="pills-tab"
        role="tablist"
      >
        <li
          class="handle"
          (click)="handleChangeTab(type)"
          *ngFor="let type of typeList"
          [ngClass]="chosenTab.id === type.id ? 'active' : ''"
        >
          <div class="nav-link font-size full-height">{{ type.name }}</div>
        </li>
      </ul>
    </div>
    <!-- </div> -->
  </div>
</div>
