<div class="layout">
    <div class="layout-header">
      <app-nav-bar [changeICon]="false"></app-nav-bar>
    </div>
    <div class="layout-content">
      <div class="row full-height">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-4 full-width full-height">
          <router-outlet></router-outlet>
            <!-- <app-list-event-images></app-list-event-images> -->
        </div>
      </div>
    </div>
</div>
  