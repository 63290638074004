<div class="slim-scroll hidden-scrollbar"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScroll()"
    [scrollWindow]="false">
    <div class="row" style="position: sticky; top: 0">
      <app-evaluate-search class="full-width" [allowAll]="false"
      (clickedBranch)="handleClickedBranch($event)"
      (clickedTimeOption)="handleClickedTimeOption($event)"
      (clickedStall)="handleClickedStall($event)"
      (selectedImg)="handleSelectImg($event)"
      (clickedGender)="handleClickedGender($event)"
      (clickedAge)="handleClickedAge($event)"
      (changedSlider)="handleChangedThreshold($event)"
      [showSlider]="true"
        [showEle]="showElement" [showPopup]="showModalVar" [hiddenSearchBar]="true"></app-evaluate-search>
    </div>
    <div class="d-flex flex-column transition" style="flex: 1 1 auto;height:0">
        <div class="mt-3" style="display: grid; grid-template-columns: repeat(6, 1fr); grid-gap: 2rem;">
            <div class="box d-flex flex-column" style="padding: 0 !important" *ngFor="let event of listEvent" [ngClass]="{'new-event': latestAnalyzeHour && event.hour >= latestAnalyzeHour && event.daytime == today}">
                <img draggable="true" class="img-list"  (drag)="drag()" (dragend)="dragLeaveEle();dragLeaveModal()"
                    src="{{deviceHost + event.url}}"
                    alt=""
                    (error)="onImgError($event)">
                <div class="mt-2 mb-2" style="margin: auto">
                    <div class="pb-2">
                        <div *ngIf="!userInfo.branchid" class="sub-title" style="display: grid; grid-template-columns: 1fr 5fr; align-items: baseline;">
                            <i class="fa fa-xs fa-id-card pr-2"></i><span>{{event.face_uid}}_{{event.age}}</span>
                        </div>
                        <div class="sub-title" style="display: grid; grid-template-columns: 1fr 5fr; align-items: baseline;">
                            <i class="fa fa-xs fa-camera pr-2"></i><span>{{event.group_device_id}}</span>
                        </div>
                        <div class="sub-title" style="display: grid; grid-template-columns: 1fr 5fr; align-items: baseline;">
                            <i class="fa fa-xs fa-user pr-2"></i>
                            <div class="d-flex" style="justify-content: space-between;">
                                <span>Độ tuổi:</span>
                                <div>{{event.ageRange ? event.ageRange : event.age}}</div>
                            </div>
                        </div>
                        <div class="sub-title" style="display: grid; grid-template-columns: 1fr 5fr; align-items: baseline;">
                            <i class="fa fa-xs fa-venus-mars pr-2"></i>
                            <div class="d-flex" style="justify-content: space-between;">
                                <span>Giới tính:</span>
                                <i class="fa fa-xs fa-mars" *ngIf="event.gender == 'M'"></i>
                                <i class="fa fa-xs fa-venus" *ngIf="event.gender == 'F'"></i>
                            </div>
                        </div>
                        <div class="sub-title" style="display: grid; grid-template-columns: 1fr 5fr; align-items: baseline;">
                            <i class="fa fa-xs fa-hourglass pr-2"></i>
                            <div class="d-flex" style="justify-content: space-between;">
                                <span>Thời gian quan sát:</span>
                                <span>{{event.duration}}s</span>
                            </div>
                        </div>
                    </div>
                    <div class="sub-title" style="display: grid; grid-template-columns: 1fr 5fr"><span></span>{{event.created_timestamp | date: 'dd-MM-yyyy HH:mm:ss'}}</div> 
                </div>
            </div>
            <ngx-spinner
                bdColor="rgba(255,255,255,0.4)" size="medium" color="#000"
                type="ball-spin"
                [fullScreen]="false"
                [name]="spinnerName"
                >
            </ngx-spinner>
        </div>
        <div *ngIf="listEvent.length == 0" class="title" style="text-align: center;">Không có dữ liệu</div>
    </div>
</div>
