<div
  id="top-page"
  #topPage
  class="box slim-scroll"
  *ngIf="eventInfo"
  style="scroll-behavior: smooth"
  (scroll)="backToTop()"
>
  <div class="">
    <img
      #top
      class="full-width img-info"
      src="{{ deviceHost + eventInfo.url }}"
      alt=""
    />
    <!--    <div class="position-absolute btn-close" (click)="handleClose()"><span><i class="fa fa-close"></i></span></div>-->
    <div class="position-absolute" style="top: 20px; right: 20px">
      <button class="btn-close" (click)="handleClose()"></button>
    </div>
    <!--    <div *ngIf="showBackToTop === true" class="position-absolute" style="bottom: 20px;right:20px">-->
    <!--      <button class="goTop" (click)="scrollTopPage()"><i class="fa fa-angle-up"></i></button>-->
    <!--    </div>-->
  </div>
  <div class="pl-4 pt-3 pb-3 pb-1">
    <div class="title-header font-weight-bold">
      {{
        eventInfo.t_customer
          ? eventInfo.t_customer.name
          : eventInfo.t_option.name
      }}
    </div>
    <div class="sub-title sub-style font-weight-bold">
      {{ eventInfo.t_option.name }}
    </div>
    <div
      class="sub-title sub-style pt-2"
      *ngIf="eventInfo.note.frequent_info && eventInfo.t_option.code != 'BLK'"
    >
      Đã quay lại {{ eventInfo.note.frequent_info.nrof_turn_since_date }} lần từ
      {{ eventInfo.note.frequent_info.since_date | date: "dd-MM-YYYY" }} đến
      {{ eventInfo.createddate | date: "dd-MM-YYYY HH:mm:ss" }}
    </div>
  </div>

  <div
    class="pl-4 pb-4"
    style="border-bottom: 1px solid #d2d1d1"
    *ngIf="eventInfo.t_customer"
  >
    <div class="title font-weight-bold pb-3">Hồ sơ</div>
    <div class="sub-title multi-data-cell font-weight-normal pb-1">
      <span class="font-weight-bold">Họ tên</span
      >{{ eventInfo.t_customer.name }}
    </div>
    <div class="sub-title multi-data-cell font-weight-normal pb-1">
      <span class="font-weight-bold">DOB</span>{{ eventInfo.t_customer.dob }}
    </div>
    <div class="sub-title multi-data-cell font-weight-normal pb-1">
      <span class="font-weight-bold">Giới tính</span
      >{{
        eventInfo.t_customer.cust_gender
          ? eventInfo.t_customer.cust_gender.name
          : noText
      }}
    </div>
    <div class="sub-title multi-data-cell font-weight-normal pb-1">
      <span class="font-weight-bold">Số điện thoại</span
      >{{ eventInfo.t_customer.phone }}
    </div>
    <div class="sub-title multi-data-cell font-weight-normal pb-1">
      <span class="font-weight-bold">Địa chỉ</span
      >{{ eventInfo.t_customer.address }}
    </div>
  </div>

  <!--  <div class="pl-4 pt-4 pb-4" style="border-bottom: 1px solid #d2d1d1;" *ngIf="eventInfo.t_customer">-->
  <!--    <div class="title font-weight-bold pb-3">Thành viên</div>-->
  <!--    <div class="sub-title multi-data-cell font-weight-normal pb-1">-->
  <!--      <span class="font-weight-bold">Cấp thành viên</span> Bạch kim-->
  <!--    </div>-->
  <!--    <div class="sub-title multi-data-cell font-weight-normal pb-1"><span-->
  <!--      class="font-weight-bold">Ngày tham gia</span>16 Tháng 6, 2018-->
  <!--    </div>-->
  <!--    <div class="sub-title multi-data-cell font-weight-normal pb-1"><span-->
  <!--      class="font-weight-bold">Đã chi tiêu</span>12.563.500đ-->
  <!--    </div>-->
  <!--  </div>-->

  <div
    class="p-3"
    style="border-bottom: 1px solid #d2d1d1"
    *ngIf="!eventInfo.t_customer"
  >
    <div class="title font-weight-bold pb-2">Hồ sơ</div>
    <button class="btn btn-primary btn-sm" (click)="handleShowPersonForm()">
      Tạo hồ sơ khách hàng
    </button>
  </div>
  <div class="p-3">
    <div class="title font-weight-bold pb-2">Lịch sử 10 lần gần nhất</div>
    <div>
      <div
        class="pb-2 handle"
        style="display: grid; grid-template-columns: 1fr 2fr"
        *ngFor="let event of customerHistory"
      >
        <img
          class="sub-img"
          src="{{ deviceHost + event.url }}"
          (click)="changeImg(event, top)"
        />
        <div class="pl-3 pt-3">
          <div class="sub-title pb-2">
            {{ event.createddate | date: "dd-MM-YYYY" }}
          </div>
          <div class="title font-weight-bold pb-2">
            {{ event.t_cameragroup.t_branch.name }}
          </div>
          <div class="sub-title sub-style pb-2">
            {{ event.createddate | date: "HH:mm:ss" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
