import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subscription } from 'rxjs';
import { Cameragroup } from 'src/app/models/cameragroup';
import { Option } from 'src/app/models/option';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SocketService } from 'src/app/services/socket.service';
import {
  API,
  DEVICE_IMG_HOST,
  FUNC,
  REPLACEMENT,
} from 'src/environments/environment';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss'],
})
export class NotifyComponent implements OnInit, OnDestroy {
  @ViewChild('customNotification', { static: true }) customNotificationTmpl;
  public eventSubscription: Subscription;
  public currentUserInfo = null;
  public userBranchChildren = [];
  public userCameraGroups = [];
  public currentUserSubscription: Subscription;
  public freqGuess = REPLACEMENT.frequentGuess;
  public deviceHost = DEVICE_IMG_HOST;
  public typeList = [];
  public prevNotify = null;
  public browserAllowNotify = false;
  public notifyTimeout = 5000;
  public notifyTimer;
  public notifySound;
  constructor(
    private notifierService: NotifierService,
    private socketService: SocketService,
    private authService: AuthService,
    private apiService: ApiService,
    private router: Router,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.notifySound = new Audio('assets/sounds/ios_notification.mp3');
    this.currentUserInfo = this.authService.getUserInfo();
    this.getTypes();
    if (this.currentUserInfo) {
      if (this.currentUserInfo.branchid) this.getUserBranchChildren();
      else this.getCameragroups();
    }
    this.currentUserSubscription = this.authService
      .onChangeUser()
      .subscribe((newUser) => {
        if (newUser) {
          this.currentUserInfo = newUser;
          this.getTypes();
          if (newUser.branchid) this.getUserBranchChildren();
          else this.getCameragroups();
        } else {
          this.notifierService.hideAll();
        }
      });
    this.unsubscribeEvent();
    this.eventSubscription = this.socketService
      .onNewEvent()
      .subscribe((data) => {
        if (data && this.authService.getToken()) {
          let content = JSON.parse(data.note);
          let type = this.getNotifyType(content.person_type);
          let personType = this.typeList.find(
            (type) => type.code == content.person_type
          );
          let cameragroup = this.userCameraGroups.find(
            (cameragroup) => cameragroup.id == data.cameragroupid
          );
          content.cameragroup = cameragroup;
          if (personType) content.personType = personType.name;
          if (!this.prevNotify || this.prevNotify.id != data.id) {
            this.prevNotify = data;
            if (
              !this.currentUserInfo.branchid ||
              (this.currentUserInfo.branchid &&
                this.userCameraGroups
                  .map((cameragroup) => cameragroup.id)
                  .includes(Number(data.cameragroupid)))
            ) {
              if (document.hasFocus()) {
                this.showNotification(content, type, data.id);
              }
              this.generateOSNotification(data.id, personType, content);
            }
          }
        }
      });
    this.browserAllowNotify = this.checkNotificationPermission();
  }

  ngOnDestroy() {
    this.unsubscribeEvent();
    this.unsubscribeUser();
  }

  public unsubscribeEvent() {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
      this.eventSubscription = null;
    }
  }

  public unsubscribeUser() {
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }
  }

  public showNotification(msg: any, type: string, msgid: string) {
    this.notifierService.show({
      id: msgid,
      message: msg,
      type: type,
      template: this.customNotificationTmpl,
    });
  }

  public getUserBranchChildren() {
    this.apiService
      .get<any[]>(API.view + FUNC.getBranchChildren, {
        parent: this.currentUserInfo.branchid,
      })
      .subscribe((branches) => {
        if (branches) {
          this.userBranchChildren = branches.map((branch) => branch.id);
          this.getBranchCameragroups();
        }
      });
  }

  public getBranchCameragroups() {
    let cameragroupQuery = {
      op: 'AND',
      val: [
        {
          model: 't_cameragroup',
          col: 'branchid',
          op: 'in',
          val: this.userBranchChildren,
        },
      ],
    };
    this.apiService
      .get<Cameragroup[]>(API.cameraGroup, {
        query: JSON.stringify(cameragroupQuery),
      })
      .subscribe((cameragroups) => {
        if (cameragroups) {
          this.userCameraGroups = cameragroups;
        }
      });
  }

  public getCameragroups() {
    this.apiService
      .get<Cameragroup[]>(API.cameraGroup)
      .subscribe((cameragroups) => {
        if (cameragroups) {
          this.userCameraGroups = cameragroups;
        }
      });
  }

  public getTypes() {
    let typeInclude = [
      {
        model: 't_optiongroup',
        include: [],
      },
    ];
    let typeQuery = {
      op: 'AND',
      val: [
        {
          model: 't_optiongroup',
          col: 'code',
          op: '=',
          val: 'customertypeoptionid',
        },
      ],
    };
    this.apiService
      .get<Option[]>(API.options, {
        include: JSON.stringify(typeInclude),
        query: JSON.stringify(typeQuery),
      })
      .subscribe((types) => {
        if (types) {
          this.typeList = types;
        }
      });
  }

  public askNotificationPermission() {
    // function to actually ask the permissions
    this.handlePermission();
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      console.log('This browser does not support notifications.');
    } else {
      if (this.checkNotificationPromise()) {
        Notification.requestPermission().then((permission) => {
          this.handlePermission();
        });
      } else {
        Notification.requestPermission(function (permission) {
          this.handlePermission();
        });
      }
    }
  }

  public checkNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  }

  public checkNotificationPermission() {
    if (window.Notification && Notification.permission === 'granted')
      return true;
    else return false;
  }

  public handlePermission() {
    // set the button to shown or hidden, depending on what the user answers
    if (
      Notification.permission === 'denied' ||
      Notification.permission === 'default'
    ) {
      this.browserAllowNotify = false;
    } else {
      this.browserAllowNotify = true;
    }
  }

  public generateOSNotification(eventid, personType, content) {
    let notification = new Notification(personType.name, {
      body: content.person ? content.person.name : this.freqGuess,
      data: content.person ? content.personType : null,
    });
    this.notifySound.play();
    notification.onclick = (event) => {
      this.ngZone.run(() =>
        this.router.navigate(['/analysis'], {
          fragment: 'Nhận dạng',
          queryParams: {
            event: eventid,
            tab: content.person_type,
            branch: content.cameragroup.branchid,
          },
        })
      );
      window.focus();
      clearTimeout(this.notifyTimer);
      this.notifyTimer = null;
    };
    this.notifyTimer = setTimeout(() => {
      notification.close();
    }, this.notifyTimeout);
  }

  public getNotifyType(customerType) {
    switch (customerType) {
      case 'VIP':
        return 'success';
      case 'BLK':
        return 'error';
      default:
        return 'info';
    }
  }
}
