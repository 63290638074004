import { Component, Input, OnInit } from '@angular/core';
import { Branch } from 'src/app/models/branch';
import { Cameragroup } from 'src/app/models/cameragroup';
import { SettingOption } from 'src/app/models/setting-option';
import { Ward } from 'src/app/models/ward';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { API, FUNC } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-analysis-customer',
  templateUrl: './analysis-customer.component.html',
  styleUrls: ['./analysis-customer.component.scss'],
})
export class AnalysisCustomerComponent implements OnInit {
  @Input() active: string;
  public eventDetails;
  public eventDetailsWithWatchtime;
  public chosenTimeOption: SettingOption;
  public chosenWard: Ward;
  public chosenBranch: Branch;
  public chosenCameraGroup: Cameragroup;
  public defaultBranch: number;
  public dateFormat = 'YYYY-MM-DD HH:mm:ss[.]SSS';
  public fromTime = moment().startOf('day').format(this.dateFormat);
  public toTime = moment().endOf('day').format(this.dateFormat);
  constructor(
    private authService: AuthService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    let userInfo = this.authService.getUserInfo();
    this.defaultBranch = userInfo.branchid ? userInfo.branchid : 0;
  }

  public getEventDetails() {
    if (this.chosenBranch && this.chosenTimeOption) {
      let params = {
        ward: this.chosenWard ? this.chosenWard.id : 0,
        branch: this.chosenBranch ? this.chosenBranch.id : this.defaultBranch,
        stall: this.chosenCameraGroup ? this.chosenCameraGroup.id : 0,
        from_time: this.fromTime,
        to_time: this.toTime,
      };
      this.apiService
        .get<any>(API.view + FUNC.getEventDetails, params)
        .subscribe((data) => {
          if (data) {
            this.eventDetails = data;
          }
        });
    }
  }

  public handleClickedWard(selectedWard: Ward) {
    this.chosenWard = selectedWard;
    if (this.chosenTimeOption) {
      this.getEventDetails();
      this.getEventDetailsWithWatchtime();
    }
  }

  public handleClickedTimeOption(selectTimeOption: SettingOption) {
    this.chosenTimeOption = selectTimeOption;
    this.getEventDetails();
    this.getEventDetailsWithWatchtime();
  }

  public handleClickedBranch(branch: Branch) {
    this.chosenBranch = branch;
    if (this.chosenTimeOption) {
      this.getEventDetails();
      this.getEventDetailsWithWatchtime();
    }
  }

  public handleClickedCameragroup(cameraGroup: Cameragroup) {
    this.chosenCameraGroup = cameraGroup;
    if (this.chosenTimeOption) {
      this.getEventDetails();
      this.getEventDetailsWithWatchtime();
    }
  }

  public getEventDetailsWithWatchtime() {
    if (this.chosenBranch && this.chosenTimeOption) {
      let params = {
        ward: this.chosenWard ? this.chosenWard.id : 0,
        place: this.chosenBranch ? this.chosenBranch.id : this.defaultBranch,
        stall: this.chosenCameraGroup ? this.chosenCameraGroup.id : 0,
        from_time: this.fromTime,
        to_time: this.toTime,
      };
      this.apiService
        .get<any[]>(API.view + FUNC.getEventDetailsWithWatchtime, params)
        .subscribe((data) => {
          if (data) {
            this.eventDetailsWithWatchtime = data;
          }
        });
    }
  }

  public handleFromDate(date) {
    this.fromTime = moment(date, 'DD-MM-YYYY')
      .startOf('day')
      .format(this.dateFormat);
    this.getEventDetails();
    this.getEventDetailsWithWatchtime();
  }

  public handleToDate(date) {
    this.toTime = moment(date, 'DD-MM-YYYY')
      .endOf('day')
      .format(this.dateFormat);
    this.getEventDetails();
    this.getEventDetailsWithWatchtime();
  }
}
