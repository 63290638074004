import { Component, Input, OnInit } from '@angular/core';
import { REPLACEMENT } from 'src/environments/environment';

@Component({
  selector: 'app-monitor-info',
  templateUrl: './monitor-info.component.html',
  styleUrls: ['./monitor-info.component.scss'],
})
export class MonitorInfoComponent implements OnInit {
  @Input() selectedMonitor;
  public noText = REPLACEMENT.noText;
  constructor() {}

  ngOnInit(): void {}
}
