import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange } from '@angular/core';
import { Camera } from 'src/app/models/camera';
import { ApiService } from 'src/app/services/api.service';
import { API, REPLACEMENT, SOCKET_EVENT } from 'src/environments/environment';
import * as moment from 'moment';
import { SocketService } from 'src/app/services/socket.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-camera-info',
  templateUrl: './camera-info.component.html',
  styleUrls: ['./camera-info.component.scss']
})
export class CameraInfoComponent implements OnInit, OnChanges, OnDestroy {

  @Input() cameraInfo: Camera;
  public noText = REPLACEMENT.noText;
  public isOnline: boolean = false;
  private setInterval;
  private timeLimit = REPLACEMENT.deviceTimeLimit;
  private timeout = REPLACEMENT.deviceTimeout;
  private subscription: Subscription;
  constructor(
    private apiService: ApiService,
    private socketService: SocketService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}) {
    if (changes['cameraInfo'] && changes['cameraInfo'].currentValue) {
      if (this.setInterval) {
        clearInterval(this.setInterval);
      }
      if(this.subscription) {
        this.subscription.unsubscribe();
      }
      this.subscription = this.socketService.onNewDeviceStatus().subscribe(deviceStatus => {
        if(deviceStatus) {
          this.checkDeviceStatus(deviceStatus);
        }
      })
      this.isOnline = false;
      this.getDeviceStatus();
      this.setInterval = setInterval(() => {
        this.getDeviceStatus();
      }, this.timeout * 1000);
    }
  }

  ngOnDestroy() {
    if (this.setInterval) {
      clearInterval(this.setInterval);
    }
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public getDeviceStatus() {
    this.socketService.getDeviceStatus(this.cameraInfo.code);
  }

  public checkDeviceStatus(status) {
    let timeDiff = moment().diff(moment(status.timestamp), 'seconds')
    if (timeDiff > this.timeLimit) {
      this.isOnline = false;
    }
    else {
      this.isOnline = true;
    }
  }

}
