import {Component, Input, OnDestroy, OnInit, SimpleChange} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {Branch} from 'src/app/models/branch';
import {EventCustomer} from 'src/app/models/event-customer';
import {Option} from 'src/app/models/option';
import {SettingOption} from 'src/app/models/setting-option';
import {ApiService} from 'src/app/services/api.service';
import {API} from 'src/environments/environment';
import {animate, state, style, transition, trigger} from "@angular/animations";

import * as moment from 'moment';

@Component({
  selector: 'app-identification',
  templateUrl: './identification.component.html',
  styleUrls: ['./identification.component.scss'],
  // animations: [
  //   trigger('fade', [
  //     state('in', style({
  //       transform: 'translate3d(0,0,0)',
  //     })),
  //     state('out', style({
  //       transform: 'translate3d(10%, 0, 0)',
  //     })),
  //     transition('in => out', animate('400ms ease-in-out')),
  //     transition('out => in', animate('400ms ease-in-out'))
  //   ]),
  // ]
})
export class IdentificationComponent implements OnInit, OnDestroy {
  @Input() active: string;
  public selectedTab: Option;
  public selectedEvent: EventCustomer;
  public selectedBranch: Branch;
  public selectedTimeOption: SettingOption;
  public activeSideBar = false;
  public textSearch: string = '';
  public showAddForm = false;
  public selectedAvatar: string;
  public selectedFaceuid: string;
  public paramsSubscription: Subscription;

  public vipList = [];
  public blackList = [];
  public onChangeList: string = 'Khách quen';
  menuState: string = 'out';
  showElement: boolean = false;
  showModalVar: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService
  ) {
  }

  ngOnInit(): void {
    this.paramsSubscription = this.route.queryParams.subscribe(params => {
      if (params.event) {
        this.getEventCustomer(params.event);
      }
    })
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }

  public handleFilterTab(newTab: Option) {
    this.selectedTab = newTab;
  }

  public handleClickedEvent(clickedEvent: EventCustomer) {
    this.selectedEvent = clickedEvent;
    this.activeSideBar = true;
    // this.menuState = 'in'
  }

  public onClose() {
    this.activeSideBar = false;
    // this.menuState = 'out';
  }

  public handleChangeBranch(clickedBranch: Branch) {
    this.selectedBranch = clickedBranch;
  }

  public handleChangeTimeOption(clickedOption: SettingOption) {
    this.selectedTimeOption = clickedOption
  }

  public handleTextSearch(text: string) {
    this.textSearch = text;
  }

  public showDetailForm() {
    this.showAddForm = true;
  }

  public hideDetailForm(event: boolean) {
    this.showAddForm = event;
  }

  public handleShowPersonForm(personInfo: any) {
    this.selectedAvatar = personInfo.forcedAvatar;
    this.selectedFaceuid = personInfo.faceuid;
    this.showDetailForm();
  }

  public getEventCustomer(eventid) {
    let eventInclude = [
      {
        model: 't_cameragroup',
        include: [
          {
            model: 't_branch',
            include: []
          }
        ]
      },
      {
        model: 't_customer',
        include: [
          {
            model: 't_option',
            as: 'type',
            include: []
          },
          {
            model: 't_option',
            as: 'cust_gender',
            include: []
          }
        ]
      },
      {
        model: 't_option',
        include: []
      }
    ];
    this.apiService.get<EventCustomer>(API.eventCustomers + "/" + eventid, {include: JSON.stringify(eventInclude)}).subscribe(event => {
      if (event) {
        let eventNote = JSON.parse(event.note);
        if (eventNote.frequent_info) {
          let now = moment();
          let eventTime = moment(eventNote.frequent_info.since_date);
          eventNote.frequent_info.nrof_day = now.diff(eventTime, 'days');
        }
        event.note = eventNote
        this.handleClickedEvent(event);
        // this.selectedEvent = event;
      }
    })
  }

  showElementFunc(e) {
    this.showElement = e;
    this.showModalVar = true;
  }

  showModalFunc(e) {
    this.showModalVar = false;
  }
}
