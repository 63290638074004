<div class="pt-2 full-width full-height d-flex flex-column">
  <!-- <div class="box-header title font-weight-bold">Báo cáo</div> -->
  <div class="box-body" style="height: 44vh; overflow: auto">
    <table id="reportTable" class="table table-striped">
      <thead>
        <tr class="table-secondary">
          <th scope="col" *ngFor="let column of columns">
            <div class="col-label">{{ column }}</div>
          </th>
          <!-- <th scope="col">
            <div class="col-label">Ngày</div>
          </th>
          <th scope="col">
            <div class="col-label">Số khách</div>
          </th>
          <th scope="col">
            <div class="col-label">Nam</div>
          </th>
          <th scope="col">
            <div class="col-label">Nữ</div>
          </th>
          <th scope="col">
            <div class="col-label">Trẻ em</div>
          </th>
          <th scope="col">
            <div class="col-label">Thanh thiếu niên</div>
          </th>
          <th scope="col">
            <div class="col-label">Trung niên</div>
          </th>
          <th scope="col">
            <div class="col-label">Người cao tuổi</div>
          </th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let event of eventList">
          <!-- <td>{{event.daytime}}</td> -->
          <td *ngFor="let column of columns">
            {{ getDataValue(event, column) }}
          </td>
          <!-- <td>{{ event.daytime | date: "dd-MM-YYYY" }}</td>
          <td>{{ event.custno }}</td>
          <td>{{ event.nam }}</td>
          <td>{{ event.nu }}</td>
          <td>{{ event.tre_em }}</td>
          <td>{{ event.thanh_nien }}</td>
          <td>{{ event.trung_nien }}</td>
          <td>{{ event.cao_tuoi }}</td> -->
        </tr>
      </tbody>
    </table>
  </div>
  <!-- <app-pagination [page]='currentPage' [itemsPerPage]='eventsPerPage' [total]='noEvent' (clickedPage)="changePage($event)"></app-pagination> -->
</div>
