import { HttpClient } from '@angular/common/http';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
} from '@angular/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { API, BASE_HOST, FUNC } from 'src/environments/environment';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-report-info',
  templateUrl: './report-info.component.html',
  styleUrls: ['./report-info.component.scss'],
})
export class ReportInfoComponent implements OnInit, OnChanges {
  @Input() reportInfo;
  public eventDetails = [];
  public checkedEventIds = [];
  public spinnerName = 'spinner';
  public allowExport = false;
  public currentParams;
  constructor(
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes['reportInfo'] && changes['reportInfo'].currentValue) {
      this.eventDetails.length = 0;
      this.allowExport = false;
    }
  }

  public handleSubmit(params: any) {
    let reportParams = this.formatParams(params);
    this.getEventDetails(reportParams);
  }

  public formatParams(params: any) {
    let orders = [];
    if (Object.values(this.reportInfo.columns).includes('daytime')) {
      orders.push({ name: 'daytime', order: 'desc' });
    }
    if (Object.values(this.reportInfo.columns).includes('cameragroup')) {
      orders.push({ name: 'cameragroup', order: 'asc' });
    }
    let result = {
      ward: 0,
      place: params.branchid,
      'stalls[]': params.cameragroups.map((cameragroup) => cameragroup.id),
      from: moment(params.startTime.singleDate.formatted, 'DD-MM-YYYY').format(
        'YYYY-MM-DD'
      ),
      to: moment(params.endTime.singleDate.formatted, 'DD-MM-YYYY').format(
        'YYYY-MM-DD'
      ),
      orders: JSON.stringify(orders),
    };
    this.currentParams = params;
    return result;
  }

  public getEventDetails(params: any) {
    this.spinner.show(this.spinnerName);
    this.allowExport = false;
    this.apiService
      .get<any[]>(API.view + this.reportInfo.function, params)
      .subscribe((eventData) => {
        if (eventData) {
          eventData.forEach((event) => {
            if (event.daytime) {
              event.daytime = moment(event.daytime).format('DD-MM-YYYY');
            }
            event.custno = Number(event.custno);
            event.nam = Number(event.nam);
            event.nu = Number(event.nu);
            event.sumwatchtime = Number(event.sumwatchtime);
            event.quan_tam = Number(event.quan_tam);
            event.tre_em = Number(event.tre_em);
            event.thanh_nien = Number(event.thanh_nien);
            event.trung_nien = Number(event.trung_nien);
            event.cao_tuoi = Number(event.cao_tuoi);
          });
          this.spinner.hide(this.spinnerName);
          this.eventDetails = eventData;
          // this.formatEventData(eventData);
          this.allowExport = true;
        }
      });
  }

  // public formatEventData(eventData: any[]) {
  //   this.checkedEventIds.length = 0;
  //   this.eventDetails.length = 0;
  //   let result = [];
  //   eventData.forEach(ev => {
  //     let eventDateIdx = result.findIndex(event => event.daytime == moment(ev.daytime).format('DD-MM-YYYY'));
  //     // let eventDateIdx = result.findIndex(event => event.daytime == ev.daytime);
  //     if (eventDateIdx < 0) {
  //       let newEvent = this.newEventDay(ev.daytime);
  //       result.push(newEvent);
  //     }
  //     else {
  //       this.addEventStat(result, eventDateIdx, ev);
  //     }
  //   })
  //   // console.log(result);
  //   this.eventDetails = result;
  // }

  // public newEventDay(day: string) {
  //   return {
  //     daytime: moment(day).format('DD-MM-YYYY'),
  //     // daytime: day,
  //     nam: 0,
  //     nu: 0,
  //     tre_em: 0,
  //     thanh_nien: 0,
  //     trung_nien: 0,
  //     cao_tuoi: 0
  //   }
  // }

  // public addEventStat(eventList, eventIdx, event) {
  //   // switch(event.attrcode) {
  //   //   case 'gender_male':
  //   //     eventList[eventIdx].nam += +event.val;
  //   //     break;
  //   //   case 'gender_female':
  //   //     eventList[eventIdx].nu += +event.val;
  //   //     break;
  //   //   case 'age_child':
  //   //     eventList[eventIdx].tre_em += +event.val;
  //   //     break;
  //   //   case 'age_teenager':
  //   //     eventList[eventIdx].thanh_nien += +event.val;
  //   //     break;
  //   //   case 'age_middleage':
  //   //     eventList[eventIdx].trung_nien += +event.val;
  //   //     break;
  //   //   case 'age_elder':
  //   //     eventList[eventIdx].cao_tuoi += +event.val;
  //   //     break;
  //   // }
  //   eventList[eventIdx].nam += +event.nam;
  //   eventList[eventIdx].nu += +event.nu;
  //   eventList[eventIdx].tre_em += +event.tre_em;
  //   eventList[eventIdx].thanh_nien += +event.thanh_nien;
  //   eventList[eventIdx].trung_nien += +event.trung_nien;
  //   eventList[eventIdx].cao_tuoi += +event.cao_tuoi;
  // }

  public handleExport() {
    let params = {
      template: this.reportInfo.template,
      data: {
        from: this.currentParams.startTime.singleDate.formatted,
        to: this.currentParams.endTime.singleDate.formatted,
        stalls: this.currentParams.cameragroups
          .map((cameragroup) => cameragroup.name)
          .join(', '),
        reports: this.eventDetails,
      },
    };
    this.http
      .post(BASE_HOST + API.exportExcel, params, {
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe((data) => {
        if (
          data.status == 200 &&
          data.body.type == 'application/octet-stream'
        ) {
          saveAs(data.body, this.reportInfo.name + '.xlsx');
        } else {
          this.alertService.informError(
            'Lỗi khi xuất file excel, xin vui lòng liên hệ người quản trị'
          );
        }
      });
  }
}
