<div class="full-height full-width d-flex">
  <div class="box p-3" style="overflow: auto; width: 25%; min-width: 200px">
    <app-tree-view
      [useItemTypeToRoute]="true"
      [itemType]="'report'"
      [defaultItem]="selectedReport"
      [autoSelectFirst]="true"
      [itemList]="reportList"
      (changedSelectedItem)="handleChangeReport($event)"
    ></app-tree-view>
  </div>
  <div class="flex-grow-1 box p-3 ml-2" style="width: 75%">
    <app-report-info [reportInfo]="selectedReport"></app-report-info>
  </div>
</div>
