<div class="box">
  <div class="list d-flex flex-column">
    <div class="list-header">
      <div class="pt-4 title-header">Danh sách camera</div>
      <div class="input-group pt-4">
        <input type="text" class="form-control" placeholder="Tìm kiếm thiết bị" [(ngModel)]="searchText" (keydown.enter)="getCameras()">
        <button class="btn custom-button" (click)="getCameras()"><span class="custom-icon"><i class="fa fa-search"></i></span></button>
      </div>
    </div>
    <div class="list-body hidden-scrollbar d-flex flex-column flex-grow-1" style="height: 100%;overflow:auto;">
      <div class="pt-2" style="flex: 1 1 auto;height:0;">
        <div class="list-group pt-2" *ngFor="let cameraGroup of cameraGroupList">
          <div class="list-title handle d-flex flex-row" (click)="onShow(cameraGroup.id)">
            <div class="title-group flex-grow-1">{{cameraGroup.name}}</div>
            <span class="pl-5 pr-4"><i class="fa list-title-icon"
                                                    [ngClass]="openingCameraGroup == cameraGroup.id?'fa-caret-up':'fa-caret-down'"></i></span>
          </div>
          <div class="list-content" *ngIf="openingCameraGroup == cameraGroup.id">
            <div class="list-content-item" *ngFor="let camera of cameraGroup.t_cameras">
              <div class="list-content-title handle" (click)="handleClickedCamera(camera, cameraGroup)" [ngClass]="{'active': selectedCamera && selectedCamera.id == camera.id}">
                <div class="title pl-3">{{camera.name}}</div>
                <div class="sub-title ml-3">123 khách</div>
              </div>
            </div>
          </div>
        </div>
        <div class="list-group pt-2 pb-2" *ngIf="cameraGroupList.length == 0">
          <a>{{noData}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
