<div class="layout">
  <div class="layout-header">
    <app-nav-bar [changeICon]="isShowSideBar" (showSideBar)="handleShowSideBar()"></app-nav-bar>
  </div>
  <div class="layout-content">
    <div class="row full-height">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 column full-height">
        <app-toggle-sidebar [showSideBar]="isShowSideBar"></app-toggle-sidebar>
        <div class="list-events full-height">
          <app-list-camera class="position-relative" (clickedCamera)="handleClickedCamera($event)"
                           (clickedCameraGroup)="handleClickedCameraGroup($event)"></app-list-camera>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 column">
        <div class="d-flex flex-column full-height">
          <div>
            <app-view [imageUrl]="currentDeviceImg"></app-view>
          </div>
          <div class="flex-grow-1">
            <app-info [selectedCameraGroup]="chosenCameraGroup"></app-info>
          </div>
        </div>
        <!--        <div class="">-->
        <!--          <div class="mb-2 mt-4 mr-3 camera-info flex-grow-1">-->
        <!--            <app-view></app-view>-->
        <!--          </div>-->
        <!--          <div class="mb-2 mt-2 mr-3 list-events">-->
        <!--            <app-info [selectedCameraGroup]="chosenCameraGroup"></app-info>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 column full-height">
        <div class="d-flex flex-column full-height">
          <div class="mb-2 mt-4 mr-3 camera-info">
            <app-camera-info [cameraInfo]="chosenCamera"></app-camera-info>
          </div>
          <div class="mb-2 mt-2 mr-3 list-events flex-grow-1">
            <app-list-events class="position-relative"></app-list-events>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
