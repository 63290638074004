import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Camera } from 'src/app/models/camera';
import { Option } from 'src/app/models/option';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-branch-info',
  templateUrl: './branch-info.component.html',
  styleUrls: ['./branch-info.component.scss']
})
export class BranchInfoComponent implements OnInit, OnDestroy, OnChanges {

  @Input() showBranchMasterForm = false;
  public frmObject: FormGroup;
  public paramsSubscription: Subscription;
  public itemType = 'branch';
  public itemId;
  public currentBranchId = null;
  public cameraTypeList = [{id: null, name: 'Chọn loại camera'}];
  public selectedTab = 'info';
  public cameraList = [];
  public showCameraForm = false;
  public cameraFormGroup = new FormGroup({
    code: new FormControl(),
    name: new FormControl(),
    streamlink: new FormControl(),
    lat: new FormControl(),
    lon: new FormControl(),
    cameratypeoptid: new FormControl(),
    cameragroupid: new FormControl(),
    deactiveyn: new FormControl(),
    // empid: new FormControl(),
  })
  public selectedCameraId = null;
  @Output() changedItem = new EventEmitter();
  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.paramsSubscription = this.route.queryParams.subscribe(params => {
      if (params.branch) {
        this.itemType = 'branch';
        this.itemId = params.branch;
        this.currentBranchId = params.branch;
      }
      else if (params.cameragroup) {
        this.itemType = 'cameragroup';
        this.itemId = params.cameragroup;
        if (params.camera) {
          this.selectedTab = 'device';
          this.selectedCameraId = params.camera;
        }
        else {
          this.showCameraForm = false;
          this.selectedCameraId = null;
        }
      }
    })
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}) {
    if (changes['showBranchMasterForm']) {
      this.newBranchForm();
      this.currentBranchId = null;
    }
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }

  public newBranchForm() {
    this.itemType = 'branch';
    this.itemId = null;
  }

  public newCameragroupForm() {
    this.itemType = 'cameragroup';
    this.itemId = null;
  }

  public handleChangedItem(event: any) {
    this.changedItem.emit();
  }
}
