<div class="sidebar-menu" *ngIf="showSideBar === true">
  <div class="item home tris-handle" [routerLink]="['/']">
    <img class="home-icon"
         src="https://www.rawshorts.com/freeicons/wp-content/uploads/2017/01/blue_repicthousebase_1484336386-1.png"
         alt="">
    Trở về trang chủ
  </div>
  <div class="item tris-handle">
    <img src="https://tris.vn/landing/images/icons/tris_guard_icon.svg" alt="">
    TRIS GUARD
  </div>
  <div class="item tris-handle">
    <img src="https://tris.vn/landing/images/icons/tris_road_icon.svg" alt="">
    TRIS ROAD
  </div>
  <div class="item tris-handle">
    <img src="https://tris.vn/landing/images/icons/tris_face_icon.svg" alt="">
    FACE RECOGNITION
  </div>
  <div class="item tris-handle">
    <img src="https://tris.vn/landing/images/icons/tris_vms_icon.svg" alt="">
    TRIS VMS
  </div>
  <div class="item tris-handle">
    <img src="https://tris.vn/landing/images/icons/tris_reid_icon.svg" alt="">
    TRIS RE-ID
  </div>
</div>
