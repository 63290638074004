<div class="info">
  <form class="active p-3" [formGroup]="frmObject" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label>Chi nhánh <a class="required-field">*</a></label>
      <div class="mt-2 position-relative" *ngIf="branchList.length > 0">
        <div class="form-control handle" (click)="onClickBranch()">
          {{ selectedBranch ? selectedBranch.name : noText }}
        </div>
        <div style="width: 50%">
          <app-dropdown-list
            [dataList]="formatedBranchList"
            [saveBranch]="true"
            [isShow]="isShowBranchOption"
            [selectedBranch]="selectedBranch"
            (handleSelect)="handleSelectBranch($event)"
          ></app-dropdown-list>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label>Quầy <a class="required-field">*</a></label>
      <div class="mt-2" style="background-color: #fff">
        <ng-multiselect-dropdown
          [placeholder]="'Chọn quầy'"
          [settings]="dropdownSettings"
          [data]="cameragroupList"
          [(ngModel)]="selectedCameragroups"
          [ngModelOptions]="{ standalone: true }"
        ></ng-multiselect-dropdown>
      </div>
    </div>
    <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 5px">
      <div class="form-group">
        <label>Thời gian bắt đầu <a class="required-field">*</a></label>
        <div class="input-group mt-2">
          <input
            class="form-control"
            formControlName="startTime"
            required
            angular-mydatepicker
            name="startDate"
            #dpStart="angular-mydatepicker"
            [options]="datePickerOptions"
            (click)="dpStart.toggleCalendar()"
            [locale]="locale"
          />
          <div class="input-group-append">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="dpStart.clearDate()"
            >
              <i class="fa fa-close"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Thời gian kết thúc <a class="required-field">*</a></label>
        <div class="input-group mt-2">
          <input
            class="form-control"
            formControlName="endTime"
            required
            angular-mydatepicker
            name="endDate"
            #dpEnd="angular-mydatepicker"
            [options]="datePickerOptions"
            (click)="dpEnd.toggleCalendar()"
            [locale]="locale"
          />
          <div class="input-group-append">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="dpEnd.clearDate()"
            >
              <i class="fa fa-close"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <button
        type="submit"
        class="btn btn-sm btn-secondary mt-2 mr-2 pull-left full-width"
      >
        Xem báo cáo
      </button>
      <button
        type="button"
        class="btn btn-sm btn-secondary mt-2 pull-right full-width"
        (click)="onExport()"
        [disabled]="!exportable"
      >
        Xuất báo cáo
      </button>
    </div>
  </form>
</div>
