import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
} from '@angular/core';
import { Branch } from 'src/app/models/branch';
import { Event } from 'src/app/models/event';
import { PagedItem } from 'src/app/models/paged-item';
import { SettingOption } from 'src/app/models/setting-option';
import { Stall } from 'src/app/models/stall';
import { Ward } from 'src/app/models/ward';
import { ApiService } from 'src/app/services/api.service';
import { API, FUNC, REPLACEMENT } from 'src/environments/environment';

@Component({
  selector: 'app-table-events',
  templateUrl: './table-events.component.html',
  styleUrls: ['./table-events.component.scss'],
})
export class TableEventsComponent implements OnInit, OnChanges {
  @Input() chosenWard: Ward;
  @Input() chosenBranch: Branch;
  @Input() chosenStall: Stall;
  @Input() chosenTimeOption: SettingOption;
  @Input() fromTime;
  @Input() toTime;
  public eventList = [];
  public eventShowList = [];
  public eventsPerPage = 10;
  public currentPage: number = 1;
  public eventOrder = [
    ['daytime', 'DESC'],
    ['hourtime', 'DESC'],
  ];
  public noStatistic = REPLACEMENT.noStatistic;
  public noText = REPLACEMENT.noText;
  public noEvent: number = 0;
  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    // this.getEvents();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes['chosenWard'] &&
      changes['chosenWard'].currentValue &&
      changes['chosenWard'].previousValue
    ) {
      this.getEvents();
    }
    if (changes['chosenBranch'] && changes['chosenBranch'].currentValue) {
      this.getEvents();
    }
    if (
      changes['chosenStall'] &&
      changes['chosenStall'].currentValue &&
      changes['chosenStall'].previousValue
    ) {
      this.getEvents();
    }
    if (
      changes['chosenTimeOption'] &&
      changes['chosenTimeOption'].currentValue &&
      changes['chosenTimeOption'].previousValue
    ) {
      this.getEvents();
    }
  }

  public getEvents() {
    let params = {
      ward: this.chosenWard ? this.chosenWard.id : 0,
      place: this.chosenBranch ? this.chosenBranch.id : 0,
      stall: this.chosenStall ? this.chosenStall.id : 0,
    };
    if (this.fromTime && this.toTime) {
      params['from_time'] = this.fromTime;
      params['to_time'] = this.toTime;
    } else {
      params['timerange'] = this.chosenTimeOption
        ? this.chosenTimeOption.value
        : 1;
    }
    this.apiService
      .get<any>(API.view + FUNC.getEvents, params)
      .subscribe((events) => {
        if (events) {
          events.map((event) => {
            event.custno = +event.custno;
            event.sumwatchtime = +event.sumwatchtime;
            event.interestedcustno = +event.interestedcustno;
          });
          this.eventList = events;
          this.noEvent = events.length;
          this.changePage(1);
        }
      });
  }

  changePage(page: number) {
    this.currentPage = page;
    let nextBeginItem = (this.currentPage - 1) * this.eventsPerPage;
    this.eventShowList = this.eventList.slice(
      nextBeginItem,
      nextBeginItem + this.eventsPerPage
    );
  }

  public sortEventTable(type: string, order: string) {
    let sortAttr;
    switch (type) {
      case 'time':
        sortAttr = ['daytime', 'hourtime'];
        break;
      case 'place':
        sortAttr = ['branch'];
        break;
      case 'stall':
        sortAttr = ['cameragroup'];
        break;
      case 'custno':
        sortAttr = ['custno'];
        break;
      case 'interest':
        sortAttr = ['interestedcustno'];
        break;
    }
    this.eventShowList = this.sortArrayObject(
      this.eventShowList,
      sortAttr,
      order
    );
  }

  public sortArrayObject(arr: any[], attr: string[], order: string) {
    let result = [...arr];
    result.sort((item1, item2) => {
      return this.sortByAttr(item1, item2, attr, order);
    });
    return result;
  }

  public sortByAttr(item1: any, item2: any, attr: string[], order) {
    let sortOrder;
    for (let i = 0; i < attr.length; i++) {
      let sortAttr = attr[i];
      if (order == 'ASC') {
        sortOrder = this.compare(item1[sortAttr], item2[sortAttr]);
      } else {
        sortOrder = -this.compare(item1[sortAttr], item2[sortAttr]);
      }
      if (sortOrder == 0) {
        continue;
      } else {
        break;
      }
    }
    return sortOrder;
  }

  public compare(a, b) {
    if (a > b) return 1;
    if (a < b) return -1;
    if ((a = b)) return 0;
  }
}
