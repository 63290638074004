import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Branch } from 'src/app/models/branch';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-manage-branch',
  templateUrl: './manage-branch.component.html',
  styleUrls: ['./manage-branch.component.scss']
})
export class ManageBranchComponent implements OnInit, OnDestroy {

  public branchList: Branch[] = [];
  public selectedItem;
  public defaultItemId;
  public paramsSubscription: Subscription;
  public itemType = 'branch';
  public currentUser;
  public addCompany = false;
  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getUserInfo();
    this.paramsSubscription = this.route.queryParams.subscribe(params => {
      if (params.branch) {
        this.itemType = 'branch';
        this.defaultItemId = params.branch;
      }
      else if (params.cameragroup) {
        this.itemType = 'cameragroup';
        this.defaultItemId = params.cameragroup;
      }
    })
    this.getBranches();
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }

  public getBranches() {
    this.branchList.length = 0;
    let branchInclude = [
      {
        model: 't_cameragroup',
        include: []
      }
    ]
    this.apiService.get<any[]>(API.branches, {include: JSON.stringify(branchInclude)}).subscribe(branches => {
      if (branches) {
        this.formatBranchList(branches);
      }
    })
  }

  public formatBranchList(branches: any[]) {
    let tempList = [];
    branches.map(branch => {
      branch.t_branches = this.getBranchChildren(branch, branches);
      if (this.itemType == 'branch' && branch.id == this.defaultItemId) {
        this.selectedItem = branch;
        branch.isExpand = true;
      }
      if (branch.t_cameragroups && branch.t_cameragroups.length > 0) {
        branch.hasChildren = true;
        if (this.itemType == 'cameragroup') {
          let selectedCameragroup = branch.t_cameragroups.find(cameragroup => cameragroup.id == this.defaultItemId);
          if (selectedCameragroup) {
            this.selectedItem = selectedCameragroup;
            branch.isExpand = true;
          }
        }
      }
      if (branch.t_branches && branch.t_branches.length > 0) {
        branch.hasChildren = true;
        if (this.itemType == 'branch') {
          let selectedBranch = branch.t_branches.find(branch => branch.id == this.defaultItemId);
          if (selectedBranch) {
            this.selectedItem = selectedBranch;
            branch.isExpand = true;
          }
        }
        if (branch.t_branches.find(branch => branch.isExpand == true)) branch.isExpand = true;
      }
      if (!branch.parentid) tempList.push(branch);
    })
    this.branchList = [...this.branchList, ...tempList];
  }

  public getBranchChildren(parent: Branch, branchList: Branch[]) {
    let children = []
    branchList.map((branch, idx) => {
      if (branch.parentid == parent.id) {
        children.push(branch);
        // branchList.splice(idx, 1);
      }
    })
    return children;
  }

  public handleSelectBranch(selectedBranch) {
    this.selectedItem = selectedBranch;
  }

  public handleBranchInfoChanged() {
    this.getBranches();
  }

  public showAddCompanyForm() {
    this.addCompany = !this.addCompany;
  }

}
