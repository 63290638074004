import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Camera } from 'src/app/models/camera';
import { Config } from 'src/app/models/config';
import { Option } from 'src/app/models/option';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { API, REPLACEMENT } from 'src/environments/environment';

@Component({
  selector: 'app-cameragroup-detail',
  templateUrl: './cameragroup-detail.component.html',
  styleUrls: ['./cameragroup-detail.component.scss'],
})
export class CameragroupDetailComponent implements OnInit, OnChanges {
  @Output() changedCameragroup = new EventEmitter();
  @Input() selectedCameragroupId;
  @Input() parentId = null;
  public frmObject: FormGroup;
  @Input() chosenTab = 'info';
  public cameraList = [];
  public cameraTypeList = [{ id: null, name: 'Chọn loại camera' }];
  @Input() cameraId = null;
  public showCameraForm = false;
  public cameraFormGroup = new FormGroup({
    code: new FormControl({ value: null, disabled: true }),
    name: new FormControl(),
    streamlink: new FormControl(),
    cameraurl: new FormControl(),
    lat: new FormControl(),
    lon: new FormControl(),
    cameratypeoptid: new FormControl(),
    cameragroupid: new FormControl(),
    deactiveyn: new FormControl(),
    // empid: new FormControl(),
  });
  public cameraConfigForm = new FormGroup({
    stream_width: new FormControl(),
    stream_height: new FormControl(),
    capture_fps: new FormControl(),
    enable_monitor_mode: new FormControl(),
    min_face_size: new FormControl(),
    stream_fps: new FormControl(),
    stream_ffmpeg_vcodec: new FormControl(),
    rtsp_transport: new FormControl(),
    rtsp_flags: new FormControl(),
    enable: new FormControl(),
  });
  public isDrawingBoundingBox = false;
  public boundingBoxIsFinished = true;
  public boundingPoints = [];
  public canvas;
  public imageSrc;
  public drawingColor = '#ff2626';
  public cameraConfig;
  public isScaledPoints = false;
  public isCCTV = false;
  public CameraAIConfig = null;
  public CameraConfigVal = null;
  public CameraGroupAIConfig = null;
  public CameraGroupConfigVal = null;
  public userInfo;
  public errorImg = REPLACEMENT.noDeviceImg;
  public customerTypeList: Option[] = [];
  public multiSelectSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllText: 'Tất cả loại đối tượng',
    unSelectAllText: 'Bỏ chọn tất cả',

    // itemsShowLimit: 5,
  };
  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.generateFormGroup();
    this.getCameraTypes();
    this.getCustomerTypes();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes['selectedCameragroupId']) {
      if (this.selectedCameragroupId) {
        this.getCameragroupInfo();
        this.getCameras();
        this.showCameraForm = false;
      } else {
        if (this.frmObject) {
          this.resetForm();
        }
      }
    }
  }

  public resetForm() {
    this.frmObject.reset();
  }

  public generateFormGroup() {
    this.frmObject = new FormGroup({
      code: new FormControl({ value: null, disabled: true }),
      name: new FormControl(null),
      kpi_custno: new FormControl(null),
      kpi_interestedcustno: new FormControl(null),
      kpi_sumwatchtime: new FormControl(null),
      info: new FormControl(),
      deactiveyn: new FormControl('F'),
      cameratypeoptionid: new FormControl(),
      branchid: new FormControl(null),
      notifytypes: new FormControl([]),
    });
  }

  public getCameragroupInfo() {
    this.apiService
      .get<any>(API.cameraGroup + '/' + this.selectedCameragroupId)
      .subscribe((cameragroup) => {
        if (cameragroup) {
          this.parentId = cameragroup.branchid;
          this.setItemInfo(cameragroup);
          // this.getAiConfig('cameragroup');
        }
      });
  }

  public getCameras() {
    this.cameraList.length = 0;
    let cameraInclude = [
      {
        model: 't_option',
        include: [],
      },
    ];
    let cameraQuery = {
      op: 'AND',
      val: [
        {
          model: 't_camera',
          col: 'cameragroupid',
          op: '=',
          val: this.selectedCameragroupId,
        },
      ],
    };
    this.apiService
      .get<Camera[]>(API.cameras, {
        include: JSON.stringify(cameraInclude),
        query: JSON.stringify(cameraQuery),
      })
      .subscribe((cameras) => {
        if (cameras) {
          this.cameraList = cameras;
          if (this.cameraId) {
            let selectedCamera = this.cameraList.find(
              (camera) => camera.id == this.cameraId
            );
            if (selectedCamera) this.selectCamera(selectedCamera);
          }
        }
      });
  }

  public getCameraTypes() {
    this.cameraTypeList.length = 1;
    let typeInclude = [
      {
        model: 't_optiongroup',
        include: [],
      },
    ];
    let typeQuery = {
      op: 'AND',
      val: [
        {
          model: 't_optiongroup',
          col: 'code',
          op: '=',
          val: 'cameratypeoptionid',
        },
      ],
    };
    this.apiService
      .get<Option[]>(API.options, {
        include: JSON.stringify(typeInclude),
        query: JSON.stringify(typeQuery),
      })
      .subscribe((types) => {
        if (types) {
          this.cameraTypeList = [...this.cameraTypeList, ...types];
        }
      });
  }

  public setItemInfo(itemInfo: any) {
    this.frmObject.patchValue(itemInfo);
  }

  public addCameragroup() {
    this.frmObject.patchValue({ branchid: this.parentId });
    const newCameragroup = this.frmObject.value;
    newCameragroup.notifytypes = newCameragroup.notifytypes.map(
      (type) => type.code
    );
    this.apiService
      .post<any>(API.cameraGroup, newCameragroup)
      .subscribe((newCameragroup) => {
        if (newCameragroup) {
          this.router.navigate(['/manage/branch'], {
            queryParams: { cameragroup: newCameragroup.id },
          });
          this.alertService.informSuccess('Thêm thành công');
          this.changedCameragroup.emit();
        }
      });
  }

  public updateCameragroup() {
    const newCameragroupInfo = this.frmObject.value;
    newCameragroupInfo.notifytypes = newCameragroupInfo.notifytypes.map(
      (type) => type.code
    );
    this.apiService
      .put<any>(
        API.cameraGroup + '/' + this.selectedCameragroupId,
        newCameragroupInfo
      )
      .subscribe((result) => {
        if (result) {
          this.alertService.informSuccess('Cập nhật thành công');
          this.changedCameragroup.emit();
        }
      });
  }

  public deleteCameragroup() {
    this.alertService
      .confirm('Bạn có chắc muốn xóa quầy ' + this.frmObject.value.name + ' ?')
      .then((result) => {
        if (result.isConfirmed) {
          this.apiService
            .put<any>(API.cameraGroup + '/' + this.selectedCameragroupId, {
              deactiveyn: 'T',
            })
            .subscribe((result) => {
              if (result) {
                // this.selectedCameragroupId = null;
                this.getCameragroupInfo();
                this.alertService.informSuccess('Xóa thành công');
                this.changedCameragroup.emit();
              }
            });
        }
      });
  }

  public selectCamera(camera) {
    this.cameraId = camera.id;
    if (!camera.deactiveyn) camera.deactiveyn = 'F';
    this.cameraFormGroup.patchValue(camera);
    if (camera.t_option.val == 'C') this.isCCTV = true;
    else this.isCCTV = false;
    this.showCameraForm = true;
    this.getCameraConfig(camera);
    // if (this.isCCTV) this.getAiConfig('camera');
  }

  public cancelCameraForm() {
    this.showCameraForm = false;
    this.cameraId = null;
    this.isScaledPoints = false;
    this.cameraConfig = null;
    this.cameraFormGroup.reset();
    this.cameraConfigForm.reset();
    this.cancelDrawing();
  }

  public showAddCameraForm() {
    this.cameraConfig = null;
    this.cameraFormGroup.reset();
    this.cameraConfigForm.reset();
    this.cancelDrawing();
    this.showCameraForm = true;
    this.cameraId = null;
    this.cameraFormGroup.patchValue({
      code: 'A',
      cameragroupid: this.selectedCameragroupId,
    });
  }

  public onSubmit() {
    if (this.frmObject.valid) {
      if (this.selectedCameragroupId) {
        this.updateCameragroup();
      } else {
        let newCode = this.frmObject.value.code;
        if (newCode && newCode.includes('__'))
          this.alertService.informError('Mã không thể chứa kí tự "__"');
        else this.addCameragroup();
      }
    } else this.alertService.informError('Xin hãy điền đầy đủ thông tin');
  }

  public handleSelectTab(selectedTab) {
    this.chosenTab = selectedTab;
  }

  public addCamera() {
    this.apiService
      .post<Camera>(API.cameras, this.cameraFormGroup.value)
      .subscribe((newCamera) => {
        if (newCamera) {
          this.cameraId = newCamera.id;
          this.alertService.informSuccess('Thêm thành công');
          this.getCameras();
        }
      });
  }

  public updateCamera() {
    this.apiService
      .put<Camera>(
        API.cameras + '/' + this.cameraId,
        this.cameraFormGroup.value
      )
      .subscribe((result) => {
        if (result) {
          this.alertService.informSuccess('Cập nhật thành công');
          this.getCameras();
        }
      });
  }

  public submitCameraForm() {
    if (this.cameraFormGroup.valid) {
      if (this.cameraId) {
        this.updateCamera();
      } else {
        this.addCamera();
      }
      if (this.isCCTV && this.cameraConfigForm.valid) this.confirmDrawing();
      else this.alertService.informError('Xin hãy điền đầy đủ cấu hình camera');
      this.saveAIConfig('camera');
    } else this.alertService.informError('Xin hãy điền đầy đủ thông tin');
  }

  public startDrawing() {
    this.isDrawingBoundingBox = true;
    // this.boundingBoxIsFinished = false;
  }

  public cancelDrawing() {
    this.isDrawingBoundingBox = false;
    this.clearDrawing();
    this.isScaledPoints = false;
    this.loadConfig();
  }

  public getPosition(event) {
    if (this.isDrawingBoundingBox) {
      var rect = this.canvas.getBoundingClientRect();
      var x = event.clientX - rect.left;
      var y = event.clientY - rect.top;
      this.drawOnImage(x, y);
    }
  }

  public drawOnImage(x, y) {
    this.drawCoordinates(x, y);
    if (this.boundingPoints.length > 0) {
      this.drawLines(this.boundingPoints[this.boundingPoints.length - 1], [
        x,
        y,
      ]);
      if (
        Math.abs(this.boundingPoints[0][0] - x) == 0 &&
        Math.abs(this.boundingPoints[0][1] - y) == 0
      ) {
        this.boundingBoxIsFinished = true;
      } else this.boundingBoxIsFinished = false;
    }
    this.boundingPoints.push([x, y]);
  }

  public drawCoordinates(x, y) {
    if (this.canvas) {
      let ctx = this.canvas.getContext('2d');
      if (this.boundingBoxIsFinished) {
        this.boundingPoints.length = 0;
        this.clearDrawing();
        this.boundingBoxIsFinished = false;
      }
      ctx.fillStyle = this.drawingColor;
      ctx.beginPath();
      ctx.arc(x, y, 3, 0, Math.PI * 2, true);
      ctx.fill();
    }
  }
  public drawLines(from, to) {
    if (this.canvas) {
      let ctx = this.canvas.getContext('2d');
      ctx.strokeStyle = this.drawingColor;
      ctx.beginPath();
      ctx.moveTo(from[0], from[1]);
      ctx.lineTo(to[0], to[1]);
      ctx.closePath();
      ctx.stroke();
    }
  }

  public clearDrawing() {
    if (this.canvas) {
      let ctx = this.canvas.getContext('2d');
      ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
  }

  public confirmDrawing() {
    this.isDrawingBoundingBox = false;
    // this.submitCameraConfig();
  }

  public autoCorrectDrawing() {
    let firstPoint = this.boundingPoints[0];
    let lastPoint = this.boundingPoints[this.boundingPoints.length - 1];
    this.drawLines(firstPoint, lastPoint);
    this.boundingBoxIsFinished = true;
  }

  public submitCameraConfig() {
    if (this.cameraConfig && this.cameraConfig.id) this.updateCameraConfig();
    else this.addCameraConfig();
  }

  public loadBoundingPoints() {
    if (this.boundingPoints.length > 0) {
      if (!this.isScaledPoints) {
        let scaledPoints = this.scalePointsToWeb();
        this.boundingPoints = scaledPoints;
        this.isScaledPoints = true;
      }
      this.boundingBoxIsFinished = false;
      for (let i = 0; i < this.boundingPoints.length; i++) {
        this.drawCoordinates(
          this.boundingPoints[i][0],
          this.boundingPoints[i][1]
        );
        if (i > 0)
          this.drawLines(
            [this.boundingPoints[i - 1][0], this.boundingPoints[i - 1][1]],
            [this.boundingPoints[i][0], this.boundingPoints[i][1]]
          );
      }
      this.autoCorrectDrawing();
    }
  }

  public getCameraConfig(camera) {
    this.cameraConfig = null;
    this.cameraConfigForm.reset();
    let configQuery = {
      op: 'AND',
      val: [
        {
          model: 't_device_config',
          col: 'device_id',
          op: '=',
          val: camera.code,
        },
      ],
    };
    this.apiService
      .get<Config[]>(API.deviceConfig, { query: JSON.stringify(configQuery) })
      .subscribe((config) => {
        if (config) {
          this.isScaledPoints = false;
          if (config.length > 0) {
            this.cameraConfig = config[0];
            this.setCameraConfig();
          }
        }
      });
  }

  public addCameraConfig() {
    let newConfigObject = this.generateConfig();
    this.apiService
      .post<Config>(API.configs, newConfigObject)
      .subscribe((newConfig) => {
        if (newConfig) {
          this.cameraConfig = newConfig;
          // this.alertService.informSuccess('Cấu hình thành công');
        }
      });
  }

  public updateCameraConfig() {
    let newConfigObject = this.generateConfig();
    this.apiService
      .put<Config>(API.configs + '/' + this.cameraConfig.id, newConfigObject)
      .subscribe((newConfig) => {
        if (newConfig) {
          // this.alertService.informSuccess('Cấu hình thành công');
        }
      });
  }

  public generateConfig() {
    let scaledPoints = null;
    if (this.boundingPoints.length > 0) {
      scaledPoints = this.scalePointsToOrg();
      this.boundingPoints = scaledPoints;
      console.log({ scaledPoints }); // keep for AI
    }
    let config = {
      width: this.cameraConfigForm.value.stream_width,
      height: this.cameraConfigForm.value.stream_height,
      bounding_polygon: scaledPoints,
    };
    let configObject = {
      cameraid: this.cameraId,
      code: 'cameraSetting',
      val: JSON.stringify(config),
    };
    return configObject;
  }

  public loadConfig() {
    if (!this.isScaledPoints && this.isCCTV) {
      this.canvas = document.getElementById('canvas');
      this.imageSrc = document.getElementById('source');
      if (this.imageSrc) {
        this.canvas.width = this.imageSrc.width;
        this.canvas.height = this.imageSrc.height;
      }
      if (this.cameraConfig) {
        this.setCameraConfig();
        this.boundingPoints = this.cameraConfig.bounding_polygon
          ? this.cameraConfig.bounding_polygon
          : [];
        if (this.boundingPoints && this.boundingPoints.length > 0) {
          this.loadBoundingPoints();
        }
      }
    }
  }

  public scalePointsToOrg() {
    let orgWidth = this.cameraConfigForm.value.stream_width;
    let orgHeight = this.cameraConfigForm.value.stream_height;
    let webWidth = this.canvas.width;
    let webHeight = this.canvas.height;
    let widthRatio = orgWidth / webWidth;
    let heightRatio = orgHeight / webHeight;
    let scaledPoints = [];
    this.boundingPoints.map((point) => {
      let scaledWidth = point[0] * widthRatio;
      let scaledHeight = point[1] * heightRatio;
      scaledPoints.push([scaledWidth, scaledHeight]);
    });
    return scaledPoints;
  }

  public scalePointsToWeb() {
    let orgWidth = this.cameraConfigForm.value.stream_width;
    let orgHeight = this.cameraConfigForm.value.stream_height;
    let webWidth = this.canvas.width;
    let webHeight = this.canvas.height;
    let widthRatio = webWidth / orgWidth;
    let heightRatio = webHeight / orgHeight;
    let scaledPoints = [];
    this.boundingPoints.map((point) => {
      let scaledWidth = point[0] * widthRatio;
      let scaledHeight = point[1] * heightRatio;
      scaledPoints.push([scaledWidth, scaledHeight]);
    });
    return scaledPoints;
  }

  public setCameraConfig() {
    this.cameraConfigForm.patchValue(this.cameraConfig);
  }

  public getAiConfig(type: string) {
    this.resetAiConfig(type);
    let url =
      API.AIConfigs +
      '?branchid=' +
      this.parentId +
      '&cameragroupid=' +
      this.selectedCameragroupId;
    if (type == 'camera') url += '&cameraid=' + this.cameraId;
    this.apiService.get<any>(url).subscribe((AIConfig) => {
      if (AIConfig) {
        if (type == 'camera') {
          this.CameraAIConfig = AIConfig;
          this.CameraConfigVal = JSON.stringify(AIConfig.val);
        }
        if (type == 'cameragroup') {
          this.CameraGroupAIConfig = AIConfig;
          this.CameraGroupConfigVal = JSON.stringify(AIConfig.val);
        }
      }
    });
  }

  public saveAIConfig(type: string) {
    if (type !== 'camera') return;
    if (this.cameraConfig) {
      try {
        let cameraAIConfig = JSON.parse(this.CameraConfigVal);
        this.updateAIConfig(type);
      } catch (err) {
        this.alertService.informError('Định dạng cấu hình không đúng!');
      }
    } else {
      this.addAIConfig(type);
    }
  }

  public addAIConfig(type: string) {
    const camera = this.cameraFormGroup.getRawValue();
    const cameraGroup = this.frmObject.getRawValue();
    let newAIConfig = {
      code: camera.code,
      name: camera.name,
      device_id: camera.code,
      group_device_id: cameraGroup.code,
      stream_url: camera.streamlink,
      bounding_polygon: this.scalePointsToOrg(),
      ...this.cameraConfigForm.value,
    };
    this.apiService
      .post<any>(API.deviceConfig, newAIConfig)
      .subscribe((res) => {
        if (res && type == 'cameragroup') {
          this.alertService.informSuccess('Cập nhật thành công');
        }
      });
  }

  public updateAIConfig(type: string) {
    if (type !== 'camera') return;
    const config = this.cameraConfigForm.value;
    const camera = this.cameraFormGroup.getRawValue();
    config.bounding_polygon = this.scalePointsToOrg();
    config.stream_url = camera.streamlink;
    this.apiService
      .put<any>(API.deviceConfig + '/' + this.cameraConfig.id, config)
      .subscribe((res) => {
        if (res) {
          this.alertService.informSuccess('Cập nhật thành công');
        }
      });
  }

  public resetAiConfig(type: string) {
    switch (type) {
      case 'cameragroup':
        this.CameraGroupAIConfig = null;
        this.CameraGroupConfigVal = null;
        break;
      case 'camera':
        this.CameraAIConfig = null;
        this.CameraConfigVal = null;
        break;
    }
  }

  public onImgError(event) {
    event.target.src = this.errorImg;
  }

  public getCustomerTypes() {
    let typeInclude = [
      {
        model: 't_optiongroup',
        include: [],
      },
    ];
    let typeQuery = {
      op: 'AND',
      val: [
        {
          model: 't_optiongroup',
          col: 'code',
          op: '=',
          val: 'customertypeoptionid',
        },
      ],
    };
    this.apiService
      .get<Option[]>(API.options, {
        include: JSON.stringify(typeInclude),
        query: JSON.stringify(typeQuery),
      })
      .subscribe((types) => {
        if (types) {
          types.map((type) => (type.val = JSON.parse(type.val)));
          this.customerTypeList = types.filter(
            (type) => type.val.is_identify == true
          );
        }
      });
  }
}
