<div
  class="box full-width"
  style="
    display: grid;
    grid-template-columns: 3fr 2fr 2fr 2fr 2fr;
    grid-gap: 2px;
  "
>
  <!-- <div class="position-relative" style="display: grid; grid-template-columns: 1fr 8fr 1fr;" [ngClass]="
          isShowCameraGroupOption? 'background-shadow' : ''
        ">
    <div class="size-icon" style="margin: auto"><i class="fa fa-search"></i></div>
    <div class="">
      <div class="parent full-height full-width">
        <input class="line-height-50 full-height" style="outline: none; border: none;" type = 'text' placeholder="Tìm kiếm" [ngModel]="textSearch" (ngModelChange)="getCameragroups($event)" (focus)="handleSelectList('isShowCameraGroupOption')" (focusout)="onFocusOutEvent()"/>
      </div>
    </div>
    <ul class="top-level-menu" [hidden]="!isShowCameraGroupOption">
      <li class="handle title-menu" *ngFor="let cameraGroup of cameraGroupList">
        <a (mousedown)="handleSelectCameraGroup(cameraGroup);">{{cameraGroup.name}}</a>
      </li>
    </ul>
    <div class="handle" (click)="resetSearch()" [hidden]="!chosenCameraGroup"><i class="fa fa-sm fa-close"></i></div>
    <div class="borderLeft"></div>
  </div> -->
  <!-- <div class="d-flex flex-row"> -->
  <div class="ml-1 handle position-relative w-100">
    <div
      class="parent w-100"
      [ngClass]="isShowBranchOption ? 'background-shadow' : ''"
      (click)="handleSelectList('isShowBranchOption')"
    >
      <div class="line-height-50" style="display: flex">
        <div class="showing-select">
          {{ chosenBranch ? chosenBranch.name : noText }}
        </div>
        <div class="ml-3">
          <i
            class="fa"
            [ngClass]="{
              'fa-caret-down': !isShowBranchOption,
              'fa-caret-up': isShowBranchOption
            }"
          ></i>
        </div>
      </div>
    </div>
    <!--      <ul class="top-level-menu" [hidden]="!isShowBranchOption">-->
    <!--        <li class="title-menu" *ngFor="let branch of branchList" [ngClass]="{'select-active': chosenBranch && branch.name == chosenBranch.name}">-->
    <!--          <a (click)="handleClickedBranch(branch)">{{branch.name}}</a>-->
    <!--        </li>-->
    <!--      </ul>-->

    <app-dropdown-list
      [dataList]="branchList"
      [saveBranch]="true"
      [isShow]="isShowBranchOption"
      [selectedBranch]="chosenBranch"
      (clickedOutside)="closePopup()"
      (handleSelect)="handleClickedBranch($event)"
    ></app-dropdown-list>
  </div>
  <div class="handle position-relative w-100">
    <div
      class="parent w-100"
      [ngClass]="isShowCameraGroupOption ? 'background-shadow' : ''"
      (click)="handleSelectList('isShowCameraGroupOption')"
    >
      <div class="line-height-50" style="display: flex">
        <div class="showing-select">
          {{ chosenStall ? chosenStall.name : noText }}
        </div>
        <div class="ml-3">
          <i
            class="fa"
            [ngClass]="{
              'fa-caret-down': !isShowCameraGroupOption,
              'fa-caret-up': isShowCameraGroupOption
            }"
          ></i>
        </div>
      </div>
    </div>
    <app-dropdown-list
      [dataList]="stallList"
      [isShow]="isShowCameraGroupOption"
      [selectedBranch]="chosenStall"
      (clickedOutside)="closePopup()"
      (handleSelect)="handleClickedStall($event)"
    ></app-dropdown-list>
  </div>

  <div class="position-relative w-100" *ngIf="showFromToDate">
    <div class="date-selector">
      <label class="p-2 m-0">{{ fromDateLabel }}: </label>
      <input
        class="form-control"
        angular-mydatepicker
        [(ngModel)]="fromDate"
        #dpFrom="angular-mydatepicker"
        (dateChanged)="handleFromDateChange($event)"
        [options]="datePickerOptions"
        (click)="dpFrom.toggleCalendar()"
        [locale]="locale"
      />
    </div>
  </div>

  <div class="position-relative w-100" *ngIf="showFromToDate">
    <div class="date-selector">
      <label class="p-2 m-0">{{ toDateLabel }}: </label>
      <input
        class="form-control"
        angular-mydatepicker
        [(ngModel)]="toDate"
        #dpTo="angular-mydatepicker"
        (dateChanged)="handleToDateChange($event)"
        [options]="datePickerOptions"
        (click)="dpTo.toggleCalendar()"
        [locale]="locale"
      />
    </div>
  </div>
  <!-- <div
    class="ml-1 handle position-relative w-100"
    (click)="handleSelectList('isShowTimeOption')"
  >
    <div
      class="parent w-100"
      [ngClass]="isShowTimeOption ? 'background-shadow' : ''"
    >
      <div class="line-height-50" style="display: flex">
        <div class="showing-select">
          {{ chosenTime ? chosenTime.name : noText }}
        </div>
        <div class="ml-3">
          <i
            class="fa"
            [ngClass]="{
              'fa-caret-down': !isShowTimeOption,
              'fa-caret-up': isShowTimeOption
            }"
          ></i>
        </div>
      </div>
    </div>

    <app-dropdown-list
      [dataList]="timeOptions"
      [saveBranch]="true"
      [isShow]="isShowTimeOption"
      [selectedBranch]="chosenTime"
      (clickedOutside)="closePopup()"
      (handleSelect)="handleSelectTime($event)"
    ></app-dropdown-list>
  </div> -->
  <!-- </div> -->
  <!-- <div class="box-header row">
    <ul class="nav justify-content-end col">
      <li>
        <div class="nav-link full-height">Xem theo</div>
      </li>
      <li>
        <div
          class="nav-link full-height"
          [ngClass]="{
            active: chosenTab == 'day',
            handle: chosenTab != 'day',
            disabled: chosenTime && !chosenTime.day
          }"
          (click)="handleClickedTab('day')"
        >
          Ngày
        </div>
      </li>
      <li>
        <div
          class="nav-link full-height"
          [ngClass]="{
            active: chosenTab == 'week',
            handle: chosenTab != 'week',
            disabled: chosenTime && !chosenTime.week
          }"
          (click)="handleClickedTab('week')"
        >
          Tuần
        </div>
      </li>
      <li>
        <div
          class="nav-link full-height"
          [ngClass]="{
            active: chosenTab == 'month',
            handle: chosenTab != 'month',
            disabled: chosenTime && !chosenTime.month
          }"
          (click)="handleClickedTab('month')"
        >
          Tháng
        </div>
      </li>
    </ul>
  </div> -->
</div>
