import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';
import { Option } from 'src/app/models/option';
import { ApiService } from 'src/app/services/api.service';
import { API, FUNC, REPLACEMENT } from 'src/environments/environment';
import { Branch } from '../../models/branch';
import { GlobalVariablesService } from '../../services/variables/global-variables.service';
import { ActivatedRoute } from '@angular/router';
import { Cameragroup } from '../../models/cameragroup';

@Component({
  selector: 'app-person-search',
  templateUrl: './person-search.component.html',
  styleUrls: ['./person-search.component.scss'],
})
export class PersonSearchComponent implements OnInit, OnChanges {
  @Output() startSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() openForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() changeTab: EventEmitter<Option> = new EventEmitter<Option>();
  @Output() clickedBranch: EventEmitter<Branch> = new EventEmitter<Branch>();
  @Input() personType: string = 'emp';
  public textSearch: string = '';
  public isFocus: boolean = false;
  public customerType: Option[] = [
    { id: 0, code: '', name: 'Tất cả', val: '', groupid: null },
  ];
  public chosenTab: Option = {
    id: 0,
    code: '',
    name: 'Tất cả',
    val: '',
    groupid: null,
  };
  public branchList: Branch[] = [];
  public isShowBranchOption: boolean = false;
  public chosenBranch: Branch;
  public chosenBranchChildren = [];
  private branchParam = 0;
  public noText = REPLACEMENT.noText;
  @Input() allowAll = true;
  public stallList: Cameragroup[] = [];

  constructor(
    private apiService: ApiService,
    private globalService: GlobalVariablesService,
    private route: ActivatedRoute,
    private element: ElementRef
  ) {}

  ngOnInit(): void {
    let routeBranchParam = this.route.snapshot.queryParamMap.get('branch');
    if (routeBranchParam) {
      this.branchParam = Number(routeBranchParam);
    }
    this.getBranches();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes['personType'] && changes['personType'].currentValue) {
      if (this.personType == 'customer') {
        this.getCustomerType();
      }
    }
  }

  public search() {
    this.startSearch.emit(this.textSearch);
  }

  public onFocusOutEvent() {
    this.isFocus = false;
  }

  public onFocusInEvent() {
    this.isFocus = true;
  }

  public resetSearch() {
    this.textSearch = '';
    this.search();
  }

  public openEmptyForm() {
    this.openForm.emit(true);
  }

  public getCustomerType() {
    this.customerType.length = 1;
    let typeInclude = [
      {
        model: 't_optiongroup',
        include: [],
      },
    ];
    let typeQuery = {
      op: 'AND',
      val: [
        {
          model: 't_optiongroup',
          col: 'code',
          op: '=',
          val: 'customertypeoptionid',
        },
      ],
    };
    this.apiService
      .get<Option[]>(API.options, {
        include: JSON.stringify(typeInclude),
        query: JSON.stringify(typeQuery),
        order: JSON.stringify([['id', 'ASC']]),
      })
      .subscribe((types) => {
        if (types) {
          types.map((type) => (type.val = JSON.parse(type.val)));
          this.customerType = [
            ...this.customerType,
            ...types.filter((type) => type.val.is_identify == true),
          ];
          this.handleClickedTab(this.customerType[0]);
        }
      });
  }

  public handleClickedTab(tab: Option) {
    this.chosenTab = tab;
    this.changeTab.emit(tab);
  }

  public handleClickedBranch(selectedBranch: Branch) {
    this.isShowBranchOption = false;
    this.chosenBranch = selectedBranch;
    this.clickedBranch.emit(selectedBranch);
    this.getChosenBranchChildren();
  }

  public getChosenBranchChildren() {
    this.apiService
      .get<any[]>(API.view + FUNC.getBranchChildren, {
        parent: this.chosenBranch.id,
      })
      .subscribe((branches) => {
        if (branches) {
          this.chosenBranchChildren = branches.map((branch) => branch.id);
        }
      });
  }

  public getBranches() {
    this.chosenBranch = this.globalService.globalBranch;
    this.apiService.get<Branch[]>(API.branches).subscribe((branches) => {
      if (branches) {
        this.formatBranchList(branches);
        let selectedBranch = branches.find(
          (branch) => branch.id == this.branchParam
        );
        if (selectedBranch) {
          this.handleClickedBranch(selectedBranch);
        } else {
          if (this.chosenBranch === undefined) {
            this.handleClickedBranch(this.branchList[0]);
          } else {
            this.handleClickedBranch(this.chosenBranch);
          }
        }
      }
    });
  }

  public formatBranchList(branches: Branch[]) {
    let tempList = [];
    branches.map((branch) => {
      branch.t_branches = this.getBranchChildren(branch, branches);
      if (!branch.parentid) {
        tempList.push(branch);
      }
    });
    this.branchList = [...this.branchList, ...tempList];
  }

  public getBranchChildren(parent: Branch, branchList: Branch[]) {
    let children = [];
    branchList.map((branch, idx) => {
      if (branch.parentid == parent.id) {
        children.push(branch);
        // branchList.splice(idx, 1);
      }
    });
    return children;
  }

  public handleSelectList(type: string) {
    let selectList = [
      'isShowBranchOption',
      'isShowCameraGroupOption',
      'isShowTimeOption',
    ];
    for (let i = 0; i < selectList.length; i++) {
      if (selectList[i] == type) {
        this[type] = !this[type];
      } else {
        this[selectList[i]] = false;
      }
    }
  }

  // @HostListener('document:click', ['$event'])
  // public clickOutside(e) {
  //   if (!this.element.nativeElement.contains(e.target)) {
  //     this.closePopup();
  //   }
  // }

  public closePopup() {
    this.isShowBranchOption = false;
  }
}
