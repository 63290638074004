import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Branch } from '../../models/branch';
import { GlobalVariablesService } from '../../services/variables/global-variables.service';
import { API } from '../../../environments/environment';
import { ApiService } from '../../services/api.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss'],
  animations: [
    trigger('animation-opacity', [
      transition('void => *', [
        style({ transform: 'translateX(-5%)', opacity: 0 }),
        animate(300, style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class DropdownListComponent implements OnInit {
  @Input() dataList = [];
  @Input() isShow: boolean;
  @Input() selectedBranch;
  @Input() saveBranch = false;
  @Output() handleSelect = new EventEmitter<any>();
  @Output() clickedBranch: EventEmitter<Branch> = new EventEmitter<Branch>();
  @Output() clickedOutside: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isShowChildren = false;
  public isShowIconChildBranch = false;
  public state;
  private text: string;

  constructor(
    private globalService: GlobalVariablesService,
    private apiService: ApiService,
    private element: ElementRef
  ) {}

  ngOnInit(): void {
    if (this.dataList) {
      this.dataList.forEach((branch) => {
        branch.active = false;
      });
    }
  }

  onSelectBrand(option) {
    if (this.saveBranch) {
      if (option.id) {
        this.globalService.globalBranch = option;
      } else {
        this.globalService.globalTime = option;
      }
    }
    this.handleSelect.emit(option);
  }

  // mouseEnter(data) {
  //   // this.isShowChildren = true;
  //   this.dataList.map(branch => {
  //     if (data.name === branch.name) {
  //       branch.active = true;
  //     } else {
  //       branch.active = false;
  //     }
  //   })
  // }
  //
  // mouseLeave($event: MouseEvent, data: any) {
  //   // this.isShowChildren = false;
  // }

  showChildren(data) {
    this.globalService.globalDataList = this.dataList;
    this.isShowIconChildBranch = true;
    this.dataList = data.t_branches;
  }

  backDataList() {
    this.isShowIconChildBranch = false;
    this.dataList = this.globalService.globalDataList;
  }

  public onLoseFocus() {
    this.clickedOutside.emit(true);
  }

  @HostListener('document:click', ['$event'])
  public clickOutside(e) {
    if (
      e.target.offsetParent &&
      !e.target.offsetParent?.classList.contains('show-children') &&
      !e.target.offsetParent?.classList.contains('parent')
    ) {
      this.onLoseFocus();
    }
  }
}
