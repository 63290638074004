import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import {
  API,
  DEVICE_IMG_HOST,
  FUNC,
  REPLACEMENT,
} from 'src/environments/environment';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventAttribute } from 'src/app/models/event-attribute';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ai-list-event-image',
  templateUrl: './ai-list-event-image.component.html',
  styleUrls: ['./ai-list-event-image.component.scss'],
})
export class AiListEventImageComponent implements OnInit {
  public pagingState = [];
  public listEvent = [];
  public deviceHost = DEVICE_IMG_HOST;
  public errorImg = REPLACEMENT.noPersonImg;
  public isOver = false;
  public cameraGroup;
  public branchChildren;
  public dateFormat = 'YYYY-MM-DD[T]HH:mm:ss[.]SSS';
  public fromTime = moment().startOf('day').format(this.dateFormat);
  public toTime = moment().endOf('day').format(this.dateFormat);
  showElement: boolean = false;
  showModalVar: boolean = false;
  @Output() handleElm = new EventEmitter<boolean>();
  @Output() handleModal = new EventEmitter<boolean>();
  public elm = false;
  public modal = false;
  public faceuid: string[] = [''];
  public userInfo;
  public spinnerName = 'spinner';
  public ageAttrs = [];
  public thresHold = REPLACEMENT.minThresHold;
  public minThresHold = REPLACEMENT.minThresHold;
  public maxThresHold = REPLACEMENT.maxThresHold;
  public selectedImg;
  public page = 1;
  public itemsPerPage = 100;
  public selectedAge: EventAttribute;
  public selectedGender: EventAttribute;
  public latestAnalyzeHour;
  public today = moment().format('DD-MM-YYYY');

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private alertService: AlertService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getAgeAttributes();
    this.userInfo = this.authService.getUserInfo();
    this.latestAnalyzeHour = this.route.snapshot.queryParamMap.get('hour');
  }

  public getEvents() {
    if (this.cameraGroup) {
      this.spinner.show(this.spinnerName);
      this.faceuid.map((faceuid, idx) => {
        if (this.pagingState[idx] !== null) {
          let params = {
            from_datetime: this.fromTime,
            to_datetime: this.toTime,
            group_device_id: this.cameraGroup,
            face_uid: faceuid,
            paging_state: encodeURIComponent(this.pagingState[idx]),
          };
          this.apiService
            .get<any>(API.aiEvents, params)
            .subscribe((response) => {
              this.spinner.hide(this.spinnerName);
              if (response && response.success) {
                let events = response.message;
                this.pagingState[idx] = events.paging_state
                  ? events.paging_state
                  : null;
                if (events && events.data) {
                  events.data.map((event) => {
                    event.ageRange = this.setAgeAttr(this.ageAttrs, event.age);
                  });
                }
                if (events.data && events.data.length > 0) {
                  let newEvents = events.data.filter(
                    (event) => event.is_exclude_identified_person != true
                  );
                  this.listEvent = [...this.listEvent, ...newEvents];
                }
                if (
                  this.pagingState.some((paging_state) => paging_state == null)
                )
                  this.isOver = true;
              }
            });
        }
      });
    }
  }

  // public getEvents() {
  //   if (this.branchChildren) {
  //     let eventQuery: any = {
  //       op: 'AND',
  //       val: [
  //         {
  //           model: 't_event_realtime',
  //           col: 'isexcludeperson',
  //           op: 'not',
  //           val: true
  //         },
  //         {
  //           model: 't_event_realtime',
  //           col: 'branchcode',
  //           op: 'in',
  //           val: this.branchChildren
  //         },
  //         {
  //           model: 't_event_realtime',
  //           col: 'createdtimestamp',
  //           op: '>=',
  //           val: this.fromTime
  //         },
  //         {
  //           model: 't_event_realtime',
  //           col: 'createdtimestamp',
  //           op: '<=',
  //           val: this.toTime
  //         }
  //       ]
  //     }
  //     if (this.cameraGroup) {
  //       eventQuery.val.push(
  //         {
  //           model: 't_event_realtime',
  //           col: 'groupdevice',
  //           op: '=',
  //           val: this.cameraGroup
  //         }
  //       );
  //     }
  //     if (this.faceuid.length > 0 && this.faceuid[0] != '') {
  //       eventQuery.val.push({
  //         model: 't_event_realtime',
  //         col: 'faceuid',
  //         op: 'in',
  //         val: this.faceuid
  //       })
  //     }
  //     if (this.selectedGender && this.selectedGender.val) {
  //       eventQuery.val.push({
  //         model: 't_event_realtime',
  //         col: 'gender',
  //         op: '=',
  //         val: this.selectedGender.val
  //       })
  //     }
  //     if (this.selectedAge && this.selectedAge.val) {
  //       let op = this.generateAgeOp(this.selectedAge.op);
  //       if (op) {
  //         if (op == 'between')
  //           eventQuery.val.push({
  //             model: 't_event_realtime',
  //             col: 'age',
  //             op: op,
  //             val: [this.selectedAge.val, this.selectedAge.valmax]
  //           })
  //         else
  //         eventQuery.val.push({
  //           model: 't_event_realtime',
  //           col: 'age',
  //           op: op,
  //           val: this.selectedAge.val
  //         })
  //       }
  //     }
  //     let order = [['createdtimestamp', 'DESC']]
  //     this.spinner.show(this.spinnerName)
  //     this.apiService.get<any>(API.realtimeEvents, {query: JSON.stringify(eventQuery), page: this.page, itemsPerPage: this.itemsPerPage, order: JSON.stringify(order)}).subscribe(data => {
  //       if (data) {
  //         if (data.rows.length == 0) this.isOver = true;
  //         if (data && data.rows) {
  //           data.rows.map(event => {
  //             // console.log(this.setAgeAttr(this.ageAttrs, event.age))
  //             event.ageRange = this.setAgeAttr(this.ageAttrs, event.age);
  //             event.hour = moment(event.createdtimestamp).hour();
  //             event.daytime = moment(event.createdtimestamp).format('DD-MM-YYYY');
  //           })
  //         }
  //         this.listEvent = [...this.listEvent, ...data.rows];
  //       }
  //       this.spinner.hide(this.spinnerName)
  //     })
  //   }
  // }

  public onScroll() {
    if (!this.isOver) {
      this.page += 1;
      this.getEvents();
    }
  }

  public onImgError(event) {
    event.target.src = this.errorImg;
  }

  public handleClickedBranch(branch) {
    this.getBranchChildren(branch.id);
  }

  public handleClickedTimeOption(time) {
    this.toTime = moment().endOf('day').format(this.dateFormat);
    this.fromTime = moment()
      .subtract(Number(time.value) - 1, 'days')
      .startOf('day')
      .format(this.dateFormat);
    // if (this.listEvent.length > 0) {
    this.resetData();
    this.getEvents();
    // }
  }

  public handleClickedStall(cameraGroup) {
    this.cameraGroup = cameraGroup.code;
    this.resetData();
    this.getEvents();
  }

  public resetData() {
    this.listEvent.length = 0;
    this.pagingState.length = this.faceuid.length;
    this.pagingState.fill('');
    // this.pagingState.length = 0;
    this.pagingState.push('');
    this.isOver = false;
    this.page = 1;
  }

  public drag() {
    this.showElement = true;
    this.showModalVar = true;
  }

  dragLeaveEle() {
    this.showElement = false;
  }

  dragLeaveModal() {
    this.showModalVar = false;
  }

  public handleSelectImg(img: any) {
    if (img) {
      let formData = new FormData();
      if (img.file) {
        this.selectedImg = img.file;
        formData.append('file', img.file);
        this.getFaceuid(formData);
      } else {
        this.http.get(img, { responseType: 'blob' }).subscribe((data) => {
          if (data) {
            this.selectedImg = data;
            formData.append('file', data);
            this.getFaceuid(formData);
          }
        });
      }
    } else {
      this.selectedImg = null;
      this.faceuid.length = 0;
      this.faceuid.push('');
      // this.pagingState.length = this.faceuid.length;
      // this.pagingState.fill('')
      // this.listEvent.length = 0;
      this.resetData();
      this.getEvents();
    }
  }

  public getFaceuid(formData: any) {
    this.spinner.show(this.spinnerName);
    this.apiService
      .post<any>(
        API.searchFaceuid +
          '?search_type=multiple_uids&threshold=' +
          this.thresHold,
        formData
      )
      .subscribe((result) => {
        this.spinner.hide(this.spinnerName);
        if (result) {
          if (result.error_code == 0) {
            this.faceuid = result.data.map((data) =>
              data ? data.uid : 'null'
            );
            this.resetData();
            this.getEvents();
          } else {
            this.alertService.informError('Không nhận diện được hình ảnh');
          }
        }
      });
  }

  @HostListener('dragover', ['$event'])
  public allowDrop(ev: DragEvent) {
    this.showElement = true;
    this.showModalVar = true;
  }

  @HostListener('drop', ['$event'])
  public drop() {
    this.showModalVar = false;
    this.showElement = false;
  }

  public getAgeAttributes() {
    const ageQuery = {
      op: 'AND',
      val: [
        {
          model: 't_eventattribute',
          col: 'prop',
          op: '=',
          val: 'age',
        },
      ],
    };
    this.apiService
      .get<any[]>(API.eventAttributes, { query: JSON.stringify(ageQuery) })
      .subscribe((ageAttributes) => {
        if (ageAttributes) {
          this.ageAttrs = ageAttributes;
        }
      });
  }

  public setAgeAttr(attrs, age) {
    let attrName;
    attrs.map((attr) => {
      switch (attr.op) {
        case 'le':
          if (age <= attr.val) attrName = attr.name;
          break;
        case 'bt':
          if (age >= attr.val && age <= attr.valmax) attrName = attr.name;
          break;
        case 'eq':
          if (age == attr.val) attrName = attr.name;
          break;
        case 'lt':
          if (age < attr.val) attrName = attr.name;
          break;
        case 'ge':
          if (age >= attr.val) attrName = attr.name;
          break;
        case 'gt':
          if (age > attr.val) attrName = attr.name;
          break;
        default:
          console.log(
            'Not support event attribute ' + attr.name + ' with op ' + attr.op
          );
          break;
      }
    });
    return attrName;
  }

  public handleChangedThreshold(sliderValue) {
    this.thresHold =
      this.maxThresHold -
      (sliderValue * (this.maxThresHold - this.minThresHold)) / 100;
    if (this.thresHold < this.minThresHold) this.thresHold = this.minThresHold;
    if (this.selectedImg) {
      let formData = new FormData();
      formData.append('file', this.selectedImg);
      this.getFaceuid(formData);
    }
  }

  public handleClickedAge(selectedAge: EventAttribute) {
    this.selectedAge = selectedAge;
    this.resetData();
    this.getEvents();
  }

  public handleClickedGender(selectedGender: EventAttribute) {
    this.selectedGender = selectedGender;
    this.resetData();
    this.getEvents();
  }

  public generateAgeOp(orgOp: string) {
    switch (orgOp) {
      case 'bt':
        return 'between';
      case 'gt':
        return '>';
      case 'eq':
        return '=';
      case 'lt':
        return '<';
      case 'ge':
        return '>=';
      case 'le':
        return '<=';
      default:
        this.alertService.informError(
          'Toán tử của độ tuổi chưa được định nghĩa!'
        );
        return null;
    }
  }

  public getBranchChildren(branchid: number) {
    let param = {
      parent: branchid,
    };
    this.apiService
      .get<any[]>(API.view + FUNC.getBranchChildren, param)
      .subscribe((branchChildren) => {
        if (branchChildren) {
          this.branchChildren = branchChildren.map((branch) => branch.code);
        }
      });
  }
}
