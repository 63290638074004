<div
  class="slim-scroll hidden-scrollbar"
  *ngIf="active === 'Đối tượng giám sát'"
>
  <div class="row">
    <app-searchbar-analysis-customer
      class="full-width"
      (clickedWard)="handleClickedWard($event)"
      (clickedTimeOption)="handleClickedTimeOption($event)"
      (clickedBranch)="handleClickedBranch($event)"
      (clickedCameragroup)="handleClickedCameragroup($event)"
      [showFromToDate]="true"
      (changedFromDate)="handleFromDate($event)"
      (changedToDate)="handleToDate($event)"
    ></app-searchbar-analysis-customer>
  </div>
  <div class="row">
    <app-realtime-report-object
      class="full-width mt-4"
      [selectedWard]="chosenWard"
      [selectedBranch]="chosenBranch"
      [selectedTime]="chosenTimeOption"
      [selectedCameraGroup]="chosenCameraGroup"
      [fromTime]="fromTime"
      [toTime]="toTime"
    ></app-realtime-report-object>
  </div>
  <div class="row">
    <div class="col-4 pr-md-2 p-0">
      <app-gender [eventData]="eventDetailsWithWatchtime"></app-gender>
    </div>
    <div class="col-8 pl-md-3 p-0">
      <app-age [eventData]="eventDetailsWithWatchtime"></app-age>
    </div>
  </div>
  <div class="row">
    <app-realtime-table-event
      class="full-width mt-4 mb-5"
      [eventData]="eventDetails"
    ></app-realtime-table-event>
  </div>
</div>
