import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import { Camera } from 'src/app/models/camera';
import { ApiService } from 'src/app/services/api.service';
import {
  API,
  DEVICE_IMG_HOST,
  FUNC,
  REPLACEMENT,
} from 'src/environments/environment';
import * as moment from 'moment';
import { Ward } from 'src/app/models/ward';
import { Branch } from 'src/app/models/branch';
import { Cameragroup } from 'src/app/models/cameragroup';
import { SocketService } from 'src/app/services/socket.service';
import { Subscription } from 'rxjs';
import { latLng, Marker, MarkerCluster, tileLayer } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import { PopupComponent } from './popup/popup.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';

interface MarkerMetaData {
  name: String;
  markerInstance: Marker;
  componentInstance: ComponentRef<PopupComponent>;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  public selectValue;
  public cameraGroupList: Cameragroup[] = [];
  public moment: any = moment;
  public noStatistic = REPLACEMENT.noStatistic;
  public noText = REPLACEMENT.noText;
  public selectedCameraGroupStat = {
    cameragroup: '',
    custno: 0,
    quan_tam: 0,
    sumwatchtime: 0,
    nam: 0,
    nu: 0,
    tre_em: 0,
    thanh_nien: 0,
    trung_nien: 0,
    cao_tuoi: 0,
  };
  public selectedCamera: Camera;
  public selectedCameraGroup: string = 'Tìm kiếm';
  private deviceTimeLimit = REPLACEMENT.deviceTimeLimit;
  private deviceTimeout = REPLACEMENT.deviceTimeout;
  private normalZoom = REPLACEMENT.normalZoom;
  private focusZoom = REPLACEMENT.focusZoom;
  private cameraInterval;
  public tempSocketData;
  public originCameraGrouptStat;
  public prevSocketEventData;
  public currentDeviceImg: string = '';
  public deviceStatus: any;
  public mapCenter = null;
  @Input() minimize = false;
  @Input() selectedWard: Ward = null;
  @Input() selectedBranch: Branch = null;
  public zoom: number = this.normalZoom;
  leafletMaxZoom = 19;
  public isShowOption = false;
  @Input() height;
  public options = {
    layers: [
      tileLayer(
        'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}{r}.png',
        {
          maxZoom: this.leafletMaxZoom,
          attribution: '...',
        }
      ),
    ],
    maxZoom: this.leafletMaxZoom,
    minZoom: 12,
    maxBounds: [
      [22.400157, 100.310826],
      [8.564919, 109.396003],
    ],
    zoomControl: false,
    zoom: this.normalZoom,
    center: latLng(10.796499126609119, 106.65799955598793),
    gridLayers: L.GridLayer,
  };
  public branchList: Branch[] = [];
  public filterLevel = ['branch', 'cameragroup', 'cameragroupDetail'];
  public currentLevel = this.filterLevel[0];
  public cameragroupList: Cameragroup[] = [];
  @Input() selectedCameragroup: Cameragroup;
  public displayFilter = false;

  markerClusterGroup: L.MarkerClusterGroup;
  markerClusterData: any[] = [];
  markerClusterOptions: L.MarkerClusterGroupOptions = {
    animateAddingMarkers: true,
    // maxClusterRadius: 20,
    iconCreateFunction: (cluster: MarkerCluster) => {
      return L.divIcon({
        html: `<div class='cluster-text'>${cluster.getChildCount()}</div>`,
        className: 'mycluster',
        iconSize: [50, 50],
      });
    },
  };
  public cameras = [];
  public deviceSubscription: Subscription;
  private dataSubscription: Subscription;
  public isMobile = false;
  public textSearch = '';
  public eventList = [];
  public deviceHost = DEVICE_IMG_HOST;
  public spinnerName = 'camera-info-spinner';
  public cameraEnable = false;

  constructor(
    private apiService: ApiService,
    private socketService: SocketService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // this.getCameraGroupsByBranch();
    this.getCameras();
    this.getBranch();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    // if (changes['selectedWard'] && changes['selectedWard'].currentValue) {
    //   this.getCamerasByWard();
    // }
    // if (changes['selectedBranch'] && changes['selectedBranch'].currentValue) {
    //   this.getBranchChildren();
    // }
    if (
      changes['selectedCameragroup'] &&
      changes['selectedCameragroup'].currentValue
    ) {
      this.onClickCameragroup(this.selectedCameragroup);
    }
  }

  ngOnDestroy() {
    this.clearDeviceInterval();
    this.closeSubscription();
  }

  // onChange(cameraGroup) {
  //   console.log(this.markerClusterData);
  //   this.markerClusterData.map(marker => {
  //
  //   })
  //   // const data = this .generateMarker(this.cameraGroupList);
  //   // let startedInterval = false;
  //   // this.selectedCameraGroup = cameraGroup;
  //   // this.zoomDefaultCluster(cameraGroup);
  //   // this.cameraGroupList.map(item => {
  //   //   if (item.code === cameraGroup) {
  //   //     // this.showInfoWindow(item);
  //   //     this.getCameraGroupStat(item);
  //   //     if (item.t_cameras && item.t_cameras.length > 0) {
  //   //       this.selectedCamera = item.t_cameras[0];
  //   //       this.startWatchingCamera(item.t_cameras[0])
  //   //       startedInterval = true;
  //   //     }
  //   //   } else {
  //   //     // this.hideInfoWindow(item);
  //   //   }
  //   // })
  //   // if (startedInterval == false) {
  //   //   this.clearDeviceInterval();
  //   // }
  // }

  // public zoomDefaultCluster(value) {
  // if (value === 'Tìm kiếm') {
  //   this.zoom = this.normalZoom;
  // }
  // }

  // public onClickMarker(cameraGroup) {
  //   console.log(this.cameraGroupList)
  //   this.zoomDefaultCluster(cameraGroup.code);
  //   this.cameraGroupList.map(item => {
  //     if (item.code === cameraGroup.code) {
  //       this.showInfoWindow(item);
  //       this.getCameraGroupStat(item);
  //       this.selectedCamera = item.t_cameras[0];
  //       this.selectedCameraGroup = cameraGroup.code;
  //       this.startWatchingCamera(item.t_cameras[0])
  //     } else {
  //       this.hideInfoWindow(item)
  //     }
  //   })
  // }
  // public onClickMarker(cameraGroup) {
  //   this.zoomDefaultCluster(cameraGroup.code);
  //   this.cameraGroupList.map(item => {
  //     if (item.code === cameraGroup.code) {
  //       this.showInfoWindow(item);
  //       this.getCameraGroupStat(item);
  //       this.selectedCamera = item.t_cameras[0];
  //       this.selectedCameraGroup = cameraGroup.code;
  //       this.startWatchingCamera(item.t_cameras[0])
  //     } else {
  //       this.hideInfoWindow(item)
  //     }
  //   })
  // }

  // public showInfoWindow(info) {
  //   info.isOpen = true;
  //   this.lat = info.lat;
  //   this.lon = info.lon;
  //   // this.zoom = this.focusZoom;
  // }

  // public hideInfoWindow(info) {
  //   info.isOpen = false;
  // }

  // public zoomMap(e) {
  //   this.zoom = e;
  //   this.cameraGroupList.map(item => {
  //     if (this.zoom < this.focusZoom) {
  //       item.isOpen = false;
  //     }
  //   })
  // }

  // public getCamerasByWard() {
  //   let cameraInclude = [
  //     {
  //       model: 't_cameragroup',
  //       include: [
  //         {
  //           model: 't_branch',
  //           include: []
  //         }
  //       ]
  //     }
  //   ]
  //   let cameraQuery = null;
  //   if (this.selectedWard && this.selectedWard.id) {
  //     cameraQuery = {
  //       op: 'OR',
  //       val: [
  //         {
  //           model: 't_cameragroup.t_branch',
  //           col: 'wardid',
  //           op: '=',
  //           val: this.selectedWard.id
  //         },
  //         {
  //           model: 't_cameragroup.t_branch',
  //           col: 'districtid',
  //           op: '=',
  //           val: this.selectedWard.id
  //         },
  //         {
  //           model: 't_cameragroup.t_branch',
  //           col: 'provinceid',
  //           op: '=',
  //           val: this.selectedWard.id
  //         }
  //       ]
  //     }
  //   }
  //   this.apiService.get<Camera[]>(API.cameras, {include: JSON.stringify(cameraInclude), query: JSON.stringify(cameraQuery)}).subscribe(cameras => {
  //     if (cameras) {
  //       this.cameraList = cameras;
  //     }
  //   })
  // }
  // zoomCluster = true;

  // public getBranchChildren() {
  //   this.apiService
  //     .get<any>(API.view + FUNC.getBranchChildren, {
  //       parent: this.selectedBranch.id,
  //     })
  //     .subscribe((branches) => {
  //       if (branches) {
  //         let branchIds = branches.map((branch) => branch.id);
  //         // this.getCameraGroupsByBranch(branchIds);
  //         this.getCameras(branchIds);
  //       }
  //     });
  // }

  // public getCameraGroupsByBranch(branchIds?: any[]) {
  //   let cameraGroupInclude = [
  //     {
  //       model: 't_camera',
  //       include: []
  //     }
  //   ]
  //   let cameraGroupQuery = null;
  //   if (branchIds && branchIds.length > 0) {
  //     cameraGroupQuery = {
  //       op: 'OR',
  //       val: [
  //         {
  //           model: 't_cameragroup',
  //           col: 'branchid',
  //           op: 'in',
  //           val: branchIds
  //         }
  //       ]
  //     }
  //   }
  //   this.apiService.get<Cameragroup[]>(API.cameraGroup, {
  //     include: JSON.stringify(cameraGroupInclude),
  //     query: JSON.stringify(cameraGroupQuery)
  //   }).subscribe(cameraGroups => {
  //     if (cameraGroups) {
  //       this.cameraGroupList = cameraGroups;
  //       this.markerClusterData = this.generateMarker(this.cameraGroupList);
  //       if (cameraGroups[0] && cameraGroups[0].t_cameras.length > 0 && cameraGroups[0].t_cameras[0].lat && cameraGroups[0].t_cameras[0].lon) {
  //         this.lat = +cameraGroups[0].t_cameras[0].lat;
  //         this.lon = +cameraGroups[0].t_cameras[0].lon;
  //       }
  //     }
  //   })
  // }

  // public onInfoWindowClose(cameraGroup) {
  //   if (this.selectedCameraGroup) {
  //     this.zoomDefaultCluster(this.selectedCameraGroup);
  //     if (this.selectedCameraGroup === cameraGroup.code) {
  //       cameraGroup.isOpen = false;
  //       this.selectedCameraGroup = 'Tìm kiếm';
  //       // this.clearDeviceInterval()
  //     }
  //   }
  // }

  // public getCameraRealtimeStat(groupid) {
  //   this.socketService.getEventStat(groupid);
  // }

  // public handleEventStatRealtime(newEventStatData) {
  //   if (!this.prevSocketEventData)
  //     this.prevSocketEventData = newEventStatData;
  //   if (this.prevSocketEventData.hourtime != newEventStatData.hourtime) {
  //     this.prevSocketEventData = newEventStatData;
  //     this.originCameraGrouptStat = Object.assign({}, this.selectedCameraGroupStat);
  //   }
  //   this.selectedCameraGroupStat.custno = this.originCameraGrouptStat.custno + newEventStatData.custno;
  //   this.selectedCameraGroupStat.interestedcustno = this.originCameraGrouptStat.interestedcustno + newEventStatData.quan_tam;
  //   this.selectedCameraGroupStat.sumwatchtime = this.originCameraGrouptStat.sumwatchtime + newEventStatData.sumwatchtime;
  // }

  public markerClusterReady(group: L.MarkerClusterGroup) {
    this.markerClusterGroup = group;
  }

  public getCameras(branchIds?: any[]) {
    this.cameras.length = 0;
    this.markerClusterData.length = 0;
    let cameraInclude = [
      {
        model: 't_cameragroup',
        include: [
          {
            model: 't_branch',
            include: [
              {
                model: 't_cameragroup',
                include: [
                  {
                    model: 't_camera',
                    include: [
                      {
                        model: 't_option',
                        include: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        model: 't_option',
        include: [],
      },
    ];
    let cameraQuery = null;
    if (branchIds && branchIds.length > 0) {
      cameraQuery = {
        op: 'AND',
        val: [
          {
            model: 't_cameragroup',
            col: 'branchid',
            op: 'in',
            val: branchIds,
          },
        ],
      };
    }
    this.apiService
      .get<Camera[]>(API.cameras, {
        include: JSON.stringify(cameraInclude),
        query: JSON.stringify(cameraQuery),
      })
      .subscribe((cameras) => {
        if (cameras && cameras.length > 0) {
          this.cameras = cameras;
          this.markerClusterData = this.generateMarker(this.cameras);
          const selectedCamera = this.cameras.find(
            (camera) =>
              camera.code === this.activatedRoute.snapshot.queryParams.camera
          );
          this.mapCenter = selectedCamera
            ? latLng(Number(selectedCamera.lat), Number(selectedCamera.lon))
            : latLng(Number(cameras[0].lat), Number(cameras[0].lon));
          if (selectedCamera) {
            this.onClickCameragroup(selectedCamera.t_cameragroup);
            this.onChange(selectedCamera);
          }
        }
      });
  }

  public generateMarker(cameras): L.Marker[] {
    const data: L.Marker[] = [];
    const icon = L.icon({
      iconSize: [34, 34],
      iconUrl: 'assets/image/marker.svg',
    });
    const selectedCamera = this.cameras.find(
      (camera) =>
        camera.code === this.activatedRoute.snapshot.queryParams.camera
    );
    cameras.map((camera) => {
      // const factory = this.resolver.resolveComponentFactory(PopupComponent);
      // const component = factory.create(this.injector);
      // const popupContent = component.location.nativeElement;
      // component.instance.isMinimize = this.minimize;
      // component.instance.camera = camera;
      // component.changeDetectorRef.detectChanges();
      const isSelectedCamera =
        camera.code === this.activatedRoute.snapshot.queryParams.camera;
      const popup = L.popup({
        closeButton: false,
        autoPan: false,
        className: isSelectedCamera
          ? 'marker-image-container'
          : 'marker-popup-container',
      });
      if (isSelectedCamera) {
        popup.setContent(
          `<img class='marker-image' src='${
            this.deviceHost + this.activatedRoute.snapshot.queryParams.image
          }'>`
        );
      } else {
        popup.setContent(
          `<img class='marker-popup' src='/assets/image/place.svg'>`
        );
      }
      const marker = L.marker([camera.lat, camera.lon], {
        icon,
        title: camera.code,
      })
        .bindPopup(popup)
        .on('popupopen', (e) => this.onOpenPopup(camera))
        .on('popupclose', (e) => this.onClosePopup());
      data.push(marker);
    });
    return data;
  }

  public onChange(vCamera) {
    this.isShowOption = true;
    let cameraPopup = this.markerClusterData.find(
      (cluster) => cluster.options.title == vCamera.code
    );

    if (cameraPopup) {
      if (
        (cameraPopup._map === undefined || cameraPopup._map === null) &&
        cameraPopup.__parent
      ) {
        cameraPopup.__parent.spiderfy();
      }
      cameraPopup.openPopup();
    }
  }

  // public onOpenPopup(popupComponentInstance) {
  //   // console.log(popupComponentInstance)
  //   popupComponentInstance.startWatchingCamera();
  //   popupComponentInstance.getCameraGroupStat();
  //   this.selectedCameraGroup = popupComponentInstance.camera;
  // }

  public onClosePopup() {
    this.clearDeviceInterval();
    this.closeSubscription();
    this.textSearch = '';
    this.currentDeviceImg = null;
    this.cdr.detectChanges();
  }

  public getBranch() {
    const branchInclude = [
      {
        model: 't_cameragroup',
        include: [],
      },
    ];
    let branchQuery: any = {
      op: 'and',
      val: [
        {
          model: 't_branch',
          col: 'parentid',
          op: '!=',
          val: null,
        },
      ],
    };
    if (this.currentLevel == 'branch') {
      branchQuery.val.push({
        op: 'or',
        val: [
          {
            model: 't_branch',
            col: 'name',
            op: 'ilike',
            val: `%${this.textSearch}%`,
          },
          {
            model: 't_branch',
            col: 'code',
            op: 'ilike',
            val: `%${this.textSearch}%`,
          },
        ],
      });
    }
    this.apiService
      .get<Branch[]>(API.branches, {
        include: JSON.stringify(branchInclude),
        query: JSON.stringify(branchQuery),
      })
      .subscribe((branches) => {
        if (branches && branches.length > 0) {
          this.branchList = branches;
          this.cdr.detectChanges();
        }
      });
  }

  public filterText(e?: Event) {
    if (this.displayFilter && e) {
      this.stopEventPropagation(e);
    }
    if (this.currentLevel == 'branch') {
      this.getBranch();
    } else if (this.currentLevel == 'cameragroup') {
      this.getCameragroups();
    }
  }

  public getCameragroups() {
    const cameragroupInclude = [
      {
        model: 't_camera',
        include: [
          {
            model: 't_option',
            include: [],
          },
        ],
      },
    ];
    let cameragroupQuery: any = {
      op: 'and',
      val: [
        {
          model: 't_cameragroup',
          col: 'branchid',
          op: '=',
          val: this.selectedBranch.id,
        },
      ],
    };
    if (this.currentLevel == 'cameragroup') {
      cameragroupQuery.val.push({
        op: 'or',
        val: [
          {
            model: 't_cameragroup',
            col: 'name',
            op: 'ilike',
            val: `%${this.textSearch}%`,
          },
          {
            model: 't_cameragroup',
            col: 'code',
            op: 'ilike',
            val: `%${this.textSearch}%`,
          },
        ],
      });
    }
    this.apiService
      .get<Cameragroup[]>(API.cameraGroup, {
        include: JSON.stringify(cameragroupInclude),
        query: JSON.stringify(cameragroupQuery),
      })
      .subscribe((cameragroups) => {
        if (cameragroups && cameragroups.length > 0) {
          this.cameraGroupList = cameragroups;
          this.cdr.detectChanges();
        }
      });
  }

  public onClickBranch(branch: Branch) {
    this.selectedBranch = branch;
    this.getCameragroups();
    this.toNextFilterLevel();
    this.textSearch = branch.name;
  }

  public toNextFilterLevel() {
    let curLevelIdx = this.filterLevel.findIndex(
      (level) => level == this.currentLevel
    );
    this.currentLevel = this.filterLevel[curLevelIdx + 1];
    this.cdr.detectChanges();
  }

  public backToPrevFilterLevel(e: Event) {
    this.stopEventPropagation(e);
    let curLevelIdx = this.filterLevel.findIndex(
      (level) => level == this.currentLevel
    );
    if (curLevelIdx > 0) {
      this.closeSubscription();
      this.clearDeviceInterval();
      if (this.currentDeviceImg) {
        this.currentDeviceImg = null;
      }
      this.currentLevel = this.filterLevel[curLevelIdx - 1];
      if (this.currentLevel == 'cameragroup') {
        this.textSearch = this.selectedBranch.name;
      } else if (this.currentLevel == 'branch') {
        this.textSearch = '';
      }
    }
    this.cdr.detectChanges();
  }

  public onClickCameragroup(cameragroup: Cameragroup) {
    this.selectedCameragroup = cameragroup;
    this.textSearch = cameragroup.name;
    if (cameragroup.t_cameras && cameragroup.t_cameras.length > 0) {
      this.mapCenter = latLng(
        Number(cameragroup.t_cameras[0].lat),
        Number(cameragroup.t_cameras[0].lon)
      );
      this.zoom = this.leafletMaxZoom;
      setTimeout(() => {
        this.onChange(cameragroup.t_cameras[0]);
      }, 10);
      // this.startWatchingCamera(cameragroup.t_cameras[0]);
      this.cameraEnable =
        cameragroup.t_cameras[0].deactiveyn == 'F' ? true : false;
    }
    // this.spinner.show(this.spinnerName);
    // this.getCameraGroupStat();
    // this.getCameragroupEvents();
    this.toNextFilterLevel();
    this.cdr.detectChanges();
  }

  public onOpenPopup(camera: Camera) {
    if (this.minimize == false) {
      this.textSearch = camera.t_cameragroup.name;
      this.displayFilter = true;
      this.selectedCameragroup = camera.t_cameragroup;
      this.cameraEnable = camera.deactiveyn == 'F' ? true : false;
      this.selectedBranch = camera.t_cameragroup.t_branch;
      this.cameraGroupList = camera.t_cameragroup.t_branch.t_cameragroups;
      this.currentLevel = this.filterLevel[2];
      this.spinner.show(this.spinnerName);
      this.startWatchingCamera(camera);
      this.getCameraGroupStat();
      this.getCameragroupEvents();
      this.cdr.detectChanges();
    }
  }

  public getCameraGroupStat() {
    this.selectedCameraGroupStat = {
      cameragroup: '',
      custno: 0,
      quan_tam: 0,
      sumwatchtime: 0,
      nam: 0,
      nu: 0,
      tre_em: 0,
      thanh_nien: 0,
      trung_nien: 0,
      cao_tuoi: 0,
    };
    let params = {
      ward: 0,
      place: this.selectedBranch.id,
      stall: this.selectedCameragroup.id,
      from_time: moment().startOf('month').format('YYYY-MM-DD'),
      end_time: moment().format('YYYY-MM-DD'),
    };
    this.apiService
      .get<any>(API.view + FUNC.getEventDetails, params)
      .subscribe((data) => {
        if (data) {
          this.spinner.hide(this.spinnerName);
          this.selectedCameraGroupStat.cameragroup = data[0].cameragroup;
          data.forEach((day) => {
            this.selectedCameraGroupStat.custno += +day.custno;
            this.selectedCameraGroupStat.sumwatchtime += +day.sumwatchtime;
            this.selectedCameraGroupStat.quan_tam += +day.quan_tam;
            this.selectedCameraGroupStat.nam += +day.nam;
            this.selectedCameraGroupStat.nu += +day.nu;
            this.selectedCameraGroupStat.tre_em += +day.tre_em;
            this.selectedCameraGroupStat.thanh_nien += +day.thanh_nien;
            this.selectedCameraGroupStat.trung_nien += +day.trung_nien;
            this.selectedCameraGroupStat.cao_tuoi += +day.cao_tuoi;
          });
          this.cdr.detectChanges();
        }
      });
  }

  public closeSubscription() {
    if (this.deviceSubscription) {
      this.deviceSubscription.unsubscribe();
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  public startWatchingCamera(camera) {
    if (this.deviceSubscription) {
      this.deviceSubscription.unsubscribe();
    }
    this.clearDeviceInterval();
    switch (camera.t_option.code) {
      case 'A':
        this.deviceSubscription = this.socketService
          .onNewDeviceStatus()
          .subscribe((deviceStatus) => {
            if (deviceStatus) {
              if (deviceStatus.device_id == camera.code) {
                this.deviceStatus = deviceStatus;
                // this.checkDeviceStatus(this.deviceStatus);
                this.currentDeviceImg =
                  DEVICE_IMG_HOST +
                  deviceStatus.url +
                  '?' +
                  deviceStatus.timestamp;
              }
            }
          });
        camera.deactiveyn = 'T';
        // this.getDeviceStatus(camera.code);
        // this.cameraInterval = setInterval(() => {
        //   this.getDeviceStatus(camera.code);
        // }, this.deviceTimeout * 1000);
        break;
      case 'C':
        if (camera.cameraurl) this.currentDeviceImg = camera.cameraurl;
        break;
      case 'M':
        this.isMobile = true;
        break;
    }
  }

  public clearDeviceInterval() {
    if (this.cameraInterval) {
      clearInterval(this.cameraInterval);
    }
  }

  public toggleFilter() {
    this.displayFilter = !this.displayFilter;
    this.cdr.detectChanges();
  }

  public getCameragroupEvents() {
    const eventQuery = {
      op: 'and',
      val: [
        {
          model: 't_event_realtime',
          col: 'isexcludeperson',
          op: 'not',
          val: true,
        },
        {
          model: 't_event_realtime',
          col: 'groupdevice',
          op: '=',
          val: this.selectedCameragroup.code,
        },
        {
          model: 't_event_realtime',
          col: 'timestamp',
          op: '>=',
          val: moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss[.]sss'),
        },
      ],
    };
    this.apiService
      .get<any>(API.realtimeEvents, {
        query: JSON.stringify(eventQuery),
        page: 1,
        itemsPerPage: 1000,
      })
      .subscribe((events) => {
        this.eventList = events.rows;
        this.cdr.detectChanges();
      });
  }

  public onClickFilter(e: Event) {
    if (this.displayFilter) {
      this.stopEventPropagation(e);
    }
  }

  public stopEventPropagation(event: Event) {
    event.stopPropagation();
  }
}
