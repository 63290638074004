<div class="full-width full-height">
  <div class="d-flex flex-row full-height">
    <div class="full-width full-height">
      <div class="info d-flex flex-column full-height">
        <app-report-detail
          [exportable]="allowExport"
          (submitReportParam)="handleSubmit($event)"
          (exportReport)="handleExport()"
        ></app-report-detail>
        <div class="flex-grow-1" style="position: relative">
          <app-report-table
            [eventList]="eventDetails"
            [report]="reportInfo"
          ></app-report-table>
          <ngx-spinner
            bdColor="rgba(255,255,255,0.4)"
            size="medium"
            color="#000"
            type="ball-spin"
            [fullScreen]="false"
            [name]="spinnerName"
          >
          </ngx-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
