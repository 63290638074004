<div class="position-relative full-width full-height">
<div class="custom-info grid-responsive hidden-scrollbar position-absolute full-height full-width overflow-auto">
  <div class="box full-height" style="text-align: center">
    <combo-chart-component
      [scheme]="colorScheme"
      [colorSchemeLine]="lineChartScheme"
      [results]="barChartData"
      [lineChart]="lineChartData"
      [xAxis]="true"
    ></combo-chart-component>
  </div>
  <div class="d-flex flex-row full-height">
    <div class="box full-width girl-box" style="text-align: center;border-radius: 4px;margin-left: 10px;margin-right: 10px">
      <div class="info-box-content">
        <div><img src="assets/image/12,650.png" alt=""></div>
        <div class="title-header">{{eventStat && eventStat.nu ? eventStat.nu : noStat}}</div>
        <div
          class="sub-title">{{eventStat && eventStat.custno ? (eventStat.nu / eventStat.custno * 100 | number: '1.0-1') : noStat}}
          %
        </div>
      </div>
    </div>
    <div class="box full-width boy-box" style="text-align: center;border-radius: 4px;margin-right: 10px">
      <div class="info-box-content">
        <div><img src="assets/image/12,650-1.png" alt=""></div>
        <div class="title-header">{{eventStat && eventStat.nam ? eventStat.nam : noStat}}</div>
        <div
          class="sub-title">{{eventStat && eventStat.custno ? (eventStat.nam / eventStat.custno * 100 | number: '1.0-1') : noStat}}
          %
        </div>
      </div>
    </div>
  </div>
  <div class="full-height">
    <div style="display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 10px;
    height: 100%">
      <div class="box full-width" style="text-align: center;border-radius: 4px">
        <div class="info-box-content">
          <div><img src="assets/image/p-1.png" alt=""></div>
          <div class="title-group">{{eventStat && eventStat.tre_em ? eventStat.tre_em : noStat}}</div>
        </div>
      </div>
      <div class="box full-width" style="text-align: center;border-radius: 4px">
        <div class="info-box-content">
          <div><img src="assets/image/p-2.png" alt=""></div>
          <div class="title-group">{{eventStat && eventStat.thanh_nien ? eventStat.thanh_nien : noStat}}</div>
        </div>
      </div>
      <div class="box full-width" style="text-align: center;border-radius: 4px">
        <div class="info-box-content">
          <div><img src="assets/image/p-3.png" alt=""></div>
          <div class="title-group">{{eventStat && eventStat.trung_nien ? eventStat.trung_nien : noStat}}</div>
        </div>
      </div>
      <div class="box full-width" style="text-align: center;border-radius: 4px">
        <div class="info-box-content">
          <div><img src="assets/image/p-4.png" alt=""></div>
          <div class="title-group">{{eventStat && eventStat.cao_tuoi ? eventStat.cao_tuoi : noStat}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>