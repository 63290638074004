import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
} from '@angular/core';

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.scss'],
})
export class ReportTableComponent implements OnInit, OnChanges {
  @Input() eventList = [];
  public currentPage = 1;
  public eventsPerPage = 8;
  public noEvent = 0;
  public showEventList = [];
  @Input() report;
  public columns = [];
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes['report'] && changes['report'].currentValue) {
      this.columns = Object.keys(this.report.columns);
    }
    if (changes['eventList']) {
      this.noEvent = this.eventList.length;
      this.changePage(1);
    }
  }

  public changePage(page: any) {
    this.currentPage = page;
    let nextBeginItem = (this.currentPage - 1) * this.eventsPerPage;
    this.showEventList = this.eventList.slice(
      nextBeginItem,
      nextBeginItem + this.eventsPerPage
    );
  }

  public getDataValue(data, key) {
    return data[this.report.columns[key]];
  }
}
