import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { Right } from 'src/app/models/right';
import { Role } from 'src/app/models/role';
import { ApiService } from 'src/app/services/api.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-role-table',
  templateUrl: './role-table.component.html',
  styleUrls: ['./role-table.component.scss'],
})
export class RoleTableComponent implements OnInit {
  public readonly spinnerName = 'user-table-spinner';
  public roleList: Role[] = [];
  public isEditing: number = null;
  public selectedRights: Right[] = [];
  public rightList: Right[] = [];
  public rightDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Tất cả quyền',
    unSelectAllText: 'Bỏ chọn tất cả',
    itemsShowLimit: 2,
  };
  constructor(
    private apiService: ApiService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getRoleList();
    this.getRightList();
  }

  public getRoleList(): void {
    this.spinner.show(this.spinnerName);
    const include = [
      {
        model: 't_right',
        include: [],
      },
    ];
    const query = {
      op: 'AND',
      val: [
        {
          model: 't_role',
          col: 'code',
          op: '!=',
          val: 'admin',
        },
      ],
    };
    this.apiService
      .get<Role[]>(API.roles, {
        include: JSON.stringify(include),
        query: JSON.stringify(query),
      })
      .subscribe((roleList) => {
        this.spinner.hide(this.spinnerName);
        this.roleList = roleList;
      });
  }

  public getRightList(): void {
    const order = [['id', 'ASC']];
    this.apiService
      .get<Right[]>(API.rights, { order: JSON.stringify(order) })
      .subscribe((rightList) => {
        this.rightList = rightList;
      });
  }

  public toggleEditMode(role: Role): void {
    if (this.isEditing === role.id) {
      this.isEditing = null;
      return;
    }
    this.isEditing = role.id;
    if (role.t_right) {
      this.selectedRights = role.t_right;
    }
  }

  public submitChange(userId: number): void {
    const newUserRole = {
      id: userId,
      rightIdList: this.selectedRights.map((role) => role.id),
    };
    this.apiService.post(API.updateRoleRight, newUserRole).subscribe((res) => {
      this.getRoleList();
      this.isEditing = null;
    });
  }
}
