import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Branch } from 'src/app/models/branch';
import { Cameragroup } from 'src/app/models/cameragroup';
import { Ward } from 'src/app/models/ward';
import { ApiService } from 'src/app/services/api.service';
import { SocketService } from 'src/app/services/socket.service';
import { API, VIEW, FUNC } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-area-analysis',
  templateUrl: './area-analysis.component.html',
  styleUrls: ['./area-analysis.component.scss'],
})
export class AreaAnalysisComponent implements OnInit, OnChanges, OnDestroy {
  @Input() chosenWard: Ward = null;
  @Input() chosenBranch: Branch = null;
  @Input() chosenCameragroup: Cameragroup = null;
  public statisticData = {
    camera_no: '0',
    custno: 0,
    emp_no: '0',
    interestedcustno: 0,
    stall_no: '0',
    sumwatchtime: 0,
  };
  public originalChartData;
  public custnoChartData;
  public interestChartData;
  public watchtimeChartData;
  public originalStat;
  private subscription: Subscription;
  private tempRealtimeData = [];
  public chosenBranchCameraGroups: Cameragroup[] = [];
  public colorScheme = {
    domain: ['#7fedff'],
  };
  public chosenBranchChildren = [];
  constructor(
    private apiService: ApiService,
    private socketService: SocketService
  ) {}

  ngOnInit(): void {
    this.subscription = this.socketService
      .onNewEventStat()
      .subscribe((data) => {
        if (
          data &&
          data.hourtime == moment().hour() &&
          data.daytime == moment().format('DD-MM-yyyy')
        ) {
          if (
            this.chosenBranchCameraGroups
              .map((cameragroup) => cameragroup.code)
              .includes(data.groupid)
          ) {
            let currentTempDataIdx = this.tempRealtimeData.findIndex(
              (tempData) =>
                tempData.groupid == data.groupid &&
                tempData.hourtime == data.hourtime
            );
            if (currentTempDataIdx > -1) {
              if (
                this.tempRealtimeData[currentTempDataIdx].custno <= data.custno
              ) {
                this.tempRealtimeData[currentTempDataIdx] = data;
                this.handleRealtimeData();
              }
            } else {
              this.tempRealtimeData.push(data);
              this.handleRealtimeData();
            }
          }
        }
      });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    // if (changes['chosenWard'] && changes['chosenWard'].currentValue) {
    //   this.getCameraGroup();
    // }
    if (changes['chosenBranch'] && changes['chosenBranch'].currentValue) {
      this.getBranchChildren();
    }
    if (
      changes['chosenCameragroup'] &&
      changes['chosenCameragroup'].currentValue
    ) {
      this.getCameraGroup();
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public getStatistics() {
    let params = {
      ward: this.chosenWard ? this.chosenWard.id : 0,
      branch: this.chosenBranch ? this.chosenBranch.id : 0,
      cameragroup: this.chosenCameragroup ? this.chosenCameragroup.id : 0,
    };
    this.apiService
      .get<any>(API.view + FUNC.getDailyStatistics, params)
      .subscribe((data) => {
        if (data) {
          this.statisticData = data[0];
          this.originalStat = Object.assign({}, data[0]);
        }
      });
  }

  public getChartData() {
    this.custnoChartData = [];
    this.interestChartData = [];
    this.watchtimeChartData = [];
    let params = {
      ward: this.chosenWard ? this.chosenWard.id : 0,
      branch: this.chosenBranch ? this.chosenBranch.id : 0,
      stall: this.chosenCameragroup ? this.chosenCameragroup.id : 0,
    };
    this.apiService
      .get<any>(API.view + FUNC.sumEventByHour, params)
      .subscribe((data) => {
        if (data) {
          this.originalChartData = data;
          this.custnoChartData = this.formatChartData(data, 'custno');
          this.interestChartData = this.formatChartData(
            data,
            'interestedcustno'
          );
          this.watchtimeChartData = this.formatChartData(data, 'sumwatchtime');
          this.getEventData();
        }
      });
  }

  public getCameraGroup() {
    let cameraGroupQuery = {
      op: 'AND',
      val: [],
    };
    if (this.chosenBranch && this.chosenBranch.id) {
      cameraGroupQuery.val.push({
        model: 't_cameragroup',
        col: 'branchid',
        op: 'in',
        val: this.chosenBranchChildren.map((branch) => branch.id),
      });
    }
    this.apiService
      .get<Cameragroup[]>(API.cameraGroup, {
        query: JSON.stringify(cameraGroupQuery),
      })
      .subscribe((cameraGroups) => {
        if (cameraGroups) {
          if (this.chosenCameragroup && this.chosenCameragroup.id != 0) {
            this.chosenBranchCameraGroups = [this.chosenCameragroup];
          } else {
            this.chosenBranchCameraGroups = cameraGroups;
          }
          this.getStatistics();
          this.getChartData();
        }
      });
  }

  public getEventData() {
    this.chosenBranchCameraGroups.map((cameraGroup) => {
      this.socketService.getEventStat(cameraGroup.code);
    });
  }

  public formatChartData(data, type) {
    let result = [];
    let column;
    for (let i = 0; i <= 24; i++) {
      let value = data.find((row) => row.hour.substring(0, 2) == i);
      if (type == 'sumwatchtime') {
        column = {
          name: i.toString() + ' giờ',
          value:
            value && +value['custno'] ? +value[type] / +value['custno'] : 0,
        };
      } else {
        column = {
          name: i.toString() + ' giờ',
          value: value && value[type] ? +value[type] : 0,
        };
      }
      result.push(column);
    }
    return result;
  }

  public handleRealtimeData() {
    this.custnoChartData = this.formatChartData(
      this.originalChartData,
      'custno'
    );
    this.interestChartData = this.formatChartData(
      this.originalChartData,
      'interestedcustno'
    );
    this.watchtimeChartData = this.formatChartData(
      this.originalChartData,
      'sumwatchtime'
    );
    this.statisticData = Object.assign({}, this.originalStat);
    this.tempRealtimeData.map((data) => {
      if (
        this.chosenBranchCameraGroups
          .map((cameragroup) => cameragroup.code)
          .includes(data.groupid)
      ) {
        let colName = data.hourtime + ' giờ';
        let colIdx = this.custnoChartData.findIndex(
          (col) => col.name == colName
        );

        // let tempObject = Object.assign({}, this.custnoChartData[colIdx])
        // tempObject.value += data.custno;
        // this.custnoChartData[colIdx] = tempObject;
        this.custnoChartData[colIdx].value += data.custno;

        // tempObject = Object.assign({}, this.interestChartData[colIdx])
        // tempObject.value += data.quan_tam;
        // this.interestChartData[colIdx] = tempObject;
        this.interestChartData[colIdx].value += data.quan_tam;

        // tempObject = Object.assign({}, this.watchtimeChartData[colIdx])
        // tempObject.value += (data.custno ? data.sumwatchtime/data.custno : 0);
        // this.watchtimeChartData[colIdx] = tempObject;
        this.watchtimeChartData[colIdx].value += data.custno
          ? data.sumwatchtime / data.custno
          : 0;

        this.custnoChartData = [...this.custnoChartData];
        this.interestChartData = [...this.interestChartData];
        this.watchtimeChartData = [...this.watchtimeChartData];

        this.statisticData.custno = +this.statisticData.custno + data.custno;
        this.statisticData.interestedcustno =
          +this.statisticData.interestedcustno + data.quan_tam;
        this.statisticData.sumwatchtime =
          +this.statisticData.sumwatchtime + data.sumwatchtime;
      }
    });
  }

  public getBranchChildren() {
    this.apiService
      .get<any>(API.view + FUNC.getBranchChildren, {
        parent: this.chosenBranch.id,
      })
      .subscribe((branches) => {
        if (branches) {
          this.chosenBranchChildren = branches;
          this.getCameraGroup();
        }
      });
  }
}
