<div class="d-flex flex-column">
  <div class="box">
    <div
      class="box-header row"
      style="display: grid; grid-template-columns: 2fr 1fr"
    >
      <div class="title-group p-3 font-weight-bold">Thời gian xem hàng</div>
      <!-- <div class="p-2">
        <ul class="nav justify-content-end col">
          <li class="nav-item">
            <div class="nav-link">Xem theo</div>
          </li>
          <li class="nav-item">
            <div
              class="nav-link"
              [ngClass]="{
                active: selectedTab == 'day',
                handle: selectedTab != 'day',
                disabled: selectedTimeOption && !selectedTimeOption.day
              }"
              (click)="handleClickedTab('day')"
            >
              Ngày
            </div>
          </li>
          <li class="nav-item">
            <div
              class="nav-link"
              [ngClass]="{
                active: selectedTab == 'week',
                handle: selectedTab != 'week',
                disabled: selectedTimeOption && !selectedTimeOption.week
              }"
              (click)="handleClickedTab('week')"
            >
              Tuần
            </div>
          </li>
          <li class="nav-item">
            <div
              class="nav-link"
              [ngClass]="{
                active: selectedTab == 'month',
                handle: selectedTab != 'month',
                disabled: selectedTimeOption && !selectedTimeOption.month
              }"
              (click)="handleClickedTab('month')"
            >
              Tháng
            </div>
          </li>
        </ul>
      </div> -->
    </div>
    <div>
      <div class="chart-container">
        <ngx-charts-line-chart
          [scheme]="watchtimeColorScheme"
          [results]="watchtimeData"
          [xAxis]="true"
          [yAxis]="true"
        >
          <ng-template #tooltipTemplate let-model="model">
            <div *ngIf="model">
              <span style="font-weight: bold"
                >{{ xAxisUnit }} {{ model.name }}</span
              >
              <div>
                <a>{{ watchtime }}: {{ model.value }}</a>
              </div>
            </div>
          </ng-template>

          <ng-template #seriesTooltipTemplate let-model="model">
            <div *ngIf="model">
              <span style="font-weight: bold">{{
                model[0] ? model[0].name : ""
              }}</span>
              <div>
                <a>{{ watchtime }}: {{ model[0] ? model[0].value : "" }}</a
                ><br />
              </div>
            </div>
          </ng-template>
        </ngx-charts-line-chart>
      </div>
      <div class="d-flex flex-row p-3">
        <div class="d-flex flex-row">
          <div
            class="square-shape pink-style mr-2"
            style="height: 22px !important; width: 22px !important"
          ></div>
          Thời gian xem TB
        </div>
      </div>
    </div>
  </div>
</div>
