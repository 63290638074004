<!-- <div *ngIf="!isMinimize">
  <div class="box camera-info">
    <div class="d-flex flex-row full-height">
      <div class="w-25">
        <img class="full-height full-width custom-image pl-4 pt-4 pb-4"
             src="assets/image/camera.PNG" alt="">
      </div>
      <div class="flex-grow-1">
        <div class="text-group">
          <div class="title font-weight-bold">{{camera.name}}</div>
          <div class="sub-title">{{camera.t_cameragroup.name}}</div>
        </div>
      </div>
      <div class="circle-status">
        <span
          [ngClass]="{'dot-disable': !camera || !camera.deactiveyn,  'dot-offline': camera && camera.deactiveyn == 'T', 'dot-online': camera && camera.deactiveyn == 'F'}"></span>
      </div>
    </div>
  </div>
  <div class="pb-2">
    <div class="sub-title pl-4 pt-2 div-title">
      <span>Số liệu trong tháng</span>
    </div>
    <div class="pt-1 pb-2 pl-2 pr-2" style="display: grid; grid-template-columns: repeat(3,1fr)">
      <div style="text-align: center">
        <div
          class="title-group font-weight-bold">{{selectedCameraGroupStat && selectedCameraGroupStat.custno ? selectedCameraGroupStat.custno : noStatistic}}</div>
        <div class="sub-title">Khách</div>
      </div>
      <div style="text-align: center">
        <div
          class="title-group font-weight-bold">{{selectedCameraGroupStat && selectedCameraGroupStat.interestedcustno ? selectedCameraGroupStat.interestedcustno : noStatistic}}</div>
        <div class="sub-title">Quan tâm</div>
      </div>
      <div style="text-align: center">
        <div
          class="title-group font-weight-bold">{{(selectedCameraGroupStat && +selectedCameraGroupStat.custno) ? (+selectedCameraGroupStat.sumwatchtime / +selectedCameraGroupStat.custno | formatTime) : noStatistic}}</div>
        <div class="sub-title">Thời lượng</div>
      </div>
    </div>
  </div>
  <div>
  </div>
  <div class="view">
    <div class="sub-title pl-4 pb-1 div-title">
      {{deviceStatus ? (deviceStatus.timestamp | date:'dd/MM/yyyy HH:mm:ss') : noText}}
    </div>
    <app-view [useWebRTC]="isMobile" [imageUrl]="currentDeviceImg"></app-view>
  </div>
  <div class="mb-3 mt-3" style="display: grid; grid-template-columns: repeat(3,1fr)">
    <div style="text-align: center">
      <a [routerLink]="['/analysis']" [queryParams]="{branch: camera.t_cameragroup.branchid}" [fragment]="'Khu vực'"
         routerLinkActive="router-link-active">
        <div class="title-group"><img src="assets/image/mask-group-7.png" alt=""></div>
        <div class="title">Chi tiết</div>
      </a>
    </div>
    <div style="text-align: center">
      <a [routerLink]="['/analysis']" [queryParams]="{branch: camera.t_cameragroup.branchid}"
         [fragment]="'Khách hàng'" routerLinkActive="router-link-active">
        <div class="title-group"><img src="assets/image/mask-group-5.png" alt=""></div>
        <div class="title">Khách hàng</div>
      </a>
    </div>
    <div style="text-align: center">
      <a [routerLink]="['/analysis']" [queryParams]="{branch: camera.t_cameragroup.branchid, stall: camera.t_cameragroup.id}"
         [fragment]="'Đánh giá kết quả'" routerLinkActive="router-link-active">
        <div class="title-group"><img src="assets/image/mask-group-6.png" alt=""></div>
        <div class="title">Chỉ tiêu</div>
      </a>
    </div>
  </div>
</div>
<div *ngIf="isMinimize">
  <div class="p-3">
    <div style="display: grid; grid-template-columns: repeat(3,1fr)">
      <div style="text-align: center">
        <div class="sub-title">Khách</div>
        <div
          class="title-group">{{selectedCameraGroupStat && selectedCameraGroupStat.custno ? selectedCameraGroupStat.custno : noStatistic}}</div>
      </div>
      <div class="pl-3 pr-3"
          style="text-align: center; border-right: solid 1px gray;border-left: solid 1px gray">
        <div class="sub-title">Quan tâm</div>
        <div
          class="title-group">{{selectedCameraGroupStat && selectedCameraGroupStat.interestedcustno ? selectedCameraGroupStat.interestedcustno : noStatistic}}</div>
      </div>
      <div style="text-align: center">
        <div class="sub-title">Thời lượng</div>
        <div
          class="title-group">{{(selectedCameraGroupStat && +selectedCameraGroupStat.custno) ? (+selectedCameraGroupStat.sumwatchtime / +selectedCameraGroupStat.custno | formatTime) : noStatistic}}</div>
      </div>
    </div>
  </div>
</div> -->
<img src="assets/image/place.png" />
