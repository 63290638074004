import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { API, FUNC, REPLACEMENT, VIEW } from 'src/environments/environment';
import * as moment from 'moment';
import { SocketService } from 'src/app/services/socket.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Cameragroup } from 'src/app/models/cameragroup';
import { Branch } from 'src/app/models/branch';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() chosenBranch: Branch;
  @Input() chosenCameragroup: Cameragroup;
  constructor(
    private apiService: ApiService,
    private socketService: SocketService
  ) {}
  public selectedTab = 'day';
  public colorScheme = {
    domain: ['#B2F4FF', '#11deff'],
  };
  public chartData = [];
  public originalData;
  private subscription: Subscription;
  private tempRealtimeData = [];
  public custnoLabel = REPLACEMENT.custno;
  public interestedcustnoLabel = REPLACEMENT.interestedcustno;
  public xAxisUnit = 'Ngày';
  public chosenBranchChildren = [];
  public chosenBranchCameraGroups = [];
  public noCol = 10;
  public dataTimer;
  public eventData = [];
  public interestDuration = 10;
  public chartColumnWidth = REPLACEMENT.chartColumnWidth;
  public chartColumnPadding = REPLACEMENT.chartColumnPadding;
  public chartColNameFormat = 'HH:mm:ss';
  public columnNameInterval = REPLACEMENT.realtimeChartColumnInterval;
  public chartMaxYTick = REPLACEMENT.realtimeChartDefaultMaxY;
  ngOnInit(): void {
    this.calNoChartCol();
    this.getInterestDuration(); //new
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes['chosenBranch'] && changes['chosenBranch'].currentValue) {
      this.getBranchChildren();
    }
    if (
      changes['chosenCameragroup'] &&
      changes['chosenCameragroup'].currentValue
    ) {
      this.getCameraGroup();
    }
  }

  ngOnDestroy() {
    this.stopSocketSubscription();
  }

  public calNoChartCol() {
    let chartContainer = document.getElementById('chart');
    this.noCol = Math.floor(
      (chartContainer.clientWidth + this.chartColumnPadding - 100) /
        (this.chartColumnWidth + this.chartColumnPadding)
    );
  }

  public startSocketSubscription() {
    this.stopSocketSubscription();
    this.subscription = this.socketService
      .onNewRealtimeEvent()
      .subscribe((data) => {
        if (
          data &&
          this.chosenBranchCameraGroups
            .map((cameragroup) => cameragroup.code)
            .includes(data.group_device_id)
        ) {
          let currentTempDataIdx = this.tempRealtimeData.findIndex(
            (tempData) => tempData.group_device_id == data.group_device_id
          );
          if (currentTempDataIdx > -1) {
            if (
              this.tempRealtimeData[currentTempDataIdx].created_timestamp !=
              data.created_timestamp
            ) {
              this.handleRealtimeData(data);
              this.tempRealtimeData[currentTempDataIdx] = data;
            }
          } else {
            this.handleRealtimeData(data);
            this.tempRealtimeData.push(data);
          }
        }
        // if (data && data.hourtime == moment().hour() && data.daytime == moment().format('DD-MM-yyyy')) {
        //   if (this.chosenBranchCameraGroups.map(cameragroup => cameragroup.code).includes(data.groupid)) {
        //     //new
        //     let currentCustno = this.eventData.filter(event => moment(event.timestamp).hour() == moment().hour()).length;
        //     console.log(moment().toLocaleString());
        //     console.log(data)
        //     console.log(currentCustno)
        //     if (data.custno > currentCustno) {
        //       let currentTempDataIdx = this.tempRealtimeData.findIndex(tempData => tempData.groupid == data.groupid);
        //       if (currentTempDataIdx > -1 ) {
        //         console.log(this.tempRealtimeData[currentTempDataIdx])
        //         if (this.tempRealtimeData[currentTempDataIdx].custno < data.custno) {
        //           let isInterest = this.tempRealtimeData[currentTempDataIdx].quan_tam < data.quan_tam ? true : false;
        //           this.tempRealtimeData[currentTempDataIdx] = data;
        //           this.handleRealtimeData(isInterest);
        //         }
        //       }
        //       else {
        //         let currentInterest = this.eventData.filter(event => (moment(event.timestamp).hour() == moment().hour() && event.duration >= this.interestDuration)).length;
        //         let isInterest = data.quan_tam > currentInterest ? true : false;
        //         this.tempRealtimeData.push(data);
        //         this.handleRealtimeData(isInterest);
        //       }
        //     }

        //     //old
        //     // let currentTempDataIdx = this.tempRealtimeData.findIndex(tempData => tempData.groupid == data.groupid);
        //     // if (currentTempDataIdx > -1 ) {
        //     //   if (this.tempRealtimeData[currentTempDataIdx].custno <= data.custno) {
        //     //     this.tempRealtimeData[currentTempDataIdx] = data;
        //     //     this.handleRealtimeData() //old
        //     //   }
        //     // }
        //     // else {
        //     //   this.tempRealtimeData.push(data);
        //     //   this.handleRealtimeData() //old
        //     // }
        //   }
        // }
        // if (data) {
        //   this.handleRealtimeData(data, this.selectedTab);
        // }
      });
  }

  public stopSocketSubscription() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  //old
  // public handleSelectTab(tab: string) {
  //   this.selectedTab = tab;
  //   switch(tab) {
  //     case 'day':
  //       this.xAxisUnit = 'Ngày';
  //       break;
  //     case 'month':
  //       this.xAxisUnit = 'Tháng';
  //       break;
  //     case 'week':
  //       this.xAxisUnit = 'Tuần';
  //       break;
  //   }
  //   this.getData();
  // }

  // public getData() {
  //   this.chartData = [];
  //   let viewName;
  //   switch(this.selectedTab) {
  //     case 'day':
  //       viewName = VIEW.sumCustnoByDay;
  //       break;
  //     case 'month':
  //       viewName = VIEW.sumCustnoByMonth;
  //       break;
  //     case 'week':
  //       viewName = VIEW.sumCustnoByWeek;
  //       break;
  //   }
  //   this.apiService.get<any>(API.view + viewName).subscribe(data => {
  //     if (data) {
  //       let filteredData = data.filter(row => row.branchid == this.chosenBranch.id);
  //       this.chartData = this.formatChartData(filteredData, this.selectedTab);
  //       this.originalData = filteredData;

  //       this.getEventData();
  //     }
  //   })
  // }

  // public getEventData() {
  //   this.chosenBranchCameraGroups.map(cameraGroup => {
  //     this.socketService.getEventStat(cameraGroup.code);
  //   })
  // }

  //old chart data handle
  // public formatChartData(data, type) {
  //   let result = [];
  //   let noCol = 0;
  //   if (data) {
  //     switch(type) {
  //       case 'day':
  //         noCol = moment().daysInMonth();
  //         break;
  //       case 'month':
  //         noCol = 12;
  //         break;
  //       case 'week':
  //         noCol = moment().isoWeeksInYear();
  //         break;
  //     }
  //     for(let i = 1; i <= noCol; i++) {
  //       let value = data.find(row => row[type] == i);
  //       let column = {
  //         name: i.toString(),
  //         series: [
  //           {
  //             name: 'interestedcustno',
  //             value: (value && value.interestedcustno) ? +value.interestedcustno : 0
  //           },
  //           {
  //             name: 'custno',
  //             value: (value && value.custno) ? (+value.custno - +value.interestedcustno) : 0,
  //           }
  //         ]
  //       }
  //       result.push(column);
  //     }
  //   }
  //   return result;
  // }

  // public handleRealtimeData() {
  //   this.chartData = this.formatChartData(this.originalData, this.selectedTab);
  //   this.tempRealtimeData.map(data => {
  //     let colName;
  //     switch(this.selectedTab) {
  //       case 'day':
  //         colName = moment(data.daytime, 'DD-MM-yyyy').date();
  //         break;
  //       case 'month':
  //         colName = moment(data.daytime, 'DD-MM-yyyy').month() + 1;
  //         break;
  //       case 'week':
  //         colName = moment(data.daytime, 'DD-MM-yyyy').isoWeeks();
  //         break;
  //     }
  //     let colIdx = this.chartData.findIndex(col => col.name == colName);
  //     if (colIdx > -1) {
  //       let temp = Object.assign({}, this.chartData[colIdx].series[1]);
  //       temp.value += data.custno - data.quan_tam;
  //       this.chartData[colIdx].series[1] = temp;

  //       temp = Object.assign({}, this.chartData[colIdx].series[0]);
  //       temp.value += data.quan_tam;
  //       this.chartData[colIdx].series[0] = temp;
  //     }
  //     this.chartData = [...this.chartData];
  //   })
  // }

  //new chart data handle

  public getData() {
    this.eventData = [];
    this.chartData = [];
    this.chartData = this.formatChartData();
    this.startTimer();
    this.chosenBranchCameraGroups.forEach((cameraGroup) => {
      this.getAIEvent(cameraGroup);
    });
  }

  public startTimer() {
    if (this.dataTimer) {
      clearInterval(this.dataTimer);
      this.dataTimer = null;
    }
    this.dataTimer = setInterval(
      () => this.updateChartColumn(),
      this.columnNameInterval * 1000
    ); //new
  }

  public getAIEvent(cameraGroup, paging_state = '') {
    let startOfHour = moment().startOf('hour');
    let startOfCol = moment().subtract(
      this.noCol * this.columnNameInterval,
      'seconds'
    );
    let params = {
      group_device_id: cameraGroup.code,
      from_datetime:
        startOfHour.diff(startOfCol, 'minutes') > 0
          ? startOfCol.format('YYYY-MM-DD[T]HH:mm:ss[.]SSS')
          : startOfHour.format('YYYY-MM-DD[T]HH:mm:ss[.]SSS'),
      to_datetime: moment().format('YYYY-MM-DD[T]HH:mm:ss[.]SSS'),
      paging_state: paging_state,
    };
    this.apiService.get<any>(API.aiEvents, params).subscribe((data) => {
      if (data && data.success) {
        let events = data.message;
        if (events.data && events.data.length > 0) {
          events.data.map((newEvent) => {
            let tmpEventIdx = this.eventData.findIndex(
              (event) =>
                event.created_timestamp == newEvent.created_timestamp &&
                event.timestamp == newEvent.timestamp
            );
            if (tmpEventIdx < 0) this.eventData.push(newEvent);
          });
          // this.eventData = [...this.eventData, ...events.data];
        }
        if (events.paging_state)
          this.getAIEvent(cameraGroup, events.paging_state);
        else {
          this.updateChartData();
          this.startSocketSubscription();
        }
      }
    });
  }

  public formatChartData() {
    let result = [];
    for (let i = 0; i <= this.noCol; i++) {
      let columnName = moment()
        .subtract((this.noCol - i) * this.columnNameInterval, 'seconds')
        .format(this.chartColNameFormat);
      let column = {
        name: columnName,
        series: [
          {
            name: 'interestedcustno',
            value: 0,
          },
          {
            name: 'custno',
            value: 0,
          },
        ],
      };
      result.push(column);
    }
    return result;
  }

  public updateChartData() {
    let result = [];
    this.chartData.forEach((column) => {
      let filteredData = this.eventData.filter((data) => {
        let timeDiff = moment(data.created_timestamp).diff(
          moment(column.name, 'HH:mm:ss'),
          'seconds'
        );
        if (timeDiff >= 0 && timeDiff < this.columnNameInterval) return data;
      });
      let interestCustno = filteredData.filter(
        (data) => data.duration >= this.interestDuration
      ).length;
      let newColumn = {
        name: column.name,
        series: [
          {
            name: 'interestedcustno',
            value: interestCustno,
          },
          {
            name: 'custno',
            value: filteredData.length - interestCustno,
          },
        ],
      };
      if (interestCustno > this.chartMaxYTick)
        this.chartMaxYTick = interestCustno;
      if (filteredData.length - interestCustno > this.chartMaxYTick)
        this.chartMaxYTick = filteredData.length - interestCustno;
      result.push(newColumn);
    });
    this.chartData = result;
  }

  // public handleRealtimeData(hasInterest: boolean = false) {
  //   if (this.chartData && this.chartData.length > 0) {
  //     let currentChartColumnData = this.chartData.pop();

  //     if (currentChartColumnData) {
  //       // let currentCustno = this.eventData.filter(event => moment(event.timestamp).hour() == moment().hour()).length;
  //       // let currentInterest = this.eventData.filter(event => moment(event.timestamp).hour() == moment().hour() && event.duration >= this.interestDuration).length;
  //       // let currentSocketData = this.tempRealtimeData.filter(tempData => this.chosenBranchCameraGroups.map(cameragroup => cameragroup.code).includes(tempData.groupid));
  //       // let currentSocketDataCustno = currentSocketData.length > 0 ? currentSocketData.reduce((a, b) => a + (b['custno'] || 0), 0) : 0;
  //       // let currentSocketDataInterest = currentSocketData.length > 0 ? currentSocketData.reduce((a, b) => a + (b['quan_tam'] || 0), 0) : 0;
  //       // console.log(this.tempRealtimeData)
  //       // console.log(currentInterest)
  //       // console.log(currentCustno);
  //       // console.log(currentSocketData.reduce((a, b) => a + (b['custno'] || 0), 0));
  //       // console.log(currentSocketData.reduce((a, b) => a + (b['quan_tam'] || 0), 0));
  //       if (hasInterest) currentChartColumnData.series[0].value += 1;
  //       // if (hasInterest) currentChartColumnData.series[0].value += currentSocketDataCustno > 0 ? currentSocketDataCustno - currentCustno : 0;
  //       else currentChartColumnData.series[1].value += 1;
  //       // else currentChartColumnData.series[1].value += currentSocketDataInterest > 0 ? currentSocketDataInterest - currentInterest : 0;
  //       this.chartData = [...this.chartData, currentChartColumnData];
  //     }
  //   }
  // }

  public handleRealtimeData(data: any) {
    if (this.chartData && this.chartData.length > 0) {
      let column = this.chartData.find((column) => {
        let timeDiff = -moment(column.name, 'HH:mm:ss').diff(
          moment(data.created_timestamp),
          'seconds'
        );
        if (timeDiff >= 0 && timeDiff < this.columnNameInterval) return data;
      });
      if (!column) {
        column = {
          name: moment(data.created_timestamp).format('HH:mm:ss'),
          series: [
            {
              name: 'interestedcustno',
              value: data.duration >= this.interestDuration ? 1 : 0,
            },
            {
              name: 'custno',
              value: data.duration >= this.interestDuration ? 0 : 1,
            },
          ],
        };
        this.chartData.shift();
        this.chartData = [...this.chartData, column];
      } else {
        // column.series[1].value += 1;
        if (data.duration >= this.interestDuration) {
          // column.series[0].value += 1;
          let tempInterest = Object.assign({}, column.series[0]);
          tempInterest.value += 1;
          if (tempInterest.value > this.chartMaxYTick)
            this.chartMaxYTick = tempInterest.value;
          column.series[0] = tempInterest;
        } else {
          let tempCustno = Object.assign({}, column.series[1]);
          tempCustno.value += 1;
          if (tempCustno.value > this.chartMaxYTick)
            this.chartMaxYTick = tempCustno.value;
          column.series[1] = tempCustno;
        }
        this.chartData = [...this.chartData];
      }
    }
  }

  public updateChartColumn() {
    let currentTime = moment().format(this.chartColNameFormat);
    if (this.chartData.length > 0) {
      let lastestTimeData = this.chartData[this.chartData.length - 1].name;
      if (currentTime != lastestTimeData) {
        let newColumn = {
          name: currentTime,
          series: [
            {
              name: 'interestedcustno',
              value: 0,
            },
            {
              name: 'custno',
              value: 0,
            },
          ],
        };
        this.chartData.shift();
        this.chartData = [...this.chartData, newColumn];
      }
    }
  }

  public getColumnData(columnName: string) {
    let data = this.chartData.find(
      (column) => column.name == columnName
    ).series;
    return data;
  }

  public getBranchChildren() {
    this.apiService
      .get<any>(API.view + FUNC.getBranchChildren, {
        parent: this.chosenBranch.id,
      })
      .subscribe((branches) => {
        if (branches) {
          this.chosenBranchChildren = branches;
          this.getCameraGroup();
        }
      });
  }

  public getCameraGroup() {
    let cameraGroupQuery = {
      op: 'AND',
      val: [],
    };
    if (this.chosenBranch && this.chosenBranch.id) {
      cameraGroupQuery.val.push({
        model: 't_cameragroup',
        col: 'branchid',
        op: 'in',
        val: this.chosenBranchChildren.map((branch) => branch.id),
      });
    }
    this.apiService
      .get<Cameragroup[]>(API.cameraGroup, {
        query: JSON.stringify(cameraGroupQuery),
      })
      .subscribe((cameraGroups) => {
        if (cameraGroups) {
          if (this.chosenCameragroup && this.chosenCameragroup.id != 0) {
            this.chosenBranchCameraGroups = [this.chosenCameragroup];
          } else {
            this.chosenBranchCameraGroups = cameraGroups;
          }
          this.getData();
        }
      });
  }

  //new
  public getInterestDuration() {
    let attributeQuery = {
      op: 'AND',
      val: [
        {
          model: 't_eventattribute',
          col: 'code',
          op: '=',
          val: 'interestingtime',
        },
      ],
    };
    this.apiService
      .get<any>(API.eventAttributes, { query: JSON.stringify(attributeQuery) })
      .subscribe((attr) => {
        if (attr) {
          this.interestDuration = attr[0].val;
        }
      });
  }

  public formatYAxisTick(val) {
    if ((val * 10) % 10 == 0) return val;
  }
}
