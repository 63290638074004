import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { API, BASE_HOST, OIDC_CONFIG } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AlertService } from '../alert.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public newUserObserver = new Subject<any>();
  private readonly oidcCodeFlowConfig: AuthConfig = OIDC_CONFIG;
  constructor(
    private oauthService: OAuthService,
    private htttpClient: HttpClient,
    private router: Router,
    private alertService: AlertService
  ) {}

  public setLoginData(loginData) {
    localStorage.setItem('token', loginData.token);
    localStorage.setItem('userInfo', JSON.stringify(loginData.user));
    this.newUserObserver.next(loginData.user);
  }

  public getToken() {
    let token = localStorage.getItem('token');
    return token;
  }

  public getUserInfo() {
    let userInfo = localStorage.getItem('userInfo');
    if (userInfo) return JSON.parse(userInfo);
    else return null;
  }

  public deleteLoginData() {
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    this.newUserObserver.next(null);
  }

  public setPreviousPage(previousPage) {
    localStorage.setItem('prevPage', previousPage);
  }

  public getPreviousPage() {
    let previousPage = localStorage.getItem('prevPage');
    return previousPage;
  }

  public onChangeUser() {
    return this.newUserObserver.asObservable();
  }

  public initOIDCConfig() {
    if (!this.oauthService.discoveryDocumentLoaded) {
      this.oauthService.configure(this.oidcCodeFlowConfig);
    }
  }

  public initLoginOIDC(targetUrl?: string) {
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  public getOIDCToken() {
    const token = this.oauthService.getAccessToken();
    return token;
  }

  public logoutOIDC() {
    return this.oauthService.revokeTokenAndLogout();
  }

  public loginOIDC(token: string): Observable<any> {
    return this.htttpClient.post<any>(`${BASE_HOST}${API.loginOIDC}`, {
      token,
    });
  }

  public logout() {
    this.deleteLoginData();
    if (this.hasValidToken()) {
      this.logoutOIDC();
    }
    this.router.navigate(['/login']);
  }

  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }

  public runInitialLoginSequence() {
    this.initOIDCConfig();
    this.oauthService
      .loadDiscoveryDocumentAndTryLogin()
      .then((_) => {
        if (this.hasValidToken()) {
          return Promise.resolve();
        }

        this.oauthService
          .silentRefresh()
          .then(() => Promise.resolve())
          .catch((result) => {
            // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
            // Only the ones where it's reasonably sure that sending the
            // user to the IdServer will help.
            const errorResponsesRequiringUserInteraction = [
              'interaction_required',
              'login_required',
              'account_selection_required',
              'consent_required',
            ];

            if (
              result &&
              result.reason &&
              errorResponsesRequiringUserInteraction.indexOf(
                result.reason.error
              ) >= 0
            ) {
              // 3. ASK FOR LOGIN:
              // At this point we know for sure that we have to ask the
              // user to log in, so we redirect them to the IdServer to
              // enter credentials.
              //
              // Enable this to ALWAYS force a user to login.
              // this.initLoginOIDC();
              //
              // Instead, we'll now do this:
              console.warn(
                'User interaction is needed to log in, we will wait for the user to manually log in.'
              );
              return Promise.resolve();
            }

            // We can't handle the truth, just pass on the problem to the
            // next handler.
            return Promise.reject(result);
          });
      })
      .then(() => {
        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        if (
          this.oauthService.state &&
          this.oauthService.state !== 'undefined' &&
          this.oauthService.state !== 'null'
        ) {
          let stateUrl = this.oauthService.state;
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl);
          }
          // console.info(
          //   `There was state of ${this.oauthService.state}, so we are sending you from: ${stateUrl} to Dashboard Page`
          // );
          const token = this.getOIDCToken();
          this.loginOIDC(token).subscribe(
            (data) => {
              if (!data.success) {
                this.alertService.informError(data.message);
                return;
              }
              this.setLoginData(data);
              this.router.navigate(['/analysis']);
            },
            (error) => {
              this.alertService.informError(error.error).then(() => {
                if (error.status === 401) {
                  this.logoutOIDC();
                }
              });
            }
          );
        }
      })
      .catch(() => {});
  }
}
