import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { API, REPLACEMENT } from 'src/environments/environment';

@Component({
  selector: 'app-list-monitor',
  templateUrl: './list-monitor.component.html',
  styleUrls: ['./list-monitor.component.scss'],
})
export class ListMonitorComponent implements OnInit {
  public cameraGroups: any[] = [];
  public totalCameras: number = 0;
  public activeCameras: number = 0;
  public selectedCamera: any = null;
  public textSearch = '';
  public noData = REPLACEMENT.noData;
  @Output() changedSelectCamera = new EventEmitter<any>();
  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.getCameras();
  }

  public getCameras() {
    const cameraInclude = [
      {
        model: 't_cameragroup',
        include: [],
      },
    ];
    const cameraQuery = {
      op: 'AND',
      val: [
        {
          model: 't_camera',
          col: 'name',
          op: 'ilike',
          val: `%${this.textSearch}%`,
        },
        {
          model: 't_cameragroup',
          col: 'deactiveyn',
          op: '!=',
          val: 'T',
        },
        {
          model: 't_camera',
          col: 'deactiveyn',
          op: '!=',
          val: 'T',
        },
      ],
    };
    this.apiService
      .get<any[]>(API.cameras, {
        include: JSON.stringify(cameraInclude),
        query: JSON.stringify(cameraQuery),
      })
      .subscribe((cameras) => {
        this.formatCameras(cameras);
        this.totalCameras = cameras.length;
        this.activeCameras = cameras.filter(
          (camera) => camera.deactiveyn == 'F'
        ).length;
        if (cameras.length > 0) this.onClickCamera(cameras[0]);
      });
  }

  public formatCameras(cameras: any[]) {
    this.cameraGroups = [];
    cameras.forEach((camera, idx) => {
      let cameraGroup = this.cameraGroups.find(
        (cameraGroup) => cameraGroup.id == camera.t_cameragroup.id
      );
      if (cameraGroup) {
        cameraGroup.cameras.push(camera);
      } else {
        cameraGroup = camera.t_cameragroup;
        cameraGroup.cameras = [camera];
        cameraGroup.isExpand = true;
        this.cameraGroups.push(cameraGroup);
      }
    });
  }

  public toggleCameraGroup(cameraGroup: any) {
    cameraGroup.isExpand = !cameraGroup.isExpand;
  }

  public onClickCamera(camera: any) {
    this.selectedCamera = camera;
    this.changedSelectCamera.emit(camera);
  }
}
