import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})
export class TreeViewComponent implements OnInit, OnChanges {

  @Input() itemList = [];
  @Input() autoSelectFirst = true;
  @Input() itemType = 'branch';
  @Output() changedSelectedItem = new EventEmitter()
  @Input() defaultItem = null;
  @Input() useItemTypeToRoute = false;
  public selectedItem;
  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}) {
    if (changes['itemList'] && changes['itemList'].currentValue && changes['itemList'].currentValue.length > 0) {
      if (this.autoSelectFirst && !this.defaultItem) this.selectItem(this.itemList[0]);
      else if (this.defaultItem) this.selectItem(this.defaultItem);
    }
    if (changes['defaultItem']) {
      if (this.defaultItem) this.selectItem(this.defaultItem);
      else this.selectedItem = null;
    }
  }

  public selectItem(item: any) {
    this.selectedItem = item;
    this.changedSelectedItem.emit(item);
    if (this.itemList.map(tmpItem => tmpItem.code).includes(item.code)) {
      let queryParams = {}
      queryParams[this.itemType] = this.selectedItem.id;
      this.defaultItem = item;
      if (this.useItemTypeToRoute) this.router.navigate(['/manage/' + this.itemType], { queryParams: queryParams})
      else this.router.navigate(['/manage/branch'], { queryParams: queryParams})
    }
  }

  public expandItem(clickedItem: any) {
    clickedItem.isExpand = !clickedItem.isExpand;
  }

}
