<div class="full-width full-height position-relative background-login-page">
  <div class="parent-background">
    <div class="child-background"></div>
  </div>
  <ng-container *ngIf="!isHandlingOIDC; else handlingOIDCTemplate">
    <div class="body-container">
      <h3 class="header-login">Giám sát khuôn mặt</h3>
      <div class="form-container p-4">
        <div class="input-container row">
          <!--      <i class="fa fa-user icon"></i>-->
          <div class="login-name col-md-5 col-sm-12">Tên đăng nhập</div>
          <input
            class="input-field col-md-7 col-sm-12"
            type="text"
            placeholder="Tên đăng nhập"
            name="usrnm"
            [(ngModel)]="username"
            (keydown.enter)="login()"
          />
        </div>
        <div class="input-container row">
          <!--      <i class="fa fa-key icon"></i>-->
          <div class="password col-md-5 col-sm-12">Mật khẩu</div>
          <input
            class="input-field col-md-7 col-sm-12"
            type="password"
            placeholder="Mật khẩu"
            name="psw"
            [(ngModel)]="password"
            (keydown.enter)="login()"
          />
        </div>
        <div class="row" style="margin-top: 6vh !important">
          <button
            type="submit"
            class="btn background-primary-style pull-right col-md-5 col-sm-12"
            (click)="login()"
          >
            Đăng nhập
          </button>
          <div class="col-xs-12 col-md-2" style="height: 15px"></div>
          <button
            type="button"
            class="btn background-default-style color-black-style col-md-5 col-sm-12"
            (click)="initLoginOIDC()"
          >
            TRIS ID
          </button>
        </div>
        <div
          class="row"
          style="margin-top: 10px !important; justify-content: center"
        >
          <a class="sub-title">Quên mật khẩu?</a>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #handlingOIDCTemplate>
    <ngx-spinner
      bdColor="rgba(255,255,255,0.4)"
      size="medium"
      color="#000"
      type="ball-spin"
      [name]="spinnerName"
      [zIndex]="1"
    >
      <span>Đang xử lý ...</span>
    </ngx-spinner>
  </ng-template>
</div>
