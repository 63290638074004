import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DelayInputService {
  constructor() {}

  private codeCallbackFns = {};

  public delay(code: string, callback: Function, delayTime = 500) {
    if (this.codeCallbackFns[code]) {
      clearTimeout(this.codeCallbackFns[code]);
    }
    this.codeCallbackFns[code] = setTimeout(() => {
      callback();
      delete this.codeCallbackFns[code];
    }, delayTime);
  }
}
