import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
} from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from 'src/app/models/customer';
import { Employee } from 'src/app/models/employee';
import { Option } from 'src/app/models/option';
import { PagedItem } from 'src/app/models/paged-item';
import { User } from 'src/app/models/user';
import { ApiService } from 'src/app/services/api.service';
import {
  API,
  API_IMG_HOST,
  FUNC,
  REPLACEMENT,
} from 'src/environments/environment';
import { Branch } from '../../models/branch';

@Component({
  selector: 'app-manage-person',
  templateUrl: './manage-person.component.html',
  styleUrls: ['./manage-person.component.scss'],
})
export class ManagePersonComponent implements OnInit, OnChanges {
  public manageType: string = 'emp';
  public noText = REPLACEMENT.noText;
  public isShow: boolean = true;
  public apiImgHost = API_IMG_HOST;
  public showDetail = false;
  public selectedPersonId: number = null;
  public personList = [];
  public currentPage = 1;
  public personsPerPage = 10;
  public noPerson: number = 0;
  public textSearch: string = '';
  public currentFilterTab: Option;
  public isAddForm = false;
  private personType = ['emp', 'customer', 'user'];
  public chosenBranchChildren = [];

  constructor(private apiService: ApiService, private router: Router) {}

  ngOnInit(): void {
    this.manageType = this.router.url.split('/')[2];
    this.resetPageVar();
    this.getPerson();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    // if (changes['manageType'] && changes['manageType'].currentValue) {
    //   if (this.personType.includes(this.manageType)) this.isShow = true;
    //   else this.isShow = false;
    //   this.resetPageVar();
    //   this.getPerson();
    // }
  }

  public resetPageVar() {
    this.showDetail = false;
    this.currentPage = 1;
    this.noPerson = 0;
    this.currentFilterTab = null;
    this.personList.length = 0;
  }

  public getPerson() {
    this.personList.length = 0;
    switch (this.manageType) {
      case 'customer':
        this.getCustomer();
        break;
      case 'user':
        this.getUser();
        break;
      default:
        this.getEmployee();
    }
  }

  public getEmployee() {
    let employeeInclude = [
      {
        model: 't_branch',
        include: [],
      },
      {
        model: 't_empteam',
        include: [],
      },
    ];
    let employeeQuery = {
      op: 'AND',
      val: [
        {
          model: 't_emp',
          col: 'name',
          op: 'ilike',
          val: '%' + this.textSearch + '%',
        },
        {
          model: 't_emp',
          col: 'branchid',
          op: 'in',
          val: this.chosenBranchChildren,
        },
      ],
    };
    this.apiService
      .get<PagedItem<Employee>>(API.employees, {
        include: JSON.stringify(employeeInclude),
        query: JSON.stringify(employeeQuery),
        page: this.currentPage,
        itemsPerPage: this.personsPerPage,
      })
      .subscribe((employees) => {
        if (employees) {
          this.noPerson = employees.count;
          employees.rows.forEach((employee) => {
            employee.img = employee.img + `?${Date.now()}`;
          });
          this.personList = employees.rows;
        }
      });
  }

  public getCustomer() {
    let customerInclude = [
      {
        model: 't_option',
        as: 'type',
        include: [],
      },
      {
        model: 't_branch',
        include: [],
      },
    ];
    let customerQuery = {
      op: 'AND',
      val: [
        {
          model: 't_customer',
          col: 'name',
          op: 'ilike',
          val: '%' + this.textSearch + '%',
        },
        {
          model: 't_customer',
          col: 'branchid',
          op: 'in',
          val: this.chosenBranchChildren,
        },
      ],
    };
    if (this.currentFilterTab && this.currentFilterTab.id != 0) {
      customerQuery.val.push({
        model: 't_customer',
        col: 'customertypeoptid',
        op: '=',
        val: this.currentFilterTab.id.toString(),
      });
    }
    this.apiService
      .get<PagedItem<Customer>>(API.customers, {
        include: JSON.stringify(customerInclude),
        query: JSON.stringify(customerQuery),
        page: this.currentPage,
        itemsPerPage: this.personsPerPage,
      })
      .subscribe((customers) => {
        if (customers) {
          this.noPerson = customers.count;
          customers.rows.forEach((customer) => {
            customer.img = customer.img + `?${Date.now()}`;
          });
          this.personList = customers.rows;
        }
      });
  }

  public getUser() {
    let userInclude = [
      {
        model: 't_branch',
        include: [],
      },
    ];
    let userQuery = {
      op: 'AND',
      val: [
        {
          model: 't_user',
          col: 'name',
          op: 'ilike',
          val: '%' + this.textSearch + '%',
        },
        {
          model: 't_user',
          col: 'branchid',
          op: 'in',
          val: this.chosenBranchChildren,
        },
      ],
    };
    this.apiService
      .get<PagedItem<User>>(API.users, {
        include: JSON.stringify(userInclude),
        query: JSON.stringify(userQuery),
        page: this.currentPage,
        itemsPerPage: this.personsPerPage,
      })
      .subscribe((users) => {
        if (users) {
          this.noPerson = users.count;
          this.personList = users.rows;
        }
      });
  }

  public showEmptyForm() {
    this.selectedPersonId = null;
    this.isAddForm = true;
    this.showDetail = true;
  }

  public hideDetailForm(event) {
    this.showDetail = event;
  }

  public showPersonDetail(empId) {
    this.selectedPersonId = empId;
    this.isAddForm = false;
    this.showDetail = true;
  }

  public changePage(page: number) {
    this.currentPage = page;
    this.getPerson();
  }

  public searchPerson(searchString: string) {
    this.textSearch = searchString;
    this.getPerson();
  }

  public handleChangeTab(newTab: Option) {
    this.currentFilterTab = newTab;
    this.currentPage = 1;
    this.getPerson();
  }

  filterBranch(branch) {
    this.getChosenBranchChildren(branch);
  }
  public getChosenBranchChildren(parent) {
    this.apiService
      .get<any[]>(API.view + FUNC.getBranchChildren, { parent: parent.id })
      .subscribe((branches) => {
        if (branches) {
          this.chosenBranchChildren = branches.map((branch) => branch.id);
          this.getPerson();
        }
      });
  }
}
