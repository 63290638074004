import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Branch } from 'src/app/models/branch';
import { ApiService } from 'src/app/services/api.service';
import { API, FUNC } from 'src/environments/environment';

@Component({
  selector: 'app-evaluate-report',
  templateUrl: './evaluate-report.component.html',
  styleUrls: ['./evaluate-report.component.scss']
})
export class EvaluateReportComponent implements OnInit, OnChanges {

  @Input() selectedBranch: Branch;
  public branchKPI = {custno: 0, interestedcustno: 0, sumwatchtime: 0};
  public branchStatistic = {custno: 0, interestedcustno: 0, sumwatchtime: 0};
  public custnoData = [{name: 'Khách hàng', value: 0}];
  public interestingData = [{name: 'Quan tâm', value: 0}];
  public watchtimeData = [{name: 'Thời gian xem', value: 0}];
  public stallList;
  public customerColorScheme = {
    domain: ['#00BE5F']
  };
  public interestingColorScheme = {
    domain: ['#00DCFF']
  };
  public watchtimeColorScheme = {
    domain: ['#FF00D5']
  };
  public maxPoint = 100;
  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange}) {
    if (changes['selectedBranch'] && changes['selectedBranch'].currentValue) {
      this.getBranchStatistic();
    }
  }

  public getBranchStatistic() {
    this.maxPoint = 0;
    let kpi = {custno: 0, interestedcustno: 0, sumwatchtime: 0};
    let statistic = {custno: 0, interestedcustno: 0, sumwatchtime: 0};
    this.apiService.get<any>(API.view + FUNC.getBranchStatistic, {branch: this.selectedBranch.id}).subscribe(data => {
      if (data) {
        this.stallList = data;
        data.map(stall => {
          kpi.custno += stall.kpi_custno ? +stall.kpi_custno : 0;
          kpi.interestedcustno += stall.kpi_interestedcustno ? +stall.kpi_interestedcustno : 0;
          kpi.sumwatchtime += (+stall.kpi_custno && stall.kpi_sumwatchtime) ? (+stall.kpi_sumwatchtime/+stall.kpi_custno) : 0;
          statistic.custno += stall.custno ? +stall.custno : 0;
          statistic.interestedcustno += stall.interestedcustno ? +stall.interestedcustno : 0;
          statistic.sumwatchtime += (+stall.custno && stall.sumwatchtime) ? (+stall.sumwatchtime/+stall.custno) : 0;
        })
        this.branchKPI = kpi;
        this.branchStatistic = statistic;
        this.custnoData = [{name: 'Khách hàng', value: (statistic.custno && kpi.custno) ? Math.round((+statistic.custno/+kpi.custno)*100) : 100}];
        this.interestingData = [{name: 'Quan tâm', value: (statistic.interestedcustno && +kpi.interestedcustno > 0) ? Math.round((+statistic.interestedcustno/+kpi.interestedcustno)*100) : 100}];
        this.watchtimeData = [{name: 'Thời gian xem', value: (statistic.sumwatchtime && kpi.sumwatchtime) ? Math.round((statistic.sumwatchtime/kpi.sumwatchtime)*100) : 100}];
      }
      this.maxPoint = 100;
    })
  }

  public formatChartData(val) {
    return val + '%';
  }
}
