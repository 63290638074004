import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalVariablesService } from '../../services/variables/global-variables.service';
@Component({
  selector: 'app-analysis-layout',
  templateUrl: './analysis-layout.component.html',
  styleUrls: ['./analysis-layout.component.scss'],
})
export class AnalysisLayoutComponent implements OnInit {
  public isShowSideBar: any = false;
  public isShowAnalysis: string = 'Dashboard';
  public urlSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private globalService: GlobalVariablesService
  ) {}

  ngOnInit(): void {
    this.urlSubscription = this.route.queryParams.subscribe((params) => {
      let tab = this.route.snapshot.fragment;
      if (tab) {
        this.isShowAnalysis = tab;
      }
    });
  }

  public handleShowSideBar() {
    this.isShowSideBar = this.isShowSideBar !== true;
  }

  public handleShowAnalysis(tabName) {
    this.isShowAnalysis = tabName;
  }
}
