import { Component, Input, OnInit } from '@angular/core';
import { Branch } from 'src/app/models/branch';
import { Camera } from 'src/app/models/camera';
import { Cameragroup } from 'src/app/models/cameragroup';
import { SettingOption } from 'src/app/models/setting-option';
import { ApiService } from 'src/app/services/api.service';
import { API, REPLACEMENT } from 'src/environments/environment';

import * as h337 from 'heatmapjs';
import { Config } from 'src/app/models/config';
import * as moment from 'moment';

@Component({
  selector: 'app-heat-map',
  templateUrl: './heat-map.component.html',
  styleUrls: ['./heat-map.component.scss'],
})
export class HeatMapComponent implements OnInit {
  @Input() active: string;
  public chosenBranch: Branch;
  public chosenTimeOption: SettingOption;
  public chosenStall: Cameragroup;
  public chosenCamera: Camera;
  public errorImg = REPLACEMENT.noDeviceImg;
  public heatmap;
  public cameraConfig;
  public loadImgSuccess = true;
  public dateFormat = 'YYYY-MM-DD HH:mm:ss[.]SSS';
  public maxTimeOption = 30;
  public pointRadius = 40;
  public originRadius = 80;
  public maxHeatmapValue = 1;
  public showHeatmapSlider = false;
  public fromDate = moment().startOf('day').format(this.dateFormat);
  public toDate = moment().endOf('day').format(this.dateFormat);
  public maxUpperBound;
  public maxLowerBound;
  constructor(private apiService: ApiService) {}

  ngOnInit(): void {}

  public onImgError(event) {
    this.loadImgSuccess = false;
    event.target.src = this.errorImg;
  }

  public handleClickedBranch(branch: Branch) {
    this.chosenBranch = branch;
  }

  public handleClickedTimeOption(timeOption: SettingOption) {
    this.chosenTimeOption = timeOption;
    if (timeOption.name == 'Hôm nay') {
      this.fromDate = moment().startOf('day').format(this.dateFormat);
      this.toDate = moment().endOf('day').format(this.dateFormat);
    } else
      this.fromDate = moment(this.toDate, this.dateFormat)
        .subtract(+timeOption.value - 1, 'days')
        .startOf('day')
        .format(this.dateFormat);
    if (this.chosenCamera) {
      this.resetHeatmap();
      if (this.loadImgSuccess) this.showHeatMap();
    }
  }

  public handleClickedStall(stall: Cameragroup) {
    this.chosenStall = stall;
    this.resetHeatmap();
    this.getCamera(stall);
  }

  public getCamera(stall: Cameragroup) {
    if (this.chosenCamera) this.chosenCamera.cameraurl = null;
    let cameraInclude = [
      {
        model: 't_option',
        include: [],
      },
    ];
    let cameraQuery = {
      op: 'AND',
      val: [
        {
          model: 't_camera',
          col: 'cameragroupid',
          op: '=',
          val: stall.id,
        },
        {
          model: 't_option',
          col: 'code',
          op: '=',
          val: 'C',
        },
      ],
    };
    this.apiService
      .get<any[]>(API.cameras, {
        include: JSON.stringify(cameraInclude),
        query: JSON.stringify(cameraQuery),
      })
      .subscribe((cameras) => {
        if (cameras && cameras.length > 0) {
          this.chosenCamera = cameras[0];
          this.loadImgSuccess = true;
        }
      });
  }

  public getCameraConfig() {
    if (this.loadImgSuccess) {
      let configQuery = {
        op: 'AND',
        val: [
          {
            model: 't_config',
            col: 'cameraid',
            op: '=',
            val: this.chosenCamera.id,
          },
          {
            model: 't_config',
            col: 'code',
            op: '=',
            val: 'cameraSetting',
          },
        ],
      };
      this.apiService
        .get<Config[]>(API.configs, { query: JSON.stringify(configQuery) })
        .subscribe((configs) => {
          if (configs && configs.length > 0) {
            this.cameraConfig = JSON.parse(configs[0].val);
            this.showHeatMap();
          }
        });
    }
  }

  public showHeatMap() {
    this.initHeatmap();
    this.getHeatmapPoints();
  }

  public initHeatmap() {
    if (this.heatmap) {
      delete this.heatmap;
      this.heatmap = null;
    }
    if (!this.heatmap) {
      let container = document.getElementById('container');
      this.heatmap = h337.create({
        container: container,
        gradient: {
          // enter n keys between 0 and 1 here
          // for gradient color customization
          '.3': 'rgb(0,0,255)',
          '.55': 'rgb(0,255,0)',
          '.85': 'yellow',
          '1': 'rgb(255,0,0)',
        },
        // maxOpacity: .5,
        minOpacity: 0,
        blur: 0.5,
        onExtremaChange: (data) => this.updateLegend(data),
      });
    }
  }

  public getHeatmapPoints() {
    // this.maxHeatmapValue = 0;
    let eventQuery = {
      op: 'AND',
      val: [
        {
          model: 't_event_realtime',
          op: '=',
          col: 'groupdevice',
          val: this.chosenStall.code,
        },
        {
          model: 't_event_realtime',
          op: '!=',
          col: 'x',
          val: null,
        },
        {
          model: 't_event_realtime',
          op: '!=',
          col: 'y',
          val: null,
        },
        {
          model: 't_event_realtime',
          op: '>=',
          col: 'createdtimestamp',
          // val: moment().subtract(+this.chosenTimeOption.value - 1, 'days')
          val: this.fromDate,
        },
        {
          model: 't_event_realtime',
          op: '<=',
          col: 'createdtimestamp',
          val: this.toDate,
        },
      ],
    };
    this.apiService
      .get<any[]>(API.realtimeEvents, { query: JSON.stringify(eventQuery) })
      .subscribe((events) => {
        if (events) {
          this.originRadius =
            events.map((event) => event.faceradius).reduce((a, b) => a + b, 0) /
            events.length;
          this.scaleHeatmapRadius();
          this.setMaxBound(events.length);
          let data = {
            min: 0,
            max: this.maxHeatmapValue,
            points: [],
          };
          for (let i = 0; i < events.length; i++) {
            let scaledPoint = this.scaleHeatPointToWeb(
              events[i].x,
              events[i].y
            );
            data.points.push({
              x: scaledPoint.x,
              y: scaledPoint.y,
              value: 1,
              radius: this.pointRadius,
            });
          }
          this.heatmap.setData({
            max: data.max,
            min: data.min,
            data: data.points,
          });
          this.showHeatmapSlider = true;
        }
      });
  }

  public resetHeatmap() {
    if (this.heatmap) {
      this.heatmap.setData({
        max: 0,
        data: [],
      });
    }
  }

  public scaleHeatPointToWeb(x, y) {
    let orgWidth = this.cameraConfig.width;
    let orgHeight = this.cameraConfig.height;
    let container = document.getElementById('source');
    let webWidth = container.clientWidth;
    let webHeight = container.clientHeight - 1;
    let widthRatio = webWidth / orgWidth;
    let heightRatio = webHeight / orgHeight;
    let scaledPoint = { x: x * widthRatio, y: y * heightRatio };
    return scaledPoint;
  }

  public scaleHeatmapRadius() {
    let container = document.getElementById('source');
    let webWidth = container.clientWidth;
    let orgWidth = this.cameraConfig.width;
    let widthRatio = webWidth / orgWidth;
    this.pointRadius = Math.floor(this.originRadius * widthRatio);
  }

  public updateLegend(data) {
    // the onExtremaChange callback gives us min, max, and the gradientConfig
    // so we can update the legend
    let legendCanvas = document.createElement('canvas');
    legendCanvas.width = 100;
    legendCanvas.height = 10;
    let min = document.querySelector('#min');
    let avg1: HTMLElement = document.querySelector('#avg1');
    let avg2: HTMLElement = document.querySelector('#avg2');
    let max = document.querySelector('#max');
    let gradientImg: any = document.querySelector('#gradient');
    let legendCtx = legendCanvas.getContext('2d');
    let gradientCfg = {};
    min.innerHTML = data.min;
    let avg1Val = Math.ceil((data.min + data.max) * 0.3);
    let avg2Val = Math.ceil((data.min + data.max) * 0.55);
    if (avg1Val > data.min && avg1Val < data.max) {
      avg1.innerHTML = avg1Val.toString();
      avg1.style.display = 'block';
    } else avg1.style.display = 'none';
    if (avg2Val > data.min && avg2Val < data.max) {
      avg2.innerHTML = avg2Val.toString();
      avg2.style.display = 'block';
    } else avg2.style.display = 'none';
    max.innerHTML = data.max + '+';
    // regenerate gradient image
    if (data.gradient != gradientCfg) {
      gradientCfg = data.gradient;
      let gradient = legendCtx.createLinearGradient(0, 0, 100, 1);
      for (let key in gradientCfg) {
        gradient.addColorStop(+key, gradientCfg[key]);
      }
      legendCtx.fillStyle = gradient;
      legendCtx.fillRect(0, 0, 100, 10);
      gradientImg.src = legendCanvas.toDataURL();
    }
  }

  public handleChangedMaxHeatmapVal(newValue) {
    if (this.heatmap) {
      let data = this.heatmap.getData();
      data.max = newValue;
      this.heatmap.setData(data);
    }
  }

  public handleChangeDate(newDate, type) {
    if (type == 'from') {
      this.fromDate = moment(newDate, 'DD-MM-YYYY')
        .startOf('day')
        .format(this.dateFormat);
    }
    if (type == 'to') {
      this.toDate = moment(newDate, 'DD-MM-YYYY')
        .endOf('day')
        .format(this.dateFormat);
    }
    this.getHeatmapPoints();
  }

  public setMaxBound(noEvent) {
    this.maxUpperBound = Math.ceil(noEvent / 5);
    this.maxLowerBound = Math.ceil(this.maxUpperBound / 10);
    this.maxHeatmapValue = Math.ceil(
      (this.maxUpperBound + this.maxLowerBound) * 0.35
    );
  }
}
