<div class="form-modal" [ngClass]="{ 'display-none': !isShow }">
  <div class="full-height p-3" style="overflow-y: auto">
    <div class="full-width full-height row p-2">
      <input
        #file
        type="file"
        id="avatarFile"
        (change)="handleChangeAvatar(file.files)"
        hidden
      />
      <div class="close-form-btn" (click)="hideDetailForm()">
        <i class="fa fa-close"></i>
      </div>
      <ng-container *ngIf="currentStep && currentStep.stepNo == 1">
        <div class="col-12">
          <div class="img-container">
            <div style="position: relative">
              <img
                class="solo-img"
                [src]="avatarFile"
                alt=""
                (click)="openFileExplorer('avatarFile')"
                onerror="this.src='assets/image/no-avatar.png'"
                [ngClass]="{
                  handle: !disableAvatarInput,
                  'animate-img': zoomImg
                }"
              />
              <ngx-spinner
                bdColor="rgba(255,255,255,0.4)"
                size="medium"
                color="#000"
                type="ball-spin"
                [fullScreen]="false"
                [name]="spinnerName"
              >
              </ngx-spinner>
            </div>
            <div
              class="text-group mt-3"
              [ngClass]="{ handle: !disableAvatarInput }"
              style="align-items: flex-end !important"
              (click)="openFileExplorer('avatarFile')"
            >
              <div class="text-delete mb-5">
                <h5>
                  <span><i class="fa fa-camera"></i></span> Chọn ảnh đại diện
                  của đối tượng
                </h5>
              </div>
            </div>
          </div>
          <div class="d-flex" style="align-items: flex-start; width: 100%">
            <button
              class="btn btn-secondary btn-sm mt-2 mr-2 pull-left full-width"
              (click)="hideDetailForm()"
            >
              <i class="fa fa-arrow-left mr-1"></i>Trở về
            </button>
            <button
              class="btn btn-secondary btn-sm mt-2 mr-2 pull-right full-width"
              (click)="getFaceuid()"
            >
              Tiếp theo<i class="fa fa-arrow-right ml-1"></i>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep && currentStep.stepNo == 2">
        <div class="col-4" style="text-align: center">
          <img class="custom-img" [src]="avatarFile" alt="" />
          <div class="d-flex">
            <button
              class="btn btn-secondary btn-sm mt-2 mr-2 pull-left full-width"
              (click)="prevStep()"
            >
              <i class="fa fa-arrow-left mr-1"></i>Trở về
            </button>
            <!-- <button class="btn btn-sm btn-secondary mt-2 mr-2 pull-right full-width" (click)="showEmptyForm()">
                            Tạo mới<i class="fa fa-arrow-right ml-1"></i></button> -->
          </div>
        </div>
        <div class="col-8 person-list-container">
          <div>
            <div class="title">Các đối tượng có khuôn mặt tương tự</div>
            <!-- <div class="close-person-list-btn" (click)="hidePersonList()"><i class="fa fa-close"></i></div> -->
          </div>
          <div class="grid-repeat-5 mt-1 person-list">
            <!-- <div *ngFor="let person of personsWithFaceuid"
                        [ngClass]="{
                        'selected': person.code == frmObject.value.code}" (click)="handleChoosePerson(person)">
                            <img class="person-img-item" [ngClass]="{'inactive': person.isactive == false}" [src]="imgUrl + person.img" alt="">
                            <div class="pl-2">{{person.name}}</div>
                            <div class="pl-2">{{person.type ? person.type.name : person.role.name}}</div>
                        </div> -->
            <div
              style="padding: 0 !important; max-height: 36vh"
              class="box d-flex flex-column handle"
              (click)="showEmptyForm()"
            >
              <div class="add-person-btn">
                <i class="fa fa-plus fa-lg"></i>
              </div>
            </div>
            <div
              style="padding: 0 !important; max-height: 36vh"
              class="box d-flex flex-column handle"
              *ngFor="let person of personsWithFaceuid"
              (click)="handleChoosePerson(person)"
              [ngClass]="{
                inactive: person.isactive == false,
                selected: person.code == frmObject.value.code
              }"
            >
              <img
                style="margin: 0 !important; height: 80%"
                #img
                class=""
                style="
                  max-width: 200px;
                  object-fit: contain;
                  margin: auto;
                  max-height: 300px;
                  width: 100%;
                "
                [src]="imgUrl + person.img"
                onerror="this.src='assets/image/no-avatar.png'"
                alt=""
              />
              <div class="pt-3 pl-3">
                <div class="title font-weight-bold">{{ person.name }}</div>
                <div class="sub-title sub-style">
                  {{ person.type ? person.type.name : person.role.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep && currentStep.stepNo == 3">
        <div class="d-flex flex-grow-1">
          <div class="col-4">
            <div class="img-container">
              <ng-container *ngIf="allowEditImg">
                <img
                  class="custom-img"
                  [src]="avatarFile"
                  alt=""
                  (click)="openFileExplorer('avatarFile')"
                  onerror="this.src='assets/image/no-avatar.png'"
                  [ngClass]="{ handle: !disableAvatarInput }"
                />
                <div
                  class="text-group mt-3"
                  [ngClass]="{ handle: !disableAvatarInput }"
                  style="align-items: flex-end !important"
                  (click)="openFileExplorer('avatarFile')"
                >
                  <div class="text-delete mb-5">
                    <h5>
                      <span><i class="fa fa-camera"></i></span> Chọn ảnh mới
                    </h5>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!allowEditImg">
                <img class="custom-img" [src]="avatarFile" alt="" />
              </ng-container>
            </div>
          </div>
          <div
            class="col-8 d-flex flex-column"
            style="height: 100%; overflow: auto"
          >
            <div class="info flex-grow-1">
              <!-- <div style=" margin: 1%">
                            <input #file type="file" id="avatarFile" (change)="handleChangeAvatar(file.files)" hidden>
                        </div> -->
              <form [formGroup]="frmObject" (ngSubmit)="onSubmit()">
                <div
                  style="
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 5px;
                  "
                >
                  <div class="form-group" *ngIf="personType !== 'user'">
                    <label>Mã</label>
                    <input
                      type="text"
                      class="form-control mt-1"
                      formControlName="code"
                      disabled
                    />
                  </div>
                  <div class="form-group" *ngIf="personType === 'user'">
                    <label>Mã <a class="required-field">*</a></label>
                    <input
                      type="text"
                      class="form-control mt-1"
                      formControlName="code"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label>Họ và tên <a class="required-field">*</a></label>
                    <input
                      type="text"
                      class="form-control mt-1"
                      formControlName="name"
                      required
                    />
                  </div>
                </div>
                <ng-container *ngIf="formType == 'user'">
                  <div class="form-group">
                    <label>Tên đăng nhập <a class="required-field">*</a></label>
                    <input
                      type="text"
                      class="form-control mt-1"
                      formControlName="username"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label>Mật khẩu mới</label>
                    <input
                      type="password"
                      class="form-control mt-1"
                      formControlName="newpassword"
                    />
                  </div>
                  <div class="form-group">
                    <label>Xác nhận mật khẩu</label>
                    <input
                      type="password"
                      class="form-control mt-1"
                      formControlName="confirmpassword"
                    />
                  </div>
                </ng-container>
                <div
                  style="
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 5px;
                  "
                  *ngIf="formType != 'user'"
                >
                  <div class="form-group">
                    <label>Ngày sinh</label>
                    <!-- <input id="birthday" class="form-control" type="date" name="birthday"
                                            formControlName="dob"> -->
                    <div class="input-group">
                      <input
                        class="form-control"
                        formControlName="dob"
                        angular-mydatepicker
                        name="birthday"
                        #dp="angular-mydatepicker"
                        [options]="datePickerOptions"
                        (click)="dp.toggleCalendar()"
                        [locale]="locale"
                      />
                      <div class="input-group-append">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          (click)="dp.clearDate()"
                        >
                          <i class="fa fa-close"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Giới tính</label>
                    <select
                      id="gender"
                      class="form-control"
                      name="gender"
                      formControlName="genderoptid"
                    >
                      <option
                        *ngFor="let gender of genderList"
                        [ngValue]="gender.id"
                      >
                        {{ gender.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <ng-container
                  *ngIf="formType !== 'customer'; else notifyOptionTemplate"
                >
                  <div
                    style="
                      display: grid;
                      grid-template-columns: 1fr 1fr;
                      grid-gap: 5px;
                    "
                  >
                    <div class="form-group">
                      <label>Số điện thoại</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="phone"
                      />
                    </div>
                    <div class="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="email"
                      />
                    </div>
                  </div>
                </ng-container>
                <ng-template #notifyOptionTemplate>
                  <div class="form-group">
                    <label>Cách nhận thông báo</label>
                    <ng-container *ngFor="let notifyOption of NOTIFY_METHODS">
                      <div class="row align-items-center">
                        <div class="form-check col-md-6 my-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            [value]="notifyOption.value"
                            [id]="notifyOption.code"
                            [disabled]="notifyOption.disabled"
                            [checked]="
                              frmObject.value.notifymethods.includes(
                                notifyOption.value
                              )
                            "
                            (change)="
                              handleChangeNotifyOption(notifyOption.value)
                            "
                          />
                          <label
                            class="form-check-label"
                            [for]="notifyOption.code"
                          >
                            {{ notifyOption.label }}
                          </label>
                        </div>
                        <ng-container *ngIf="notifyOption.additionalInput">
                          <input
                            [type]="notifyOption.additionalInput.type"
                            class="form-control col-md-6 mb-2"
                            [formControlName]="
                              notifyOption.additionalInput.code
                            "
                            [placeholder]="
                              notifyOption.additionalInput.placeholder
                            "
                          />
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </ng-template>
                <ng-container *ngIf="formType == 'emp'">
                  <div
                    style="
                      display: grid;
                      grid-template-columns: 1fr 1fr;
                      grid-gap: 5px;
                    "
                  >
                    <div class="form-group">
                      <label
                        >Đơn vị công tác <a class="required-field">*</a></label
                      >
                      <div
                        class="mt-2 position-relative"
                        *ngIf="branchList.length > 0"
                      >
                        <div
                          class="form-control handle"
                          (click)="onClickBranch()"
                        >
                          {{ selectedBranch ? selectedBranch.name : noText }}
                        </div>
                        <div style="width: 50%">
                          <app-dropdown-list
                            [dataList]="formatedBranchList"
                            [saveBranch]="true"
                            [isShow]="isShowBranchOption"
                            [selectedBranch]="selectedBranch"
                            (handleSelect)="handleSelectBranch($event)"
                          ></app-dropdown-list>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Phòng ban</label>
                      <select
                        class="form-control mt-1"
                        formControlName="teamid"
                      >
                        <option
                          *ngFor="let team of teamList"
                          [ngValue]="team.id"
                        >
                          {{ team.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Chức vụ <a class="required-field">*</a></label>
                    <select
                      class="form-control mt-1"
                      formControlName="roleoptid"
                      required
                    >
                      <option *ngFor="let role of roleList" [ngValue]="role.id">
                        {{ role.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>Trạng thái <a class="required-field">*</a></label>
                    <select
                      class="form-control mt-1"
                      formControlName="isactive"
                      required
                    >
                      <option [ngValue]="true">Hiện</option>
                      <option [ngValue]="false">Ẩn</option>
                    </select>
                  </div>
                </ng-container>
                <ng-container *ngIf="formType == 'customer'">
                  <div
                    style="
                      display: grid;
                      grid-template-columns: 1fr 1fr;
                      grid-gap: 5px;
                    "
                  >
                    <div class="form-group">
                      <label
                        >Loại đối tượng <a class="required-field">*</a></label
                      >
                      <select
                        class="form-control mt-1"
                        formControlName="customertypeoptid"
                        required
                      >
                        <option
                          *ngFor="let type of typeList"
                          [ngValue]="type.id"
                        >
                          {{ type.name }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label>Trạng thái <a class="required-field">*</a></label>
                      <select
                        class="form-control mt-1"
                        formControlName="isactive"
                        required
                      >
                        <option [ngValue]="true">Hiện</option>
                        <option [ngValue]="false">Ẩn</option>
                      </select>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="formType == 'user'">
                  <div class="form-group">
                    <label>Địa chỉ</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="address"
                    />
                  </div>
                  <div
                    style="
                      display: grid;
                      grid-template-columns: 1fr 1fr;
                      grid-gap: 5px;
                    "
                  >
                    <div class="form-group">
                      <label
                        >Đơn vị công tác <a class="required-field">*</a></label
                      >
                      <div
                        class="form-control mt-1 handle position-relative"
                        (click)="onClickBranch()"
                        *ngIf="branchList.length > 0"
                      >
                        {{ selectedBranch ? selectedBranch.name : noText }}
                        <div style="width: 50%">
                          <app-dropdown-list
                            [dataList]="formatedBranchList"
                            [saveBranch]="true"
                            [isShow]="isShowBranchOption"
                            [selectedBranch]="selectedBranch"
                            (handleSelect)="handleSelectBranch($event)"
                          ></app-dropdown-list>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Đang hoạt động</label>
                      <select
                        id="active"
                        class="form-control mt-1"
                        name="active"
                        formControlName="active"
                      >
                        <option [ngValue]="true">Có</option>
                        <option [ngValue]="false">Không</option>
                      </select>
                    </div>
                  </div>
                </ng-container>
                <div class="d-flex">
                  <button
                    type="button"
                    class="btn btn-sm btn-secondary mt-2 mr-2 pull-left full-width"
                    (click)="prevStep()"
                  >
                    <i class="fa fa-arrow-left mr-1"></i>Trở về
                  </button>
                  <!-- <button *ngIf="personId" type="button" class="btn btn-sm btn-secondary mt-2 mr-2 pull-right full-width"
                                        (click)="deletePerson()">
                                        <i class="fa fa-trash mr-1"></i>Xóa
                                </button> -->
                  <button
                    type="submit"
                    class="btn btn-sm btn-secondary mt-2 pull-right full-width"
                  >
                    <i class="fa fa-save mr-1"></i>Lưu
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="full-width">
          <hr />
        </div>
        <ng-container *ngIf="formType == 'customer' && personId">
          <div style="width: 100%; position: relative">
            <span class="title-header"
              >Ảnh mẫu tăng cường<span class="title"
                >&nbsp;(Tối đa {{ maxPersonAiImages }} ảnh)</span
              >
            </span>
            <div class="person-image-wrapper">
              <ng-container>
                <div class="grid-repeat-10 mt-1">
                  <label
                    *ngIf="personAiImages.length < maxPersonAiImages"
                    class="box d-flex flex-column handle add-person-image"
                    for="personImageFileInput"
                  >
                    <div class="add-person-btn">
                      <i class="fa fa-plus fa-lg"></i>
                    </div>
                    <input
                      #personImageFileInput
                      type="file"
                      id="personImageFileInput"
                      (change)="uploadPersonImage(personImageFileInput.files)"
                      hidden
                    />
                  </label>
                  <div
                    class="box d-flex flex-column handle person-image-container"
                    *ngFor="let image of personAiImages"
                  >
                    <img
                      style="margin: 0 !important; height: auto"
                      class="person-image"
                      [src]="
                        image.url
                          ? image.url
                          : deviceHost + image.t_event_realtime.url
                      "
                      onerror="this.src='assets/image/no-avatar.png'"
                    />
                    <div
                      class="person-image-overlay"
                      (click)="removePersonAIImages(image.id)"
                    >
                      Xoá
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <hr />
            <div class="d-flex">
              <span class="title-header pt-3">Ảnh tương đồng</span>
              <ngx-slider
                class="similar-image-slider"
                [(value)]="sliderValue"
                [options]="sliderOptions"
                (userChangeEnd)="handleChangeSlider()"
              ></ngx-slider>
            </div>
            <div class="person-image-wrapper">
              <ng-container
                *ngIf="personSimilarImages.length > 0; else noImage"
              >
                <div class="grid-repeat-10 mt-1">
                  <div
                    class="box d-flex flex-column handle person-image-container"
                    *ngFor="let image of personSimilarImages"
                  >
                    <img
                      style="margin: 0 !important; height: auto"
                      class="person-image"
                      [src]="deviceHost + image.url"
                      onerror="this.src='assets/image/no-avatar.png'"
                    />
                    <div
                      class="person-image-overlay"
                      (click)="addPersonAIImages(image.id)"
                    >
                      Thêm
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-template #noImage>
                <span class="title">Chưa có ảnh</span>
              </ng-template>
            </div>
            <ngx-spinner
              bdColor="rgba(255,255,255,0.4)"
              size="medium"
              color="#000"
              type="ball-spin"
              [fullScreen]="false"
              [name]="personImagesSpinner"
            >
            </ngx-spinner>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
