import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { API, REPLACEMENT } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public username: string = REPLACEMENT.devUsername;
  public password: string = REPLACEMENT.devPwd;
  public spinnerName = 'oidc-spinner';
  public isHandlingOIDC = false;
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinner.show(this.spinnerName);
    this.activatedRoute.queryParams
      .pipe(
        tap((queryParams) => {
          if (queryParams.code) {
            this.isHandlingOIDC = true;
          }
        })
      )
      .subscribe();
  }

  public login() {
    this.apiService
      .post<any>(API.login, {
        username: this.username,
        password: this.password,
      })
      .subscribe((loginResult) => {
        if (loginResult) {
          if (loginResult.success) {
            this.authService.setLoginData(loginResult);
            this.router.navigate(['/analysis']);
          } else {
            this.alertService.informError(loginResult.message);
          }
        }
      });
  }

  public initLoginOIDC(): void {
    this.authService.initLoginOIDC();
  }
}
