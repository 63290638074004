import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Branch } from 'src/app/models/branch';
import { EmpTeam } from 'src/app/models/emp-team';
import { Employee } from 'src/app/models/employee';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import {
  API,
  API_IMG_HOST,
  DEVICE_IMG_HOST,
  FUNC,
  REPLACEMENT,
} from 'src/environments/environment';
import { Option } from 'src/app/models/option';
import { AuthService } from 'src/app/services/auth/auth.service';
import { User } from 'src/app/models/user';
import { Observable, of } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import * as moment from 'moment';
import { Options } from '@angular-slider/ngx-slider';

declare var $: any;

@Component({
  selector: 'app-person-detail',
  templateUrl: './person-detail.component.html',
  styleUrls: ['./person-detail.component.scss'],
})
export class PersonDetailComponent implements OnInit, OnChanges {
  @Input() personType: string = 'emp';
  @Input() isShow = false;
  @Input() forcedAvatar: string;
  @Input() faceuid: string;
  @Input() personId: any;
  @Input() isAddNew: boolean = true;
  @Output() closeForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updatedDetail: EventEmitter<boolean> = new EventEmitter<boolean>();
  public avatarFile;
  public teamList: EmpTeam[] = [];
  public branchList: Branch[] = [];
  public roleList: Option[] = [];
  public genderList: Option[] = [];
  public typeList: Option[] = [];
  public frmObject: FormGroup;
  public disableAvatarInput: boolean = false;
  public currentUserInfo;
  public userBranchChildren = [];
  public isShowBranchOption = false;
  public selectedBranch = null;
  public formatedBranchList = [];
  public selectedBranchId = null;
  public steps: Array<userStep> = [];
  public currentStep: userStep = null;
  public personsWithFaceuid: any[] = [];
  public imgUrl = API_IMG_HOST;
  public allowEditImg = true;
  public formType;
  public zoomImg = false;
  public allowAddPerson = true;
  public spinnerName = 'avatarSpinner';
  public datePickerOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mm-yyyy',
    // other options...
  };
  public locale = 'vi';
  public personAiImages = [];
  public personSimilarImages = [];
  public deviceHost = DEVICE_IMG_HOST;
  public maxPersonAiImages = 30;
  public personImagesSpinner = 'personImagesSpinner';
  public similarFaceuids = [];
  public maxThreshold = REPLACEMENT.maxThresHold;
  public minThreshold = REPLACEMENT.minThresHold;
  public sliderValue = 50;
  public thresholdValue = this.convertSliderValueToThreshold(this.sliderValue);
  public sliderOptions: Options = {
    floor: 0,
    ceil: 100,
    autoHideLimitLabels: false,
    hidePointerLabels: true,
    translate: (value: number) => {
      if (value == 0) return 'Thấp';
      if (value == 100) return 'Cao';
      return '';
    },
  };
  public readonly NOTIFY_METHODS = [
    {
      code: 'emailnotify',
      label: 'Email',
      value: 'email',
      additionalInput: {
        code: 'email',
        type: 'email',
        placeholder: 'Email',
      },
    },
    {
      code: 'smsnotify',
      label: 'SMS',
      value: 'sms',
      additionalInput: {
        code: 'phone',
        type: 'tel',
        placeholder: 'Số điện thoại',
      },
    },
    {
      code: 'appnotify',
      label: 'Ứng dụng di động',
      value: 'app',
    },
    {
      code: 'monitornotify',
      label: 'Màn hình giám sát',
      value: 'monitor',
      disabled: true,
    },
  ];
  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.currentUserInfo = this.authService.getUserInfo();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes['isShow'] && changes['isShow'].currentValue) {
      this.formType = this.personType;
      this.generateFormGroup();
      this.getAdditionalOpt();
      this.resetSteps();
      this.getGender();
      this.zoomImg = false;
      if (!this.isAddNew) {
        this.getPersonInfo();
        this.allowEditImg = true;
        this.currentStep = this.steps[2];
      } else {
        if (this.personType != 'user') {
          this.allowEditImg = false;
          this.currentStep = this.steps[0];
        } else {
          this.allowEditImg = true;
          this.currentStep = this.steps[2];
        }
        this.personId = null;
        this.frmObject.reset();
        this.selectedBranch = null;

        if (this.forcedAvatar) {
          this.disableAvatarInput = true;
          this.avatarFile = this.forcedAvatar;
        } else this.avatarFile = null;
      }
    }
    if (changes['personType'] && changes['personType'].currentValue) {
      this.hideDetailForm();
      this.formType = this.personType;
      this.generateFormGroup();
    }
  }

  public resetSteps() {
    this.steps = [
      { stepNo: 1, stepName: '' },
      { stepNo: 2, stepName: '' },
      { stepNo: 3, stepName: '' },
    ];
  }

  public generateFormGroup() {
    this.frmObject = null;
    switch (this.formType) {
      case 'customer':
        this.frmObject = new FormGroup({
          code: new FormControl(null),
          name: new FormControl(null),
          dob: new FormControl(null),
          phone: new FormControl(null),
          email: new FormControl(null),
          genderoptid: new FormControl(),
          customertypeoptid: new FormControl(null),
          isactive: new FormControl(true),
          img: new FormControl(null),
          notifymethods: new FormControl(['monitor']),
        });
        break;
      case 'user':
        this.frmObject = new FormGroup({
          code: new FormControl(null),
          name: new FormControl(null),
          username: new FormControl(null),
          newpassword: new FormControl(null),
          confirmpassword: new FormControl(null),
          dob: new FormControl(null),
          phone: new FormControl(null),
          email: new FormControl(null),
          branchid: new FormControl(),
          address: new FormControl(null),
          img: new FormControl(null),
          active: new FormControl(false),
        });
        break;
      default:
        this.frmObject = new FormGroup({
          code: new FormControl(null),
          name: new FormControl(null),
          dob: new FormControl(null),
          phone: new FormControl(null),
          email: new FormControl(null),
          genderoptid: new FormControl(),
          teamid: new FormControl(null),
          roleoptid: new FormControl(null),
          branchid: new FormControl(null),
          isactive: new FormControl(true),
          img: new FormControl(null),
        });
    }
  }

  public getAdditionalOpt() {
    this.roleList.length = 0;
    this.teamList.length = 0;
    this.typeList.length = 0;
    this.branchList.length = 0;
    this.personSimilarImages.length = 0;
    this.similarFaceuids.length = 0;
    switch (this.formType) {
      case 'customer':
        this.getType();
        if (this.personId && this.avatarFile) {
          this.getFaceuid();
        }
        break;
      case 'user':
        if (this.currentUserInfo.branchid) this.getBranchChildren();
        else this.getBranch();
        break;
      default:
        if (this.currentUserInfo.branchid) this.getBranchChildren();
        else this.getBranch();
        this.getRole();
        this.getTeam();
    }
  }

  public handleChangeAvatar(files) {
    if (files.length > 0) {
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.avatarFile = reader.result;
      };
    }
  }

  public openFileExplorer(inputId) {
    if (!this.disableAvatarInput) $('#' + inputId).click();
  }

  public onSubmit() {
    if (this.frmObject.status == 'VALID') {
      if (
        this.frmObject.value.newpassword &&
        this.frmObject.value.newpassword != this.frmObject.value.confirmpassword
      ) {
        this.alertService.informError(
          'Mật khẩu xác nhận không giống với mật khẩu mới'
        );
      } else {
        if (this.personId) {
          this.updatePerson();
        } else {
          this.addPerson();
        }
      }
    } else {
      this.alertService.informError('Xin hãy điền đầy đủ thông tin');
    }
  }

  public addPerson() {
    switch (this.formType) {
      case 'customer':
        this.addCustomer();
        break;
      case 'user':
        this.addUser();
        break;
      default:
        this.addEmployee();
    }
  }

  public updatePerson() {
    switch (this.formType) {
      case 'customer':
        this.updateCustomer();
        break;
      case 'user':
        this.updateUser();
        break;
      default:
        this.updateEmployee();
    }
  }

  public generateFormData(data) {
    let formData = new FormData();
    Object.keys(data).map((key) => {
      if (data[key] != null) {
        if (key == 'dob') formData.append(key, data[key].singleDate.formatted);
        else formData.append(key, data[key]);
      }
    });
    let type;
    if (this.formType == 'emp')
      type = this.roleList.find((role) => role.id == data.roleoptid);
    else if (this.formType == 'customer' && this.currentUserInfo.branchid) {
      formData.append('branchid', this.currentUserInfo.branchid);
      type = this.typeList.find(
        (tmpType) => tmpType.id == data.customertypeoptid
      );
    }
    if (type) formData.append('type', type.code);
    if (this.faceuid) formData.append('faceuid', this.faceuid);
    if (this.forcedAvatar) formData.append('avatarUrl', this.forcedAvatar);
    if (this.avatarFile)
      formData.append('file', $('#avatarFile').prop('files').item(0));
    return formData;
  }

  public addEmployee() {
    let newEmployeeInfo = this.generateFormData(this.frmObject.value);
    this.apiService
      .post<any>(API.uploadEmployee, newEmployeeInfo)
      .subscribe((newEmployee) => {
        if (newEmployee) {
          this.personId = newEmployee.id;
          this.getEmployeeInfo();
          this.alertService.informSuccess('Thêm cán bộ thành công');
          this.updatedDetail.emit(true);
        }
      });
  }

  public updateEmployee() {
    let employeeNewInfo;
    employeeNewInfo = this.generateFormData(this.frmObject.value);
    this.apiService
      .put(API.uploadEmployee + '/' + this.personId, employeeNewInfo)
      .subscribe((result) => {
        if (result) {
          this.getEmployeeInfo();
          this.alertService.informSuccess('Cập nhật cán bộ thành công');
          this.updatedDetail.emit(true);
        }
      });
  }

  public addCustomer() {
    let newCustomerInfo = this.generateFormData(this.frmObject.value);
    this.apiService
      .post<any>(API.uploadCustomer, newCustomerInfo)
      .subscribe((newCustomer) => {
        if (newCustomer) {
          this.personId = newCustomer.id;
          this.getCustomerInfo();
          this.alertService.informSuccess('Thêm thành công');
          this.updatedDetail.emit(true);
        }
      });
  }

  public updateCustomer() {
    let customerNewInfo;
    customerNewInfo = this.generateFormData(this.frmObject.value);
    this.apiService
      .put(API.uploadCustomer + '/' + this.personId, customerNewInfo)
      .subscribe((result) => {
        if (result) {
          this.getCustomerInfo();
          this.alertService.informSuccess('Cập nhật thành công');
          this.updatedDetail.emit(true);
        }
      });
  }

  public addUser() {
    let newUserInfo = this.generateFormData(this.frmObject.value);
    this.apiService
      .post<User>(API.uploadUser, newUserInfo)
      .subscribe((newUser) => {
        if (newUser) {
          this.personId = newUser.id;
          this.getUserInfo();
          this.alertService.informSuccess('Thêm người dùng thành công');
          this.updatedDetail.emit(true);
        }
      });
  }

  public updateUser() {
    let newUserInfo = this.generateFormData(this.frmObject.value);
    this.apiService
      .put<User>(API.uploadUser + '/' + this.personId, newUserInfo)
      .subscribe((result) => {
        if (result) {
          this.getUserInfo();
          this.alertService.informSuccess('Cập nhật người dùng thành công');
          this.updatedDetail.emit(true);
        }
      });
  }

  public hideDetailForm() {
    this.isShow = false;
    this.selectedBranchId = null;
    this.personSimilarImages.length = 0;
    this.similarFaceuids.length = 0;
    this.avatarFile = null;
    this.closeForm.emit(false);
  }

  public getGender() {
    let genderInclude = [
      {
        model: 't_optiongroup',
        include: [],
      },
    ];
    let genderQuery = {
      op: 'AND',
      val: [
        {
          model: 't_optiongroup',
          col: 'code',
          op: '=',
          val: 'genderoptionid',
        },
      ],
    };
    let genderOrder = [['id', 'ASC']];
    this.apiService
      .get<Option[]>(API.options, {
        include: JSON.stringify(genderInclude),
        query: JSON.stringify(genderQuery),
        order: JSON.stringify(genderOrder),
      })
      .subscribe((genders) => {
        if (genders) {
          this.genderList = genders;
        }
      });
  }

  public getTeam() {
    this.teamList.length = 0;
    this.apiService.get<EmpTeam[]>(API.employeeTeam).subscribe((teams) => {
      if (teams) {
        this.teamList = teams;
      }
    });
  }

  public getBranch() {
    this.branchList.length = 0;
    let branchQuery = {
      op: 'AND',
      val: [],
    };
    if (this.userBranchChildren.length > 0) {
      branchQuery.val.push({
        model: 't_branch',
        col: 'id',
        op: 'in',
        val: this.userBranchChildren,
      });
    }
    this.apiService
      .get<Branch[]>(API.branches, { query: JSON.stringify(branchQuery) })
      .subscribe((branches) => {
        if (branches) {
          this.branchList = branches;
          this.formatedBranchList.length = 0;
          this.formatBranchList(branches);
          if (this.selectedBranchId) {
            this.selectedBranch = this.branchList.find(
              (branch) => branch.id == this.selectedBranchId
            );
          }
        }
      });
  }

  public getBranchChildren() {
    this.apiService
      .get<any>(API.view + FUNC.getBranchChildren, {
        parent: this.currentUserInfo.branchid,
      })
      .subscribe((branches) => {
        if (branches) {
          this.userBranchChildren = branches.map((branch) => branch.id);
          this.getBranch();
        }
      });
  }

  public getRole() {
    let roleInclude = [
      {
        model: 't_optiongroup',
        include: [],
      },
    ];
    let roleQuery = {
      op: 'AND',
      val: [
        {
          model: 't_optiongroup',
          col: 'code',
          op: '=',
          val: 'roleoptionid',
        },
      ],
    };
    this.apiService
      .get<Option[]>(API.options, {
        include: JSON.stringify(roleInclude),
        query: JSON.stringify(roleQuery),
      })
      .subscribe((roles) => {
        if (roles) {
          this.roleList = roles;
        }
      });
  }

  public getType() {
    let typeInclude = [
      {
        model: 't_optiongroup',
        include: [],
      },
    ];
    let typeQuery = {
      op: 'AND',
      val: [
        {
          model: 't_optiongroup',
          col: 'code',
          op: '=',
          val: 'customertypeoptionid',
        },
      ],
    };
    this.apiService
      .get<Option[]>(API.options, {
        include: JSON.stringify(typeInclude),
        query: JSON.stringify(typeQuery),
      })
      .subscribe((types) => {
        if (types) {
          types.map((type) => (type.val = JSON.parse(type.val)));
          this.typeList = types.filter((type) => type.val.is_identify == true);
        }
      });
  }

  public getPersonInfo() {
    switch (this.formType) {
      case 'customer':
        this.getCustomerInfo();
        break;
      case 'user':
        this.getUserInfo();
        break;
      default:
        this.getEmployeeInfo();
    }
  }

  public getEmployeeInfo() {
    this.apiService
      .get<Employee>(API.employees + '/' + this.personId)
      .subscribe((employeeInfo) => {
        if (employeeInfo) {
          this.setPersonInfo(employeeInfo);
        }
      });
  }

  public getCustomerInfo() {
    const customerInclude = [
      {
        model: 't_person_image',
        include: [
          {
            model: 't_event_realtime',
            include: [],
          },
        ],
      },
    ];
    this.apiService
      .get<any>(API.customers + '/' + this.personId, {
        include: JSON.stringify(customerInclude),
      })
      .subscribe((customerInfo) => {
        if (customerInfo) {
          this.setPersonInfo(customerInfo);
          this.personAiImages = customerInfo.t_person_images;
          this.getAIItemids();
        }
      });
  }

  public getUserInfo() {
    this.apiService
      .get<any>(API.users + '/' + this.personId)
      .subscribe((userInfo) => {
        if (userInfo) {
          this.setPersonInfo(userInfo);
        }
      });
  }

  public setPersonInfo(personInfo: any) {
    this.setPersonFormInfo(personInfo);
    this.setPersonAvatar(personInfo);
  }

  public setPersonFormInfo(personInfo: any) {
    if (personInfo.dob) {
      personInfo.dob = {
        isRange: false,
        singleDate: {
          jsDate: new Date(
            moment(personInfo.dob, 'DD-MM-YYYY').format('MM-DD-YYYY')
          ),
        },
        dateRange: null,
      };
    }
    this.frmObject.patchValue(personInfo);
    this.selectedBranchId = personInfo.branchid;
    this.selectedBranch = this.branchList.find(
      (branch) => branch.id == this.selectedBranchId
    );
  }

  public setPersonAvatar(personInfo: any) {
    $('#avatarFile').val('');
    if (personInfo.img)
      this.avatarFile = API_IMG_HOST + personInfo.img + `?${Date.now()}`;
    else this.avatarFile = null;
  }

  public deletePerson() {
    switch (this.formType) {
      case 'customer':
        this.deleteCustomer();
        break;
      default:
        this.deleteEmployee();
    }
  }

  public deleteEmployee() {
    this.alertService
      .confirm('Bạn có muốn xóa cán bộ ' + this.frmObject.value.name + '?')
      .then((result) => {
        if (result.isConfirmed) {
          this.apiService
            .delete(API.deleteEmployees + '/' + this.personId)
            .subscribe((result) => {
              if (result) {
                this.alertService.informSuccess('Xóa thành công cán bộ');
                this.updatedDetail.emit(true);
                this.hideDetailForm();
              }
            });
        }
      });
  }

  public deleteCustomer() {
    this.alertService
      .confirm('Bạn có muốn xóa đối tượng ' + this.frmObject.value.name + '?')
      .then((result) => {
        if (result.isConfirmed) {
          this.apiService
            .delete(API.deleteCustomers + '/' + this.personId)
            .subscribe((result) => {
              if (result) {
                this.alertService.informSuccess('Xóa thành công');
                this.updatedDetail.emit(true);
                this.hideDetailForm();
              }
            });
        }
      });
  }

  public formatBranchList(branches: Branch[]) {
    let tempList = [];
    branches.map((branch) => {
      branch.t_branches = this.getBranchChildrenFromLocal(branch, branches);
      if (!branch.parentid) {
        tempList.push(branch);
      }
    });
    this.formatedBranchList = [...this.formatedBranchList, ...tempList];
  }

  public getBranchChildrenFromLocal(parent: Branch, branchList: Branch[]) {
    let children = [];
    branchList.map((branch, idx) => {
      if (branch.parentid == parent.id) {
        children.push(branch);
        // branchList.splice(idx, 1);
      }
    });
    return children;
  }

  public onClickBranch() {
    this.isShowBranchOption = !this.isShowBranchOption;
  }

  public handleSelectBranch(selectedBranch) {
    this.frmObject.patchValue({
      branchid: selectedBranch.id,
    });
    this.selectedBranch = this.branchList.find(
      (branch) => branch.id == selectedBranch.id
    );
    this.isShowBranchOption = false;
  }

  public nextStep(nextStepNo: number) {
    if (nextStepNo == 2 && !this.currentStep.result) {
      this.nextStep(3);
    }
    if (this.currentStep.result) {
      this.currentStep.result.subscribe((data) => {
        if (data) {
          let prevStep = this.currentStep.stepNo;
          this.currentStep = this.steps.find(
            (step) => step.stepNo == nextStepNo
          );
          this.currentStep.prevStep = prevStep;
        }
      });
    } else {
      let prevStep = this.currentStep.stepNo;
      this.currentStep = this.steps.find((step) => step.stepNo == nextStepNo);
      this.currentStep.prevStep = prevStep;
    }
  }

  public prevStep() {
    let prevStep = this.steps.find(
      (step) => step.stepNo == this.currentStep.prevStep
    );
    if (prevStep) this.currentStep = prevStep;
    else this.hideDetailForm();
  }

  public getFaceuid() {
    this.allowAddPerson = true;
    if (this.avatarFile) {
      let formData = new FormData();
      if ($('#avatarFile').prop('files').item(0)) {
        formData.append('file', $('#avatarFile').prop('files').item(0));
        this.getFaceuidFromAI(formData);
      } else {
        this.http.get(this.avatarFile, { responseType: 'blob' }).subscribe(
          (data) => {
            if (data) {
              // this.selectedImg = data;
              formData.append('file', data);
              this.getFaceuidFromAI(formData);
            }
          },
          (err) => {
            if (err) this.alertService.informError('Không thể lấy được hình!');
          }
        );
      }
    } else this.alertService.informError('Xin hãy chọn ảnh của đối tượng!');
  }

  public getFaceuidFromAI(formData) {
    this.spinnerService.show(this.spinnerName);
    const api =
      API.searchFaceuid +
      (this.isAddNew ? '?customer=true' : '?search_type=multiple_uids');
    this.apiService.post<any>(api, formData).subscribe((result) => {
      this.spinnerService.hide(this.spinnerName);
      if (result) {
        if (result.error_code == 0) {
          if (this.isAddNew) {
            this.getPersonByFaceuid(result.data.uid);
          } else {
            this.similarFaceuids = result.data.map((data) => data.uid);
            this.getPersonSimilarImages();
          }
        } else
          this.alertService.informError(
            'Hình không hợp lệ, xin hãy đổi hình khác'
          );
      }
    });
  }

  public getPersonByFaceuid(faceuid: any) {
    this.zoomImg = true;
    setTimeout(() => {
      this.zoomImg = false;
      if (this.currentStep.stepNo != 2) this.nextStep(2);
    }, 1000);
    this.formType = this.personType;
    this.personId = null;
    this.personsWithFaceuid.length = 0;
    this.generateFormGroup();
    this.getAdditionalOpt();
    this.getCustomerByFaceuid(faceuid);
    this.getEmployeeByFaceuid(faceuid);
  }

  public getCustomerByFaceuid(faceuid: any) {
    const personInclude = [
      {
        model: 't_option',
        as: 'type',
        include: [],
      },
    ];
    const personQuery = {
      op: 'AND',
      val: [
        // {
        //   model: 't_customer',
        //   col: 'isactive',
        //   op: '=',
        //   val: true
        // },
        {
          model: 't_customer',
          col: 'faceuid',
          op: '=',
          val: faceuid,
        },
      ],
    };
    this.apiService
      .get<any>(API.customers, {
        include: JSON.stringify(personInclude),
        query: JSON.stringify(personQuery),
      })
      .subscribe((persons) => {
        if (persons) {
          if (persons.length > 0) {
            this.currentStep.result = of(this.personsWithFaceuid);
            if (this.personType == 'customer') {
              this.allowAddPerson = false;
              this.personsWithFaceuid = [
                ...persons,
                ...this.personsWithFaceuid,
              ];
            } else
              this.personsWithFaceuid = [
                ...this.personsWithFaceuid,
                ...persons,
              ];
          } else {
            this.currentStep.result = of(true);
          }
        }
      });
  }

  public getEmployeeByFaceuid(faceuid: any) {
    const personInclude = [
      {
        model: 't_option',
        as: 'role',
        include: [],
      },
    ];
    const personQuery = {
      op: 'AND',
      val: [
        // {
        //   model: 't_emp',
        //   col: 'isactive',
        //   op: '=',
        //   val: true
        // },
        {
          model: 't_emp',
          col: 'faceuid',
          op: '=',
          val: faceuid,
        },
      ],
    };
    this.apiService
      .get<any>(API.employees, {
        include: JSON.stringify(personInclude),
        query: JSON.stringify(personQuery),
      })
      .subscribe((persons) => {
        if (persons) {
          if (persons.length > 0) {
            this.currentStep.result = of(this.personsWithFaceuid);
            if (this.personType == 'emp') {
              this.allowAddPerson = false;
              this.personsWithFaceuid = [
                ...persons,
                ...this.personsWithFaceuid,
              ];
            } else
              this.personsWithFaceuid = [
                ...this.personsWithFaceuid,
                ...persons,
              ];
          } else {
            this.currentStep.result = of(true);
          }
        }
      });
  }

  public async handleChoosePerson(person) {
    this.isAddNew = false;
    this.currentStep.result = await this.choosePersonInfo(person);
    if (this.currentStep.result) this.nextStep(3);
  }

  public async choosePersonInfo(person) {
    // if (person.type && this.formType == 'emp') {
    //   if (!this.allowAddPerson) this.alertService.informError('Đã tồn tại nhân viên với khuôn mặt tương tự, xin hãy cập nhật nhân viên đó!')
    //   else {
    //     let confirm = await this.alertService.confirm('Đối tượng hiện tại đang là ' + person.type.name +', bạn có muốn chuyển đối tượng này thành nhân viên?');
    //     if (confirm && confirm.isConfirmed) {
    //       let chosenPerson = Object.assign({}, person);
    //       chosenPerson.isactive = true;
    //       chosenPerson.code = null;
    //       this.generateFormGroup();
    //       this.getAdditionalOpt();
    //       this.setPersonFormInfo(chosenPerson);
    //       return of(chosenPerson)
    //     }
    //   }
    // }
    // else if (person.role && this.formType == 'customer') {
    //   if (!this.allowAddPerson) this.alertService.informError('Đã tồn tại khách hàng với khuôn mặt tương tự, xin hãy cập nhật khách hàng đó!')
    //   else {
    //     let confirm = await this.alertService.confirm('Đối tượng hiện tại đang là nhân viên, bạn có muốn chuyển đối tượng này thành khách hàng?');
    //     if (confirm && confirm.isConfirmed) {
    //       let chosenPerson = Object.assign({}, person);
    //       chosenPerson.isactive = true;
    //       chosenPerson.code = null;
    //       this.generateFormGroup();
    //       this.getAdditionalOpt();
    //       this.setPersonFormInfo(chosenPerson);
    //       return of(chosenPerson)
    //     }
    //   }
    // }
    // else {
    //   let chosenPerson = Object.assign({}, person);
    //   chosenPerson.isactive = true;
    //   this.personId = person.id;
    //   // if(person.type && person.type.code) this.setPersonType(person.type.code);
    //   // else if (person.role && person.role.code) this.setPersonType(person.role.code);
    //   // else this.setPersonType(null);
    //   this.generateFormGroup();
    //   this.getAdditionalOpt();
    //   this.setPersonFormInfo(chosenPerson);
    //   return of(chosenPerson)
    // }
    let chosenPerson = Object.assign({}, person);
    chosenPerson.isactive = true;
    this.personId = person.id;
    if (person.type && person.type.code) this.setPersonType(person.type.code);
    else if (person.role && person.role.code)
      this.setPersonType(person.role.code);
    else this.setPersonType(null);
    this.generateFormGroup();
    this.getAdditionalOpt();
    this.setPersonFormInfo(chosenPerson);
    return of(chosenPerson);
  }

  public setPersonType(code: string) {
    switch (code) {
      case 'EMP':
        this.formType = 'emp';
        break;
      case null:
        this.formType = 'user';
        break;
      default:
        this.formType = 'customer';
        break;
    }
  }

  public showEmptyForm() {
    this.formType = this.personType;
    this.personId = null;
    this.generateFormGroup();
    this.getAdditionalOpt();
    this.generatePersonCode();
    this.nextStep(3);
  }

  // public getPersonAIImages() {
  //   const imageInclude = [
  //     {
  //       model: 't_event_realtime',
  //       include: []
  //     }
  //   ]
  //   const imageQuery = {
  //     op: 'AND',
  //     val: [
  //       {
  //         model: 't_person_image',
  //         col: 'customerid',
  //         op: '=',
  //         val: this.personId,
  //       },
  //     ],
  //   };
  //   this.apiService
  //     .get<any>(API.personImages, {
  //       include: JSON.stringify(imageInclude),
  //       query: JSON.stringify(imageQuery),
  //     })
  //     .subscribe((images) => {
  //       if (images) {
  //         this.personAiImages = images;
  //       }
  //     });
  // }

  public getPersonSimilarImages() {
    const imageQuery = {
      op: 'AND',
      val: [
        {
          model: 't_event_realtime',
          col: 'itemid',
          op: 'in',
          val: this.similarFaceuids.slice(0, 100),
        },
        {
          model: 't_event_realtime',
          col: 'id',
          op: 'notIn',
          val: this.personAiImages
            .filter((image) => image.eventimageid != null)
            .map((image) => image.eventimageid),
        },
      ],
    };
    this.apiService
      .get<any>(API.realtimeEvents, {
        query: JSON.stringify(imageQuery),
        page: 1,
        itemsPerPage: 100,
        order: JSON.stringify([['timestamp', 'DESC']]),
      })
      .subscribe((images) => {
        if (images) {
          this.personSimilarImages = images.rows;
        }
      });
  }

  public addPersonAIImages(eventImageId: number) {
    this.spinnerService.show(this.personImagesSpinner);
    if (this.personAiImages.length < this.maxPersonAiImages) {
      this.apiService
        .post<any>(`${API.personImages}/add-image`, {
          eventImageId,
          customerId: this.personId,
        })
        .subscribe((response) => {
          if (response) {
            if (response.error_code) {
              this.alertService.informError(
                `AI message: ${response.error_message}`
              );
            } else {
              this.getCustomerInfo();
            }
          }
          this.spinnerService.hide(this.personImagesSpinner);
        });
    } else {
      this.alertService.informError('Đã đạt số lượng ảnh mẫu tối đa!');
    }
  }

  public removePersonAIImages(personAIImageId: number) {
    this.spinnerService.show(this.personImagesSpinner);
    this.apiService
      .delete<any>(`${API.personImages}/remove-image/${personAIImageId}`)
      .subscribe((response) => {
        if (response) {
          if (response.error_code) {
            this.alertService.informError(
              `AI message: ${response.error_message}`
            );
          } else {
            this.getCustomerInfo();
          }
        }
        this.spinnerService.hide(this.personImagesSpinner);
      });
  }

  public uploadPersonImage(files) {
    this.spinnerService.show(this.personImagesSpinner);
    const data = new FormData();
    data.append('code', this.frmObject.value.code);
    data.append('customerId', this.personId);
    data.append('file', files[0]);
    this.apiService.post(API.uploadPersonImage, data).subscribe((data) => {
      if (data) {
        this.getCustomerInfo();
      }
      this.spinnerService.hide(this.personImagesSpinner);
    });
  }

  public getAIItemids() {
    this.spinnerService.show(this.spinnerName);
    let formData = new FormData();
    this.http
      .get(this.avatarFile, { responseType: 'blob' })
      .subscribe((data) => {
        if (data) {
          // this.selectedImg = data;
          formData.append('file', data);
          const api = `${API.searchAIItemid}?threshold=${this.thresholdValue}`;
          this.apiService.post<any>(api, formData).subscribe((result) => {
            this.spinnerService.hide(this.spinnerName);
            if (result) {
              if (result.error_code == 0) {
                this.similarFaceuids = result.data
                  .filter((data) => data)
                  .sort((a, b) => {
                    if (a.dist < b.dist) return 1;
                    if (a.dist > b.dist) return -1;
                    return 0;
                  })
                  .map((data) => data.itemid);
                this.getPersonSimilarImages();
              } else
                this.alertService.informError(
                  'Hình không hợp lệ, xin hãy đổi hình khác'
                );
            }
          });
        }
      });
  }

  public generatePersonCode() {
    this.apiService
      .post<any>(API.generateCode, { table: this.personType })
      .subscribe((result) => {
        if (result.success) {
          this.frmObject.patchValue({
            code: result.data,
          });
        }
      });
  }

  public handleChangeSlider(): void {
    this.thresholdValue = this.convertSliderValueToThreshold(this.sliderValue);
    this.getAIItemids();
  }

  public convertSliderValueToThreshold(sliderValue: number): number {
    let threshold =
      this.maxThreshold -
      (sliderValue * (this.maxThreshold - this.minThreshold)) / 100;
    if (threshold < this.minThreshold) threshold = this.minThreshold;
    return threshold;
  }

  public handleChangeNotifyOption(value: string) {
    const currentOptions: string[] = this.frmObject.value.notifymethods ?? [];
    const selectedOptionIdx = currentOptions.findIndex(
      (option) => option === value
    );
    if (selectedOptionIdx > -1) {
      currentOptions.splice(selectedOptionIdx, 1);
    } else {
      currentOptions.push(value);
    }
    this.frmObject.patchValue({
      notifymethods: currentOptions,
    });
  }
}

interface userStep {
  stepNo: number;
  stepName: string;
  prevStep?: number;
  result?: Observable<any>;
}
