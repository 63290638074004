<div class="slim-scroll hidden-scrollbar" *ngIf="active === 'Heatmap'">
  <div class="row full-height">
    <div class="col-12 full-width full-height">
      <div class="row">
        <app-evaluate-search
          class="full-width"
          [allowAll]="false"
          [maxTimeValue]="maxTimeOption"
          (clickedBranch)="handleClickedBranch($event)"
          (clickedTimeOption)="handleClickedTimeOption($event)"
          (clickedStall)="handleClickedStall($event)"
          [hiddenSearchBar]="false"
          [showPointSlider]="showHeatmapSlider"
          [maxSliderValue]="maxHeatmapValue"
          [upperBound]="maxUpperBound"
          [lowerBound]="maxLowerBound"
          (changedMaxSliderValue)="handleChangedMaxHeatmapVal($event)"
        ></app-evaluate-search>
      </div>
      <div style="display: flex; justify-content: space-around">
        <div style="padding: 0">
          <div class="img-container">
            <img
              *ngIf="chosenCamera"
              src="{{ chosenCamera.cameraurl }}"
              style="max-width: 100%"
              (error)="onImgError($event)"
            />
          </div>
        </div>
        <div style="padding: 0">
          <div class="img-container" id="container">
            <img
              id="source"
              *ngIf="chosenCamera"
              src="{{ chosenCamera.cameraurl }}"
              style="max-width: 100%"
              (error)="onImgError($event)"
              (load)="getCameraConfig()"
            />
            <div class="legend-area" *ngIf="heatmap">
              <div class="title">Mật độ khách hàng</div>
              <div class="legend-value">
                <span class="sub-title" id="min">0</span>
                <span class="sub-title" id="avg1">1</span>
                <span class="sub-title" id="avg2">1</span>
                <span class="sub-title" id="max">1+</span>
              </div>
              <img
                id="gradient"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAKCAYAAABCHPt+AAABP0lEQVRYR9WXzW7CMBAGx8Ju7Lz/cwKn2BFpXdnxpqGiKoHYtJHQ6juQy2T2R0GMlEepdYLdspnAepQLxM5DH5pko9/piPSABRyqSTYBOAJnBccI5/vzNwSCZueqJ3AebGhYA1pfcAsMgVK/ap9gCIRtNQPZzYSfTDMT0XpwKzMaZKOnxQxLXMFR1Mw6bDdDTGpjiLnctkOgFFCprWVoO2WtpwxBLJnbVv2cDTk99rsCUs2U9QxpYIYATTOkpglpJt16/xcQBae4gvN7bmfI8tWXOdIgm4O0rPpWiH2pPm1INTNkpuQZMoAbwQ4oN27IZTtb/n9/ng2Zt6uWpmj/H2aIGNGwJkNesWUdhie3rPp3SBrqHtWPxC6ZUratyvlNf+S7Q6D05Q6plcXE3LL+9B2StqyGZsi29lJDHtyyPgGkE3hon50JdgAAAABJRU5ErkJggg=="
                style="width: 100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
