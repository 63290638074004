<div class="box camera-info">
  <div class="d-flex flex-row full-height">
    <div class="w-25">
      <img class="img-fluid full-width custom-image pl-4 pt-4 pb-4"
           src="assets/image/camera.PNG" alt="">
    </div>
    <div class="flex-grow-1">
      <div class="text-group">
        <div class="sub-title">{{cameraInfo ? cameraInfo.code : noText}}</div>
        <div class="title">{{cameraInfo ? cameraInfo.name : noText}}</div>
        <div class="sub-title">{{cameraInfo && (!cameraInfo.deactiveyn || cameraInfo.deactiveyn == 'T') ? 'Đang hoạt động' : 'Không hoạt động'}}</div>
      </div>
    </div>
    <div class="circle-status">
      <span  [ngClass]="{'dot-offline': !isOnline, 'dot-online': isOnline}"></span>
    </div>
  </div>
</div>
