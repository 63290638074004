<ng-template #customNotification let-notificationData="notification">
  <my-custom-alert type="notificationData.type">
    <div
      class="p-1 handle"
      [routerLink]="['/analysis']"
      [queryParams]="{
        event: notificationData.id,
        tab: notificationData.message.person_type,
        branch: notificationData.message.cameragroup.branchid
      }"
      [fragment]="'Nhận dạng'"
      routerLinkActive="router-link-active"
    >
      <div class="title font-weight-bold pl-2 pr-2 pb-3">
        <i class="fa fa-bell"></i> Thông báo
      </div>
      <div class="pl-2 pr-2 d-flex" style="width: 15vw">
        <div>
          <img
            class="notify-icon"
            src="{{ deviceHost + notificationData.message.url }}"
          />
        </div>
        <div>
          <div class="title pb-1" style="color: white">
            {{
              notificationData.message.person
                ? notificationData.message.person.name
                : freqGuess
            }}
          </div>
          <div class="sub-title">
            {{
              notificationData.message.person
                ? notificationData.message.personType
                : ""
            }}
          </div>
          <div class="sub-title">
            {{
              notificationData.message.created_timestamp
                | date: "dd-MM-yyyy HH:mm:ss"
            }}
          </div>
        </div>
      </div>
    </div>
  </my-custom-alert>
</ng-template>
<notifier-container></notifier-container>
<a
  [ngClass]="{
    hide: browserAllowNotify == true,
    show: browserAllowNotify == false
  }"
  ><b>Giám sát khuôn mặt</b> cần sự cho phép của bạn để hiện các thông báo:
  <span
    class="handle"
    style="font-weight: bold"
    (click)="askNotificationPermission()"
    >Cho phép nhận thông báo</span
  ></a
>
