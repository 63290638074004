<ul class="pt-2 nav col">
  <li class="title">
    <div
      class="nav-link"
      [ngClass]="{ active: chosenTab == 'info', handle: chosenTab != 'info' }"
      (click)="handleSelectTab('info')"
    >
      Thông tin
    </div>
  </li>
  <li class="title">
    <div
      class="nav-link"
      [ngClass]="{
        active: chosenTab == 'device',
        handle: chosenTab != 'device'
      }"
      (click)="handleSelectTab('device')"
    >
      Thiết bị
    </div>
  </li>
</ul>
<div class="box-body tab-content full-height">
  <div
    id="info"
    class="tab-pane p-3"
    [ngClass]="{ active: chosenTab == 'info' }"
  >
    <form [formGroup]="frmObject" (ngSubmit)="onSubmit()">
      <div class="form-group" *ngIf="selectedCameragroupId">
        <label>Mã</label>
        <input type="text" class="form-control mt-2" formControlName="code" />
      </div>
      <div class="form-group">
        <label>Tên <a class="required-field">*</a></label>
        <input
          type="text"
          class="form-control mt-2"
          formControlName="name"
          required
        />
      </div>
      <div class="form-group">
        <label>KPI số khách hàng</label>
        <input
          type="text"
          class="form-control mt-2"
          formControlName="kpi_custno"
        />
      </div>
      <div class="form-group">
        <label>KPI số khách hàng quan tâm</label>
        <input
          type="text"
          class="form-control mt-2"
          formControlName="kpi_interestedcustno"
        />
      </div>
      <div class="form-group">
        <label>KPI thời gian quan tâm</label>
        <input
          type="text"
          class="form-control mt-2"
          formControlName="kpi_sumwatchtime"
        />
      </div>
      <div class="form-group">
        <label>Thông tin</label>
        <input type="text" class="form-control mt-2" formControlName="info" />
      </div>
      <div class="form-group">
        <label>Loại đối tượng nhận diện</label>
        <div class="mt-2">
          <ng-multiselect-dropdown
            [placeholder]="'Chọn loại đối tượng'"
            [settings]="multiSelectSettings"
            [data]="customerTypeList"
            formControlName="notifytypes"
          ></ng-multiselect-dropdown>
        </div>
      </div>
      <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 5px">
        <div class="form-group">
          <label>Trạng thái</label>
          <select class="form-control mt-2" formControlName="deactiveyn">
            <option value="T">Tắt</option>
            <option value="F">Bật</option>
          </select>
        </div>
        <div class="form-group">
          <label>Loại camera <a class="required-field">*</a></label>
          <select
            class="form-control mt-2"
            formControlName="cameratypeoptionid"
            required
          >
            <option *ngFor="let type of cameraTypeList" [ngValue]="type.id">
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="d-flex">
        <button
          *ngIf="selectedCameragroupId"
          type="button"
          class="btn btn-sm btn-secondary mt-2 mr-2 pull-right full-width"
          (click)="deleteCameragroup()"
        >
          Xóa
        </button>
        <button
          type="submit"
          class="btn btn-sm btn-secondary mt-2 pull-right full-width"
        >
          Lưu
        </button>
      </div>
    </form>
  </div>
  <div
    id="device"
    class="tab-pane p-3 full-height"
    [ngClass]="{ active: chosenTab == 'device' }"
  >
    <ng-container *ngIf="showCameraForm">
      <div class="row">
        <div [ngClass]="{ 'col-6': isCCTV, 'col-12': !isCCTV }">
          <form [formGroup]="cameraFormGroup">
            <div class="form-group" *ngIf="cameraId">
              <label>Mã</label>
              <input
                type="text"
                class="form-control mt-2"
                formControlName="code"
              />
            </div>
            <div class="form-group">
              <label>Tên<a class="required-field">*</a></label>
              <input
                type="text"
                class="form-control mt-2"
                formControlName="name"
                required
              />
            </div>
            <div class="form-group">
              <label>Link camera<a class="required-field">*</a></label>
              <textarea
                class="form-control mt-2"
                rows="2"
                formControlName="cameraurl"
                required
              ></textarea>
            </div>
            <div class="form-group">
              <label>Link stream<a class="required-field">*</a></label>
              <textarea
                class="form-control mt-2"
                rows="2"
                formControlName="streamlink"
                required
              ></textarea>
            </div>
            <label>Tọa độ<a class="required-field">*</a></label>
            <div
              style="
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 5px;
              "
            >
              <div class="form-group">
                <input
                  type="text"
                  class="form-control mt-2"
                  formControlName="lat"
                  placeholder="Vĩ độ"
                  required
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control mt-2"
                  formControlName="lon"
                  placeholder="Kinh độ"
                  required
                />
              </div>
            </div>
            <div
              style="
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 5px;
              "
            >
              <div class="form-group">
                <label>Trạng thái</label>
                <select class="form-control mt-2" formControlName="deactiveyn">
                  <option value="T">Tắt</option>
                  <option value="F">Bật</option>
                </select>
              </div>
              <div class="form-group">
                <label>Loại camera<a class="required-field">*</a></label>
                <select
                  class="form-control mt-2"
                  formControlName="cameratypeoptid"
                  required
                >
                  <option
                    *ngFor="let type of cameraTypeList"
                    [ngValue]="type.id"
                  >
                    {{ type.name }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="col-6" *ngIf="isCCTV">
          <div>
            <form id="cameraConfig" [formGroup]="cameraConfigForm">
              <div
                style="
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr 1fr;
                  grid-gap: 5px;
                "
              >
                <div class="form-group">
                  <label>AI</label>
                  <select class="form-control mt-2" formControlName="enable">
                    <option value="true" defaultSelected>Bật</option>
                    <option value="false">Tắt</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Dài<a class="required-field">*</a></label>
                  <input
                    type="text"
                    class="form-control mt-2"
                    formControlName="stream_width"
                    required
                  />
                </div>
                <div class="form-group">
                  <label>Rộng<a class="required-field">*</a></label>
                  <input
                    type="text"
                    class="form-control mt-2"
                    formControlName="stream_height"
                    required
                  />
                </div>
                <div class="form-group">
                  <label>Giám sát</label>
                  <select
                    class="form-control mt-2"
                    formControlName="enable_monitor_mode"
                  >
                    <option value="true" defaultSelected>Bật</option>
                    <option value="false">Tắt</option>
                  </select>
                </div>
              </div>
              <div
                style="
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr;
                  grid-gap: 5px;
                "
              >
                <div class="form-group">
                  <label>
                    Tốc độ phân tích (FPS)<a class="required-field">*</a>
                  </label>
                  <input
                    type="text"
                    class="form-control mt-2"
                    formControlName="capture_fps"
                    required
                  />
                </div>
                <div class="form-group">
                  <label>Tốc độ camera (FPS)</label>
                  <input
                    type="text"
                    class="form-control mt-2"
                    formControlName="stream_fps"
                  />
                </div>
                <div class="form-group">
                  <label>Kích thước khuôn mặt</label>
                  <input
                    type="text"
                    class="form-control mt-2"
                    formControlName="min_face_size"
                  />
                </div>
              </div>
              <div class="img-container" id="container">
                <img
                  id="source"
                  *ngIf="cameraFormGroup && cameraFormGroup.value"
                  src="{{ cameraFormGroup.value.cameraurl }}"
                  style="max-width: 100%; max-height: 100%"
                  (load)="loadConfig()"
                  (error)="onImgError($event)"
                />
                <canvas
                  id="canvas"
                  [ngClass]="{
                    drawing: isDrawingBoundingBox,
                    'not-drawing': !isDrawingBoundingBox
                  }"
                  (click)="getPosition($event)"
                >
                </canvas>
              </div>
              <div class="d-flex mt-2">
                <ng-container *ngIf="isDrawingBoundingBox">
                  <button
                    class="btn btn-sm"
                    [ngClass]="{
                      'btn-secondary':
                        boundingBoxIsFinished || boundingPoints.length <= 2,
                      'btn-success':
                        !boundingBoxIsFinished && boundingPoints.length > 2
                    }"
                    [disabled]="
                      boundingBoxIsFinished || boundingPoints.length <= 2
                    "
                    (click)="autoCorrectDrawing()"
                  >
                    Tự động điều chỉnh
                  </button>
                  <button
                    class="btn btn-sm btn-secondary ml-1"
                    (click)="cancelDrawing()"
                  >
                    Hủy
                  </button>
                </ng-container>
                <ng-container *ngIf="!isDrawingBoundingBox">
                  <button
                    class="btn btn-sm btn-secondary"
                    (click)="startDrawing()"
                  >
                    Cấu hình camera
                  </button>
                </ng-container>
              </div>
              <ng-container *ngIf="!boundingBoxIsFinished">
                <div style="color: red">*Khu vực nhận diện chưa khép kín</div>
              </ng-container>
              <div
                style="
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr;
                  grid-gap: 5px;
                  margin-top: 10px;
                "
              >
                <div class="form-group">
                  <label>Ffmpeg vcodec</label>
                  <input
                    type="text"
                    class="form-control mt-2"
                    formControlName="stream_ffmpeg_vcodec"
                  />
                </div>
                <div class="form-group">
                  <label>Rtsp transport</label>
                  <input
                    type="text"
                    class="form-control mt-2"
                    formControlName="rtsp_transport"
                  />
                </div>
                <div class="form-group">
                  <label>Rtsp flags</label>
                  <input
                    type="text"
                    class="form-control mt-2"
                    formControlName="rtsp_flags"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="d-flex full-width">
          <button
            type="button"
            class="btn btn-sm btn-secondary mt-2 mr-2 pull-right full-width"
            (click)="cancelCameraForm()"
          >
            Trở về
          </button>
          <button
            type="butotn"
            class="btn btn-sm btn-secondary mt-2 pull-right full-width"
            [disabled]="!boundingBoxIsFinished"
            (click)="submitCameraForm()"
          >
            Lưu
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!showCameraForm">
      <div *ngIf="cameraList.length > 0">
        <table class="table table-striped table-hover">
          <thead>
            <tr class="table-secondary">
              <th scope="col" style="width: 30%">
                <div class="col-label">Mã</div>
              </th>
              <th scope="col" style="width: 40%">
                <div class="col-label">Tên</div>
              </th>
              <th scope="col" style="width: 15%">
                <div class="col-label">Loại thiết bị</div>
              </th>
              <th scope="col" style="width: 15%">
                <div class="col-label">Trạng thái</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let camera of cameraList"
              class="handle"
              (click)="selectCamera(camera)"
            >
              <td>{{ camera.code }}</td>
              <td>{{ camera.name }}</td>
              <td>{{ camera.t_option ? camera.t_option.name : "" }}</td>
              <td>
                <span
                  class="mr-1"
                  [ngClass]="{
                    'dot-offline': camera.deactiveyn == 'T',
                    'dot-online': !camera.deactiveyn || camera.deactiveyn == 'F'
                  }"
                ></span>
                {{ camera.deactiveyn == "T" ? "Tắt" : "Bật" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="cameraList.length == 0">
        <p>Không có thiết bị nào</p>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          (click)="showAddCameraForm()"
        >
          Thêm thiết bị
        </button>
      </div>
    </ng-container>
  </div>
</div>
