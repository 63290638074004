<div class="full-width full-height" style="overflow: auto; position: relative">
  <ngx-spinner
    bdColor="rgba(255,255,255,0.4)"
    size="medium"
    color="#000"
    type="ball-spin"
    [fullScreen]="false"
    [name]="spinnerName"
  ></ngx-spinner>
  <table class="table table-striped" style="margin: 0">
    <thead>
      <tr class="table-secondary">
        <th scope="col">
          <div class="sortable-col">
            <div class="col-label">Tên</div>
          </div>
        </th>
        <th scope="col">
          <div class="sortable-col">
            <div class="col-label">Tên đăng nhập</div>
          </div>
        </th>
        <th scope="col">
          <div class="sortable-col">
            <div class="col-label">Cơ quan</div>
          </div>
        </th>
        <th scope="col">
          <div class="sortable-col">
            <div class="col-label">Nhóm quyền</div>
          </div>
        </th>
        <th scope="col">
          <div class="sortable-col">
            <div class="col-label">Trạng thái</div>
          </div>
        </th>
        <th scope="col">
          <div class="sortable-col">
            <div class="col-label">Thao tác</div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of userList">
        <td class="col-2">{{ row.name }}</td>
        <td class="col-2">{{ row.username }}</td>
        <td class="col-2">
          {{ row.t_branch ? row.t_branch.name : "-" }}
        </td>
        <td class="col-3">
          <ng-container *ngIf="isEditing === row.id; else userRoleTemplate">
            <ng-multiselect-dropdown
              [placeholder]="'-'"
              [settings]="roleDropdownSettings"
              [data]="roleList"
              [(ngModel)]="selectedRole"
            ></ng-multiselect-dropdown>
          </ng-container>
          <ng-template #userRoleTemplate>
            <div style="padding: 6px 12px">
              <ng-container
                *ngIf="
                  row.t_roles && row.t_roles.length > 0;
                  else emptyRoleTemplate
                "
              >
                <ng-container *ngFor="let role of row.t_roles">
                  <span class="badge badge-primary text-uppercase mr-2">
                    {{ role.name }}
                  </span>
                </ng-container>
              </ng-container>
              <ng-template #emptyRoleTemplate>
                <span>-</span>
              </ng-template>
            </div>
          </ng-template>
        </td>
        <td class="col-1">
          <ng-container *ngIf="isEditing === row.id; else userActiveTemplate">
            <input
              type="checkbox"
              class="form-control"
              style="max-width: 20px; max-height: 20px"
              [(ngModel)]="selectedUserActive"
            />
          </ng-container>
          <ng-template #userActiveTemplate>
            <ng-container *ngIf="row.active; else inactiveTemplate">
              <span class="badge badge-success text-uppercase">Active</span>
            </ng-container>
            <ng-template #inactiveTemplate>
              <span class="badge badge-secondary text-uppercase">Inactive</span>
            </ng-template>
          </ng-template>
        </td>
        <td class="col-1">
          <ng-container *ngIf="isEditing === row.id; else editTemplate">
            <div class="d-flex">
              <button
                class="btn btn-sm btn-success"
                (click)="submitChange(row.id)"
              >
                <i class="fa fa-check"></i>
              </button>
              <button
                class="btn btn-sm btn-danger ml-2"
                (click)="toggleEditMode(row)"
              >
                <i class="fa fa-close"></i>
              </button>
            </div>
          </ng-container>
          <ng-template #editTemplate>
            <button
              class="btn btn-sm btn-primary"
              (click)="toggleEditMode(row)"
            >
              <i class="fa fa-edit"></i>
            </button>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
