import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Camera } from 'src/app/models/camera';
import { Cameragroup } from 'src/app/models/cameragroup';
import { ApiService } from 'src/app/services/api.service';
import { API, REPLACEMENT } from 'src/environments/environment';

@Component({
  selector: 'app-list-camera',
  templateUrl: './list-camera.component.html',
  styleUrls: ['./list-camera.component.scss']
})
export class ListCameraComponent implements OnInit {
  @Output() clickedCamera: EventEmitter<Camera> = new EventEmitter<Camera>();
  @Output() clickedCameraGroup: EventEmitter<Cameragroup> = new EventEmitter<Cameragroup>();
  public openingCameraGroup: number = 0;
  public cameraGroupList: Cameragroup[] = [];
  public selectedCamera: Camera;
  public searchText: string = '';
  public noData = REPLACEMENT.noData;

  constructor(
    private apiService: ApiService
  ) {
  }

  ngOnInit(): void {
    this.getCameras();
  }

  public onShow(cameraGroupId) {
    if (this.openingCameraGroup != cameraGroupId) {
      this.openingCameraGroup = cameraGroupId;
    }
    else {
      this.openingCameraGroup = 0;
    }
  }

  public getCameras() {
    this.cameraGroupList.length = 0;
    let cameraInclude = [
      {
        model: 't_camera',
        include: []
      }
    ]
    let cameraQuery = {
      op: 'AND',
      val: [
        {
          model: 't_cameras',
          col: 'name',
          op: 'ilike',
          val: '%' + this.searchText + '%'
        }
      ]
    }
    this.apiService.get<Cameragroup[]>(API.cameraGroup, {include: JSON.stringify(cameraInclude), query: JSON.stringify(cameraQuery)}).subscribe(cameraGroups => {
      if (cameraGroups && cameraGroups.length > 0) {
        this.cameraGroupList = cameraGroups;
        this.openingCameraGroup = cameraGroups[0].id;
        this.handleClickedCamera(this.cameraGroupList[0].t_cameras[0], this.cameraGroupList[0]);
      }
    })
  }

  public handleClickedCamera(camera: Camera, cameraGroup: Cameragroup) {
    this.selectedCamera = camera;
    this.clickedCamera.emit(camera);
    this.clickedCameraGroup.emit(cameraGroup);
  }
}
