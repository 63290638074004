import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Camera } from '../../../models/camera';
import { SocketService } from '../../../services/socket.service';
import {
  API,
  DEVICE_IMG_HOST,
  FUNC,
  REPLACEMENT,
} from '../../../../environments/environment';
import { Cameragroup } from '../../../models/cameragroup';
import * as moment from 'moment';
import { ApiService } from '../../../services/api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit, OnDestroy {
  public isMinimize = false;
  public camera: Camera;
  public currentDeviceImg = REPLACEMENT.noDeviceImg;
  public deviceStatus;
  private cameraInterval;
  private deviceTimeout = REPLACEMENT.deviceTimeout;
  // public cameraGroupList: Cameragroup[];
  public selectedCameraGroupStat = {
    month: moment().format('MM-yyyy'),
    custno: 0,
    interestedcustno: 0,
    sumwatchtime: 0,
  };
  public noStatistic = REPLACEMENT.noStatistic;
  public noText = REPLACEMENT.noText;
  public deviceSubscription: Subscription;
  private dataSubscription: Subscription;
  public selectedCameraGroup: string = 'Tìm kiếm';
  private deviceTimeLimit = REPLACEMENT.deviceTimeLimit;
  public prevSocketEventData;
  public originCameraGrouptStat;
  public isMobile: boolean = false;

  constructor(
    private socketService: SocketService,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.closeSubscription();
  }

  public checkDeviceStatus(status) {
    let timeDiff = moment().diff(moment(status.timestamp), 'seconds');
    if (timeDiff > this.deviceTimeLimit) {
      this.camera.deactiveyn = 'F';
    } else {
      this.camera.deactiveyn = 'T';
    }
  }

  public startWatchingCamera() {
    if (this.deviceSubscription) {
      this.deviceSubscription.unsubscribe();
    }
    this.clearDeviceInterval();
    switch (this.camera.t_option.code) {
      case 'A':
        this.deviceSubscription = this.socketService
          .onNewDeviceStatus()
          .subscribe((deviceStatus) => {
            if (deviceStatus) {
              if (deviceStatus.device_id == this.camera.code) {
                this.deviceStatus = deviceStatus;
                this.checkDeviceStatus(this.deviceStatus);
                this.currentDeviceImg =
                  DEVICE_IMG_HOST +
                  deviceStatus.url +
                  '?' +
                  deviceStatus.timestamp;
                this.cdr.detectChanges(); //to apply variable change on this component
              }
            }
          });
        this.camera.deactiveyn = 'T';
        this.getDeviceStatus(this.camera.code);
        this.cameraInterval = setInterval(() => {
          this.getDeviceStatus(this.camera.code);
        }, this.deviceTimeout * 1000);
        break;
      case 'C':
        if (this.camera.cameraurl)
          this.currentDeviceImg = this.camera.cameraurl;
        break;
      case 'M':
        this.isMobile = true;
        break;
    }
  }

  public clearDeviceInterval() {
    if (this.cameraInterval) {
      clearInterval(this.cameraInterval);
    }
  }

  public getDeviceStatus(deviceid) {
    this.socketService.getDeviceStatus(deviceid);
  }

  public closeSubscription() {
    if (this.deviceSubscription) {
      this.deviceSubscription.unsubscribe();
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  public getCameraGroupStat() {
    let params = {
      ward: 0,
      branch: this.camera.t_cameragroup.branchid,
      cameraGroup: this.camera.t_cameragroup.id,
      timerange: 31,
    };
    this.apiService
      .get<any[]>(API.view + FUNC.getStatByMonth, params)
      .subscribe((data) => {
        if (data) {
          let cameraGroupData = data.find(
            (stat) => stat.month == moment().format('MM-yyyy')
          );
          this.selectedCameraGroupStat = {
            month: cameraGroupData ? cameraGroupData.month : 0,
            custno:
              cameraGroupData && +cameraGroupData.custno
                ? +cameraGroupData.custno
                : 0,
            interestedcustno:
              cameraGroupData && +cameraGroupData.interestedcustno
                ? +cameraGroupData.interestedcustno
                : 0,
            sumwatchtime:
              cameraGroupData && +cameraGroupData.sumwatchtime
                ? +cameraGroupData.sumwatchtime
                : 0,
          };
          this.originCameraGrouptStat = {
            month: cameraGroupData ? cameraGroupData.month : 0,
            custno:
              cameraGroupData && +cameraGroupData.custno
                ? +cameraGroupData.custno
                : 0,
            interestedcustno:
              cameraGroupData && +cameraGroupData.interestedcustno
                ? +cameraGroupData.interestedcustno
                : 0,
            sumwatchtime:
              cameraGroupData && +cameraGroupData.sumwatchtime
                ? +cameraGroupData.sumwatchtime
                : 0,
          };
          this.cdr.detectChanges(); //to apply variable change on this component
          this.getCameraRealtimeStat(this.camera.t_cameragroup.code);
        }
      });
  }

  public getCameraRealtimeStat(groupid) {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    this.dataSubscription = this.socketService
      .onNewEventStat()
      .subscribe((data) => {
        if (data && data.groupid == this.camera.t_cameragroup.code) {
          let today = moment().format('DD-MM-yyyy');
          let thisTime = moment().hour();
          if (
            (data.daytime == today &&
              data.hourtime == thisTime &&
              !this.prevSocketEventData) ||
            (this.prevSocketEventData &&
              this.prevSocketEventData.custno <= data.custno)
          )
            this.handleEventStatRealtime(data);
        }
      });
    this.socketService.getEventStat(groupid);
  }

  public handleEventStatRealtime(newEventStatData) {
    if (!this.prevSocketEventData) this.prevSocketEventData = newEventStatData;
    if (this.prevSocketEventData.hourtime != newEventStatData.hourtime) {
      this.prevSocketEventData = newEventStatData;
      this.originCameraGrouptStat = Object.assign(
        {},
        this.selectedCameraGroupStat
      );
    }
    this.selectedCameraGroupStat.custno =
      this.originCameraGrouptStat.custno + newEventStatData.custno;
    this.selectedCameraGroupStat.interestedcustno =
      this.originCameraGrouptStat.interestedcustno + newEventStatData.quan_tam;
    this.selectedCameraGroupStat.sumwatchtime =
      this.originCameraGrouptStat.sumwatchtime + newEventStatData.sumwatchtime;
    this.cdr.detectChanges(); //to apply variable change on this component
  }
}
