<div class="box full-width full-height mt-4">
  <div class="title-group p-3 font-weight-bold">Độ tuổi</div>
  <div
    style="display: grid; grid-template-columns: repeat(4, 1fr)"
    class="pt-3"
  >
    <div
      class="row align-items-center"
      style="text-align: center; border-right: solid 1px #dedede"
    >
      <div class="col-5">
        <div class="mb-3"><img src="assets/image/lg-7358.png" alt="" /></div>
        <div class="title-group">TRẺ EM</div>
      </div>
      <div class="col-7">
        <div>
          <div class="sub-title">Đối tượng</div>
          <a
            class="handle title-header font-weight-bold"
            [routerLink]="['/image']"
            [queryParams]="{ age: 'age_child', hour: currentAnalyzeHour }"
            routerLinkActive="router-link-active"
            >{{ ageData.treEm }}</a
          >
          <div class="sub-title">
            {{
              ageData.total
                ? ((ageData.treEm / ageData.total) * 100 | number: "1.0-1")
                : noStatistic
            }}%
          </div>
        </div>
        <div>
          <div class="sub-title">Thời gian xuất hiện</div>
          <div class="title-header font-weight-bold">
            {{
              ageData.treEm
                ? (ageData.treEm_watchtime / ageData.treEm | number: "1.0-0")
                : noStatistic
            }}s
          </div>
          <div class="sub-title">
            {{
              ageData.total_watchtime
                ? ((ageData.treEm_watchtime / ageData.total_watchtime) * 100
                  | number: "1.0-1")
                : noStatistic
            }}%
          </div>
        </div>
      </div>
    </div>
    <div
      class="row align-items-center"
      style="text-align: center; border-right: solid 1px #dedede"
    >
      <div class="col-5">
        <div class="mb-3"><img src="assets/image/lg-6963.png" alt="" /></div>
        <div class="title-group">THANH THIẾU NIÊN</div>
      </div>
      <div class="col-7">
        <div>
          <div class="sub-title">Đối tượng</div>
          <a
            class="handle title-header font-weight-bold"
            [routerLink]="['/image']"
            [queryParams]="{ age: 'age_teenager', hour: currentAnalyzeHour }"
            routerLinkActive="router-link-active"
            >{{ ageData.thanhNien }}</a
          >
          <div class="sub-title">
            {{
              ageData.total
                ? ((ageData.thanhNien / ageData.total) * 100 | number: "1.0-1")
                : noStatistic
            }}%
          </div>
        </div>
        <div>
          <div class="sub-title">Thời gian xuất hiện</div>
          <div class="title-header font-weight-bold">
            {{
              ageData.thanhNien
                ? (ageData.thanhNien_watchtime / ageData.thanhNien
                  | number: "1.0-0")
                : noStatistic
            }}s
          </div>
          <div class="sub-title">
            {{
              ageData.total_watchtime
                ? ((ageData.thanhNien_watchtime / ageData.total_watchtime) * 100
                  | number: "1.0-1")
                : noStatistic
            }}%
          </div>
        </div>
      </div>
    </div>
    <div
      class="row align-items-center"
      style="text-align: center; border-right: solid 1px #dedede"
    >
      <div class="col-5">
        <div class="mb-3"><img src="assets/image/lg-6964.png" alt="" /></div>
        <div class="title-group">TRUNG NIÊN</div>
      </div>
      <div class="col-7">
        <div>
          <div class="sub-title">Đối tượng</div>
          <a
            class="handle title-header font-weight-bold"
            [routerLink]="['/image']"
            [queryParams]="{ age: 'age_middleage', hour: currentAnalyzeHour }"
            routerLinkActive="router-link-active"
            >{{ ageData.trungNien }}</a
          >
          <div class="sub-title">
            {{
              ageData.total
                ? ((ageData.trungNien / ageData.total) * 100 | number: "1.0-1")
                : noStatistic
            }}%
          </div>
        </div>
        <div>
          <div class="sub-title">Thời gian xuất hiện</div>
          <div class="title-header font-weight-bold">
            {{
              ageData.trungNien
                ? (ageData.trungNien_watchtime / ageData.trungNien
                  | number: "1.0-0")
                : noStatistic
            }}s
          </div>
          <div class="sub-title">
            {{
              ageData.total_watchtime
                ? ((ageData.trungNien_watchtime / ageData.total_watchtime) * 100
                  | number: "1.0-1")
                : noStatistic
            }}%
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center" style="text-align: center">
      <div class="col-5">
        <div class="mb-3"><img src="assets/image/lg-6965.png" alt="" /></div>
        <div class="title-group">NGƯỜI CAO TUỔI</div>
      </div>
      <div class="col-7">
        <div>
          <div class="sub-title">Đối tượng</div>
          <a
            class="handle title-header font-weight-bold"
            [routerLink]="['/image']"
            [queryParams]="{ age: 'age_elder', hour: currentAnalyzeHour }"
            routerLinkActive="router-link-active"
            >{{ ageData.caoTuoi }}</a
          >
          <div class="sub-title">
            {{
              ageData.total
                ? ((ageData.caoTuoi / ageData.total) * 100 | number: "1.0-1")
                : noStatistic
            }}%
          </div>
        </div>
        <div>
          <div class="sub-title">Thời gian xuất hiện</div>
          <div class="title-header font-weight-bold">
            {{
              ageData.caoTuoi
                ? (ageData.caoTuoi_watchtime / ageData.caoTuoi
                  | number: "1.0-0")
                : noStatistic
            }}s
          </div>
          <div class="sub-title">
            {{
              ageData.total_watchtime
                ? ((ageData.caoTuoi_watchtime / ageData.total_watchtime) * 100
                  | number: "1.0-1")
                : noStatistic
            }}%
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
