<div class="layout">
  <div class="layout-content">
    <div class="row full-height">
      <div
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0 full-width full-height"
      >
        <app-login></app-login>
      </div>
    </div>
  </div>
</div>
