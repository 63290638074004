import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Camera } from 'src/app/models/camera';
import { WebrtcService } from 'src/app/services/webrtc.service';
import {REPLACEMENT} from "../../../environments/environment";

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit, OnChanges {

  @Input() imageUrl: string;
  @Input() device: Camera;
  @Input() useWebRTC: boolean = false;
  constructor(
    private webRTCService: WebrtcService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}) {
    if (changes['useWebRTC'] && changes['useWebRTC'].currentValue) {
      if (this.device) this.webRTCService.start(this.device);
    }
  }

}
