import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CctvLayoutComponent } from '../cctv-layout/cctv-layout.component';
import { AnalysisLayoutComponent } from '../analysis-layout/analysis-layout.component';
import { MapLayoutComponent } from '../map-layout/map-layout.component';
import { AuthGuard } from '../../services/auth/auth.guard';
import { ManageLayoutComponent } from '../manage-layout/manage-layout.component';
import { ManagePersonComponent } from '../../components/manage-person/manage-person.component';
import { ManageBranchComponent } from '../../components/manage-branch/manage-branch.component';
import { ImageLayoutComponent } from '../image-layout/image-layout.component';
import { ManageReportComponent } from '../../components/manage-report/manage-report.component';
import { ListEventImagesComponent } from '../../components/list-event-images/list-event-images.component';
import { AiListEventImageComponent } from '../../components/ai-list-event-image/ai-list-event-image.component';
import { HeatmapLayoutComponent } from '../heatmap-layout/heatmap-layout.component';
import { MonitorLayoutComponent } from '../monitor-layout/monitor-layout.component';
import { ManageRoleComponent } from '../../components/manage-role/manage-role.component';

const routes: Routes = [
  // {
  //   path: 'cctv', component: CctvLayoutComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'analysis',
    component: AnalysisLayoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'map',
    component: MapLayoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'manage',
    component: ManageLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'emp', pathMatch: 'full' },
      { path: 'emp', component: ManagePersonComponent },
      { path: 'customer', component: ManagePersonComponent },
      { path: 'user', component: ManagePersonComponent },
      { path: 'branch', component: ManageBranchComponent },
      { path: 'report', component: ManageReportComponent },
      { path: 'role', component: ManageRoleComponent },
    ],
  },
  {
    path: 'image',
    component: ImageLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ListEventImagesComponent },
      // { path: 'old', component: AiListEventImageComponent },
    ],
  },
  {
    path: 'heatmap',
    component: HeatmapLayoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'monitor',
    component: MonitorLayoutComponent,
    canActivate: [AuthGuard],
  },

  //last
  {
    path: '**',
    redirectTo: 'analysis',
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainLayoutRoutingModule {}
