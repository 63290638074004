<div class="box full-width mt-4">
  <div class="box-header row">
    <div class="pt-3 col title font-weight-bold">Đối tượng</div>
    <!-- <ul class="pt-2 nav justify-content-end col">
      <li class="nav-item title">
        <div class="nav-link" [ngClass]="{'active': selectedTab == 'day', 'handle': selectedTab != 'day'}" (click)="handleSelectTab('day')">Ngày</div>
      </li>
      <li class="nav-item title">
        <div class="nav-link" [ngClass]="{'active': selectedTab == 'week', 'handle': selectedTab != 'week'}" (click)="handleSelectTab('week')">Tuần</div>
      </li>
      <li class="nav-item title">
        <div class="nav-link" [ngClass]="{'active': selectedTab == 'month', 'handle': selectedTab != 'month'}" (click)="handleSelectTab('month')">Tháng</div>
      </li>
    </ul> -->
  </div>
  <div class="box-body tab-content">
    <div id="chart" class="tab-pane chart-container active">
      <ngx-charts-bar-vertical-stacked
        [scheme]="colorScheme"
        [results]="chartData"
        [barPadding]="chartColumnPadding"
        [xAxis]="true"
        [yAxis]="true"
        [yScaleMax]="chartMaxYTick"
        [yAxisTickFormatting]="formatYAxisTick"
      >
        <ng-template #tooltipTemplate let-model="model">
          <div *ngIf="model">
            <span style="font-weight: bold"
              >{{ xAxisUnit }} {{ model.series }}</span
            >
            <div *ngIf="getColumnData(model.series); let data">
              <a>{{ interestedcustnoLabel }}: {{ data[0].value }}</a
              ><br />
              <a>{{ custnoLabel }}: {{ data[0].value + data[1].value }}</a>
            </div>
          </div>
        </ng-template>
      </ngx-charts-bar-vertical-stacked>
    </div>
    <!-- <div id="week" class="tab-pane chart-container" [ngClass]="{'active': selectedTab == 'week', 'fade': selectedTab != 'week'}">
      <ngx-charts-bar-vertical-stacked
      [scheme]="colorScheme"
      [results]="chartData"
      [barPadding]="3"
      [xAxis]="true"
      [yAxis]="true">
    </ngx-charts-bar-vertical-stacked>
    </div>
    <div id="month" class="tab-pane chart-container" [ngClass]="{'active': selectedTab == 'month', 'fade': selectedTab != 'month'}">
      <ngx-charts-bar-vertical-stacked
      [scheme]="colorScheme"
      [results]="chartData"
      [barPadding]="3"
      [xAxis]="true"
      [yAxis]="true">
    </ngx-charts-bar-vertical-stacked>
    </div> -->
  </div>
</div>
