import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor() {}

  public informSuccess(msg: string) {
    Swal.fire({
      icon: 'success',
      title: msg,
      timer: 3000,
      heightAuto: false,
    });
  }

  public informError(msg: string) {
    return Swal.fire({
      icon: 'error',
      title: msg,
      timer: 3000,
      heightAuto: false,
    });
  }

  public confirm(msg: string) {
    return Swal.fire({
      icon: 'warning',
      title: msg,
      showCancelButton: true,
      confirmButtonText: `Xác nhận`,
      cancelButtonText: `Hủy bỏ`,
      heightAuto: false,
    });
  }
}
