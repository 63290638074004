import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { Branch } from 'src/app/models/branch';
import { Role } from 'src/app/models/role';
import { User } from 'src/app/models/user';
import { ApiService } from 'src/app/services/api.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
})
export class UserTableComponent implements OnInit {
  public readonly spinnerName = 'user-table-spinner';
  public userList: User[] = [];
  public branchList: Branch[] = [];
  public roleList: Role[] = [];
  public isEditing: number = null;
  public selectedRole: Role[] = [];
  public roleDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Tất cả quyền',
    unSelectAllText: 'Bỏ chọn tất cả',
    itemsShowLimit: 2,
  };
  public selectedUserActive: boolean = false;
  constructor(
    private apiService: ApiService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getUserList();
    this.getBranchList();
    this.getRoleList();
  }

  public getUserList(): void {
    this.spinner.show(this.spinnerName);
    const include = [
      {
        model: 't_role',
        include: [],
      },
      {
        model: 't_branch',
        include: [],
      },
    ];
    this.apiService
      .get<User[]>(API.users, { include: JSON.stringify(include) })
      .subscribe((data) => {
        this.spinner.hide(this.spinnerName);
        this.userList = data;
      });
  }

  public getBranchList(): void {
    const query = {
      op: 'and',
      val: [
        {
          model: 't_branch',
          op: 'is',
          col: 'parentid',
          val: null,
        },
      ],
    };
    this.apiService
      .get<Branch[]>(API.branches, { query: JSON.stringify(query) })
      .subscribe((branchList) => {
        this.branchList = branchList;
      });
  }

  public getRoleList(): void {
    this.apiService.get<Role[]>(API.roles).subscribe((roleList) => {
      this.roleList = roleList;
    });
  }

  public toggleEditMode(user: User): void {
    if (this.isEditing === user.id) {
      this.isEditing = null;
      return;
    }
    this.isEditing = user.id;
    this.selectedUserActive = user.active;
    if (user.t_roles) {
      this.selectedRole = user.t_roles;
    }
  }

  public submitChange(userId: number): void {
    const newUserRole = {
      id: userId,
      roleIdList: this.selectedRole.map((role) => role.id),
      active: this.selectedUserActive,
    };
    this.apiService.post(API.updateUserRole, newUserRole).subscribe((res) => {
      this.getUserList();
      this.isEditing = null;
    });
  }
}
