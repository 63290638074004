import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SocketService } from 'src/app/services/socket.service';
import { REPLACEMENT } from 'src/environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-main-monitor',
  templateUrl: './main-monitor.component.html',
  styleUrls: ['./main-monitor.component.scss'],
})
export class MainMonitorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedMonitor: any;
  public defaultImg = REPLACEMENT.noDeviceImg;
  public imgSrc: string | SafeUrl = this.defaultImg;
  public monitorSubscription: Subscription = null;
  public currentMonitorData: any = null;
  constructor(
    private socketService: SocketService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes['selectedMonitor']) {
      this.imgSrc = this.defaultImg;
      if (changes['selectedMonitor'].previousValue) {
        this.unsubscribeMonitor(changes['selectedMonitor'].previousValue.code);
      }
      if (this.selectedMonitor) {
        this.subscribeMonitor(this.selectedMonitor.code);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.monitorSubscription) {
      this.monitorSubscription.unsubscribe();
    }
    if (this.selectedMonitor) {
      this.unsubscribeMonitor(this.selectedMonitor.code);
    }
  }

  public handleErrorImg(err) {
    this.imgSrc = this.defaultImg;
  }

  public subscribeMonitor(cameraCode: string) {
    this.monitorSubscription = this.socketService
      .getMonitorEvent(cameraCode)
      .subscribe((data) => {
        if (
          !this.currentMonitorData ||
          (this.currentMonitorData &&
            this.currentMonitorData.timestamp < data.timestamp)
        ) {
          this.currentMonitorData = data;
          const base64 = this.arrayBufferToBase64(data.imgBytes);
          this.imgSrc = this.sanitize(`data:image/jpg;base64,${base64}`);
        }
      });
  }

  public unsubscribeMonitor(cameraCode: string) {
    this.monitorSubscription.unsubscribe();
    this.monitorSubscription = null;
    this.currentMonitorData = null;
    this.socketService.closeMonitor(cameraCode);
  }

  public arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  public sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
