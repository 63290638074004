import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-heatmap-layout',
  templateUrl: './heatmap-layout.component.html',
  styleUrls: ['./heatmap-layout.component.scss'],
})
export class HeatmapLayoutComponent implements OnInit {
  public isShowSideBar: any = false;
  constructor() {}

  ngOnInit(): void {}

  public handleShowSideBar() {
    this.isShowSideBar = this.isShowSideBar !== true;
  }
}
