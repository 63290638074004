<div class="full-height full-width position-relative">
  <div
    *ngIf="!minimize"
    id="filter-pane"
    class="box h-auto position-absolute m-3"
    style="z-index: 1000; left: 0px; width: 23vw"
  >
    <!-- <select
      #select
      class="form-select full-width full-height p-2"
      aria-label="Default select example"
      [ngModel]="selectedCameraGroup"
      (ngModelChange)="onChange($event)"
      [ngClass]="isShowOption ? 'background-shadow' : ''"
    >
      <option selected>Tìm kiếm</option>
      <option *ngFor="let camera of cameras" [ngValue]="camera">
        {{ camera.name }}
      </option>
    </select> -->
    <div class="input-group" (click)="toggleFilter()">
      <div class="input-group-prepend">
        <span
          class="input-group-text"
          tabindex="1"
          *ngIf="currentLevel == filterLevel[0]"
        >
          <img src="assets/image/list-icon.svg" />
        </span>
        <span
          class="input-group-text"
          tabindex="1"
          *ngIf="currentLevel != filterLevel[0]"
          (click)="backToPrevFilterLevel($event)"
        >
          <i class="fa fa-chevron-left" style="color: #009fff"></i>
        </span>
      </div>
      <input
        class="form-control"
        style="border: none"
        type="text"
        placeholder="Tên, cơ quan/khu vực camera"
        [(ngModel)]="textSearch"
        (keyup.enter)="filterText()"
        (click)="onClickFilter($event)"
        [disabled]="currentLevel == filterLevel[2]"
      />
      <div class="input-group-append">
        <span class="input-group-text" tabindex="1" (click)="filterText($event)"
          ><i class="fa fa-search" style="color: #009fff"></i
        ></span>
        <span
          class="input-group-text"
          tabindex="1"
          (click)="toggleFilter(); stopEventPropagation($event)"
          *ngIf="displayFilter"
          ><i class="fa fa-close" style="color: #009fff"></i
        ></span>
      </div>
    </div>
    <div
      class="search-pane"
      tabindex="1"
      [ngClass]="{ 'display-block': displayFilter }"
      *ngIf="displayFilter"
    >
      <ngx-spinner
        bdColor="rgba(255,255,255,0.4)"
        size="medium"
        color="#000"
        type="ball-spin"
        [fullScreen]="false"
        [name]="spinnerName"
      >
      </ngx-spinner>
      <div class="search-result" *ngIf="currentLevel == filterLevel[0]">
        <span class="search-result-text">{{ branchList.length }} cơ quan</span>
        <div class="search-result-list">
          <div
            class="search-result-item d-flex"
            *ngFor="let branch of branchList"
            (click)="onClickBranch(branch)"
          >
            <div class="item-icon">
              <img src="assets/image/camera1.png" />
            </div>
            <div class="item-name flex-grow-1">
              <div class="item-name-title">{{ branch.name }}</div>
              <div class="item-name-subtitle">
                {{ branch.t_cameragroups.length }} khu vực
              </div>
            </div>
            <div class="item-arrow">
              <i class="fa fa-caret-right" style="color: #009fff"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="search-result" *ngIf="currentLevel == filterLevel[1]">
        <span class="search-result-text"
          >{{ cameraGroupList.length }} khu vực</span
        >
        <div class="search-result-list">
          <div
            class="search-result-item d-flex"
            *ngFor="let cameragroup of cameraGroupList"
            (click)="onClickCameragroup(cameragroup)"
          >
            <div class="item-icon">
              <img src="assets/image/camera2.png" />
            </div>
            <div class="item-name flex-grow-1">
              <div class="item-name-title">{{ cameragroup.name }}</div>
              <div class="item-name-subtitle">
                {{ cameragroup.t_cameras.length }} camera
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="search-result" *ngIf="currentLevel == filterLevel[2]">
        <div class="liveview-container">
          <img class="liveview" [src]="currentDeviceImg" />
        </div>
        <div class="cameragroup-detail">
          <div class="cameragroup-info">
            <div class="d-flex">
              <span class="flex-grow-1 cameragroup-title">
                {{ selectedCameragroup.name }}
              </span>
              <div class="disable-tag" *ngIf="!cameraEnable">
                <span class="dot-offline" style="margin-right: 6px"></span>
                NGƯNG HOẠT ĐỘNG
              </div>
              <div class="enable-tag" *ngIf="cameraEnable">
                <span class="dot-online" style="margin-right: 6px"></span>HOẠT
                ĐỘNG
              </div>
            </div>
            <span class="cameragroup-subtitle">{{ selectedBranch.name }}</span>
          </div>
          <div class="cameragroup-divider">
            <div class="cameragroup-general-stat">
              <div class="cameragrop-stat" style="background-color: #009fff">
                <div class="general-stat-number">
                  {{ selectedCameraGroupStat.custno }}
                </div>
                <div class="cameragrop-stat-title">ĐỐI TƯỢNG</div>
              </div>
              <div class="cameragrop-stat" style="background-color: #02c39a">
                <div class="general-stat-number">
                  {{ selectedCameraGroupStat.quan_tam }}
                </div>
                <div class="cameragrop-stat-title">LẢNG VẢNG</div>
              </div>
              <div class="cameragrop-stat" style="background-color: #af52de">
                <div class="general-stat-number">
                  {{
                    selectedCameraGroupStat && +selectedCameraGroupStat.custno
                      ? (+selectedCameraGroupStat.sumwatchtime /
                          +selectedCameraGroupStat.custno | formatTime)
                      : noStatistic
                  }}
                </div>
                <div class="cameragrop-stat-title">THỜI GIAN XUẤT HIỆN</div>
              </div>
            </div>
          </div>
          <div class="cameragroup-divider">
            <div class="divider-title">THỐNG KÊ</div>
            <div class="cameragrop-gender-stat">
              <div class="camera-stat row">
                <div class="stat-icon col-1" style="background-color: #3599fc">
                  <i class="fa fa-mars"></i>
                </div>
                <div class="cameragrop-stat-number col-2">
                  {{ selectedCameraGroupStat.nam }}
                </div>
                <div class="stat-type col-7">Nam</div>
                <div class="percentage col-2">
                  ({{
                    selectedCameraGroupStat && +selectedCameraGroupStat.custno
                      ? ((+selectedCameraGroupStat.nam /
                          +selectedCameraGroupStat.custno) *
                          100 | number : "1.0-1")
                      : noStatistic
                  }}%)
                </div>
              </div>
              <div class="camera-stat row">
                <div class="stat-icon col-1" style="background-color: #ff005f">
                  <i class="fa fa-venus"></i>
                </div>
                <div class="cameragrop-stat-number col-2">
                  {{ selectedCameraGroupStat.nu }}
                </div>
                <div class="stat-type col-7">Nữ</div>
                <div class="percentage col-2">
                  ({{
                    selectedCameraGroupStat && +selectedCameraGroupStat.custno
                      ? ((+selectedCameraGroupStat.nu /
                          +selectedCameraGroupStat.custno) *
                          100 | number : "1.0-1")
                      : noStatistic
                  }}%)
                </div>
              </div>
            </div>
          </div>
          <div class="cameragroup-divider">
            <div class="camera-stat row">
              <div class="stat-icon col-1" style="background-color: #ebebeb">
                <img class="img-icon" src="assets/image/p-1.png" />
              </div>
              <div class="cameragrop-stat-number col-2">
                {{ selectedCameraGroupStat.tre_em }}
              </div>
              <div class="stat-type col-7">Trẻ Em</div>
              <div class="percentage col-2">
                ({{
                  selectedCameraGroupStat && +selectedCameraGroupStat.custno
                    ? ((+selectedCameraGroupStat.tre_em /
                        +selectedCameraGroupStat.custno) *
                        100 | number : "1.0-1")
                    : noStatistic
                }}%)
              </div>
            </div>
            <div class="camera-stat row">
              <div class="stat-icon col-1" style="background-color: #ebebeb">
                <img class="img-icon" src="assets/image/p-2.png" />
              </div>
              <div class="cameragrop-stat-number col-2">
                {{ selectedCameraGroupStat.thanh_nien }}
              </div>
              <div class="stat-type col-7">Thanh thiếu niên</div>
              <div class="percentage col-2">
                ({{
                  selectedCameraGroupStat && +selectedCameraGroupStat.custno
                    ? ((+selectedCameraGroupStat.thanh_nien /
                        +selectedCameraGroupStat.custno) *
                        100 | number : "1.0-1")
                    : noStatistic
                }}%)
              </div>
            </div>
            <div class="camera-stat row">
              <div class="stat-icon col-1" style="background-color: #ebebeb">
                <img class="img-icon" src="assets/image/p-3.png" />
              </div>
              <div class="cameragrop-stat-number col-2">
                {{ selectedCameraGroupStat.trung_nien }}
              </div>
              <div class="stat-type col-7">Trung niên</div>
              <div class="percentage col-2">
                ({{
                  selectedCameraGroupStat && +selectedCameraGroupStat.custno
                    ? ((+selectedCameraGroupStat.trung_nien /
                        +selectedCameraGroupStat.custno) *
                        100 | number : "1.0-1")
                    : noStatistic
                }}%)
              </div>
            </div>
            <div class="camera-stat row">
              <div class="stat-icon col-1" style="background-color: #ebebeb">
                <img class="img-icon" src="assets/image/p-4.png" />
              </div>
              <div class="cameragrop-stat-number col-2">
                {{ selectedCameraGroupStat.cao_tuoi }}
              </div>
              <div class="stat-type col-7">Người cao tuổi</div>
              <div class="percentage col-2">
                ({{
                  selectedCameraGroupStat && +selectedCameraGroupStat.custno
                    ? ((+selectedCameraGroupStat.cao_tuoi /
                        +selectedCameraGroupStat.custno) *
                        100 | number : "1.0-1")
                    : noStatistic
                }}%)
              </div>
            </div>
          </div>
          <div class="cameragroup-divider">
            <div class="d-flex">
              <div class="divider-title flex-grow-1">
                {{ eventList.length }} KHUÔN MẶT
              </div>
              <div class="divider-subtitle">1h qua</div>
            </div>
            <div class="event-container">
              <div
                class="box d-flex flex-column image-container"
                style="padding: 0 !important"
                *ngFor="let event of eventList"
              >
                <img
                  #img
                  class="image-event"
                  src="{{ deviceHost + event.url }}"
                  alt=""
                  (error)="onImgError($event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="height: 100%; width: 100%"
    leaflet
    [leafletOptions]="options"
    [leafletMarkerCluster]="markerClusterData"
    [leafletMarkerClusterOptions]="markerClusterOptions"
    (leafletMarkerClusterReady)="markerClusterReady($event)"
    [leafletMaxZoom]="leafletMaxZoom"
    [(leafletCenter)]="mapCenter"
    [(leafletZoom)]="zoom"
    id="map"
  ></div>
  <!--&lt;!&ndash;  <div #sample *ngFor="let cameraGroup of cameraGroupList; let i = index">&ndash;&gt;-->
  <!--    <div>-->
  <!--      <div class="box camera-info">-->
  <!--        <div class="d-flex flex-row full-height">-->
  <!--          <div class="w-25">-->
  <!--            <img class="full-height full-width custom-image pl-4 pt-4 pb-4"-->
  <!--                 src="assets/image/camera.PNG" alt="">-->
  <!--          </div>-->
  <!--          <div class="flex-grow-1">-->
  <!--            <div class="text-group">-->
  <!--              <div class="title">-->
  <!--                <select #select class="form-select full-width full-height" aria-label="Default select example"-->
  <!--                        [ngModel]="selectedCamera" (ngModelChange)="startWatchingCamera($event)">-->
  <!--                  <option *ngFor="let camera of cameraGroup.t_cameras"-->
  <!--                          [ngValue]="camera">{{camera.name}}</option>-->
  <!--                </select>-->
  <!--              </div>-->
  <!--              <div class="sub-title">{{cameraGroup.name}}</div>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--          <div class="circle-status">-->
  <!--                      <span-->
  <!--                        [ngClass]="{'dot-offline': !selectedCamera || selectedCamera.deactiveyn == 'F', 'dot-online': selectedCamera && (!selectedCamera.deactiveyn || selectedCamera.deactiveyn == 'T')}"></span>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="p-3" style="display: grid; grid-template-columns: repeat(3,1fr)">-->
  <!--        <div style="text-align: center">-->
  <!--          <div-->
  <!--            class="title-group">{{selectedCameraGroupStat && selectedCameraGroupStat.custno ? selectedCameraGroupStat.custno : noStatistic}}</div>-->
  <!--          <div class="sub-title">Khách</div>-->
  <!--        </div>-->
  <!--        <div style="text-align: center">-->
  <!--          <div-->
  <!--            class="title-group">{{selectedCameraGroupStat && selectedCameraGroupStat.interestedcustno ? selectedCameraGroupStat.interestedcustno : noStatistic}}</div>-->
  <!--          <div class="sub-title">Quan tâm</div>-->
  <!--        </div>-->
  <!--        <div style="text-align: center">-->
  <!--          <div-->
  <!--            class="title-group">{{(selectedCameraGroupStat && +selectedCameraGroupStat.custno) ? (+selectedCameraGroupStat.sumwatchtime / +selectedCameraGroupStat.custno | formatTime) : noStatistic}}</div>-->
  <!--          <div class="sub-title">Thời lượng</div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div>-->
  <!--      </div>-->
  <!--      <div class="view">-->
  <!--        <div class="title pl-3">-->
  <!--          {{deviceStatus ? (deviceStatus.timestamp | date:'dd/MM/yyyy HH:mm:ss') : noText}}-->
  <!--        </div>-->
  <!--        <app-view [imageUrl]="currentDeviceImg"></app-view>-->
  <!--      </div>-->
  <!--      <div class="mb-3" style="display: grid; grid-template-columns: repeat(3,1fr)">-->
  <!--        <div style="text-align: center">-->
  <!--          <a [routerLink]="['/analysis']" [queryParams]="{branch: cameraGroup.branchid}" [fragment]="'Khu vực'"-->
  <!--             routerLinkActive="router-link-active">-->
  <!--            <div class="title-group"><img src="assets/image/mask-group-7.png" alt=""></div>-->
  <!--            <div class="title">Chi tiết</div>-->
  <!--          </a>-->
  <!--        </div>-->
  <!--        <div style="text-align: center">-->
  <!--          <a [routerLink]="['/analysis']" [queryParams]="{branch: cameraGroup.branchid}"-->
  <!--             [fragment]="'Khách hàng'" routerLinkActive="router-link-active">-->
  <!--            <div class="title-group"><img src="assets/image/mask-group-5.png" alt=""></div>-->
  <!--            <div class="title">Khách hàng</div>-->
  <!--          </a>-->
  <!--        </div>-->
  <!--        <div style="text-align: center">-->
  <!--          <a [routerLink]="['/analysis']" [queryParams]="{branch: cameraGroup.branchid, stall: cameraGroup.id}"-->
  <!--             [fragment]="'Đánh giá kết quả'" routerLinkActive="router-link-active">-->
  <!--            <div class="title-group"><img src="assets/image/mask-group-6.png" alt=""></div>-->
  <!--            <div class="title">Chỉ tiêu</div>-->
  <!--          </a>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <!--    <agm-map [styles]="customStyle" [streetViewControl]="false" (zoomChange)="zoomMap($event)" [zoom]="zoom"-->
  <!--             class="full-width full-height"-->
  <!--             [latitude]="lat" [longitude]="lon">-->
  <!--      <agm-marker-cluster [zoomOnClick]="false" [styles]="clusterStyles" (clusterClick)="onClickCluster($event)">-->
  <!--        <div *ngFor="let cameraGroup of cameraGroupList; let i = index">-->
  <!--          <agm-marker *ngIf="cameraGroup.t_cameras.length > 0" [markerClickable]="true"-->
  <!--                      [latitude]="cameraGroup.t_cameras[0].lat"-->
  <!--                      [longitude]="cameraGroup.t_cameras[0].lon" (markerClick)="onClickMarker(cameraGroup)"-->
  <!--                      [iconUrl]="'assets/image/marker1.png'">-->

  <!--            <agm-info-window (infoWindowClose)="onInfoWindowClose(cameraGroup)" [isOpen]="cameraGroup.isOpen"-->
  <!--                             *ngIf="minimize === false">-->
  <!--              <div>-->
  <!--                <div class="box camera-info">-->
  <!--                  <div class="d-flex flex-row full-height">-->
  <!--                    <div class="w-25">-->
  <!--                      <img class="full-height full-width custom-image pl-4 pt-4 pb-4"-->
  <!--                           src="assets/image/camera.PNG" alt="">-->
  <!--                    </div>-->
  <!--                    <div class="flex-grow-1">-->
  <!--                      <div class="text-group">-->
  <!--                        <div class="title">-->
  <!--                          <select #select class="form-select full-width full-height" aria-label="Default select example"-->
  <!--                                  [ngModel]="selectedCamera" (ngModelChange)="startWatchingCamera($event)">-->
  <!--                            <option *ngFor="let camera of cameraGroup.t_cameras"-->
  <!--                                    [ngValue]="camera">{{camera.name}}</option>-->
  <!--                          </select>-->
  <!--                        </div>-->
  <!--                        <div class="sub-title">{{cameraGroup.name}}</div>-->
  <!--                      </div>-->
  <!--                    </div>-->
  <!--                    <div class="circle-status">-->
  <!--                      <span-->
  <!--                        [ngClass]="{'dot-offline': !selectedCamera || selectedCamera.deactiveyn == 'F', 'dot-online': selectedCamera && (!selectedCamera.deactiveyn || selectedCamera.deactiveyn == 'T')}"></span>-->
  <!--                    </div>-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--                <div class="p-3" style="display: grid; grid-template-columns: repeat(3,1fr)">-->
  <!--                  <div style="text-align: center">-->
  <!--                    <div-->
  <!--                      class="title-group">{{selectedCameraGroupStat && selectedCameraGroupStat.custno ? selectedCameraGroupStat.custno : noStatistic}}</div>-->
  <!--                    <div class="sub-title">Khách</div>-->
  <!--                  </div>-->
  <!--                  <div style="text-align: center">-->
  <!--                    <div-->
  <!--                      class="title-group">{{selectedCameraGroupStat && selectedCameraGroupStat.interestedcustno ? selectedCameraGroupStat.interestedcustno : noStatistic}}</div>-->
  <!--                    <div class="sub-title">Quan tâm</div>-->
  <!--                  </div>-->
  <!--                  <div style="text-align: center">-->
  <!--                    <div-->
  <!--                      class="title-group">{{(selectedCameraGroupStat && +selectedCameraGroupStat.custno) ? (+selectedCameraGroupStat.sumwatchtime / +selectedCameraGroupStat.custno | formatTime) : noStatistic}}</div>-->
  <!--                    <div class="sub-title">Thời lượng</div>-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--                <div>-->
  <!--                </div>-->
  <!--                <div class="view">-->
  <!--                  <div class="title pl-3">-->
  <!--                    {{deviceStatus ? (deviceStatus.timestamp | date:'dd/MM/yyyy HH:mm:ss') : noText}}-->
  <!--                  </div>-->
  <!--                  <app-view [imageUrl]="currentDeviceImg"></app-view>-->
  <!--                </div>-->
  <!--                <div class="mb-3" style="display: grid; grid-template-columns: repeat(3,1fr)">-->
  <!--                  <div style="text-align: center">-->
  <!--                    <a [routerLink]="['/analysis']" [queryParams]="{branch: cameraGroup.branchid}" [fragment]="'Khu vực'"-->
  <!--                       routerLinkActive="router-link-active">-->
  <!--                      <div class="title-group"><img src="assets/image/mask-group-7.png" alt=""></div>-->
  <!--                      <div class="title">Chi tiết</div>-->
  <!--                    </a>-->
  <!--                  </div>-->
  <!--                  <div style="text-align: center">-->
  <!--                    <a [routerLink]="['/analysis']" [queryParams]="{branch: cameraGroup.branchid}"-->
  <!--                       [fragment]="'Khách hàng'" routerLinkActive="router-link-active">-->
  <!--                      <div class="title-group"><img src="assets/image/mask-group-5.png" alt=""></div>-->
  <!--                      <div class="title">Khách hàng</div>-->
  <!--                    </a>-->
  <!--                  </div>-->
  <!--                  <div style="text-align: center">-->
  <!--                    <a [routerLink]="['/analysis']" [queryParams]="{branch: cameraGroup.branchid, stall: cameraGroup.id}"-->
  <!--                       [fragment]="'Đánh giá kết quả'" routerLinkActive="router-link-active">-->
  <!--                      <div class="title-group"><img src="assets/image/mask-group-6.png" alt=""></div>-->
  <!--                      <div class="title">Chỉ tiêu</div>-->
  <!--                    </a>-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </agm-info-window>-->
  <!--            <agm-info-window (infoWindowClose)="onInfoWindowClose(cameraGroup)" [isOpen]="cameraGroup.isOpen"-->
  <!--                             *ngIf="minimize === true">-->
  <!--              <div class="p-3">-->
  <!--                <div style="display: grid; grid-template-columns: repeat(3,1fr)">-->
  <!--                  <div style="text-align: center">-->
  <!--                    <div class="sub-title">Khách</div>-->
  <!--                    <div-->
  <!--                      class="title-group">{{selectedCameraGroupStat && selectedCameraGroupStat.custno ? selectedCameraGroupStat.custno : noStatistic}}</div>-->
  <!--                  </div>-->
  <!--                  <div class="pl-3 pr-3"-->
  <!--                       style="text-align: center; border-right: solid 1px gray;border-left: solid 1px gray">-->
  <!--                    <div class="sub-title">Quan tâm</div>-->
  <!--                    <div-->
  <!--                      class="title-group">{{selectedCameraGroupStat && selectedCameraGroupStat.interestedcustno ? selectedCameraGroupStat.interestedcustno : noStatistic}}</div>-->
  <!--                  </div>-->
  <!--                  <div style="text-align: center">-->
  <!--                    <div class="sub-title">Thời lượng</div>-->
  <!--                    <div-->
  <!--                      class="title-group">{{(selectedCameraGroupStat && +selectedCameraGroupStat.custno) ? (+selectedCameraGroupStat.sumwatchtime / +selectedCameraGroupStat.custno | formatTime) : noStatistic}}</div>-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </agm-info-window>-->
  <!--          </agm-marker>-->
  <!--        </div>-->
  <!--      </agm-marker-cluster>-->
  <!--    </agm-map>-->
</div>
