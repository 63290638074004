import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.scss'],
})
export class MonitorComponent implements OnInit {
  public selectedMonitor: null;
  constructor() {}

  ngOnInit(): void {}

  public handleChangeMonitor(newMonitor: any) {
    this.selectedMonitor = newMonitor;
  }
}
