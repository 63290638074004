<div class="slim-scroll hidden-scrollbar" *ngIf="active === 'Dashboard'">
  <div class="row">
    <app-search
      class="full-width"
      (clickedWard)="handleClickedWard($event)"
      (clickedBranch)="handleClickedBranch($event)"
      (clickedCameragroup)="handleClickedCameragroup($event)"
    ></app-search>
  </div>
  <div class="row">
    <app-customer
      class="full-width"
      [chosenBranch]="selectedBranch"
      [chosenCameragroup]="selectedCameraGroup"
    ></app-customer>
  </div>
  <div class="row">
    <app-area-analysis
      class="full-width mt-4"
      [chosenWard]="selectedWard"
      [chosenBranch]="selectedBranch"
      [chosenCameragroup]="selectedCameraGroup"
    ></app-area-analysis>
  </div>
  <div class="row">
    <app-table-events
      class="full-width mt-4 mb-5"
      [chosenWard]="selectedWard"
      [chosenBranch]="selectedBranch"
      [chosenStall]="selectedCameraGroup"
    ></app-table-events>
  </div>
</div>
