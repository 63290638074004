import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
} from '@angular/core';
import { Cameragroup } from 'src/app/models/cameragroup';
import { ApiService } from 'src/app/services/api.service';
import { API, FUNC, REPLACEMENT } from 'src/environments/environment';
import * as moment from 'moment';
import { SocketService } from 'src/app/services/socket.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedCameraGroup: Cameragroup;
  public eventStat;
  public barChartData = [];
  public lineChartData = [];
  public noStat = REPLACEMENT.noStatistic;
  public colorScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#C1F6FF', '#11deff'],
  };
  public lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#C1F6FF', '#11deff', '#FF00D5'],
  };
  public subcription: Subscription;
  public originEventStat;
  public prevSocketEventData;
  public isReadyToUpdateChart = true;
  public setTimeout;
  public waitTime = 3000;
  public tempSocketData;
  constructor(
    private apiService: ApiService,
    private socketService: SocketService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.subcription) {
      this.subcription.unsubscribe();
    }
    if (this.setTimeout) {
      clearTimeout(this.setTimeout);
    }
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (
      changes['selectedCameraGroup'] &&
      changes['selectedCameraGroup'].currentValue
    ) {
      this.getEventData();
      if (this.subcription) {
        this.subcription.unsubscribe();
      }
      let today = moment().format('DD-MM-yyyy');
      let thisTime = moment().hour();
      this.subcription = this.socketService
        .onNewEventStat()
        .subscribe((data) => {
          if (
            data &&
            data.groupid == this.selectedCameraGroup.code &&
            data.daytime == today &&
            data.hourtime == thisTime
          ) {
            if (
              !this.tempSocketData ||
              (this.tempSocketData && this.tempSocketData.custno <= data.custno)
            ) {
              this.tempSocketData = data;
              if (this.isReadyToUpdateChart) {
                this.setChartTimeOut();

                this.isReadyToUpdateChart = false;
              } else {
                this.setChartTimeOut();
              }
              this.handleEventStatRealtime(data);
            }
          }
        });
    }
  }

  public getEventData() {
    let params = {
      ward: 0,
      branch: this.selectedCameraGroup ? this.selectedCameraGroup.branchid : 0,
      stall: this.selectedCameraGroup ? this.selectedCameraGroup.id : 0,
      timerange: 1,
    };
    this.apiService
      .get<any>(API.view + FUNC.getEventDetails, params)
      .subscribe((data) => {
        if (data) {
          this.getChartData();
          let result = {
            custno: 0,
            nam: 0,
            nu: 0,
            tre_em: 0,
            thanh_nien: 0,
            trung_nien: 0,
            cao_tuoi: 0,
          };
          data.map((row) => {
            result.custno += row.custno ? +row.custno : 0;
            result.nam += row.nam ? +row.nam : 0;
            result.nu += row.nu ? +row.nu : 0;
            result.tre_em += row.tre_em ? +row.tre_em : 0;
            result.thanh_nien += row.thanh_nien ? +row.thanh_nien : 0;
            result.trung_nien += row.trung_nien ? +row.trung_nien : 0;
            result.cao_tuoi += row.cao_tuoi ? +row.cao_tuoi : 0;
          });
          this.eventStat = result;
          this.originEventStat = Object.assign({}, result);
        }
      });
  }

  public getChartData() {
    let params = {
      ward: 0,
      branch: this.selectedCameraGroup ? this.selectedCameraGroup.branchid : 0,
      stall: this.selectedCameraGroup ? this.selectedCameraGroup.id : 0,
    };
    this.apiService
      .get<any>(API.view + FUNC.sumEventByHour, params)
      .subscribe((data) => {
        if (data) {
          this.barChartData = this.formatBarChartData(data);
          this.lineChartData = this.formatLineChartData(data);
          this.getSocketData();
        }
      });
  }

  public formatBarChartData(data) {
    let result = [];
    let startVal = 0;
    let endVal = moment().hours();
    for (let i = 0; i <= endVal; i++) {
      let colName = startVal + i;
      let value = data.find(
        (row) => row['hour'] && row['hour'].substring(0, 2) == colName
      );
      let column = {
        name: colName + 'h',
        series: [
          {
            name: 'Số đối tượng',
            value: value && value.custno ? +value.custno : 0,
          },
          {
            name: 'Số đối tượng danh sách đen',
            value:
              value && value.interestedcustno ? +value.interestedcustno : 0,
          },
        ],
      };
      result.push(column);
    }
    return result;
  }

  public formatLineChartData(data) {
    let result = [{ name: 'Thời gian xuất hiện', series: [] }];
    let startVal = 0;
    let endVal = moment().hours();
    for (let i = 0; i <= endVal; i++) {
      let colName = startVal + i;
      let value = data.find(
        (row) => row['hour'] && row['hour'].substring(0, 2) == colName
      );
      let column = {
        name: colName + 'h',
        value:
          value && +value.custno
            ? Math.round(
                (+value.sumwatchtime / +value.custno + Number.EPSILON) * 10
              ) / 10
            : 0,
      };
      result[0].series.push(column);
    }
    return result;
  }

  public handleEventStatRealtime(newEventStatData) {
    if (!this.prevSocketEventData) this.prevSocketEventData = newEventStatData;
    if (this.prevSocketEventData.hourtime != newEventStatData.hourtime) {
      this.prevSocketEventData = newEventStatData;
      this.originEventStat = Object.assign({}, this.eventStat);
    }
    Object.keys(this.eventStat).map((attr) => {
      this.eventStat[attr] =
        this.originEventStat[attr] + newEventStatData[attr];
    });
  }

  public handleBarChartDataRealtime(newBarChartData) {
    let barDataIndx = this.barChartData.findIndex(
      (bar) => bar.name == newBarChartData.hourtime + 'h'
    );
    if (barDataIndx > -1) {
      this.barChartData[barDataIndx].series.map((colData) => {
        if (colData.name == 'Số đối tượng') {
          colData.value = newBarChartData.custno;
        }
        if (colData.name == 'Số đối tượng danh sách đen') {
          colData.value = newBarChartData.quan_tam;
        }
      });
    } else {
      let newBarData = {
        name: newBarChartData.hourtime + 'h',
        series: [
          {
            name: 'Số đối tượng',
            value:
              newBarChartData && newBarChartData.custno
                ? +newBarChartData.custno
                : 0,
          },
          {
            name: 'Số đối tượng danh sách đen',
            value:
              newBarChartData && newBarChartData.quan_tam
                ? +newBarChartData.quan_tam
                : 0,
          },
        ],
      };
      this.barChartData.push(newBarData);
    }
    this.barChartData = [...this.barChartData];
  }

  public handleLineChartDataRealtime(newLineChartData) {
    let lineDataIndx = this.lineChartData[0].series.findIndex(
      (column) => column.name == newLineChartData.hourtime + 'h'
    );
    if (lineDataIndx > -1) {
      this.lineChartData[0].series[lineDataIndx].value = newLineChartData.custno
        ? Math.round(
            (+newLineChartData.sumwatchtime / +newLineChartData.custno +
              Number.EPSILON) *
              10
          ) / 10
        : 0;
    } else {
      this.lineChartData[0].series.push({
        name: newLineChartData.hourtime + 'h',
        value: newLineChartData.custno
          ? Math.round(
              (+newLineChartData.sumwatchtime / +newLineChartData.custno +
                Number.EPSILON) *
                10
            ) / 10
          : 0,
      });
    }
    this.lineChartData = [...this.lineChartData];
  }

  public setChartTimeOut() {
    if (this.setTimeout) {
      clearTimeout(this.setTimeout);
    }
    this.setTimeout = setTimeout(() => {
      this.isReadyToUpdateChart = true;
      this.handleBarChartDataRealtime(this.tempSocketData);
      this.handleLineChartDataRealtime(this.tempSocketData);
    }, this.waitTime);
  }

  public getSocketData() {
    this.socketService.getEventStat(this.selectedCameraGroup.code);
  }
}
