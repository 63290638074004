<div class="box full-width full-height mt-4">
  <div class="title-group p-3 font-weight-bold">Giới tính</div>
  <div
    style="display: grid; grid-template-columns: repeat(2, 1fr)"
    class="pt-3"
  >
    <div
      class="row align-items-center"
      style="text-align: center; border-right: solid 1px #dedede"
    >
      <div class="col-5">
        <div class="mb-3"><img src="assets/image/male.png" alt="" /></div>
        <div class="title-group">NAM</div>
      </div>
      <div class="col-7">
        <div>
          <div class="sub-title">Đối tượng</div>
          <a
            class="handle title-header font-weight-bold"
            [routerLink]="['/image']"
            [queryParams]="{ gender: 'gender_male', hour: currentAnalyzeHour }"
            routerLinkActive="router-link-active"
            >{{ genderData.nam }}</a
          >
          <div class="sub-title">
            {{
              genderData.total
                ? ((genderData.nam / genderData.total) * 100 | number: "1.0-1")
                : noStatistic
            }}%
          </div>
        </div>
        <div>
          <div class="sub-title">Thời gian xuất hiện</div>
          <div class="title-header font-weight-bold">
            {{
              genderData.nam
                ? (genderData.nam_watchtime / genderData.nam | number: "1.0-0")
                : noStatistic
            }}s
          </div>
          <div class="sub-title">
            {{
              genderData.total_watchtime
                ? ((genderData.nam_watchtime / genderData.total_watchtime) * 100
                  | number: "1.0-1")
                : noStatistic
            }}%
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center" style="text-align: center">
      <div class="col-5">
        <div class="mb-3"><img src="assets/image/femenine.png" alt="" /></div>
        <div class="title-group">NỮ</div>
      </div>
      <div class="col-7">
        <div>
          <div class="sub-title">Đối tượng</div>
          <a
            class="handle title-header font-weight-bold"
            [routerLink]="['/image']"
            [queryParams]="{
              gender: 'gender_female',
              hour: currentAnalyzeHour
            }"
            routerLinkActive="router-link-active"
            >{{ genderData.nu }}</a
          >
          <div class="sub-title">
            {{
              genderData.total
                ? ((genderData.nu / genderData.total) * 100 | number: "1.0-1")
                : noStatistic
            }}%
          </div>
        </div>
        <div>
          <div class="sub-title">Thời gian xuất hiện</div>
          <div class="title-header font-weight-bold">
            {{
              genderData.nu
                ? (genderData.nu_watchtime / genderData.nu | number: "1.0-0")
                : noStatistic
            }}s
          </div>
          <div class="sub-title">
            {{
              genderData.total_watchtime
                ? ((genderData.nu_watchtime / genderData.total_watchtime) * 100
                  | number: "1.0-1")
                : noStatistic
            }}%
          </div>
        </div>
      </div>
    </div>
    <!-- <div style="text-align: center">
        <div class="mb-3"><img  src="assets/image/femenine.png" alt=""></div>
        <div class="title-group">NỮ</div>
        <div class="title-header font-weight-bold">{{this.genderData.nu}}</div>
        <div class="sub-title">{{ this.genderData.total ? (this.genderData.nu / this.genderData.total*100 | number:'1.0-1') : noStatistic}}%</div>
      </div> -->
  </div>
</div>
