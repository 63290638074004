import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {

  transform(value: string): string {
    let time = moment({}).startOf('day').seconds(+value);
    let hours = time.hours();
    let minutes = time.minutes();
    let secs = time.seconds();
    if (hours > 0) return hours + 'h' + minutes + 'm';
    else return minutes + 'm' + secs + 's';
  }
  

}
