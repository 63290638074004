import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-branch-detail',
  templateUrl: './branch-detail.component.html',
  styleUrls: ['./branch-detail.component.scss']
})
export class BranchDetailComponent implements OnInit, OnChanges {

  @Output() changedBranch = new EventEmitter();
  @Input() selectedBranchId;
  @Input() parentId;
  public chosenTab = 'info';
  public frmObject: FormGroup;
  public AIConfig = null;
  public AIConfigVal = null;
  public userInfo;
  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private authService: AuthService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.generateFormGroup();
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}) {
    if(changes['selectedBranchId']) {
      if (this.selectedBranchId) {
        this.getBranchInfo();
        if (this.frmObject) this.frmObject.controls['code'].disable();
        this.getAiConfig();
      }
      else {
        if (this.frmObject) {
          this.resetForm();
          this.frmObject.controls['code'].enable();
        }
      }
    }
  }

  public generateFormGroup() {
    this.frmObject = null;
    this.frmObject = new FormGroup({
      code: new FormControl({value: null, disabled: this.selectedBranchId ? true : false}),
      name: new FormControl(null),
      address: new FormControl(null),
      lat: new FormControl(null),
      lon: new FormControl(null),
      info: new FormControl(),
      parentid: new FormControl(null),
    });;
  }

  public getBranchInfo() {
    this.apiService.get<any>(API.branches + '/' + this.selectedBranchId).subscribe(branch => {
      if (branch) {
        this.setItemInfo(branch);
      }
    })
  }

  public setItemInfo(itemInfo: any) {
    this.frmObject.patchValue(itemInfo);
  }

  public addBranch() {
    this.frmObject.patchValue({parentid: this.parentId});
    this.apiService.post<any>(API.branches, this.frmObject.value).subscribe(newBranch => {
      if (newBranch) {
        this.router.navigate(['/manage/branch'], {queryParams: {branch: newBranch.id}})
        this.alertService.informSuccess('Thêm thành công');
        this.changedBranch.emit();
      }
    })

  }

  public updateBranch() {
    this.apiService.put<any>(API.branches + "/" + this.selectedBranchId, this.frmObject.value).subscribe(result => {
      if (result) {
        this.alertService.informSuccess('Cập nhật thành công');
        this.changedBranch.emit();
      }
    })
  }
  
  public deleteBranch() {
    this.alertService.confirm('Bạn có chắc muốn xóa chi nhánh ' + this.frmObject.value.name + ' ?').then(result => {
      if (result.isConfirmed) {
        this.apiService.delete<any>(API.branches + "/" + this.selectedBranchId).subscribe(result => {
          if (result) {
            this.selectedBranchId = null;
            this.frmObject.reset();
            this.router.navigate(['/manage/branch'])
            this.alertService.informSuccess('Xóa thành công');
            this.changedBranch.emit();
          }
        })
      }
    })
  }

  public onSubmit() {
    if (this.frmObject.valid) {
      if (this.selectedBranchId) {
        this.updateBranch();
      }
      else {
        if (this.checkCode(this.frmObject.value.code)) this.addBranch();
      }
    }
    else this.alertService.informError('Xin hãy điền đầy đủ thông tin')
  }

  public checkCode(newCode: string) {
    if (newCode && newCode.includes('__')) {
      this.alertService.informError('Mã không thể chứa kí tự "__"')
      return false;
    }
    else return true;
  }

  public resetForm() {
    this.frmObject.reset();
  }

  public handleSelectTab(selectedTab) {
    this.chosenTab = selectedTab;
  }

  public saveAIConfig() {
    if (this.AIConfig) {
      try {
        let newAIConfig = JSON.parse(this.AIConfigVal);
        this.updateAIConfig();
      }
      catch(err) {
        this.alertService.informError("Định dạng cấu hình không đúng!")
      }
    }
    else {
      this.addAIConfig();
    }
  }

  public addAIConfig() {
    let newAIConfig = {
      code: 'config',
      branchid: this.parentId,
      val: JSON.stringify(this.AIConfigVal),
      desc: 'branch config',
    }
    this.apiService.post<any>(API.configs, newAIConfig).subscribe(res => {
      if (res) {
        this.alertService.informSuccess("Cập nhật thành công")
      }
    })
  }

  public updateAIConfig() {
    let newAIConfig = this.AIConfig;
    newAIConfig.val = this.AIConfigVal;
    this.apiService.put<any>(API.configs + '/' + newAIConfig.id, newAIConfig).subscribe(res => {
      if (res) {
        this.alertService.informSuccess("Cập nhật thành công")
      }
    })
  }

  public getAiConfig() {
    this.resetAiConfig();
    let url = API.AIConfigs + '?branchid=' + this.selectedBranchId;
    this.apiService.get<any>(url).subscribe(AIConfig => {
      if (AIConfig) {
          this.AIConfig = AIConfig;
          this.AIConfigVal = JSON.stringify(AIConfig.val);
      }
    })
  }

  public resetAiConfig() {
    this.AIConfig = null;
    this.AIConfigVal = null;
  }
}
