<div class="slim-scroll hidden-scrollbar" *ngIf="active === 'Đánh giá kết quả'">
  <div class="row">
    <app-evaluate-search
      class="full-width"
      (clickedBranch)="handleClickedBranch($event)"
      (clickedTimeOption)="handleClickedTimeOption($event)"
      (clickedStall)="handleClickedStall($event)"
      [hiddenSearchBar]="false"
      [showFromDate]="true"
      [showToDate]="true"
      (changedFromDate)="handleFromDate($event)"
      (changedToDate)="handleToDate($event)"
      [allowAll]="true"
      [showTimeOption]="false"
    ></app-evaluate-search>
  </div>
  <div class="row full-width mt-4">
    <app-evaluate-report
      class="full-width"
      [selectedBranch]="chosenBranch"
    ></app-evaluate-report>
  </div>
  <div class="row mt-4">
    <app-evaluate-kpi
      class="full-width"
      [selectedBranch]="chosenBranch"
    ></app-evaluate-kpi>
  </div>
  <div class="row mt-4">
    <app-evaluate-customer-chart
      class="full-width"
      [selectedBranch]="chosenBranch"
      [selectedStall]="chosenStall"
      [selectedTimeOption]="chosenTimeOption"
      [fromTime]="fromTime"
      [toTime]="toTime"
    ></app-evaluate-customer-chart>
  </div>
  <div class="row mt-4">
    <app-evaluate-watchtime-chart
      class="full-width"
      [selectedBranch]="chosenBranch"
      [selectedStall]="chosenStall"
      [selectedTimeOption]="chosenTimeOption"
      [fromTime]="fromTime"
      [toTime]="toTime"
    ></app-evaluate-watchtime-chart>
  </div>

  <div class="row full-width">
    <app-table-events
      class="full-width mb-5"
      [chosenBranch]="chosenBranch"
      [chosenStall]="chosenStall"
      [chosenTimeOption]="chosenTimeOption"
      [fromTime]="fromTime"
      [toTime]="toTime"
    ></app-table-events>
  </div>
</div>
