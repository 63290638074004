<div
  class="box full-width"
  style="
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
    grid-gap: 2px;
  "
>
  <!-- <div class="position-relative" style="display: grid; grid-template-columns: 1fr 8fr 1fr;" [ngClass]="
          isShowCameraGroupOption? 'background-shadow' : ''
        ">
    <div class="size-icon" style="margin: auto"><i class="fa fa-search"></i></div>
    <div class="">
      <div class="parent full-height full-width">
        <input class="line-height-50 full-width" style="outline: none; border: none" type='text' placeholder="Tìm kiếm"
               [ngModel]="textSearch" (ngModelChange)="getCameragroups($event)"
               (focus)="handleSelectList('isShowCameraGroupOption')" (focusout)="onFocusOutEvent()"/>
      </div>
    </div>
    <ul class="top-level-menu" [hidden]="!isShowCameraGroupOption">
      <li class="handle title-menu" *ngFor="let cameraGroup of cameraGroupList">
        <a (mousedown)="handleSelectCameraGroup(cameraGroup); ">{{cameraGroup.name}}</a>
      </li>
    </ul>
    <div class="handle line-height-50" (click)="resetSearch()" [hidden]="!selectedCameraGroup"><i
      class="fa fa-sm fa-close"></i></div>
    <div class="borderLeft"></div>
  </div> -->
  <!-- <div class="d-flex flex-row"> -->
  <!-- Filter by branch -->
  <div class="ml-1 handle position-relative w-100">
    <div
      class="parent w-100"
      [ngClass]="isShowBranchOption ? 'background-shadow' : ''"
      (click)="handleSelectList('isShowBranchOption')"
    >
      <div class="line-height-50" style="display: flex">
        <div class="showing-select font-bold">
          {{ selectedBranch ? selectedBranch.name : noText }}
        </div>
        <div class="ml-3">
          <i
            class="fa"
            [ngClass]="{
              'fa-caret-down': !isShowBranchOption,
              'fa-caret-up': isShowBranchOption
            }"
          ></i>
        </div>
      </div>
    </div>
    <!--      <ul class="top-level-menu" [hidden]="!isShowBranchOption">-->
    <!--        <li class="title-menu" *ngFor="let branch of branchList" [ngClass]="{'select-active': selectedBranch && branch.name == selectedBranch.name}">-->
    <!--          <a (click)="handleSelectBranch(branch)">{{branch.name}}</a>-->
    <!--        </li>-->
    <!--      </ul>-->
    <app-dropdown-list
      [dataList]="branchList"
      [saveBranch]="true"
      [isShow]="isShowBranchOption"
      [selectedBranch]="selectedBranch"
      (clickedOutside)="closePopup()"
      (handleSelect)="handleSelectBranch($event)"
    ></app-dropdown-list>
  </div>
  <div class="ml-1 handle position-relative w-100">
    <div
      class="parent w-100"
      [ngClass]="isShowCameraGroupOption ? 'background-shadow' : ''"
      (click)="handleSelectList('isShowCameraGroupOption')"
    >
      <div class="line-height-50" style="display: flex">
        <div class="showing-select font-bold">
          {{ selectedCameraGroup ? selectedCameraGroup.name : noText }}
        </div>
        <div class="ml-3">
          <i
            class="fa"
            [ngClass]="{
              'fa-caret-down': !isShowCameraGroupOption,
              'fa-caret-up': isShowCameraGroupOption
            }"
          ></i>
        </div>
      </div>
    </div>
    <app-dropdown-list
      [dataList]="cameraGroupList"
      [saveBranch]="false"
      [isShow]="isShowCameraGroupOption"
      [selectedBranch]="selectedCameraGroup"
      (clickedOutside)="closePopup()"
      (handleSelect)="handleSelectCameraGroup($event)"
    ></app-dropdown-list>
  </div>
  <div class="position-relative w-100" *ngIf="showFromToDate">
    <div class="date-selector">
      <label class="p-2 m-0">{{ fromDateLabel }}: </label>
      <input
        class="form-control"
        angular-mydatepicker
        [(ngModel)]="fromDate"
        #dpFrom="angular-mydatepicker"
        (dateChanged)="handleFromDateChange($event)"
        [options]="datePickerOptions"
        (click)="dpFrom.toggleCalendar()"
        [locale]="locale"
      />
    </div>
  </div>

  <div class="position-relative w-100" *ngIf="showFromToDate">
    <div class="date-selector">
      <label class="p-2 m-0">{{ toDateLabel }}: </label>
      <input
        class="form-control"
        angular-mydatepicker
        [(ngModel)]="toDate"
        #dpTo="angular-mydatepicker"
        (dateChanged)="handleToDateChange($event)"
        [options]="datePickerOptions"
        (click)="dpTo.toggleCalendar()"
        [locale]="locale"
      />
    </div>
  </div>

  <!-- Filter by ward! do not delete -->
  <!-- <div class="handle position-relative" (click)="getProvinces(); handleSelectList('isShowProvince')">
      <div class="parent" style="color: white !important;" [ngStyle]="{
          'background-color' : isShowProvince ? '#95B4CA' : ''
        }">
        <span>{{selectedWard.name}}</span>
        <span class="ml-3"><i class="fa" [ngClass]="{'fa-caret-down': !isShowProvince, 'fa-caret-up': isShowProvince}"></i></span>
      </div>
      <ul class="top-level-menu" [hidden]="!isShowProvince">
        <li class="title-menu">Tỉnh/Thành phố</li>
        <li class="title-menu" *ngFor="let province of provinceList">
          <a class="" (mouseenter)="getDistricts(province.id)"
             (click)="handleSelectWard(province)">{{province.name}}</a>
          <ul class="second-level-menu" *ngIf="districtList && districtList.length > 0">
            <li class="title-menu">Quận/Huyện</li>
            <li class="title-menu" *ngFor="let district of districtList">
              <a (mouseenter)="getWards(district.id)" (click)="handleSelectWard(district)">{{district.name}}</a>
              <ul class="third-level-menu" *ngIf="wardList && wardList.length > 0">
                <li class="title-menu">Phường/Xã</li>
                <li class="title-menu" *ngFor="let ward of wardList">
                  <a (click)="handleSelectWard(ward)">{{ward.name}}</a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div> -->
  <!-- <div
    class="ml-1 handle position-relative w-100"
    (click)="handleSelectList('isShowTimeOption')"
  >
    <div
      class="parent w-100"
      [ngClass]="isShowTimeOption ? 'background-shadow' : ''"
    >
      <div class="line-height-50" style="display: flex">
        <div class="showing-select font-bold">
          {{ selectedTime ? selectedTime.name : noText }}
        </div>
        <div class="ml-3">
          <i
            class="fa"
            [ngClass]="{
              'fa-caret-down': !isShowTimeOption,
              'fa-caret-up': isShowTimeOption
            }"
          ></i>
        </div>
      </div>
    </div>
    <app-dropdown-list
      [dataList]="timeOptions"
      [saveBranch]="true"
      [isShow]="isShowTimeOption"
      [selectedBranch]="selectedTime"
      (clickedOutside)="closePopup()"
      (handleSelect)="handleSelectTime($event)"
    ></app-dropdown-list>
  </div> -->
  <!-- </div> -->
</div>
