<div class="d-flex flex-column">
  <div class="box">
    <div class="title-group p-3 font-weight-bold">
      Khách hàng
    </div>
    <div class="chart-container">
      <ngx-charts-line-chart
      [scheme]="custnoColorScheme"
      [results]="custnoData"
      [xAxis]="true"
      [yAxis]="true">
      <ng-template #tooltipTemplate let-model="model">
        <div *ngIf="model">
          <span style="font-weight: bold;">{{xAxisUnit}} {{model.name}}</span>
          <div>
            <a *ngIf="model.series == 'custno'">{{custnoLabel}}: {{model.value}}</a>
            <a *ngIf="model.series == 'interestedcustno'">{{interestedcustnoLabel}}: {{model.value}}</a>
          </div>
        </div>
      </ng-template>

      <ng-template #seriesTooltipTemplate let-model="model">
        <div *ngIf="model">
          <span style="font-weight: bold;">{{model[0].name}}</span>
          <div>
            <a>{{custnoLabel}}: {{model[0].value}}</a><br>
            <a>{{interestedcustnoLabel}}: {{model[1].value}}</a>
          </div>
        </div>
      </ng-template>
    </ngx-charts-line-chart>
    </div>
    <div class="d-flex flex-row p-3">
      <div class="d-flex flex-row">
        <div class="square-shape customer-style mr-2" style="height: 22px !important;width: 22px !important;"></div>
        Khách hàng
      </div>
      <div class="d-flex flex-row ml-5">
        <div class="square-shape pink-style mr-2" style="height: 22px !important;width: 22px !important;"></div>
        Quan tâm
      </div>
    </div>
  </div>


  <div class="box mt-4">
    <div class="title-group p-3 font-weight-bold">
      Thời gian xem hàng
    </div>
    <div>
      <div class="chart-container">
        <ngx-charts-line-chart
        [scheme]="watchtimeColorScheme"
        [results]="watchtimeData"
        [xAxis]="true"
        [yAxis]="true">
        <ng-template #tooltipTemplate let-model="model">
          <div *ngIf="model">
            <span style="font-weight: bold;">{{xAxisUnit}} {{model.name}}</span>
            <div>
              <a>{{watchtime}}: {{model.value}}</a>
            </div>
          </div>
        </ng-template>

        <ng-template #seriesTooltipTemplate let-model="model">
          <div *ngIf="model">
            <span style="font-weight: bold;">{{model[0].name}}</span>
            <div>
              <a>{{watchtime}}: {{model[0].value}}</a><br>
            </div>
          </div>
        </ng-template>
      </ngx-charts-line-chart>
    </div>
    <div class="d-flex flex-row p-3">
      <div class="d-flex flex-row">
        <div class="square-shape pink-style mr-2" style="height: 22px !important;width: 22px !important;"></div>
        Thời gian xem TB
      </div>
    </div>
  </div>

</div>
