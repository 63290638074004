<div class="full-height full-width d-flex">
  <div class="box p-3" style="width: 25%; min-width: 200px">
    <div
      class="mb-2"
      style="text-align: right"
      *ngIf="currentUser.branchid == null"
    >
      <button class="btn btn-sm btn-secondary" (click)="showAddCompanyForm()">
        Thêm công ty
      </button>
    </div>
    <app-tree-view
      [itemType]="'branch'"
      [defaultItem]="selectedItem"
      [autoSelectFirst]="true"
      [itemList]="branchList"
    ></app-tree-view>
  </div>
  <div class="box p-3 ml-2 flex-grow-1" style="overflow: auto">
    <app-branch-info
      [showBranchMasterForm]="addCompany"
      (changedItem)="handleBranchInfoChanged()"
    ></app-branch-info>
  </div>
</div>
