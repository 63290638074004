<div class="layout">
  <div class="layout-header">
    <app-nav-bar [changeICon]="isShowSideBar" (showSideBar)="handleShowSideBar()"></app-nav-bar>
  </div>
  <div class="layout-content">
    <div class="row full-height">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 column">
        <app-toggle-sidebar [showSideBar]="isShowSideBar"></app-toggle-sidebar>
        <app-map [height]="'70vh'"></app-map>
      </div>
    </div>
  </div>
</div>
