<div class="layout">
  <app-list-monitor
    (changedSelectCamera)="handleChangeMonitor($event)"
  ></app-list-monitor>
  <app-main-monitor [selectedMonitor]="selectedMonitor"></app-main-monitor>
  <div>
    <app-monitor-info [selectedMonitor]="selectedMonitor"></app-monitor-info>
    <app-monitor-events
      [selectedMonitor]="selectedMonitor"
    ></app-monitor-events>
  </div>
</div>
