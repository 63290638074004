import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Branch } from 'src/app/models/branch';
import { SettingOption } from 'src/app/models/setting-option';
import { ApiService } from 'src/app/services/api.service';
import { API, FUNC, REPLACEMENT } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-age',
  templateUrl: './age.component.html',
  styleUrls: ['./age.component.scss']
})
export class AgeComponent implements OnInit, OnChanges {

  @Input() eventData = [];
  public ageData = {treEm: 0, treEm_watchtime: 0, thanhNien: 0, thanhNien_watchtime: 0, trungNien: 0, trungNien_watchtime: 0, caoTuoi: 0, caoTuoi_watchtime: 0, total: 0, total_watchtime: 0,};
  public noStatistic = REPLACEMENT.noStatistic;
  public events = [];
  public currentAnalyzeHour;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (changes['eventData']) {
      this.formatAgeData();
    }
  }

  public formatAgeData() {
    this.ageData = {treEm: 0, treEm_watchtime: 0, thanhNien: 0, thanhNien_watchtime: 0, trungNien: 0, trungNien_watchtime: 0, caoTuoi: 0, caoTuoi_watchtime: 0, total: 0, total_watchtime: 0,};
    this.events.length = 0;
    if (this.eventData && this.eventData.length > 0) {
      this.eventData.map(data => {
        if (String(data.attrcode).includes('age')) {
          if (!this.events.includes(data.eventid)) {
            this.events.push(data.eventid);
            this.ageData.total += data.custno;
            this.ageData.total_watchtime += data.sumwatchtime;
            let analyzeHour = moment(data.createdtimestamp).hour();
            if (!this.currentAnalyzeHour || this.currentAnalyzeHour < analyzeHour) this.currentAnalyzeHour = analyzeHour;
          }
          if (String(data.attrcode).split('_')[1] === 'child') {
            this.ageData.treEm += data.val ? +data.val : 0;
            this.ageData.treEm_watchtime += data.sumval ? +data.sumval : 0;
          }
          if (String(data.attrcode).split('_')[1] === 'teenager') {
            this.ageData.thanhNien += data.val ? +data.val : 0;
            this.ageData.thanhNien_watchtime += data.sumval ? +data.sumval : 0;
          }
          if (String(data.attrcode).split('_')[1] === 'middleage') {
            this.ageData.trungNien += data.val ? +data.val : 0;
            this.ageData.trungNien_watchtime += data.sumval ? +data.sumval : 0;
          }
          if (String(data.attrcode).split('_')[1] === 'elder') {
            this.ageData.caoTuoi += data.val ? +data.val : 0;
            this.ageData.caoTuoi_watchtime += data.sumval ? +data.sumval : 0;
          }
        }
      })
    }
  }
}
