import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-manage-report',
  templateUrl: './manage-report.component.html',
  styleUrls: ['./manage-report.component.scss'],
})
export class ManageReportComponent implements OnInit {
  public reportList = [];
  public selectedReport;
  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.getReports();
  }

  public getReports() {
    this.apiService.get<any[]>(API.reports).subscribe((reports) => {
      if (reports) {
        this.reportList = reports;
        this.selectedReport = this.reportList[0];
      }
    });
  }
  public handleChangeReport(report) {
    this.selectedReport = report;
  }
}
