import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {

  @Input() page: number = 1;
  @Input() itemsPerPage: number = 10;
  @Input() maxPages: number = 5;
  @Input() total: number = 0;
  public pages = [];
  @Output() clickedPage: EventEmitter<number> = new EventEmitter<number>()
  public totalPages: number;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: { [propName: string] : SimpleChange}): void {
    if (changes['total'] && changes['total'].currentValue != null) {
      this.handlePaging();
      // this.changePage(1);
    }
  }

  changePage(page: number) {
    if (page > 0 && page <= this.totalPages && this.page != page) {
      this.page = page;
      this.handlePaging();
      this.clickedPage.emit(page)
    }
  }

  handlePaging() {
    this.pages.length = 0;
    this.totalPages = Math.ceil(this.total / this.itemsPerPage);
    if (this.page < 1) {
      this.page = 1;
    } else if (this.page > this.totalPages) {
      this.page = this.totalPages;
    }
    let startPage;
    let endPage;
    if (this.totalPages <= this.maxPages) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = this.totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      const maxPagesBeforeCurrentPage = Math.floor(this.maxPages / 2);
      const maxPagesAfterCurrentPage = Math.ceil(this.maxPages / 2) - 1;
      if (this.page <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1;
        endPage = this.maxPages;
      } else if (this.page + maxPagesAfterCurrentPage >= this.totalPages) {
        // current page near the end
        startPage = this.totalPages - this.maxPages + 1;
        endPage = this.totalPages;
      } else {
        // current page somewhere in the middle
        startPage = this.page - maxPagesBeforeCurrentPage;
        endPage = this.page + maxPagesAfterCurrentPage;
      }
    }

    // create an array of pages to ng-repeat in the pager control
    this.pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i,
    );
  }

}
