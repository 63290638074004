import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Branch } from 'src/app/models/branch';
import { Cameragroup } from 'src/app/models/cameragroup';
import { SettingOption } from 'src/app/models/setting-option';
import { ApiService } from 'src/app/services/api.service';
import { API, FUNC, REPLACEMENT } from 'src/environments/environment';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.scss'],
})
export class AreaComponent implements OnInit {
  @Input() active: string;
  public chosenBranch: Branch;
  public chosenTimeOption: SettingOption;
  public chosenStall: Cameragroup;
  public eventData;
  public summaryStatistics = {
    custno: 0,
    sumwatchtime: 0,
    interestedcustno: 0,
  };
  public chosenTab: string;
  public noStatistic = REPLACEMENT.noStatistic;
  public noText = REPLACEMENT.noText;
  public dateFormat = 'YYYY-MM-DD HH:mm:ss[.]SSS';
  public fromTime = moment().startOf('day').format(this.dateFormat);
  public toTime = moment().endOf('day').format(this.dateFormat);
  constructor(private apiService: ApiService) {}

  ngOnInit(): void {}

  public handleClickedBranch(branch: Branch) {
    this.chosenBranch = branch;
    this.getEventData();
  }

  public handleClickedTimeOption(timeOption: SettingOption) {
    this.chosenTimeOption = timeOption;
    this.getEventData();
  }

  public handleClickedStall(stall: Cameragroup) {
    this.chosenStall = stall;
    this.getEventData();
  }

  public getEventData() {
    if (this.chosenBranch && this.chosenTimeOption) {
      let params = {
        ward: 0,
        branch: this.chosenBranch.id,
        cameragroup: this.chosenStall ? this.chosenStall.id : 0,
        // timerange: +this.chosenTimeOption.value,
        from_time: this.fromTime,
        to_time: this.toTime,
      };
      if (this.chosenTimeOption[this.chosenTab]) {
        this.apiService
          .get<any>(
            API.view + this.chosenTimeOption[this.chosenTab].function,
            params
          )
          .subscribe((data) => {
            this.summaryStatistics = {
              custno: 0,
              sumwatchtime: 0,
              interestedcustno: 0,
            };
            if (data) {
              this.eventData = data;
              data.map((row) => {
                this.summaryStatistics.custno += +row.custno;
                this.summaryStatistics.sumwatchtime += +row.sumwatchtime;
                this.summaryStatistics.interestedcustno +=
                  +row.interestedcustno;
              });
            }
          });
      }
    }
  }

  public handleClickedTimeTab(tab: string) {
    this.chosenTab = tab;
    this.getEventData();
  }

  public handleFromDate(date) {
    this.fromTime = moment(date, 'DD-MM-YYYY')
      .startOf('day')
      .format(this.dateFormat);
    this.getEventData();
  }

  public handleToDate(date) {
    this.toTime = moment(date, 'DD-MM-YYYY')
      .endOf('day')
      .format(this.dateFormat);
    this.getEventData();
  }
}
