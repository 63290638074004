<div class="position-relative">
  <ul class="top-level-menu" style="position: unset; box-shadow: none">
    <li
      class="mb-2"
      style="border-left: 1px solid #c3c3c3"
      *ngFor="let item of itemList"
    >
      <div class="full-height tree-item">
        <span class="handle" style="margin: auto" (click)="expandItem(item)">
          <i
            class="fa"
            style="font-size: small"
            *ngIf="item.hasChildren"
            [ngClass]="{ 'fa-plus': !item.isExpand, 'fa-minus': item.isExpand }"
          ></i>
        </span>
        <a
          class="label handle p-2"
          (click)="selectItem(item)"
          [ngClass]="{
            'select-active':
              selectedItem &&
              (item.code
                ? item.code == selectedItem.code
                : item.id == selectedItem.id)
          }"
          >{{ item.name }}</a
        >
      </div>
      <div *ngIf="item.isExpand">
        <div class="pl-3" *ngIf="item.t_branches && item.t_branches.length > 0">
          <app-tree-view
            [itemType]="'branch'"
            [defaultItem]="selectedItem"
            [itemList]="item.t_branches"
            [autoSelectFirst]="false"
            (changedSelectedItem)="selectItem($event)"
          ></app-tree-view>
        </div>
        <div
          class="pl-3"
          *ngIf="item.t_cameragroups && item.t_cameragroups.length > 0"
        >
          <app-tree-view
            [itemType]="'cameragroup'"
            [defaultItem]="selectedItem"
            [itemList]="item.t_cameragroups"
            [autoSelectFirst]="false"
            (changedSelectedItem)="selectItem($event)"
          ></app-tree-view>
        </div>
      </div>
    </li>
  </ul>
</div>
