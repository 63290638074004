<div class="box full-width d-flex">
  <div
    class="position-relative"
    style="display: grid; grid-template-columns: 1fr 8fr 1fr; width: 30%"
    [ngClass]="isFocus ? 'background-shadow' : ''"
  >
    <div class="size-icon handle" style="margin: auto" (click)="search()">
      <i class="fa fa-search"></i>
    </div>
    <div class="">
      <div class="parent full-height full-width">
        <input
          class="line-height-50 full-width full-height p-3"
          style="outline: none; border: none"
          type="text"
          placeholder="Tìm kiếm"
          [(ngModel)]="textSearch"
          (focus)="onFocusInEvent()"
          (focusout)="onFocusOutEvent()"
          (keydown.enter)="search()"
        />
      </div>
    </div>
    <!-- <ul class="top-level-menu" [hidden]="!isShowCameraGroupOption">
      <li class="handle title-menu" *ngFor="let cameraGroup of cameraGroupList">
        <a (mousedown)="handleSelectCameraGroup(cameraGroup)">{{cameraGroup.name}}</a>
      </li>
    </ul> -->
    <div
      class="handle line-height-50"
      (click)="resetSearch()"
      [hidden]="textSearch == ''"
    >
      <i class="fa fa-sm fa-close"></i>
    </div>
    <div class="borderLeft"></div>
  </div>
  <div class="handle position-relative mr-3 ml-3 flex-grow-1">
    <div
      class="parent"
      [ngClass]="isShowBranchOption ? 'background-shadow' : ''"
      (click)="handleSelectList('isShowBranchOption')"
    >
      <div class="line-height-50" style="display: flex">
        <div class="showing-select">
          {{ chosenBranch ? chosenBranch.name : noText }}
        </div>
        <div class="ml-3">
          <i
            class="fa"
            [ngClass]="{
              'fa-caret-down': !isShowBranchOption,
              'fa-caret-up': isShowBranchOption
            }"
          ></i>
        </div>
      </div>
    </div>
    <app-dropdown-list
      [dataList]="branchList"
      [saveBranch]="true"
      [isShow]="isShowBranchOption"
      [selectedBranch]="chosenBranch"
      (handleSelect)="handleClickedBranch($event)"
      (clickedOutside)="closePopup()"
    ></app-dropdown-list>
  </div>
  <div
    class="box-header"
    style="padding: 5px; padding-right: 15px"
    *ngIf="personType == 'customer'"
  >
    <div class="d-flex full-height">
      <div
        class="d-flex justify-content-center align-items-center"
        style="padding: 10px"
        [ngClass]="{
          active: chosenTab.id == type.id,
          handle: chosenTab.id != type.id
        }"
        (click)="handleClickedTab(type)"
        *ngFor="let type of customerType"
      >
        <span>{{ type.name }}</span>
      </div>
    </div>
  </div>
  <!-- <div class="p-1" style="justify-self: end; align-self: center;">
      {{now | date: "h:mm:ss a, d 'tháng' MM, 'năm' yyyy"}}
      <button class="btn btn-primary" (click)="openEmptyForm()">Thêm</button>
  </div> -->
</div>
