import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainLayoutRoutingModule } from './main-layout-routing.module';
import { MainLayoutComponent } from './main-layout.component';
import { NotifierModule, NotifierOptions } from 'angular-notifier';

import { ManageLayoutComponent } from '../manage-layout/manage-layout.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { TestComponent } from '../../components/test/test.component';
import { NavBarComponent } from '../../components/header/nav-bar/nav-bar.component';

import { PopupComponent } from '../../components/map/popup/popup.component';
import { NavSidebarComponent } from '../../components/nav-sidebar/nav-sidebar.component';
import { ManagePersonComponent } from '../../components/manage-person/manage-person.component';
import { PersonDetailComponent } from '../../components/person-detail/person-detail.component';
import { PersonSearchComponent } from '../../components/person-search/person-search.component';
import { IdentificationListComponent } from '../../components/identification/identification-list/identification-list.component';
import { NotifyComponent } from '../../components/notify/notify.component';
import { DropdownListComponent } from '../../components/dropdown-list/dropdown-list.component';
import { ImgSearchDirective } from '../../img-search.directive';
import { ManageBranchComponent } from '../../components/manage-branch/manage-branch.component';
import { TreeViewComponent } from '../../components/tree-view/tree-view.component';
import { BranchInfoComponent } from '../../components/branch-info/branch-info.component';
import { BranchDetailComponent } from '../../components/branch-detail/branch-detail.component';
import { CameragroupDetailComponent } from '../../components/cameragroup-detail/cameragroup-detail.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ImageLayoutComponent } from '../image-layout/image-layout.component';
import { ListEventImagesComponent } from '../../components/list-event-images/list-event-images.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ManageReportComponent } from '../../components/manage-report/manage-report.component';
import { ReportInfoComponent } from '../../components/report-info/report-info.component';
import { ReportDetailComponent } from '../../components/report-detail/report-detail.component';
import { ReportTableComponent } from '../../components/report-table/report-table.component';
import { AiListEventImageComponent } from '../../components/ai-list-event-image/ai-list-event-image.component';
import { HeatmapLayoutComponent } from '../heatmap-layout/heatmap-layout.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HeatMapComponent } from '../../components/heat-map/heat-map.component';
import { MonitorLayoutComponent } from '../monitor-layout/monitor-layout.component';
import { MonitorComponent } from '../../components/monitor/monitor.component';
import { ListMonitorComponent } from '../../components/list-monitor/list-monitor.component';
import { MainMonitorComponent } from '../../components/main-monitor/main-monitor.component';
import { MonitorInfoComponent } from '../../components/monitor-info/monitor-info.component';
import { MonitorEventsComponent } from '../../components/monitor-events/monitor-events.component';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';

import { CctvLayoutComponent } from '../cctv-layout/cctv-layout.component';
import { CctvComponent } from '../../components/cctv/cctv.component';
import { ListCameraComponent } from '../../components/list-camera/list-camera.component';
import { CameraInfoComponent } from '../../components/camera-info/camera-info.component';
import { ListEventsComponent } from '../../components/list-events/list-events.component';
import { ViewComponent } from '../../components/view/view.component';
import { InfoComponent } from '../../components/info/info.component';
import { ToggleSidebarComponent } from '../../components/toggle-sidebar/toggle-sidebar.component';
import { AnalysisLayoutComponent } from '../analysis-layout/analysis-layout.component';
import { MapLayoutComponent } from '../map-layout/map-layout.component';
import { ListAnalysisComponent } from '../../components/list-analysis/list-analysis.component';
import { InfoAnalysisComponent } from '../../components/info-analysis/info-analysis.component';
import { RealtimeComponent } from '../../components/realtime/realtime.component';
import { SearchComponent } from '../../components/realtime/search/search.component';
import { CustomerComponent } from '../../components/realtime/customer/customer.component';
import { TableEventsComponent } from '../../components/realtime/table-events/table-events.component';
import { AreaAnalysisComponent } from '../../components/realtime/area-analysis/area-analysis.component';
import { AnalysisCustomerComponent } from '../../components/analysis-customer/analysis-customer.component';
import { SearchbarAnalysisCustomerComponent } from '../../components/analysis-customer/searchbar-analysis-customer/searchbar-analysis-customer.component';
import { RealtimeReportObjectComponent } from '../../components/analysis-customer/realtime-report-object/realtime-report-object.component';
import { GenderComponent } from '../../components/analysis-customer/gender/gender.component';
import { AgeComponent } from '../../components/analysis-customer/age/age.component';
import { RealtimeTableEventComponent } from '../../components/analysis-customer/realtime-table-event/realtime-table-event.component';
import { AreaComponent } from '../../components/area/area.component';
import { AreaSearchComponent } from '../../components/area/area-search/area-search.component';
import { AreaChartComponent } from '../../components/area/area-chart/area-chart.component';
import { EvaluateComponent } from '../../components/evaluate/evaluate.component';
import { EvaluateSearchComponent } from '../../components/evaluate/evaluate-search/evaluate-search.component';
import { EvaluateReportComponent } from '../../components/evaluate/evaluate-report/evaluate-report.component';
import { EvaluateKPIComponent } from '../../components/evaluate/evaluate-kpi/evaluate-kpi.component';
import { AgmCoreModule } from '@agm/core';
import { MapComponent } from '../../components/map/map.component';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { EvaluateWatchtimeChartComponent } from '../../components/evaluate/evaluate-watchtime-chart/evaluate-watchtime-chart.component';
import { EvaluateCustomerChartComponent } from '../../components/evaluate/evaluate-customer-chart/evaluate-customer-chart.component';
import { IdentificationComponent } from '../../components/identification/identification.component';
import { IdentificationSearchComponent } from '../../components/identification/identification-search/identification-search.component';
import { IdentificationInfoComponent } from '../../components/identification/identification-info/identification-info.component';
import {
  ComboChartComponent,
  ComboSeriesVerticalComponent,
} from '../../components/combo-chart';
import { FormatTimePipe } from '../../pipes/format-time.pipe';
import { FormatDatePipe } from '../../pipes/format-date.pipe';
import { UserTableComponent } from 'src/app/components/user-table/user-table.component';
import { RoleTableComponent } from 'src/app/components/role-table/role-table.component';
import { ManageRoleComponent } from 'src/app/components/manage-role/manage-role.component';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12,
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};

@NgModule({
  declarations: [
    MainLayoutComponent,
    ManageLayoutComponent,
    PopupComponent,
    NavSidebarComponent,
    ManagePersonComponent,
    PersonDetailComponent,
    PersonSearchComponent,
    IdentificationListComponent,
    NotifyComponent,
    DropdownListComponent,
    ImgSearchDirective,
    ManageBranchComponent,
    TreeViewComponent,
    BranchInfoComponent,
    BranchDetailComponent,
    CameragroupDetailComponent,
    ImageLayoutComponent,
    ListEventImagesComponent,
    ManageReportComponent,
    ReportInfoComponent,
    ReportDetailComponent,
    ReportTableComponent,
    AiListEventImageComponent,
    HeatmapLayoutComponent,
    HeatMapComponent,
    MonitorLayoutComponent,
    MonitorComponent,
    ListMonitorComponent,
    MainMonitorComponent,
    MonitorInfoComponent,
    MonitorEventsComponent,
    ClickOutsideDirective,
    TestComponent,
    NavBarComponent,
    CctvLayoutComponent,
    CctvComponent,
    ListCameraComponent,
    CameraInfoComponent,
    ListEventsComponent,
    ViewComponent,
    InfoComponent,
    UserTableComponent,
    RoleTableComponent,
    ToggleSidebarComponent,
    AnalysisLayoutComponent,
    MapLayoutComponent,
    ListAnalysisComponent,
    InfoAnalysisComponent,
    RealtimeComponent,
    SearchComponent,
    CustomerComponent,
    TableEventsComponent,
    AreaAnalysisComponent,
    AnalysisCustomerComponent,
    SearchbarAnalysisCustomerComponent,
    RealtimeReportObjectComponent,
    GenderComponent,
    AgeComponent,
    RealtimeTableEventComponent,
    AreaComponent,
    AreaSearchComponent,
    AreaChartComponent,
    EvaluateComponent,
    EvaluateSearchComponent,
    EvaluateReportComponent,
    EvaluateKPIComponent,
    MapComponent,
    PaginationComponent,
    EvaluateWatchtimeChartComponent,
    EvaluateCustomerChartComponent,
    IdentificationComponent,
    IdentificationSearchComponent,
    IdentificationInfoComponent,
    ComboChartComponent,
    ComboSeriesVerticalComponent,
    FormatTimePipe,
    FormatDatePipe,
    ManageRoleComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MainLayoutRoutingModule,
    NgxChartsModule,
    AgmJsMarkerClustererModule,
    ReactiveFormsModule,
    LeafletModule,
    LeafletMarkerClusterModule,
    NotifierModule.withConfig(customNotifierOptions),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAbyEx4KJzCcJDrK_s-B4RaRMIrxceYSfQ',
    }),
    InfiniteScrollModule,
    NgxSpinnerModule,
    NgxSliderModule,
    AngularMyDatePickerModule,
    NgMultiSelectDropDownModule,
  ],
  exports: [InfiniteScrollModule, NgxSpinnerModule],
})
export class MainLayoutModule {}
