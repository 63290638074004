<div class="box full-width">
  <div
    class="ml-1"
    style="
      display: grid;
      grid-template-columns: 3fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 1fr;
      grid-gap: 2px;
    "
  >
    <div
      *ngIf="hiddenSearchBar === true"
      class="position-relative search-main mr-3 w-100"
      style="display: grid; grid-template-columns: 1fr 8fr 1fr"
      [ngClass]="isFocus ? 'background-shadow' : ''"
    >
      <div class="size-icon handle" style="margin: auto" (click)="search()">
        <i class="fa fa-search"></i>
      </div>
      <div class="w-100">
        <div
          class="parent full-height full-width position-relative d-flex"
          (clickOutside)="showCustomerDropdown = false"
        >
          <!-- <ng-container *ngFor="let image of imageUrl; let i = index">
            <div class="parent-img position-relative" *ngIf="showImg === true">
              <img
                #img
                (click)="handleClickedShowModal(img)"
                class="mini-img handle"
                [src]="image.url || image"
                alt=""
              />
              <span
                class="position-relative handle"
                (click)="closeImg($event, i)"
                ><i
                  class="fa fa-close pt-2 position-absolute"
                  style="right: 5px; top: 2px"
                ></i
              ></span>
            </div>
          </ng-container> -->
          <input
            type="text"
            style="border: none"
            placeholder="Tên hoặc hình ảnh"
            *ngIf="showImg === false && chosenCustomer === null"
            [ngModel]="textSearch"
            (ngModelChange)="findCustomerByName($event)"
            (keyup.enter)="getCustomers()"
          />
          <ng-container class="position-relative" *ngIf="showImg === false">
            <div class="parent-customer" *ngIf="chosenCustomer">
              <span class="customer-name">{{ chosenCustomer.name }}</span>
              <span class="position-relative handle" (click)="clearCustomer()"
                ><i
                  class="fa fa-close pt-2 position-absolute"
                  style="right: 5px; top: 5px"
                ></i
              ></span>
            </div>
          </ng-container>
          <ng-container *ngFor="let image of imageUrl; let i = index">
            <div class="parent-img position-relative">
              <img
                #img
                (click)="handleClickedShowModal(img)"
                class="mini-img handle"
                [src]="image.url || image"
                alt=""
              />
              <span
                class="position-relative handle"
                (click)="closeImg($event, i)"
                ><i
                  class="fa fa-close pt-2 position-absolute"
                  style="right: 5px; top: 5px"
                ></i
              ></span>
            </div>
          </ng-container>
          <ul class="top-level-menu" [hidden]="!showCustomerDropdown">
            <li
              style="background-color: white; padding: 8px 16px"
              *ngIf="customerList.length == 0"
            >
              <span class="sub-title sub-style"
                >Không tìm thấy đối tượng nào</span
              >
            </li>
            <li
              style="background-color: white; padding: 8px 16px"
              *ngFor="let customer of customerList; let i = index"
              [ngClass]="{
                'select-active':
                  chosenCustomer && customer.id == chosenCustomer.id
              }"
              (click)="handleSelectCustomer(customer)"
            >
              <div
                class="d-flex hover-title"
                style="gap: 16px; align-items: center"
              >
                <div>
                  <img
                    class="w-100"
                    style="max-height: 62px; max-width: 62px"
                    [src]="apiImgHost + customer.img"
                    onerror="this.src='assets/image/no-avatar.png'"
                  />
                </div>
                <div class="d-flex flex-column">
                  <span class="title">{{ customer.name }}</span>
                  <span class="sub-title">{{ customer.t_branch?.name }}</span>
                  <span class="sub-title">{{ customer.type?.name }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div
        class="handle line-height-50"
        (click)="resetSearch()"
        [hidden]="textSearch == ''"
      >
        <i class="fa fa-sm fa-close"></i>
      </div> -->
      <div class="borderLeft pr-1 show-children">
        <span class="handle" (click)="openPopup()"
          ><img src="assets/image/image.jpg" />
        </span>
      </div>
      <div class="custom-modal" *ngIf="showPopup == true">
        <div class="position-relative">
          <h5 class="pl-3 pr-3 pt-3">Tìm kiếm bằng hình ảnh</h5>
          <span
            (click)="openPopup()"
            class="position-absolute handle"
            style="top: 0.5rem; right: 1rem"
            ><i class="fa fa-close"></i
          ></span>
        </div>
        <div class="pl-3 pr-3 pb-3">
          Tìm kiếm bằng hình ảnh thay vì văn bản. Thử kéo một hình ảnh vào đây.
        </div>
        <div *ngIf="showEle === false">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item custom-nav">
              <a
                class="nav-link active"
                id="home-tab"
                data-toggle="tab"
                href="#home"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                >Dán URL của hình ảnh</a
              >
            </li>
            <li class="nav-item custom-nav">
              <a
                class="nav-link"
                id="profile-tab"
                data-toggle="tab"
                href="#profile"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                >Tải ảnh lên</a
              >
            </li>
          </ul>
          <div
            class="tab-content"
            id="myTabContent"
            style="background: #f5f5f5"
          >
            <div
              class="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div
                class="p-4"
                style="display: grid; grid-template-columns: 3fr 1fr"
              >
                <input
                  [(ngModel)]="srcImage"
                  class="full-width"
                  style="outline: none; border: none"
                  type="text"
                  placeholder="Tìm kiếm"
                />
                <button
                  class="bg-primary ml-3"
                  style="border: none !important"
                  (click)="findImage()"
                >
                  Tìm kiếm
                </button>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
              appImgSearch
              (files)="dropImage($event)"
              (drag)="onDrag($event)"
            >
              <form class="p-4">
                <input
                  #file
                  type="file"
                  accept="image/*"
                  name="file"
                  id="file"
                  class="inputfile"
                  (change)="chooseFile(file.files)"
                />
              </form>
            </div>
          </div>
        </div>
        <div
          style="
            background: #d3ceca;
            height: 150px;
            text-align: center;
            vertical-align: middle;
          "
          *ngIf="showEle === true"
          appImgSearch
          (files)="dropImage($event)"
        >
          <h3 class="pt-5" style="color: rgba(0, 0, 0, 0.5)">
            Thả ảnh vào đây
          </h3>
        </div>
      </div>
    </div>

    <div class="position-relative mr-3 w-100" *ngIf="showSlider">
      <div class="slider-container d-flex">
        <span class="mr-2" style="align-self: center">Tương tự:</span>
        <ngx-slider
          [(value)]="sliderValue"
          [options]="options"
          (userChangeEnd)="handleChangeSlider()"
        ></ngx-slider>
      </div>
    </div>

    <div class="handle position-relative w-100">
      <div
        class="parent w-100"
        [ngClass]="isShowBranchOption ? 'background-shadow' : ''"
        (click)="handleSelectList('isShowBranchOption')"
      >
        <div class="line-height-50" style="display: flex">
          <div class="showing-select">
            {{ chosenBranch ? chosenBranch.name : noText }}
          </div>
          <div class="ml-3">
            <i
              class="fa"
              [ngClass]="{
                'fa-caret-down': !isShowBranchOption,
                'fa-caret-up': isShowBranchOption
              }"
            ></i>
          </div>
        </div>
      </div>
      <!--      <ul class="top-level-menu" [hidden]="!isShowBranchOption">-->
      <!--        <li class="title-menu" *ngFor="let branch of branchList" [ngClass]="{'select-active': chosenBranch && branch.name == chosenBranch.name}">-->
      <!--          <a (click)="handleClickedBranch(branch)">{{branch.name}}</a>-->
      <!--        </li>-->
      <!--      </ul>-->
      <app-dropdown-list
        [dataList]="branchList"
        [saveBranch]="true"
        [isShow]="isShowBranchOption"
        [selectedBranch]="chosenBranch"
        (clickedOutside)="closePopup()"
        (handleSelect)="handleClickedBranch($event)"
      ></app-dropdown-list>
    </div>

    <div
      class="handle position-relative mr-3 p-0 w-100"
      (click)="handleSelectList('isShowCameraGroupOption')"
    >
      <div
        class="parent w-100"
        [ngClass]="isShowCameraGroupOption ? 'background-shadow' : ''"
      >
        <div class="line-height-50" style="display: flex">
          <div class="showing-select">
            {{ chosenStall ? chosenStall.name : noText }}
          </div>
          <div class="ml-3">
            <i
              class="fa"
              [ngClass]="{
                'fa-caret-down': !isShowCameraGroupOption,
                'fa-caret-up': isShowCameraGroupOption
              }"
            ></i>
          </div>
        </div>
      </div>
      <!--      <ul class="top-level-menu" [hidden]="!isShowCameraGroupOption">-->
      <!--        <li class="title-menu" *ngFor="let stall of stallList" [ngClass]="{'select-active': chosenStall && stall.name == chosenStall.name}">-->
      <!--          <a (click)="handleClickedStall(stall)">{{stall.name}}</a>-->
      <!--        </li>-->
      <!--      </ul>-->

      <app-dropdown-list
        [dataList]="stallList"
        [isShow]="isShowCameraGroupOption"
        [selectedBranch]="chosenStall"
        (handleSelect)="handleClickedStall($event)"
      ></app-dropdown-list>
    </div>

    <div
      *ngIf="showTimeOption"
      class="handle position-relative p-0 w-100"
      (click)="handleSelectList('isShowTimeOption')"
    >
      <div
        class="parent w-100"
        [ngClass]="isShowTimeOption ? 'background-shadow' : ''"
      >
        <div class="line-height-50" style="display: flex">
          <div class="showing-select">
            {{ chosenTime ? chosenTime.name : noText }}
          </div>
          <div class="ml-3">
            <i
              class="fa"
              [ngClass]="{
                'fa-caret-down': !isShowTimeOption,
                'fa-caret-up': isShowTimeOption
              }"
            ></i>
          </div>
        </div>
      </div>
      <!--      <ul class="top-level-menu" [hidden]="!isShowTimeOption">-->
      <!--        <li class="title-menu" *ngFor="let option of timeOptions" [ngClass]="{'select-active': chosenTime && option.name == chosenTime.name}">-->
      <!--          <a (click)="handleSelectTime(option)">{{option.name}}</a>-->
      <!--        </li>-->
      <!--      </ul>-->

      <app-dropdown-list
        [dataList]="timeOptions"
        [saveBranch]="true"
        [isShow]="isShowTimeOption"
        [selectedBranch]="chosenTime"
        (handleSelect)="handleSelectTime($event)"
      ></app-dropdown-list>
    </div>
    <div class="position-relative w-100" *ngIf="showFromDate">
      <div class="date-selector">
        <label class="p-2 m-0">{{ fromDateLabel }}: </label>
        <input
          class="form-control"
          angular-mydatepicker
          [(ngModel)]="fromDate"
          #dpFrom="angular-mydatepicker"
          (dateChanged)="handleFromDateChange($event)"
          [options]="datePickerOptions"
          (click)="dpFrom.toggleCalendar()"
          [locale]="locale"
        />
      </div>
    </div>

    <div class="position-relative w-100" *ngIf="showToDate">
      <div class="date-selector">
        <label class="p-2 m-0">{{ toDateLabel }}: </label>
        <input
          class="form-control"
          angular-mydatepicker
          [(ngModel)]="toDate"
          #dpTo="angular-mydatepicker"
          (dateChanged)="handleToDateChange($event)"
          [options]="datePickerOptions"
          (click)="dpTo.toggleCalendar()"
          [locale]="locale"
        />
      </div>
    </div>

    <div
      class="handle position-relative p-0 w-100"
      (click)="handleSelectList('isShowAgeOption')"
      *ngIf="showAgeSelection"
    >
      <div
        class="parent w-100"
        [ngClass]="isShowAgeOption ? 'background-shadow' : ''"
      >
        <div class="line-height-50" style="display: flex">
          <div class="showing-select">
            {{ chosenAge ? chosenAge.name : noText }}
          </div>
          <div class="ml-3">
            <i
              class="fa"
              [ngClass]="{
                'fa-caret-down': !isShowAgeOption,
                'fa-caret-up': isShowAgeOption
              }"
            ></i>
          </div>
        </div>
      </div>

      <app-dropdown-list
        [dataList]="ageOptions"
        [isShow]="isShowAgeOption"
        [selectedBranch]="chosenAge"
        (handleSelect)="handleSelectAge($event)"
      ></app-dropdown-list>
    </div>
    <div
      class="handle position-relative p-0 w-100"
      (click)="handleSelectList('isShowGenderOption')"
      *ngIf="showGenderSelection"
    >
      <div
        class="parent w-100"
        [ngClass]="isShowGenderOption ? 'background-shadow' : ''"
      >
        <div class="line-height-50" style="display: flex">
          <div class="showing-select">
            {{ chosenGender ? chosenGender.name : noText }}
          </div>
          <div class="ml-3">
            <i
              class="fa"
              [ngClass]="{
                'fa-caret-down': !isShowGenderOption,
                'fa-caret-up': isShowGenderOption
              }"
            ></i>
          </div>
        </div>
      </div>

      <app-dropdown-list
        [dataList]="genderOptions"
        [isShow]="isShowGenderOption"
        [selectedBranch]="chosenGender"
        (handleSelect)="handleSelectGender($event)"
      ></app-dropdown-list>
    </div>

    <div class="position-relative w-100" *ngIf="showPointSlider">
      <div
        class="d-flex full-height"
        style="align-items: center; text-align: right"
      >
        <span class="mr-2 flex-grow-1" style="align-self: center"
          >Mật độ tối đa:</span
        >
      </div>
    </div>

    <div class="position-relative w-100" *ngIf="showPointSlider">
      <div class="slider-container d-flex">
        <!-- <span class="mr-2" style="align-self: center;">Mật độ tối đa:</span> -->
        <ngx-slider
          [(value)]="maxSliderValue"
          [options]="maxSliderOptions"
          (userChangeEnd)="handleMaxChangeSlider()"
        ></ngx-slider>
      </div>
    </div>
  </div>
</div>
