import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: string): unknown {
    let newStr = value.substring(0, 8)
    return newStr;
  }

}
