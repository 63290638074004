<div class="layout">
  <div class="layout-header">
    <app-nav-bar
      [changeICon]="isShowSideBar"
      (showSideBar)="handleShowSideBar()"
    ></app-nav-bar>
  </div>
  <div class="layout-content">
    <div class="row full-height">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 column">
        <app-nav-sidebar
          [name]="sideBarName"
          [startTab]="showFirstTab"
          [sideBarItem]="sideBarOption"
          (clickedItem)="handleChangeNavSideBar($event)"
        ></app-nav-sidebar>
      </div>
      <div
        class="col-xs-12 col-sm-12 col-md-12 col-lg-10 p-4 full-width full-height"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
