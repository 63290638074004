<div class="d-flex full-height full-width">
  <div class="box p-3" style="width: 25%; min-width: 200px">
    <ul class="p-0 sidebar-container">
      <ng-container *ngFor="let item of SIDEBAR_ITEM">
        <li
          class="d-flex align-items-center px-2 sidebar-item"
          [ngClass]="{
            'sidebar-item--active': selectedSidebarItem.Value === item.Value
          }"
          (click)="onClickSidebarItem(item)"
        >
          {{ item.Label }}
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="flex-grow-1 box p-3 ml-2" style="width: 75%">
    <ng-container
      *ngIf="selectedSidebarItem.Value === 'user'; else roleTableTemplate"
    >
      <app-user-table> </app-user-table>
    </ng-container>
    <ng-template #roleTableTemplate>
      <app-role-table></app-role-table>
    </ng-template>
  </div>
</div>
