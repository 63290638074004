import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monitor-layout',
  templateUrl: './monitor-layout.component.html',
  styleUrls: ['./monitor-layout.component.scss'],
})
export class MonitorLayoutComponent implements OnInit {
  public isShowSideBar: any = false;
  constructor() {}

  ngOnInit(): void {}
  public handleShowSideBar() {
    this.isShowSideBar = this.isShowSideBar !== true;
  }
}
