import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Directive({
  selector: '[appImgSearch]'
})
export class ImgSearchDirective {

  @Output('files') files: EventEmitter<any[]> = new EventEmitter();
  @Output('drag') drag: EventEmitter<any> = new EventEmitter();
  @Input('showElement') showElement: any;
  public filesList: any = [];

  constructor(private sanitizer: DomSanitizer) {
  }

  @HostListener('dragover', ['$event'])
  public allowDrop(ev: DragEvent) {
    ev.preventDefault();
    ev.stopPropagation();
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(ev: DragEvent) {
    ev.preventDefault();
    ev.stopPropagation();
  }
  @HostListener('drag', ['$event'])
  public onDrag(ev: DragEvent) {
    this.drag.emit(ev)
  }

  @HostListener('drop', ['$event'])
  public onDrop(evt: DragEvent) {
    const data = evt.dataTransfer.getData('text/uri-list');
    let file;
    let url;
    evt.preventDefault();
    for (let i = 0; i < evt.dataTransfer.files.length; i++) {
      file = evt.dataTransfer.files[i];
      url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
      this.filesList.push({
        file,
        url
      });
    }
    const result = this.filesList.includes(data);
    if (data !== '' && result === false) {
      this.filesList.push(data);
    }
    if (this.filesList.length > 0) {
      this.files.emit(this.filesList);
    }
  }

}
