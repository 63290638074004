<div class="d-flex flex-column transition" style="flex: 1 1 auto; height: 0">
  <div class="grid-repeat-5 mt-3">
    <div
      class="box d-flex flex-column handle"
      style="
        padding: 0 !important;
        background: rgba(255, 255, 255, 0.8) !important;
      "
      *ngFor="let event of eventCustomerList"
      [ngClass]="event.active ? 'active-item-grid' : ''"
      (click)="handleClickEvent(event)"
    >
      <!-- <img #img class="img-list"
           src="{{deviceHost + event.url}}"
           alt="" (drag)="drag()" (dragstart)="dragStart($event)" (dragend)="dragLeaveEle();dragLeaveModal()">
      <div class="p-3">
        <div class="title font-weight-bold">{{event.t_customer ? event.t_customer.name : event.t_option.name}}</div>
        <div class="sub-title pb-2 sub-style font-weight-bold">{{event.t_option ? event.t_option.name : noText}}</div>
        <div class="title font-weight-bold">{{event.t_cameragroup.t_branch.name}}</div>
        <div class="sub-title sub-style font-weight-bold">{{event.createddate | date: 'dd-MM-YYYY HH:mm:ss'}}</div>
      </div> -->

      <div
        class="d-flex"
        style="
          background-color: #000000;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          height: 144px;
          text-align: center;
          justify-content: center;
        "
      >
        <img
          #img
          draggable="true"
          class="event-img"
          src="{{ deviceHost + event.url }}"
          alt=""
          (error)="onImgError($event)"
          (click)="handleClickedShowModal(img)"
        />
      </div>
      <div class="mt-2 mb-2" style="padding-left: 12px; padding-right: 12px">
        <div class="pb-2">
          <div class="d-flex justify-content-between align-items-center">
            <div class="title">
              <b>{{
                event.t_customer ? event.t_customer.name : event.t_option.name
              }}</b>
            </div>
            <a
              [href]="'map?camera=' + event.cameracode + '&image=' + event.url"
            >
              <button type="button" class="btn btn-sm btn-light">
                <i class="fa fa-map-marker"></i>
              </button>
            </a>
          </div>
          <div
            class="event-type sub-title"
            [ngClass]="{
              'event-type__vip': event.t_option.code == 'VIP',
              'event-type__blk': event.t_option.code == 'BLK',
              'event-type__id': event.t_option.code == 'IDPERSON'
            }"
          >
            {{ event.t_option ? event.t_option.name : noText }}
          </div>
          <div class="sub-title sub-style" style="margin-top: 8px">
            {{ event.createddate | date: "HH:mm:ss, dd-MM-yyyy" }}
          </div>
          <div class="sub-title">
            {{ event.t_cameragroup.t_branch.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-pagination
    [page]="currentPage"
    [itemsPerPage]="eventsPerPage"
    [total]="noEvents"
    (clickedPage)="changePage($event)"
  ></app-pagination>
</div>
