<ul class="top-level-menu" [hidden]="!isShow">
  <li
    class="title-menu p-1"
    *ngFor="let data of dataList; let i = index"
    [ngClass]="{
      'select-active': selectedBranch && data.name == selectedBranch.name
    }"
  >
    <div class="grid-2">
      <div
        class="d-flex full-height hover-title"
        (click)="onSelectBrand(data)"
        [@animation-opacity]="state"
      >
        <span class="ml-2 mr-3"><i class="fa fa-map-marker"></i></span>
        <a class="label">{{ data.name }}</a>
      </div>
      <div
        *ngIf="isShowIconChildBranch === true"
        class="pull-right hover-icon text-center show-children"
        (click)="backDataList()"
      >
        <i class="fa fa-angle-left font-weight-bold"></i>
      </div>
      <div
        *ngIf="data.t_branches && data.t_branches.length > 0"
        class="pull-right hover-icon text-center show-children"
        (click)="showChildren(data)"
      >
        <i class="fa fa-angle-right font-weight-bold"></i>
      </div>
    </div>
    <!--    <app-dropdown-list [dataList]="data.t_branches"-->
    <!--                       [isShow]="isShowChildren"-->
    <!--                       [selectedBranch]="selectedBranch"-->
    <!--                       (handleSelect)="onSelectBrand($event)"-->
    <!--                       [hidden]="!data.active"-->
    <!--    ></app-dropdown-list>-->
  </li>
</ul>
