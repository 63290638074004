import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BASE_HOST, SOCKET_HOST } from 'src/environments/environment';
import { AuthService } from './auth/auth.service';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private host: string;
  private currentHost: string = 'base';
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private alertService: AlertService
  ) {
    this.host = BASE_HOST;
  }

  public get<T>(
    api: string,
    headerParams?,
    host: string = 'base'
  ): Observable<T> {
    this.checkHost(host);
    return this.http
      .get<T>(this.host + api, { params: headerParams })
      .pipe(catchError(this.handleError<T>()));
  }

  public post<T>(
    api: string,
    body: Object,
    host: string = 'base',
    params?
  ): Observable<T> {
    this.checkHost(host);
    return this.http
      .post<T>(this.host + api, body, { params })
      .pipe(catchError(this.handleError<T>()));
  }

  public put<T>(
    api: string,
    body: Object,
    host: string = 'base'
  ): Observable<T> {
    this.checkHost(host);
    return this.http
      .put<T>(this.host + api, body)
      .pipe(catchError(this.handleError<T>()));
  }

  public delete<T>(api: string, host: string = 'base'): Observable<T> {
    this.checkHost(host);
    return this.http
      .delete<T>(this.host + api)
      .pipe(catchError(this.handleError<T>()));
  }

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      switch (error.status) {
        case 401:
          this.authService.logout();
          break;
        case 400:
          this.alertService.informError(error.error);
          break;
        default:
          console.error(
            `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`
          );
          // Return an observable with a user-facing error message.
          this.alertService.informError('Đã có lỗi xảy ra, vui lòng thử lại');
      }
      return of(result as T);
    };
  }

  private switchHost(host: string) {
    switch (host) {
      case 'socket':
        this.host = SOCKET_HOST;
        break;
      default:
        this.host = BASE_HOST;
    }
  }

  private checkHost(host: string) {
    if (this.currentHost != host) {
      this.switchHost(host);
      this.currentHost = host;
    }
  }
}
