import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Branch } from 'src/app/models/branch';
import { SettingOption } from 'src/app/models/setting-option';
import { ApiService } from 'src/app/services/api.service';
import { API, FUNC, REPLACEMENT } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-gender',
  templateUrl: './gender.component.html',
  styleUrls: ['./gender.component.scss']
})
export class GenderComponent implements OnInit, OnChanges {

  @Input() eventData = [];
  public genderData = {nam: 0, nam_watchtime: 0, nu: 0, nu_watchtime: 0, total: 0, total_watchtime: 0,};
  public noStatistic = REPLACEMENT.noStatistic;
  public events = [];
  public currentAnalyzeHour;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange}): void {
    if (changes['eventData']) {
      this.formatEventData();
    }
  }

  public formatEventData() {
    this.genderData = {nam: 0, nam_watchtime: 0, nu: 0, nu_watchtime: 0, total: 0, total_watchtime: 0}
    this.events.length = 0;
    if (this.eventData && this.eventData.length > 0) {
      this.eventData.map(data => {
        if (String(data.attrcode).includes('gender')) {
          if (!this.events.includes(data.eventid)) {
            this.events.push(data.eventid);
            this.genderData.total += data.custno;
            this.genderData.total_watchtime += data.sumwatchtime;
            let analyzeHour = moment(data.createdtimestamp).hour();
            if (!this.currentAnalyzeHour || this.currentAnalyzeHour < analyzeHour) this.currentAnalyzeHour = analyzeHour;
          }
          if (String(data.attrcode).split('_')[1] === 'male') {
            this.genderData.nam += data.val ? +data.val : 0;
            this.genderData.nam_watchtime += data.sumval ? +data.sumval : 0;
          }
          if (String(data.attrcode).split('_')[1] === 'female') {
            this.genderData.nu += data.val ? +data.val : 0;
            this.genderData.nu_watchtime += data.sumval ? +data.sumval : 0;
          }
        }
      })
    }
  }

}
