<div>
  <div class="box">
    <div class="title-group p-3 font-weight-bold">
      Key Performance Indicator (KPI)
    </div>
    <div class="p-3">
      <table class="table table-striped">
        <thead>
        <tr class="table-secondary">
          <th scope="col">STT</th>
          <th scope="col">KPI</th>
          <th scope="col">Thực tế</th>
          <th scope="col">Mục tiêu</th>
          <th scope="col">Xu hướng</th>
          <th scope="col">Thực tế <span class="sub-title">vs</span> Mục tiêu</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="table-data">1</td>
          <td class="table-data">Số lượng khách</td>
          <td class="table-data">{{branchStatistic.custno | number: '1.0'}}</td>
          <td class="table-data">{{branchKPI.custno | number: '1.0'}}</td>
          <td class="table-data">
            <ngx-charts-line-chart
            [view]="chartView"
            [scheme]="custnoColorScheme"
            [results]="custnoData"
            [autoScale]="true"
            [tooltipDisabled]="true">
          </ngx-charts-line-chart>
          </td>
          <td class="table-data">
            <ngx-charts-linear-gauge
              [view]="chartView"
              [scheme]="custnoColorScheme"
              [value]="compareChartData.custno"
              [max]="maxPoint"
              [units]="''"
              [valueFormatting]="formatValue">
            </ngx-charts-linear-gauge>
          </td>
        </tr>
        <tr>
          <td class="table-data">2</td>
          <td class="table-data">Số lượng khách quan tâm</td>
          <td class="table-data">{{branchStatistic.interestedcustno | number: '1.0'}}</td>
          <td class="table-data">{{branchKPI.interestedcustno | number: '1.0'}}</td>
          <td class="table-data">
            <ngx-charts-line-chart
            [view]="chartView"
            [scheme]="interestingColorScheme"
            [results]="interestingData"
            [autoScale]="true"
            [tooltipDisabled]="true">
          </ngx-charts-line-chart>
          </td>
          <td class="table-data">
            <ngx-charts-linear-gauge
              [view]="chartView"
              [scheme]="interestingColorScheme"
              [value]="compareChartData.interestedcustno"
              [max]="maxPoint"
              [units]="''"
              [valueFormatting]="formatValue">
            </ngx-charts-linear-gauge>
          </td>
        </tr>
        <tr>
          <td class="table-data">3</td>
          <td class="table-data">Thời gian xem TB mỗi khách</td>
          <td class="table-data">{{branchStatistic.sumwatchtime | number: '1.0-1'}}s</td>
          <td class="table-data">{{branchKPI.sumwatchtime | number:'1.0-1'}}s</td>
          <td class="table-data">
            <ngx-charts-line-chart
            [view]="chartView"
            [scheme]="watchtimeColorScheme"
            [results]="watchtimeData"
            [autoScale]="true"
            [tooltipDisabled]="true">
          </ngx-charts-line-chart>
          </td>
          <td class="table-data">
            <ngx-charts-linear-gauge
              [view]="chartView"
              [scheme]="watchtimeColorScheme"
              [value]="compareChartData.sumwatchtime"
              [max]="maxPoint"
              [units]="''"
              [valueFormatting]="formatValue">
            </ngx-charts-linear-gauge>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
