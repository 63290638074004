<div class="layout">
  <div class="layout-header">
    <app-nav-bar
      [changeICon]="isShowSideBar"
      (showSideBar)="handleShowSideBar()"
    ></app-nav-bar>
  </div>
  <div class="layout-content">
    <div class="row full-height">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 column">
        <app-toggle-sidebar [showSideBar]="isShowSideBar"></app-toggle-sidebar>
        <app-list-analysis
          [startTab]="isShowAnalysis"
          (showAnalysis)="handleShowAnalysis($event)"
        ></app-list-analysis>
      </div>
      <div
        class="col-xs-12 col-sm-12 col-md-12 col-lg-10 p-4 full-width full-height"
      >
        <app-realtime [active]="isShowAnalysis"></app-realtime>
        <app-analysis-customer
          [active]="isShowAnalysis"
        ></app-analysis-customer>
        <app-area [active]="isShowAnalysis"></app-area>
        <app-evaluate [active]="isShowAnalysis"></app-evaluate>
        <app-identification
          class="full-height"
          [active]="isShowAnalysis"
        ></app-identification>
        <app-heat-map [active]="isShowAnalysis"></app-heat-map>
      </div>
    </div>
  </div>
</div>
