<div class="layout">
  <div class="layout-header">
    <app-nav-bar
      [changeICon]="isShowSideBar"
      (showSideBar)="handleShowSideBar()"
    ></app-nav-bar>
  </div>
  <div class="layout-content">
    <app-monitor></app-monitor>
  </div>
</div>
