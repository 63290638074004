import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';

@Component({
  selector: 'app-list-analysis',
  templateUrl: './list-analysis.component.html',
  styleUrls: ['./list-analysis.component.scss'],
})
export class ListAnalysisComponent implements OnInit, OnChanges {
  @Input() startTab: string = null;
  @Output() showAnalysis = new EventEmitter<any>();
  public sideBarAnalysis = [
    // {title: 'Dashboard', active: false},
    { title: 'Dashboard', value: 'Dashboard', active: false },
    { title: 'Đối tượng giám sát', value: 'Đối tượng giám sát', active: false },
    // { title: 'Khu vực', active: false },
    // { title: 'Đánh giá kết quả', active: false },
    { title: 'Nhận dạng', value: 'Nhận dạng', active: false },
    { title: 'Mật độ đối tượng', value: 'Heatmap', active: false },
  ];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes['startTab']) {
      if (this.startTab == null) {
        this.activateClass(this.sideBarAnalysis[1]);
      } else {
        let sideBarTab = this.sideBarAnalysis.find(
          (sideBarTab) => sideBarTab.title == this.startTab
        );
        this.activateClass(sideBarTab);
      }
    }
  }

  activateClass(item) {
    this.showAnalysis.emit(item.value);
    this.sideBarAnalysis.map((check) => {
      if (item.title === check.title) {
        check.active = true;
      } else {
        check.active = false;
      }
    });
  }
}
