<!-- <ul class="pt-2 nav col">
    <li class="title">
        <div class="nav-link" [ngClass]="{'active': chosenTab == 'info', 'handle': chosenTab != 'info'}" (click)="handleSelectTab('info')">Thông tin</div>
    </li>
    <li class="title" *ngIf="!userInfo.branchid">
        <div class="nav-link" [ngClass]="{'active': chosenTab == 'config', 'handle': chosenTab != 'config'}" (click)="handleSelectTab('config')">Cấu hình</div>
    </li>
</ul> -->
<div class="box-body tab-content full-height">
  <div
    id="info"
    class="tab-pane p-3"
    [ngClass]="{ active: chosenTab == 'info' }"
  >
    <form class="active p-3" [formGroup]="frmObject" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label>Mã <a class="required-field">*</a></label>
        <input
          type="text"
          class="form-control mt-2"
          formControlName="code"
          required
        />
      </div>
      <div class="form-group">
        <label>Tên <a class="required-field">*</a></label>
        <input
          type="text"
          class="form-control mt-2"
          formControlName="name"
          required
        />
      </div>
      <div class="form-group">
        <label>Địa chỉ</label>
        <input
          type="text"
          class="form-control mt-2"
          formControlName="address"
        />
      </div>
      <label>Tọa độ</label>
      <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 5px">
        <div class="form-group">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="lat"
            placeholder="Vĩ độ"
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="lon"
            placeholder="Kinh độ"
          />
        </div>
      </div>
      <div class="form-group">
        <label>Thông tin</label>
        <input type="text" class="form-control mt-2" formControlName="info" />
      </div>
      <div class="d-flex">
        <button
          *ngIf="selectedBranchId"
          type="button"
          class="btn btn-sm btn-secondary mt-2 mr-2 pull-right full-width"
          (click)="deleteBranch()"
        >
          Xóa
        </button>
        <button
          type="submit"
          class="btn btn-sm btn-secondary mt-2 pull-right full-width"
        >
          Lưu
        </button>
      </div>
    </form>
  </div>
  <div
    id="config"
    class="tab-pane p-3"
    [ngClass]="{ active: chosenTab == 'config' }"
  >
    <textarea
      class="form-control"
      rows="10"
      [(ngModel)]="AIConfigVal"
    ></textarea>
    <button
      type="button"
      class="btn btn-secondary btn-sm mt-2"
      (click)="saveAIConfig()"
    >
      Lưu
    </button>
  </div>
</div>
