import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Branch } from 'src/app/models/branch';
import { Option } from 'src/app/models/option';
import { SettingOption } from 'src/app/models/setting-option';
import { ApiService } from 'src/app/services/api.service';
import { API, REPLACEMENT } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalVariablesService } from '../../../services/variables/global-variables.service';

@Component({
  selector: 'app-identification-search',
  templateUrl: './identification-search.component.html',
  styleUrls: ['./identification-search.component.scss'],
})
export class IdentificationSearchComponent implements OnInit {
  @Output() textSearchChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() changeTab: EventEmitter<Option> = new EventEmitter<Option>();
  @Output() clickedBranch: EventEmitter<Branch> = new EventEmitter<Branch>();
  @Output() clickedTimeOption: EventEmitter<SettingOption> =
    new EventEmitter<SettingOption>();
  @Input() showEle: boolean;
  @Input() showModal: boolean;
  public typeList: Option[] = [
    { id: 0, code: '', name: 'Tất cả', val: '', groupid: null },
  ];
  public chosenTab: Option = {
    id: 0,
    code: '',
    name: 'Tất cả',
    val: '',
    groupid: null,
  };
  public branchList: Branch[] = [];
  public selectedBranch: Branch;
  public selectedTime: SettingOption;
  public timeOptions: SettingOption[] = [];
  public textSearch: string = '';
  public isShowBranchOption: boolean = false;
  public isShowTimeOption: boolean = false;
  public isFocus = false;
  public noText = REPLACEMENT.noText;
  public paramsSubscription: Subscription;
  public defaultTypeCode = null;
  public srcMinimizeImg: string = '';
  public imageUrl = [];
  public showImg: boolean = false;
  public fileToUpload: any;
  public srcImage: string;
  public chosenBranchid;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private globalService: GlobalVariablesService,
    private element: ElementRef
  ) {}

  ngOnInit(): void {
    this.getPersonType();
    this.getTimeOptions();
    this.getBranches();
    this.paramsSubscription = this.route.queryParams.subscribe((params) => {
      if (params.tab) {
        this.defaultTypeCode = params.tab;
        let openTab = this.typeList.find(
          (type) => type.code == this.defaultTypeCode
        );
        if (openTab) this.handleChangeTab(openTab);
      }
      if (params.branch) {
        this.chosenBranchid = params.branch;
      }
    });
  }

  public onDrag(ev: DragEvent) {
    // console.log(ev)
  }

  public getPersonType() {
    let typeInclude = [
      {
        model: 't_optiongroup',
        include: [],
      },
    ];
    let typeQuery = {
      op: 'AND',
      val: [
        {
          model: 't_optiongroup',
          col: 'code',
          op: '=',
          val: 'customertypeoptionid',
        },
      ],
    };
    this.apiService
      .get<Option[]>(API.options, {
        include: JSON.stringify(typeInclude),
        query: JSON.stringify(typeQuery),
        order: JSON.stringify([['id', 'ASC']]),
      })
      .subscribe((types) => {
        if (types) {
          types.map((type) => (type.val = JSON.parse(type.val)));
          this.typeList = [
            ...this.typeList,
            ...types.filter((type) => type.val.is_identify == true),
          ];
          if (this.defaultTypeCode) {
            let openTab = this.typeList.find(
              (type) => type.code == this.defaultTypeCode
            );
            if (openTab) this.handleChangeTab(openTab);
          } else this.handleChangeTab(this.typeList[0]);
        }
      });
  }

  handleChangeTab(newTab) {
    this.chosenTab = newTab;
    this.changeTab.emit(newTab);
  }

  public getBranches() {
    this.selectedBranch = this.globalService.globalBranch;
    this.apiService.get<Branch[]>(API.branches).subscribe((branches) => {
      if (branches) {
        this.formatBranchList(branches);
        if (!this.chosenBranchid) {
          if (this.selectedBranch === undefined) {
            this.handleClickedBranch(this.branchList[0]);
          } else {
            this.handleClickedBranch(this.selectedBranch);
          }
        } else {
          this.handleClickedBranch(
            branches.find((branch) => branch.id == this.chosenBranchid)
          );
        }
      }
    });
  }

  public formatBranchList(branches: Branch[]) {
    let tempList = [];
    branches.map((branch) => {
      branch.t_branches = this.getBranchChildren(branch, branches);
      if (!branch.parentid) {
        tempList.push(branch);
      }
    });
    this.branchList = [...this.branchList, ...tempList];
  }

  public getBranchChildren(parent: Branch, branchList: Branch[]) {
    let children = [];
    branchList.map((branch, idx) => {
      if (branch.parentid == parent.id) {
        children.push(branch);
        // branchList.splice(idx, 1);
      }
    });
    return children;
  }

  public getTimeOptions() {
    this.selectedTime = this.globalService.globalTime;
    this.timeOptions.length = 0;
    const optionInclude = [
      {
        model: 't_optiongroup',
        include: [],
      },
    ];
    const optionQuery = {
      op: 'AND',
      val: [
        {
          model: 't_optiongroup',
          col: 'code',
          op: '=',
          val: 'timeoptionid',
        },
      ],
    };
    this.apiService
      .get<Option[]>(API.options, {
        include: JSON.stringify(optionInclude),
        query: JSON.stringify(optionQuery),
        order: JSON.stringify([['id', 'ASC']]),
      })
      .subscribe((options) => {
        if (options) {
          options.map((option) => {
            let optionVal = JSON.parse(option.val);
            let timeOption: SettingOption = {
              name: option.name,
              value: optionVal.value,
              day: optionVal.day,
              week: optionVal.week,
              month: optionVal.month,
            };
            this.timeOptions.push(timeOption);
          });
          if (this.selectedTime === undefined) {
            this.handleSelectTime(this.timeOptions[1]);
          } else {
            this.handleSelectTime(this.selectedTime);
          }
        }
      });
  }

  public handleSelectTime(selectedOption: SettingOption) {
    this.selectedTime = selectedOption;
    this.clickedTimeOption.emit(selectedOption);
  }

  public handleClickedBranch(selectedBranch: Branch) {
    this.isShowBranchOption = false;
    this.selectedBranch = selectedBranch;
    this.clickedBranch.emit(selectedBranch);
  }

  public handleSelectList(type: string) {
    let selectList = ['isShowBranchOption', 'isShowTimeOption', 'isFocus'];
    for (let i = 0; i < selectList.length; i++) {
      if (selectList[i] == type) {
        this[type] = !this[type];
      } else {
        this[selectList[i]] = false;
      }
    }
  }

  public onFocusOutEvent() {
    this.isFocus = false;
  }

  public search() {
    this.textSearchChange.emit(this.textSearch);
  }

  public resetSearch() {
    this.textSearch = '';
    this.search();
  }

  showModalFunc(e) {
    this.showModal = this.showModal !== true;
  }

  public hideModalFunc(e) {
    if (e.target.classList.value === 'nav nav-pills p-3') {
      this.showModal = false;
    }
  }

  dropImage(e: any[]) {
    this.imageUrl = e;
    this.showImg = true;
    this.showModal = false;
  }

  closeImg(e, index) {
    this.imageUrl.map((item, i) => {
      if (index === i) {
        this.imageUrl.splice(i, 1);
      }
    });
    // this.showImg = false;
  }

  chooseFile(files) {
    this.imageUrl = [];
    const url = URL.createObjectURL(files[0]);
    const sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    const object = { file: files[0], url: sanitizedUrl };
    this.imageUrl.push(object);
    this.showImg = true;
    this.showModal = false;
  }

  findImage() {
    this.imageUrl = [];
    this.imageUrl.push(this.srcImage);
    this.showImg = true;
    this.showModal = false;
    this.srcImage = '';
  }

  public closePopup() {
    this.isShowBranchOption = false;
    this.isShowTimeOption = false;
  }
}
