<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 custom-col-1 column " >
    <div class="box">
      <div class="box-header row">
        <div class="title p-3 font-weight-bold">Khách hàng</div>
      </div>
      <div class="chart-container">
        <ngx-charts-gauge
          [scheme]="customerColorScheme"
          [results]="custnoData"
          [showAxis]="false"
          [max]="maxPoint"
          [tooltipDisabled]="true"
          [valueFormatting]="formatChartData"
        >
        </ngx-charts-gauge>
      </div>
      <div class="text-center">
        <div class="sub-title">{{branchStatistic.custno | number: '1.0'}} Khách</div>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 custom-col-2 column margin-bottom">
    <div class="box margin-left margin-right">
      <div class="box-header row">
        <div class="title p-3 font-weight-bold">Quan tâm</div>
      </div>
      <div class="chart-container">
        <ngx-charts-gauge
          [scheme]="interestingColorScheme"
          [results]="interestingData"
          [showAxis]="false"
          [max]="maxPoint"
          [tooltipDisabled]="true"
          [valueFormatting]="formatChartData"
        >
        </ngx-charts-gauge>
      </div>
      <div class="text-center">
        <div class="sub-title">{{branchStatistic.interestedcustno | number: '1.0'}} Khách</div>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 custom-col-3 column margin-bottom">
    <div class="box margin-right">
      <div class="box-header row">
        <div class="title p-3 font-weight-bold">Thời gian xem</div>
      </div>
      <div class="chart-container">
        <ngx-charts-gauge
          [scheme]="watchtimeColorScheme"
          [results]="watchtimeData"
          [showAxis]="false"
          [max]="maxPoint"
          [tooltipDisabled]="true"
          [valueFormatting]="formatChartData"
        >
        </ngx-charts-gauge>
      </div>
      <div class="text-center">
        <div class="sub-title">TB {{branchStatistic.sumwatchtime | number:'1.0-1'}}s</div>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 custom-col-4" style="padding: 0">
    <div class="box">
      <div class="box-header">
        <div class="title p-3 font-weight-bold">Quầy</div>
      </div>
      <div class="list-content-item pl-4" *ngFor="let stall of stallList;let i = index">
        <div class="list-content">
          <div class="d-flex flex-row">
            <div class="title font-weight-bold pr-4">{{i + 1}}</div>
            <div class="d-flex flex-column w-100">
              <div class="title" title="{{stall.name}}">{{stall.name}}</div>
              <div class="title grid-sub-title">
                <div class="primary-style full-width">
                  <span class="pr-1"><i class="fa fa-user"></i></span>
                  <span class="">{{stall.custno}}</span>
                </div>
                <div class="success-style full-width">
                  <span class="pr-1"><i class="fa fa-heart"></i></span>
                  <span class="">{{stall.interestedcustno}}</span>
                </div>
                <div class="danger-style full-width">
                  <span class="pr-1"><i class="fa fa-clock-o"></i></span>
                  <span>{{+stall.custno ? (+stall.sumwatchtime / +stall.custno | number: '1.0-1') : 0}}s</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
