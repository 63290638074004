import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
} from '@angular/core';
import { Branch } from 'src/app/models/branch';
import { Cameragroup } from 'src/app/models/cameragroup';
import { Ward } from 'src/app/models/ward';
import { ApiService } from 'src/app/services/api.service';
import { API, REPLACEMENT } from 'src/environments/environment';
import { GlobalVariablesService } from '../../../services/variables/global-variables.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  @Output() clickedWard: EventEmitter<Ward> = new EventEmitter<Ward>();
  @Output() clickedBranch: EventEmitter<Branch> = new EventEmitter<Branch>();
  @Output() clickedCameragroup: EventEmitter<Cameragroup> =
    new EventEmitter<Cameragroup>();
  public now;
  public interval;
  public wardList: Ward[] = [];
  public districtList: Ward[] = [];
  public provinceList: Ward[] = [
    {
      id: 0,
      code: '',
      name: 'Tất cả khu vực',
      info: 'Tất cả khu vực',
      lvl: 0,
      parentid: null,
    },
  ];
  public isShowProvince: boolean = false;
  private wardOrder = [['code', 'ASC']];
  public selectedWard: Ward;
  public isShowBranchOption: boolean = false;
  public branchList: any[] = [];
  public selectedBranch: Branch;
  public noText = REPLACEMENT.noText;
  public textSearch: string = '';
  public cameraGroupList: Cameragroup[] = [];
  public isShowCameraGroupOption: boolean = false;
  public selectedCameraGroup: Cameragroup;
  public testObj = {};

  constructor(
    private apiService: ApiService,
    private globalService: GlobalVariablesService,
    private element: ElementRef
  ) {}

  ngOnInit(): void {
    this.now = new Date();
    this.interval = setInterval(() => {
      this.now = new Date();
    }, 1000);
    this.cameraGroupList.push({
      id: 0,
      code: '',
      name: 'Tất cả khu vực',
      info: null,
      branchid: null,
      deactiveyn: 'F',
      kpi_custno: 0,
      kpi_sumwatchtime: 0,
      cameratypeoptionid: 0,
    });
    this.getBranches();
    // this.handleSelectWard(this.provinceList[0])
    // this.selectedBranch = this.branchList[0];
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  public getBranches() {
    this.selectedBranch = this.globalService.globalBranch;
    this.branchList.length = 0;
    let branchQuery = {
      op: 'AND',
      val: [
        {
          model: 't_branch',
          col: 'parentid',
          op: '!=',
          val: null,
        },
      ],
    };
    this.apiService
      .get<Branch[]>(API.branches, { query: JSON.stringify(branchQuery) })
      .subscribe((branches) => {
        if (branches) {
          // this.formatBranchList(branches);
          this.branchList = branches;

          if (this.selectedBranch === undefined) {
            this.handleSelectBranch(this.branchList[0]);
          } else {
            this.handleSelectBranch(this.selectedBranch);
          }
        }
      });
  }

  public formatBranchList(branches: Branch[]) {
    let tempList = [];
    branches.map((branch) => {
      branch.t_branches = this.getBranchChildren(branch, branches);
      if (!branch.parentid) {
        tempList.push(branch);
      }
    });
    this.branchList = [...this.branchList, ...tempList];
  }

  public getBranchChildren(parent: Branch, branchList: Branch[]) {
    let children = [];
    branchList.map((branch, idx) => {
      if (branch.parentid == parent.id) {
        children.push(branch);
        // branchList.splice(idx, 1);
      }
    });
    return children;
  }

  public getCameragroups(text: string) {
    this.cameraGroupList.length = 1;
    this.textSearch = text;
    let cameraGroupInclude = [
      {
        model: 't_branch',
        include: [],
      },
      {
        model: 't_camera',
        include: [],
      },
    ];
    let cameraGroupQuery: any = {
      op: 'AND',
      val: [
        {
          model: 't_cameragroup',
          col: 'name',
          op: 'ilike',
          val: '%' + this.textSearch + '%',
        },
      ],
    };
    if (this.selectedBranch && this.selectedBranch.id != 0) {
      cameraGroupQuery.val.push({
        model: 't_cameragroup',
        col: 'branchid',
        op: '=',
        val: this.selectedBranch.id,
      });
    }
    this.apiService
      .get<Cameragroup[]>(API.cameraGroup, {
        include: JSON.stringify(cameraGroupInclude),
        query: JSON.stringify(cameraGroupQuery),
      })
      .subscribe((cameraGroups) => {
        if (cameraGroups) {
          this.cameraGroupList = [...this.cameraGroupList, ...cameraGroups];
          this.handleSelectCameraGroup(this.cameraGroupList[0]);
        }
      });
  }

  public getWards(parentId) {
    this.wardList.length = 0;
    let queryWard = {
      op: 'AND',
      val: [
        {
          model: 't_ward',
          col: 'lvl',
          op: '=',
          val: 2,
        },
        {
          model: 't_ward',
          col: 'parentid',
          op: '=',
          val: parentId,
        },
      ],
    };
    this.apiService
      .get<Ward[]>(API.wards, {
        query: JSON.stringify(queryWard),
        order: JSON.stringify(this.wardOrder),
      })
      .subscribe((wards) => {
        if (wards) {
          this.wardList = wards;
        }
      });
  }

  public getDistricts(parentId) {
    this.districtList.length = 0;
    let queryDistrict = {
      op: 'AND',
      val: [
        {
          model: 't_ward',
          col: 'lvl',
          op: '=',
          val: 1,
        },
        {
          model: 't_ward',
          col: 'parentid',
          op: '=',
          val: parentId,
        },
      ],
    };
    this.apiService
      .get<Ward[]>(API.wards, {
        query: JSON.stringify(queryDistrict),
        order: JSON.stringify(this.wardOrder),
      })
      .subscribe((districts) => {
        if (districts) {
          this.districtList = districts;
        }
      });
  }

  public getProvinces() {
    this.provinceList.length = 1;
    let queryProvince = {
      op: 'AND',
      val: [
        {
          model: 't_ward',
          col: 'lvl',
          op: '=',
          val: 0,
        },
      ],
    };
    this.apiService
      .get<Ward[]>(API.wards, {
        query: JSON.stringify(queryProvince),
        order: JSON.stringify(this.wardOrder),
      })
      .subscribe((provinces) => {
        if (provinces) {
          this.provinceList = [...this.provinceList, ...provinces];
        }
      });
  }

  public handleSelectWard(selectedWard: Ward) {
    this.selectedWard = selectedWard;
    this.clickedWard.emit(selectedWard);
  }

  public handleSelectBranch(selectedBranch) {
    this.isShowBranchOption = false;
    // this.globalService.globalBranch = selectedBranch;
    this.selectedBranch = selectedBranch;
    this.clickedBranch.emit(selectedBranch);
    // this.resetSearch();
    this.getCameragroups('');
  }

  public handleSelectList(type: string) {
    let selectList = [
      'isShowBranchOption',
      'isShowProvince',
      'isShowCameraGroupOption',
    ];
    this[type] = !this[type];
    selectList.map((selection) => {
      if (selection != type) {
        this[selection] = false;
      }
    });
  }

  public handleSelectCameraGroup(selectedGroup: Cameragroup) {
    this.textSearch = selectedGroup.name;
    this.selectedCameraGroup = selectedGroup;
    this.clickedCameragroup.emit(selectedGroup);
    // if (selectedGroup.t_branch) {
    //   this.selectedBranch = selectedGroup.t_branch;
    //   this.clickedBranch.emit(selectedGroup.t_branch);
    // }
    this.isShowCameraGroupOption = false;
  }

  public resetSearch() {
    this.cameraGroupList.length = 0;
    this.textSearch = '';
    this.selectedCameraGroup = null;
    // this.isShowCameraGroupOption = false;
    // this.clickedCameragroup.emit({
    //   id: 0,
    //   branchid: null,
    //   cameratypeoptionid: null,
    //   code: null,
    //   deactiveyn: null,
    //   info: null,
    //   kpi_custno: 0,
    //   kpi_sumwatchtime: 0,
    //   name: null
    // });
  }

  public onFocusOutEvent() {
    this.isShowCameraGroupOption = false;
    if (this.selectedCameraGroup) {
      this.textSearch = this.selectedCameraGroup.name;
    }
  }
  // @HostListener('document:click',['$event'])
  // public clickOutside(e) {
  //   if(!this.element.nativeElement.contains(e.target)){
  //     this.closePopup();
  //   }
  // }
  // public closePopup() {
  //   this.isShowBranchOption = false;
  // }
  public closePopup() {
    this.isShowBranchOption = false;
    // this.isShowCameraGroupOption = false;
  }
}
