<div class="paging">
  <ul class="pager">
    <li [ngClass]="page == 1 ? 'disabled-button-pagination' : ''" (click)="changePage(1)">
      <a aria-label="go to first page">
        <i class="pagingIcon fa fa-angle-double-left"></i>
      </a>
    </li>
    <li [ngClass]="page == 1 ? 'disabled-button-pagination' : ''" (click)="changePage(page - 1)">
      <a aria-label="go to previous page">
        <i class="pagingIcon fa fa-angle-left"></i>
      </a>
    </li>
    <li *ngFor="let pager of pages" [ngClass]="pager == page ? 'active' : ''"
        (click)="changePage(pager)">
      <a role="button">{{pager}}</a></li>
    <li [ngClass]="page == totalPages ? 'disabled-button-pagination' : ''" (click)="changePage(page + 1)">
      <a aria-label="go to next page">
        <i class="pagingIcon fa fa-angle-right"></i>
      </a>
    </li>
    <li [ngClass]="page == totalPages ? 'disabled-button-pagination' : ''" (click)="changePage(totalPages)">
      <a aria-label="go to last page">
        <i class="pagingIcon fa fa-angle-double-right"></i>
      </a>
    </li>
  </ul>
</div>
