import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import * as moment from 'moment';
import { Branch } from 'src/app/models/branch';
import { Cameragroup } from 'src/app/models/cameragroup';
import { SettingOption } from 'src/app/models/setting-option';
import { ApiService } from 'src/app/services/api.service';
import { API, REPLACEMENT } from 'src/environments/environment';

@Component({
  selector: 'app-evaluate-customer-chart',
  templateUrl: './evaluate-customer-chart.component.html',
  styleUrls: ['./evaluate-customer-chart.component.scss'],
})
export class EvaluateCustomerChartComponent implements OnInit {
  @Input() selectedTimeOption: SettingOption;
  @Input() selectedBranch: Branch;
  @Input() selectedStall: Cameragroup;
  @Input() fromTime;
  @Input() toTime;
  public rawData = [];
  public selectedTab = 'day';
  public custnoData = [];
  public tabList = ['day', 'week', 'month'];
  public custnoColorScheme = {
    domain: ['#00dcff', '#FF00D5'],
  };
  public watchtimeColorScheme = {
    domain: ['#FF00D5'],
  };
  public custnoLabel = REPLACEMENT.custno;
  public interestedcustnoLabel = REPLACEMENT.interestedcustno;
  constructor(private apiService: ApiService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes['selectedBranch'] &&
      changes['selectedBranch'].currentValue &&
      changes['selectedBranch'].previousValue &&
      changes['selectedBranch'].currentValue.id !=
        changes['selectedBranch'].previousValue.id
    ) {
      if (this.selectedTimeOption != null) this.getData();
    }
    if (
      changes['selectedStall'] &&
      changes['selectedStall'].currentValue &&
      changes['selectedStall'].previousValue &&
      changes['selectedStall'].currentValue.id !=
        changes['selectedStall'].previousValue.id
    ) {
      if (this.selectedTimeOption != null) this.getData();
    }
    if (
      changes['selectedTimeOption'] &&
      changes['selectedTimeOption'].currentValue
    ) {
      if (this.selectedBranch != null) this.getData();
    }
  }

  public getData() {
    if (this.selectedTimeOption[this.selectedTab]) {
      let params = {
        ward: 0,
        branch: this.selectedBranch.id,
        cameragroup: 0,
        // timerange: +this.selectedTimeOption.value,
        from_time: this.fromTime,
        to_time: this.toTime,
      };
      if (this.selectedStall && this.selectedStall.id) {
        params.cameragroup = this.selectedStall.id;
      }
      if (this.selectedTimeOption[this.selectedTab]) {
        this.apiService
          .get<any>(
            API.view + this.selectedTimeOption[this.selectedTab].function,
            params
          )
          .subscribe((data) => {
            if (data) {
              this.rawData = data;
              this.custnoData = this.formatCustnoData();
            }
          });
      }
    } else {
      this.switchTab();
    }
  }

  public handleClickedTab(tab: string) {
    this.selectedTab = tab;
    if (this.selectedTimeOption[tab]) {
      this.getData();
    } else {
      this.switchTab();
    }
  }

  public switchTab() {
    let currentTabIdx = this.tabList.findIndex(
      (tab) => tab == this.selectedTab
    );
    if (currentTabIdx < this.tabList.length - 1) {
      this.handleClickedTab(this.tabList[currentTabIdx + 1]);
    } else {
      this.handleClickedTab(this.tabList[0]);
    }
  }

  public formatCustnoData() {
    let result = [
      {
        name: 'custno',
        series: [],
      },
      {
        name: 'interestedcustno',
        series: [],
      },
    ];
    let endVal;
    let startVal;
    switch (this.selectedTimeOption[this.selectedTab].dataBasedOn) {
      case 'hour':
        startVal = 0;
        endVal = this.rawData.length - 1;
        for (let i = 0; i <= endVal; i++) {
          let colName = startVal + i;
          let value = this.rawData.find(
            (row) =>
              row[
                this.selectedTimeOption[this.selectedTab].dataBasedOn
              ].substring(0, 2) == colName
          );
          result.map((line) => {
            line.series.push({
              name: colName + 'h',
              value: value && value[line.name] ? +value[line.name] : 0,
            });
          });
        }
        break;
      case 'day':
        startVal = moment(this.rawData[0].day, 'DD-MM');
        endVal = this.rawData.length - 1;
        for (let i = 0; i <= endVal; i++) {
          let colName = startVal.format('DD-MM');
          let value = this.rawData.find(
            (row) =>
              row[this.selectedTimeOption[this.selectedTab].dataBasedOn] ==
              colName
          );
          result.map((line) => {
            line.series.push({
              name: colName,
              value: value && value[line.name] ? +value[line.name] : 0,
            });
          });
          startVal.add(
            1,
            this.selectedTimeOption[this.selectedTab].dataBasedOn
          );
        }
        break;
      case 'month':
        startVal = moment().subtract(
          +this.selectedTimeOption.value + 1,
          'days'
        );
        endVal = moment().diff(startVal, 'months');
        for (let i = 0; i <= endVal; i++) {
          let colName = startVal.format('MM-yyyy');
          let value = this.rawData.find(
            (row) =>
              row[this.selectedTimeOption[this.selectedTab].dataBasedOn] ==
              colName
          );
          result.map((line) => {
            line.series.push({
              name: colName,
              value: value && value[line.name] ? +value[line.name] : 0,
            });
          });
          startVal.add(
            1,
            this.selectedTimeOption[this.selectedTab].dataBasedOn
          );
        }
        break;
      case 'week':
        startVal = 1;
        endVal = moment().isoWeeksInYear();
        for (let i = 0; i <= endVal; i++) {
          let colName = startVal + i;
          let value = this.rawData.find(
            (row) =>
              row[this.selectedTimeOption[this.selectedTab].dataBasedOn] ==
              colName
          );
          result.map((line) => {
            line.series.push({
              name: colName,
              value: value && value[line.name] ? +value[line.name] : 0,
            });
          });
        }
        break;
    }
    return result;
  }
}
