// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AuthConfig } from 'angular-oauth2-oidc';

export const environment = {
  production: false,
};

export const BASE_HOST = 'https://f4r.dientoan.vn/bireport/dataapi';
export const SOCKET_HOST = 'https://f4r.dientoan.vn';
export const DEVICE_IMG_HOST = 'https://f4r.dientoan.vn/core-images';
export const API_IMG_HOST = 'https://f4r.dientoan.vn';
// export const BASE_HOST = 'http://localhost:8095'; //dev
// export const SOCKET_HOST = 'http://localhost:8087';

export const API = {
  events: '/api/events',
  eventAttributes: '/api/eventAttributes',
  eventAttributeValues: '/api/eventAttributeValues',
  cameras: '/api/cameras',
  cameraGroup: '/api/cameraGroups',
  configs: '/api/configs',
  configGroups: '/api/configGroups',
  configGroupSets: '/api/configGroupSets',
  employees: '/api/employees',
  employeeTeam: '/api/employeeTeams',
  options: '/api/options',
  optionGroups: '/apioptionGroups',
  stalls: '/api/stalls',
  wards: '/api/wards',
  branches: '/api/branches',
  view: '/view/',
  socketPath: '/bireport/socket/socket.io',
  // socketPath: '/socket.io', //dev
  login: '/login',
  uploadEmployee: '/api/employees/upload',
  customers: '/api/customers',
  uploadCustomer: '/api/customers/upload',
  eventCustomers: '/api/eventCustomers',
  firebase: '/firebase/message',
  users: '/api/users',
  uploadUser: '/api/users/upload',
  notifications: '/api/notifications',
  callApi: '/callApi',
  aiEvents: '/ai/events',
  deleteCustomers: '/api/customers/deletePerson',
  deleteEmployees: '/api/employees/deletePerson',
  searchFaceuid: '/ai/faceuid',
  realtimeEvents: '/api/realtimeEvents',
  reports: '/api/reports',
  exportReport: '/report',
  AIConfigs: '/AIConfigs',
  exportExcel: '/export-excel',
  personImages: '/api/personImages',
  searchCustomerFaceuid: '/ai/customers/faceuid',
  uploadPersonImage: '/api/personImages/upload-image',
  searchAIItemid: '/ai/itemid',
  generateCode: '/api/customers/generate-code',
  deviceConfig: '/api/deviceConfig',
  loginOIDC: '/login/oidc',
  roles: '/api/roles',
  updateUserRole: '/api/roles/updateUserRole',
  rights: '/api/rights',
  updateRoleRight: '/api/roles/updateRoleRight',
};

export const REPLACEMENT = {
  devUsername: 'admin',
  devPwd: 'F4r@!2021',
  noStatistic: 0,
  noText: '',
  noData: 'Không có dữ liệu',
  deviceTimeout: 60, //seconds
  deviceTimeLimit: 65, //seconds
  normalZoom: 14,
  focusZoom: 15,
  noDeviceImg: 'assets/image/Capture.PNG',
  noPersonImg: 'assets/image/no-avatar.png',
  custno: 'Số đối tượng',
  interestedcustno: 'Số đối tượng danh sách đen',
  watchtime: 'Thời gian xuất hiện',
  frequentGuess: 'Đối tượng quay lại nhiều lần',
  maxNotification: 9,
  chartColumnWidth: 60,
  chartColumnPadding: 10,
  realtimeChartColumnInterval: 10, //seconds
  realtimeChartDefaultMaxY: 5,
  maxThresHold: 0.65,
  minThresHold: 0.2,
};

export const VIEW = {
  sumCustnoByHour: 'vw_custno_by_hour_branch',
  sumCustnoByDay: 'vw_custno_by_day_branch',
  sumCustnoByMonth: 'vw_custno_by_month_branch',
  sumCustnoByWeek: 'vw_custno_by_week_branch',
};

export const FUNC = {
  sumEventByHour: 'fn_sum_event_by_hour',
  getDailyStatistics: 'fn_get_daily_statistics',
  getEventDetails: 'fn_get_event_details',
  getEvents: 'fn_get_events',
  getEventsByBranch: 'fn_get_events_by_branch',
  getBranchStatistic: 'fn_get_branch_statistic',
  getBranchMonthlyStat: 'fn_get_branch_monthly_statistic',
  getStatByMonth: 'fn_get_custno_by_month',
  getBranchChildren: 'fn_get_branch_children',
  getEventDetailsWithWatchtime: 'fn_get_event_details_with_watchtime',
  reportEventAttrVal: 'fn_report_event_attr_val',
};

export const SOCKET_EVENT = {
  auth: 'auth',
  joinGroup: 'join_group',
  sendRealtimeData: 'send_realtime_data',
  getRealtimeData: 'get_realtime_data',
  getDeviceStatus: 'get_device_status',
  sendDeviceStatus: 'send_device_status',
  sendSocketObject: 'send_socket_object',
  sendNewEventCustomer: 'send_new_event_customer',
  sendRealtimeEvent: 'send_realtime_event',
  sendMonitorEvent: 'send_monitor_event',
  leaveGroup: 'leave_group',
  informConnection: 'inform_connection',
};

export const OIDC_CONFIG: AuthConfig = {
  issuer: 'https://id2.tris.vn',
  redirectUri: window.location.origin + '/login',
  clientId: 'TrisFace.SPA',
  responseType: 'code',
  scope: 'openid profile email roles TrisFace.API',
  showDebugInformation: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
