<div class="full-width full-height" style="overflow: auto; position: relative">
  <ngx-spinner
    bdColor="rgba(255,255,255,0.4)"
    size="medium"
    color="#000"
    type="ball-spin"
    [fullScreen]="false"
    [name]="spinnerName"
  ></ngx-spinner>
  <table class="table table-striped" style="margin: 0">
    <thead>
      <tr class="table-secondary">
        <th scope="col">
          <div class="sortable-col">
            <div class="col-label">Tên nhóm quyền</div>
          </div>
        </th>
        <th scope="col">
          <div class="sortable-col">
            <div class="col-label">Quyền thực thi</div>
          </div>
        </th>
        <th scope="col">
          <div class="sortable-col">
            <div class="col-label">Thao tác</div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of roleList">
        <td class="col-2">{{ row.name }}</td>
        <td class="col-9">
          <ng-container *ngIf="isEditing === row.id; else roleRightTemplate">
            <ng-multiselect-dropdown
              [placeholder]="'-'"
              [settings]="rightDropdownSettings"
              [data]="rightList"
              [(ngModel)]="selectedRights"
            ></ng-multiselect-dropdown>
          </ng-container>
          <ng-template #roleRightTemplate>
            <div style="padding: 6px 12px">
              <ng-container
                *ngIf="
                  row.t_right && row.t_right.length > 0;
                  else emptyRightTemplate
                "
              >
                <ng-container *ngFor="let right of row.t_right">
                  <span class="badge badge-primary text-uppercase mr-2">
                    {{ right.name }}
                  </span>
                </ng-container>
              </ng-container>
              <ng-template #emptyRightTemplate>
                <span>-</span>
              </ng-template>
            </div>
          </ng-template>
        </td>
        <td class="col-1">
          <ng-container *ngIf="isEditing === row.id; else editTemplate">
            <div class="d-flex">
              <button
                class="btn btn-sm btn-success"
                (click)="submitChange(row.id)"
              >
                <i class="fa fa-check"></i>
              </button>
              <button
                class="btn btn-sm btn-danger ml-2"
                (click)="toggleEditMode(row)"
              >
                <i class="fa fa-close"></i>
              </button>
            </div>
          </ng-container>
          <ng-template #editTemplate>
            <button
              class="btn btn-sm btn-primary"
              (click)="toggleEditMode(row)"
            >
              <i class="fa fa-edit"></i>
            </button>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
