<div
  class="full-height full-width mt-4"
  style="display: grid; grid-template-columns: 1.5fr 3fr"
>
  <div class="box p-3">
    <div class="box-header title font-weight-bold">Thống kê</div>
    <div class="box-body">
      <div
        class="background-gray-style mt-3"
        *ngIf="!chosenCameragroup || chosenCameragroup.id == 0"
        style="
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          text-align: center;
        "
      >
        <div class="d-flex flex-column p-3">
          <div class="sub-title" style="color: #00000099">Khu vực</div>
          <div class="title-group font-weight-bold l-title">
            {{
              statisticData && statisticData.stall_no
                ? statisticData.stall_no
                : 0
            }}
          </div>
        </div>
        <div class="d-flex flex-column p-3">
          <div class="sub-title" style="color: #00000099">Cán bộ</div>
          <div class="title-group font-weight-bold l-title">
            {{
              statisticData && statisticData.emp_no ? statisticData.emp_no : 0
            }}
          </div>
        </div>
        <div class="d-flex flex-column p-3">
          <div class="sub-title" style="color: #00000099">Thiết bị</div>
          <div class="title-group font-weight-bold l-title">
            {{
              statisticData && statisticData.camera_no
                ? statisticData.camera_no
                : 0
            }}
          </div>
        </div>
      </div>
      <div class="d-flex flex-column mt-4">
        <div style="display: grid; grid-template-columns: 1fr 2fr">
          <div>
            <div class="title" style="color: #00000099">Đối tượng</div>
            <div class="l-title font-weight-bold">
              {{
                statisticData && statisticData.custno ? statisticData.custno : 0
              }}
            </div>
          </div>
          <div class="chart-container">
            <ngx-charts-bar-vertical
              [scheme]="colorScheme"
              [results]="custnoChartData"
              [barPadding]="3"
              [roundEdges]="false"
              [noBarWhenZero]="true"
            >
            </ngx-charts-bar-vertical>
          </div>
        </div>
        <div class="mt-4" style="display: grid; grid-template-columns: 1fr 2fr">
          <div>
            <div class="title" style="color: #00000099">Lảng vảng</div>
            <span class="l-title font-weight-bold">{{
              statisticData && statisticData.interestedcustno
                ? statisticData.interestedcustno
                : 0
            }}</span>
            <span class="sub-title ml-2"
              >{{
                statisticData && statisticData.custno
                  ? ((statisticData.interestedcustno / statisticData.custno) *
                      100 | number: "1.0-1")
                  : 0
              }}%</span
            >
          </div>
          <div class="chart-container">
            <ngx-charts-bar-vertical
              [scheme]="colorScheme"
              [results]="interestChartData"
              [barPadding]="3"
              [roundEdges]="false"
              [noBarWhenZero]="true"
            >
            </ngx-charts-bar-vertical>
          </div>
        </div>
        <div class="mt-4" style="display: grid; grid-template-columns: 1fr 2fr">
          <div>
            <div class="title" style="color: #00000099">
              Thời gian xuất hiện
            </div>
            <div class="l-title font-weight-bold">
              {{
                statisticData && statisticData.custno > 0
                  ? (statisticData.sumwatchtime / statisticData.custno
                    | number: "1.0-0")
                  : 0
              }}s
            </div>
          </div>
          <div class="chart-container">
            <ngx-charts-bar-vertical
              [scheme]="colorScheme"
              [results]="watchtimeChartData"
              [barPadding]="3"
              [roundEdges]="false"
              [noBarWhenZero]="true"
            >
            </ngx-charts-bar-vertical>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-grow-1 ml-3">
    <app-map
      [height]="'15vh'"
      [minimize]="true"
      [selectedWard]="chosenWard"
      [selectedBranch]="chosenBranch"
      [selectedCameragroup]="chosenCameragroup"
    ></app-map>
  </div>
</div>
