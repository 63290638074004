import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariablesService {
  public globalBranch: any;
  public globalTime: any;
  public globalDataList = [];

  constructor() {
  }

  public getDataBranch() {

  }
}
