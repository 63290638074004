<div
  class="box full-width"
  style="display: grid; grid-template-columns: 2fr 2fr 2fr"
>
  <!-- <div
    class="position-relative"
    style="display: grid; grid-template-columns: 1fr 8fr 1fr"
    [ngClass]="isShowCameraGroupOption ? 'background-shadow' : ''"
  >
    <div class="size-icon" style="margin: auto">
      <i class="fa fa-search"></i>
    </div>
    <div class="">
      <div class="parent full-height full-width">
        <input
          class="line-height-50 full-width"
          style="outline: none; border: none; color: black"
          type="text"
          placeholder="Tìm kiếm"
          [ngModel]="textSearch"
          (ngModelChange)="getCameragroups($event)"
          (focus)="handleSelectList('isShowCameraGroupOption')"
          (focusout)="onFocusOutEvent()"
        />
      </div>
    </div>
    <ul class="top-level-menu" [hidden]="!isShowCameraGroupOption">
      <li class="handle title-menu" *ngFor="let cameraGroup of cameraGroupList">
        <a (mousedown)="handleSelectCameraGroup(cameraGroup)">{{
          cameraGroup.name
        }}</a>
      </li>
    </ul>
    <div
      class="handle line-height-50"
      (click)="resetSearch()"
      [hidden]="!selectedCameraGroup"
    >
      <i class="fa fa-sm fa-close"></i>
    </div>
    <div class="borderLeft"></div>
  </div> -->
  <!-- <div class="d-flex flex-row"> -->
  <!-- Filter by branch -->
  <div class="handle position-relative w-100">
    <div
      class="parent w-100"
      [ngClass]="isShowBranchOption ? 'background-shadow' : ''"
      (click)="handleSelectList('isShowBranchOption')"
    >
      <div class="line-height-50" style="display: flex">
        <div class="showing-select font-bold">
          {{ selectedBranch ? selectedBranch.name : noText }}
        </div>
        <div class="ml-3">
          <i
            class="fa"
            [ngClass]="{
              'fa-caret-down': !isShowBranchOption,
              'fa-caret-up': isShowBranchOption
            }"
          ></i>
        </div>
      </div>
    </div>
    <app-dropdown-list
      [dataList]="branchList"
      [saveBranch]="true"
      [isShow]="isShowBranchOption"
      [selectedBranch]="selectedBranch"
      (clickedOutside)="closePopup()"
      (handleSelect)="handleSelectBranch($event)"
    ></app-dropdown-list>
  </div>
  <div class="handle position-relative w-100">
    <div
      class="parent w-100"
      [ngClass]="isShowCameraGroupOption ? 'background-shadow' : ''"
      (click)="handleSelectList('isShowCameraGroupOption')"
    >
      <div class="line-height-50" style="display: flex">
        <div class="showing-select font-bold">
          {{ selectedCameraGroup ? selectedCameraGroup.name : noText }}
        </div>
        <div class="ml-3">
          <i
            class="fa"
            [ngClass]="{
              'fa-caret-down': !isShowCameraGroupOption,
              'fa-caret-up': isShowCameraGroupOption
            }"
          ></i>
        </div>
      </div>
    </div>
    <app-dropdown-list
      [dataList]="cameraGroupList"
      [isShow]="isShowCameraGroupOption"
      [selectedBranch]="selectedCameraGroup"
      (clickedOutside)="closePopup()"
      (handleSelect)="handleSelectCameraGroup($event)"
    ></app-dropdown-list>
  </div>

  <!--  Filter by ward, may need later, dont delete-->
  <!--    <div class="handle position-relative" (click)="getProvinces(); handleSelectList('isShowProvince')">-->
  <!--      <div class="parent">-->
  <!--        <span>{{selectedWard.name}}</span>-->
  <!--        <span class="ml-3"><i class="fa"-->
  <!--                              [ngClass]="{'fa-caret-down': !isShowProvince, 'fa-caret-up': isShowProvince}"></i></span>-->
  <!--      </div>-->
  <!--      <ul class="top-level-menu" [hidden]="!isShowProvince">-->
  <!--        <li class="title-menu disabled">Tỉnh/Thành phố</li>-->
  <!--        <li class="title-menu" *ngFor="let province of provinceList">-->
  <!--          <a class="" (mouseenter)="getDistricts(province.id)"-->
  <!--             (click)="handleSelectWard(province)">{{province.name}}</a>-->
  <!--          <ul class="second-level-menu" *ngIf="districtList && districtList.length > 0">-->
  <!--            <li class="title-menu disabled">Quận/Huyện</li>-->
  <!--            <li class="title-menu" *ngFor="let district of districtList">-->
  <!--              <a (mouseenter)="getWards(district.id)" (click)="handleSelectWard(district)">{{district.name}}</a>-->
  <!--              <ul class="third-level-menu" *ngIf="wardList && wardList.length > 0">-->
  <!--                <li class="title-menu disabled">Phường/Xã</li>-->
  <!--                <li class="title-menu" *ngFor="let ward of wardList">-->
  <!--                  <a (click)="handleSelectWard(ward)">{{ward.name}}</a>-->
  <!--                </li>-->
  <!--              </ul>-->
  <!--            </li>-->
  <!--          </ul>-->
  <!--        </li>-->
  <!--      </ul>-->
  <!--    </div>-->
  <!-- <div class="ml-5">
      <span>Hôm nay</span>
      <span class="ml-3"><i class="fa fa-caret-down"></i></span>
    </div> -->
  <!-- </div> -->
  <div class="mt-3 mr-4 font-bold" style="justify-self: end">
    {{ now | date: "HH:mm:ss, d 'tháng' MM, 'năm' yyyy" }}
  </div>
</div>
