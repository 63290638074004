import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Branch } from 'src/app/models/branch';
import { Cameragroup } from 'src/app/models/cameragroup';
import { SettingOption } from 'src/app/models/setting-option';
import { Option } from 'src/app/models/option';
import { ApiService } from 'src/app/services/api.service';
import { API, REPLACEMENT } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { GlobalVariablesService } from '../../../services/variables/global-variables.service';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-area-search',
  templateUrl: './area-search.component.html',
  styleUrls: ['./area-search.component.scss'],
})
export class AreaSearchComponent implements OnInit {
  @Output() clickedBranch: EventEmitter<Branch> = new EventEmitter<Branch>();
  @Output() clickedStall: EventEmitter<Cameragroup> =
    new EventEmitter<Cameragroup>();
  @Output() clickedTimeOption: EventEmitter<SettingOption> =
    new EventEmitter<SettingOption>();
  @Output() clickedTimeTab: EventEmitter<string> = new EventEmitter<string>();
  public branchList: Branch[] = [];
  public stallList: Cameragroup[] = [
    {
      id: 0,
      code: '',
      name: 'Tất cả khu vực',
      info: null,
      branchid: null,
      deactiveyn: 'F',
      kpi_custno: 0,
      kpi_sumwatchtime: 0,
      cameratypeoptionid: 0,
    },
  ];
  public cameraGroupList: Cameragroup[] = [];
  public chosenBranch: Branch;
  public chosenStall: Cameragroup;
  public chosenCameraGroup: Cameragroup;
  public timeOptions: SettingOption[] = [];
  public chosenTime: SettingOption;
  public isShowBranchOption: boolean = false;
  public isShowCameraGroupOption: boolean = false;
  public isShowTimeOption: boolean = false;
  public noText = REPLACEMENT.noText;
  public chosenTab;
  public tabList = ['day', 'week', 'month'];
  public textSearch: string = '';
  private branchParam = 0;
  @Input() showFromToDate: boolean = false;
  @Input() fromDateLabel = 'Từ';
  @Input() toDateLabel = 'Đến';
  public locale = 'vi';
  @Output() changedFromDate: EventEmitter<string> = new EventEmitter<string>();
  @Output() changedToDate: EventEmitter<string> = new EventEmitter<string>();
  public dateFormat = 'dd-mm-yyyy';
  public datePickerOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: this.dateFormat,
    disableSince: {
      year: moment().year(),
      month: moment().month() + 1,
      day: moment().date() + 1,
    },
    // other options...
  };
  public fromDate: IMyDateModel;
  public toDate: IMyDateModel;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private globalService: GlobalVariablesService,
    private element: ElementRef
  ) {}

  ngOnInit(): void {
    let routeBranchParam = this.route.snapshot.queryParamMap.get('branch');
    if (routeBranchParam) {
      this.branchParam = Number(routeBranchParam);
    }
    this.getTimeOptions();
    this.fromDate = { isRange: false, singleDate: { jsDate: new Date() } };
    this.toDate = { isRange: false, singleDate: { jsDate: new Date() } };
    // this.handleClickedTab('day');
  }

  public getBranches() {
    this.chosenBranch = this.globalService.globalBranch;
    let branchQuery = {
      op: 'AND',
      val: [
        {
          model: 't_branch',
          col: 'parentid',
          op: '!=',
          val: null,
        },
      ],
    };
    this.apiService
      .get<Branch[]>(API.branches, { query: JSON.stringify(branchQuery) })
      .subscribe((branches) => {
        if (branches) {
          // this.formatBranchList(branches);
          this.branchList = branches;
          let selectedBranch = branches.find(
            (branch) => branch.id == this.branchParam
          );
          if (selectedBranch) {
            this.handleClickedBranch(selectedBranch);
          } else {
            if (this.chosenBranch === undefined) {
              this.handleClickedBranch(this.branchList[0]);
            } else {
              this.handleClickedBranch(this.chosenBranch);
            }
          }
        }
      });
  }

  public formatBranchList(branches: Branch[]) {
    let tempList = [];
    branches.map((branch) => {
      branch.t_branches = this.getBranchChildren(branch, branches);
      if (!branch.parentid) {
        tempList.push(branch);
      }
    });
    this.branchList = [...this.branchList, ...tempList];
  }

  public getBranchChildren(parent: Branch, branchList: Branch[]) {
    let children = [];
    branchList.map((branch, idx) => {
      if (branch.parentid == parent.id) {
        children.push(branch);
        // branchList.splice(idx, 1);
      }
    });
    return children;
  }

  public getStall() {
    this.stallList.length = 1;
    let stallQuery = {
      op: 'AND',
      val: [
        {
          model: 't_cameragroup',
          col: 'branchid',
          op: '=',
          val: this.chosenBranch.id,
        },
        {
          model: 't_cameragroup',
          col: 'name',
          op: 'ilike',
          val: '%' + this.textSearch + '%',
        },
      ],
    };
    this.apiService
      .get<Cameragroup[]>(API.cameraGroup, {
        query: JSON.stringify(stallQuery),
      })
      .subscribe((stalls) => {
        if (stalls) {
          this.stallList = [...this.stallList, ...stalls];
          this.handleClickedStall(this.stallList[0]);
        }
      });
  }

  public getCameragroups(text: string) {
    this.textSearch = text;
    let cameraGroupInclude = [
      {
        model: 't_branch',
        include: [],
      },
    ];
    let cameraGroupQuery = {
      op: 'AND',
      val: [
        {
          model: 't_cameragroup',
          col: 'branchid',
          op: '=',
          val: this.chosenBranch.id,
        },
        {
          model: 't_cameragroup',
          col: 'name',
          op: 'ilike',
          val: '%' + this.textSearch + '%',
        },
      ],
    };
    this.apiService
      .get<Cameragroup[]>(API.cameraGroup, {
        include: JSON.stringify(cameraGroupInclude),
        query: JSON.stringify(cameraGroupQuery),
      })
      .subscribe((cameraGroups) => {
        if (cameraGroups) {
          this.cameraGroupList = cameraGroups;
        }
      });
  }

  public handleClickedBranch(selectedBranch: Branch) {
    this.isShowBranchOption = false;
    this.chosenBranch = selectedBranch;
    this.clickedBranch.emit(selectedBranch);
    this.getStall();
  }

  public handleClickedStall(selectedStall: Cameragroup) {
    this.isShowCameraGroupOption = false;
    this.chosenStall = selectedStall;
    this.clickedStall.emit(selectedStall);
  }

  public handleSelectCameraGroup(selectedGroup: Cameragroup) {
    this.textSearch = selectedGroup.name;
    this.chosenCameraGroup = selectedGroup;
    this.clickedStall.emit(selectedGroup);
    this.chosenBranch = selectedGroup.t_branch;
    this.clickedBranch.emit(selectedGroup.t_branch);
  }

  public handleSelectList(type: string) {
    let selectList = [
      'isShowBranchOption',
      'isShowCameraGroupOption',
      'isShowTimeOption',
    ];
    this[type] = !this[type];
    selectList.map((selection) => {
      if (selection != type) {
        this[selection] = false;
      }
    });
  }

  public getTimeOptions() {
    this.chosenTime = this.globalService.globalTime;
    this.timeOptions.length = 0;
    const optionInclude = [
      {
        model: 't_optiongroup',
        include: [],
      },
    ];
    const optionQuery = {
      op: 'AND',
      val: [
        {
          model: 't_optiongroup',
          col: 'code',
          op: '=',
          val: 'timeoptionid',
        },
      ],
    };
    this.apiService
      .get<Option[]>(API.options, {
        include: JSON.stringify(optionInclude),
        query: JSON.stringify(optionQuery),
        order: JSON.stringify([['id', 'ASC']]),
      })
      .subscribe((options) => {
        if (options) {
          options.map((option) => {
            let optionVal = JSON.parse(option.val);
            let timeOption: SettingOption = {
              name: option.name,
              value: optionVal.value,
              day: optionVal.day,
              week: optionVal.week,
              month: optionVal.month,
            };
            this.timeOptions.push(timeOption);
          });
          if (this.chosenTime === undefined) {
            this.handleSelectTime(this.timeOptions[0]);
          } else {
            this.handleSelectTime(this.chosenTime);
          }
        }
        this.getBranches();
      });
  }

  public handleSelectTime(selectedOption: SettingOption) {
    this.chosenTime = selectedOption;
    if (!this.chosenTime[this.chosenTab]) {
      this.switchTab();
    }
    this.clickedTimeOption.emit(selectedOption);
  }

  public handleClickedTab(tab: string) {
    this.chosenTab = tab;
    if (this.chosenTime[tab]) {
      this.clickedTimeTab.emit(tab);
    } else {
      this.switchTab();
    }
  }

  public switchTab() {
    let currentTabIdx = this.tabList.findIndex((tab) => tab == this.chosenTab);
    if (currentTabIdx < this.tabList.length - 1) {
      this.handleClickedTab(this.tabList[currentTabIdx + 1]);
    } else {
      this.handleClickedTab(this.tabList[0]);
    }
  }

  public resetSearch() {
    this.cameraGroupList.length = 0;
    this.textSearch = '';
    this.chosenCameraGroup = null;
    this.isShowCameraGroupOption = false;
    this.clickedStall.emit({
      id: 0,
      branchid: null,
      cameratypeoptionid: null,
      code: null,
      deactiveyn: null,
      info: null,
      kpi_custno: 0,
      kpi_sumwatchtime: 0,
      name: null,
    });
  }

  public onFocusOutEvent() {
    this.isShowCameraGroupOption = false;
    if (this.chosenCameraGroup) {
      this.textSearch = this.chosenCameraGroup.name;
    }
  }

  public closePopup() {
    this.isShowBranchOption = false;
    this.isShowTimeOption = false;
  }

  public handleFromDateChange(newDate) {
    this.changedFromDate.emit(newDate.singleDate.formatted);
    if (
      moment(this.toDate.singleDate.jsDate).diff(
        moment(newDate.singleDate.formatted, 'DD-MM-YYYY'),
        'day'
      ) > 0
    ) {
      this.handleSelectTime(
        this.timeOptions.find((option) => option.value == '7')
      );
    } else {
      this.handleSelectTime(
        this.timeOptions.find((option) => option.value == '1')
      );
    }
  }

  public handleToDateChange(newDate) {
    this.changedToDate.emit(newDate.singleDate.formatted);
    if (
      moment(newDate.singleDate.formatted, 'DD-MM-YYYY').diff(
        moment(this.fromDate.singleDate.jsDate),
        'day'
      ) > 0
    ) {
      this.handleSelectTime(
        this.timeOptions.find((option) => option.value == '7')
      );
    } else {
      this.handleSelectTime(
        this.timeOptions.find((option) => option.value == '1')
      );
    }
  }
}
