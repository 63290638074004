import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ElementHelper } from 'protractor';

@Component({
  selector: 'app-manage-layout',
  templateUrl: './manage-layout.component.html',
  styleUrls: ['./manage-layout.component.scss'],
})
export class ManageLayoutComponent implements OnInit {
  public showFirstTab: string = null;
  public isShowSideBar: any = false;
  public isShowSideBarOpt: string = 'emp';
  public sideBarName = 'Quản lí';
  public sideBarOption = [
    { title: 'Cán bộ', value: 'emp', active: false },
    { title: 'Đối tượng giám sát', value: 'customer', active: false },
    { title: 'Người dùng', value: 'user', active: false },
    { title: 'Cơ quan', value: 'branch', active: false },
    { title: 'Báo cáo', value: 'report', active: false },
    { title: 'Quyền', value: 'role', active: false },
  ];
  constructor(private route: ActivatedRoute, private element: ElementRef) {}

  ngOnInit(): void {
    let tab = this.route.snapshot.fragment;
    if (tab) {
      this.showFirstTab = tab;
    }
  }

  public handleShowSideBar() {
    this.isShowSideBar = this.isShowSideBar !== true;
  }

  public handleChangeNavSideBar(tabName) {
    this.isShowSideBarOpt = tabName;
  }
  @HostListener('document:click', ['$event'])
  public clickOutside(e) {
    if (this.element.nativeElement.contains(e.target)) {
    }
  }
}
