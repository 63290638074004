import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { API, FUNC, REPLACEMENT, VIEW } from 'src/environments/environment';
import * as moment from 'moment';
import { Ward } from 'src/app/models/ward';
import { SettingOption } from 'src/app/models/setting-option';
import { Branch } from 'src/app/models/branch';
import { Cameragroup } from 'src/app/models/cameragroup';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-realtime-report-object',
  templateUrl: './realtime-report-object.component.html',
  styleUrls: ['./realtime-report-object.component.scss'],
})
export class RealtimeReportObjectComponent implements OnInit, OnChanges {
  @Input() selectedWard: Ward;
  @Input() selectedTime: SettingOption;
  @Input() selectedBranch: Branch;
  @Input() selectedCameraGroup: Cameragroup;
  public dateFormat = 'YYYY-MM-DD HH:mm:ss[.]SSS';
  @Input() fromTime = moment().startOf('day').format(this.dateFormat);
  @Input() toTime = moment().endOf('day').format(this.dateFormat);
  public selectedTab = 'day';
  public chartData;
  public statisticData = {
    custno: 0,
    interest: 0,
    watchtime: 0,
    interestedwatchtime: 0,
  };
  public colorScheme = {
    domain: ['#B2F4FF', '#11deff'],
  };
  public tabList = ['day', 'week', 'month'];
  public noStatistic = REPLACEMENT.noStatistic;
  public xAxisUnit = '';
  public custnoLabel = REPLACEMENT.custno;
  public interestedcustnoLabel = REPLACEMENT.interestedcustno;
  public defaultBranch: number;

  constructor(
    private authService: AuthService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    let userInfo = this.authService.getUserInfo();
    this.defaultBranch = userInfo.branchid ? userInfo.branchid : 0;
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    // if (changes['selectedWard'] && changes['selectedWard'].currentValue && this.selectedTime && changes['selectedWard'].previousValue && changes['selectedWard'].previousValue.id != changes['selectedWard'].currentValue.id) {
    //   this.getChartData();
    // }
    if (changes['selectedTime'] && changes['selectedTime'].currentValue) {
      if (!changes['selectedTime'].isFirstChange())
        // if (!changes['selectedTime'].previousValue || changes['selectedTime'].previousValue.name != changes['selectedTime'].currentValue.name) {
        this.getChartData();
      // }
    }
    if (
      changes['selectedBranch'] &&
      changes['selectedBranch'].currentValue &&
      this.selectedTime
    ) {
      this.getChartData();
    }
    if (
      changes['fromTime'] &&
      changes['fromTime'].currentValue &&
      this.selectedBranch
    ) {
      this.getChartData();
    }
    if (
      changes['toTime'] &&
      changes['toTime'].currentValue &&
      this.selectedBranch
    ) {
      this.getChartData();
    }
    if (
      changes['selectedCameraGroup'] &&
      changes['selectedCameraGroup'].currentValue &&
      this.selectedTime
    ) {
      this.getChartData();
    }
  }

  public handleSelectTab(tab: string) {
    this.selectedTab = tab;
    switch (tab) {
      case 'day':
        this.xAxisUnit = 'Ngày';
        break;
      case 'month':
        this.xAxisUnit = 'Tháng';
        break;
      case 'week':
        this.xAxisUnit = 'Tuần';
        break;
    }
    this.getChartData();
  }

  public getChartData() {
    this.chartData = [];
    if (this.selectedTime[this.selectedTab]) {
      let params = {
        ward: this.selectedWard ? this.selectedWard.id : 0,
        branch: this.selectedBranch
          ? this.selectedBranch.id
          : this.defaultBranch,
        cameragroup: this.selectedCameraGroup ? this.selectedCameraGroup.id : 0,
        // timerange: this.selectedTime.value
        from_time: this.fromTime,
        to_time: this.toTime,
      };
      // let apiFunction =
      //   moment(this.toTime).diff(moment(this.fromTime), 'day') > 0
      //     ? FUNC.getCustnoByDay
      //     : FUNC.getCustnoByHour;
      this.apiService
        .get<any>(
          API.view + this.selectedTime[this.selectedTab].function,
          params
        )
        .subscribe((data) => {
          if (data) {
            this.chartData = this.formatChartData(
              data,
              this.selectedTime,
              this.selectedTab
            );
            this.statisticData = this.formatStatisticData(data);
          }
        });
      // this.apiService
      //   .get<any>(API.view + apiFunction, params)
      //   .subscribe((data) => {
      //     if (data) {
      //       this.chartData = this.formatChartData(
      //         data,
      //         this.selectedTime,
      //         this.selectedTab
      //       );
      //       this.statisticData = this.formatStatisticData(data);
      //     }
      //   });
    } else {
      this.switchTab();
    }
  }

  public formatChartData(data, type: SettingOption, tab: string) {
    let result = [];
    let endVal;
    let startVal;
    switch (type[tab].dataBasedOn) {
      case 'hour':
        this.xAxisUnit = 'Giờ';
        startVal = 0;
        endVal = data.length - 1;
        for (let i = 0; i <= endVal; i++) {
          let colName = startVal + i;
          let value = data.find(
            (row) =>
              row[type[tab].dataBasedOn] &&
              row[type[tab].dataBasedOn].substring(0, 2) == colName
          );
          let column = {
            name: colName,
            series: [
              {
                name: 'interestedcustno',
                value:
                  value && value.interestedcustno ? +value.interestedcustno : 0,
              },
              {
                name: 'custno',
                value:
                  value && value.custno
                    ? +value.custno - +value.interestedcustno
                    : 0,
              },
            ],
          };
          result.push(column);
        }
        break;
      case 'day':
        this.xAxisUnit = 'Ngày';
        startVal = moment(data[0].day, 'DD-MM');
        endVal = data.length - 1;
        for (let i = 0; i <= endVal; i++) {
          let colName = startVal.format('DD-MM');
          let value = data.find((row) => row[type[tab].dataBasedOn] == colName);
          let column = {
            name: colName,
            series: [
              {
                name: 'interestedcustno',
                value:
                  value && value.interestedcustno ? +value.interestedcustno : 0,
              },
              {
                name: 'custno',
                value:
                  value && value.custno
                    ? +value.custno - +value.interestedcustno
                    : 0,
              },
            ],
          };
          result.push(column);
          startVal.add(1, type[tab].dataBasedOn);
        }
        break;
      case 'month':
        this.xAxisUnit = 'Tháng';
        startVal = moment().subtract(+type.value + 1, 'days');
        endVal = moment().diff(startVal, 'months');
        for (let i = 0; i <= endVal; i++) {
          let colName = startVal.format('MM-yyyy');
          let value = data.find((row) => row[type[tab].dataBasedOn] == colName);
          let column = {
            name: colName,
            series: [
              {
                name: 'interestedcustno',
                value:
                  value && value.interestedcustno ? +value.interestedcustno : 0,
              },
              {
                name: 'custno',
                value:
                  value && value.custno
                    ? +value.custno - +value.interestedcustno
                    : 0,
              },
            ],
          };
          result.push(column);
          startVal.add(1, type[tab].dataBasedOn);
        }
        break;
      case 'week':
        this.xAxisUnit = 'Tuần';
        startVal = 1;
        endVal = moment().isoWeeksInYear();
        for (let i = 0; i <= endVal; i++) {
          let colName = startVal + i;
          let value = data.find((row) => row[type[tab].dataBasedOn] == colName);
          let column = {
            name: colName,
            series: [
              {
                name: 'interestedcustno',
                value:
                  value && value.interestedcustno ? +value.interestedcustno : 0,
              },
              {
                name: 'custno',
                value:
                  value && value.custno
                    ? +value.custno - +value.interestedcustno
                    : 0,
              },
            ],
          };
          result.push(column);
        }
        break;
    }
    return result;
  }

  public formatStatisticData(dataArr) {
    let result = {
      custno: 0,
      interest: 0,
      watchtime: 0,
      interestedwatchtime: 0,
    };
    if (dataArr && dataArr.length > 0) {
      dataArr.map((data) => {
        result.custno += data.custno ? +data.custno : 0;
        result.interest += data.interestedcustno ? +data.interestedcustno : 0;
        result.watchtime += data.sumwatchtime ? +data.sumwatchtime : 0;
        result.interestedwatchtime += data.interestedwatchtime
          ? +data.interestedwatchtime
          : 0;
      });
    }
    return result;
  }

  public switchTab() {
    let currentTabIdx = this.tabList.findIndex(
      (tab) => tab == this.selectedTab
    );
    if (currentTabIdx < this.tabList.length - 1) {
      this.handleSelectTab(this.tabList[currentTabIdx + 1]);
    } else {
      this.handleSelectTab(this.tabList[0]);
    }
  }

  public getColumnData(columnName: string) {
    if (!columnName) columnName = '0';
    let data = this.chartData.find(
      (column) => column.name == columnName
    ).series;
    return data;
  }
}
