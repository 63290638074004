import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Branch } from 'src/app/models/branch';
import { ApiService } from 'src/app/services/api.service';
import { API, FUNC, REPLACEMENT, VIEW } from 'src/environments/environment';

@Component({
  selector: 'app-evaluate-kpi',
  templateUrl: './evaluate-kpi.component.html',
  styleUrls: ['./evaluate-kpi.component.scss']
})
export class EvaluateKPIComponent implements OnInit, OnChanges {

  @Input() selectedBranch: Branch;
  public branchKPI = {custno: 0, interestedcustno: 0, sumwatchtime: 0};
  public branchStatistic = {custno: 0, interestedcustno: 0, sumwatchtime: 0};
  public noStatistic = REPLACEMENT.noStatistic;
  public custnoData;
  public interestingData;
  public watchtimeData;
  public compareChartData = {custno: 0, interestedcustno: 0, sumwatchtime: 0};
  public chartView = [200, 100]
  public custnoColorScheme = {
    domain: ['#00dcff']
  };
  public interestingColorScheme = {
    domain: ['#00BE5F']
  };
  public watchtimeColorScheme = {
    domain: ['#FF00D5']
  };
  public maxPoint = 100;
  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange}) {
    if (changes['selectedBranch'] && changes['selectedBranch'].currentValue) {
      this.getBranchStatistic();
      this.getBranchMonthlyStat();
    }
  }

  public getBranchStatistic() {
    this.maxPoint = 0;
    let kpi = {custno: 0, interestedcustno: 0, sumwatchtime: 0};
    let statistic = {custno: 0, interestedcustno: 0, sumwatchtime: 0};
    this.branchKPI = kpi;
    this.branchStatistic = statistic;
    this.compareChartData = {custno: 0, interestedcustno: 0, sumwatchtime: 0};
    this.apiService.get<any>(API.view + FUNC.getBranchStatistic, {branch: this.selectedBranch.id}).subscribe(data => {
      if (data) {
        data.map(stall => {
          kpi.custno += stall.kpi_custno ? +stall.kpi_custno : 0;
          kpi.interestedcustno += stall.kpi_interestedcustno ? +stall.kpi_interestedcustno : 0;
          kpi.sumwatchtime += (stall.kpi_custno && stall.kpi_sumwatchtime) ? (+stall.kpi_sumwatchtime/+stall.kpi_custno) : 0;
          statistic.custno += stall.custno ? +stall.custno : 0;
          statistic.interestedcustno += stall.interestedcustno ? +stall.interestedcustno : 0;
          statistic.sumwatchtime += (+stall.custno && stall.sumwatchtime) ? (+stall.sumwatchtime/+stall.custno) : 0;
        })
        this.branchKPI = kpi;
        this.branchStatistic = statistic;
        this.compareChartData = {
          custno: this.branchKPI.custno ? (this.branchStatistic.custno/this.branchKPI.custno) * 100 : 0,
          interestedcustno: this.branchKPI.interestedcustno ? (this.branchStatistic.interestedcustno/this.branchKPI.interestedcustno) * 100 : 0,
          sumwatchtime: this.branchKPI.sumwatchtime ? (this.branchStatistic.sumwatchtime/this.branchKPI.sumwatchtime) * 100 : 0,
        }
      }
      this.maxPoint = 100;
    })
  }

  public getBranchMonthlyStat() {
    this.apiService.get<any[]>(API.view + FUNC.getBranchMonthlyStat, {branch: this.selectedBranch.id}).subscribe(data => {
      if (data && data.length > 0) {
        this.custnoData = this.formatMonthlyData(data, 'custno');
        this.interestingData = this.formatMonthlyData(data, 'interestedcustno');
        this.watchtimeData = this.formatMonthlyData(data, 'sumwatchtime');
      }
      else {
        this.custnoData = [];
        this.interestingData = [];
        this.watchtimeData = [];
      }
    })
  }

  public formatMonthlyData(data: any[], type: string) {
    let result = [{name: type, series: []}];
    for (let i = 0; i < data.length; i++) {
      let colName = data[i].day;
      let colValue = data[i];
      if (type == 'sumwatchtime') {
        result[0].series.push({
          name: colName,
          value: (colValue && +colValue.custno) ? (+colValue.sumwatchtime/+colValue.custno) : 0
        })
      }
      else {
        result[0].series.push({
          name: colName,
          value: (colValue && colValue[type]) ? +colValue[type] : 0
        })
      }
    }
    return result;
  }

  public formatValue(value) {
    return (Math.round((+value + Number.EPSILON) * 10) / 10) + '%';
  }
}
