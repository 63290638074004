<div
  class="mt-2 p-2 full-height d-flex flex-column"
  style="background-color: #ffffff; height: 90vh"
>
  <div class="p-1">
    <span class="title-header">Camera</span><br />
    <span>{{ activeCameras }}/{{ totalCameras }} CAM HOẠT ĐỘNG</span>
    <div class="input-group mt-3">
      <input
        type="text"
        class="form-control"
        placeholder="Tìm kiếm"
        [(ngModel)]="textSearch"
        (key.enter)="getCameras()"
      />
      <div
        class="input-group-append"
        style="cursor: pointer"
        (click)="getCameras()"
      >
        <span class="input-group-text"><i class="fa fa-search"></i></span>
      </div>
    </div>
  </div>
  <div class="flex-grow-1 p-1" style="overflow: auto">
    <ng-container *ngIf="cameraGroups.length == 0">{{ noData }}</ng-container>
    <div
      *ngFor="let cameraGroup of cameraGroups"
      class="p-1 mt-1"
      [ngClass]="{ 'expanded-group': cameraGroup.isExpand }"
    >
      <div class="monitor-items" (click)="toggleCameraGroup(cameraGroup)">
        <i
          class="fa mr-1"
          [ngClass]="{
            'fa-plus': !cameraGroup.isExpand,
            'fa-minus': cameraGroup.isExpand
          }"
        ></i>
        <span class="flex-grow-1">{{ cameraGroup.name }}</span>
        <span style="justify-self: end"
          >{{ cameraGroup.cameras.length }} CAM</span
        >
      </div>
      <div
        style="background-color: #ffffff"
        [ngClass]="{
          'collapsed-list': !cameraGroup.isExpand,
          'expanding-list': cameraGroup.isExpand
        }"
      >
        <div
          class="monitor-item p-1"
          *ngFor="let camera of cameraGroup.cameras"
          [ngClass]="{ 'selected-item': selectedCamera.id == camera.id }"
          (click)="onClickCamera(camera)"
        >
          <div style="overflow-x: hidden">
            <span>{{ camera.name }}</span>
          </div>
          <div style="justify-self: end">
            <i class="fa fa-caret-right primary-style"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
