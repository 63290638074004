import { Component, Input, OnInit } from '@angular/core';
import { Branch } from 'src/app/models/branch';
import { Cameragroup } from 'src/app/models/cameragroup';
import { SettingOption } from 'src/app/models/setting-option';
import * as moment from 'moment';

@Component({
  selector: 'app-evaluate',
  templateUrl: './evaluate.component.html',
  styleUrls: ['./evaluate.component.scss'],
})
export class EvaluateComponent implements OnInit {
  @Input() active: string;
  public chosenBranch: Branch;
  public chosenTimeOption: SettingOption;
  public chosenStall: Cameragroup;
  public chosenTab: string = 'day';
  public eventData;
  public tabList = ['day', 'week', 'month'];
  public dateFormat = 'YYYY-MM-DD HH:mm:ss[.]SSS';
  public fromTime = moment().startOf('day').format(this.dateFormat);
  public toTime = moment().endOf('day').format(this.dateFormat);
  constructor() {}

  ngOnInit(): void {}

  public handleClickedBranch(branch: Branch) {
    this.chosenBranch = branch;
  }

  public handleClickedTimeOption(timeOption: SettingOption) {
    this.chosenTimeOption = timeOption;
  }

  public handleClickedStall(stall: Cameragroup) {
    this.chosenStall = stall;
  }

  public handleFromDate(date) {
    this.fromTime = moment(date, 'DD-MM-YYYY')
      .startOf('day')
      .format(this.dateFormat);
    if (
      moment(this.toTime, this.dateFormat).diff(
        moment(this.fromTime, this.dateFormat),
        'day'
      ) > 0
    ) {
      this.chosenTimeOption = {
        day: { dataBasedOn: 'day', function: 'fn_get_custno_by_day' },
        month: undefined,
        name: '7 ngày trước',
        value: '7',
        week: undefined,
      };
    } else {
      this.chosenTimeOption = {
        day: {
          dataBasedOn: 'hour',
          function: 'fn_get_custno_by_hour',
        },
        month: undefined,
        name: 'Hôm nay',
        value: '1',
        week: undefined,
      };
    }
  }
  public handleToDate(date) {
    this.toTime = moment(date, 'DD-MM-YYYY')
      .endOf('day')
      .format(this.dateFormat);
    if (
      moment(this.toTime, this.dateFormat).diff(
        moment(this.fromTime, this.dateFormat),
        'day'
      ) > 0
    ) {
      this.chosenTimeOption = {
        day: { dataBasedOn: 'day', function: 'fn_get_custno_by_day' },
        month: undefined,
        name: '7 ngày trước',
        value: '7',
        week: undefined,
      };
    } else {
      this.chosenTimeOption = {
        day: {
          dataBasedOn: 'hour',
          function: 'fn_get_custno_by_hour',
        },
        month: undefined,
        name: 'Hôm nay',
        value: '1',
        week: undefined,
      };
    }
  }
}
