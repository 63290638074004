import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import { EventCustomer as any } from 'src/app/models/event-customer';
import { PagedItem } from 'src/app/models/paged-item';
import { ApiService } from 'src/app/services/api.service';
import {
  API,
  DEVICE_IMG_HOST,
  REPLACEMENT,
} from 'src/environments/environment';
import { Browser } from 'leaflet';
import win = Browser.win;
import { NavigationEnd, Router } from '@angular/router';

declare var $;

@Component({
  selector: 'app-identification-info',
  templateUrl: './identification-info.component.html',
  styleUrls: ['./identification-info.component.scss'],
})
export class IdentificationInfoComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @HostListener('window:scroll', ['$event'])
  @Output()
  close = new EventEmitter<boolean>();
  @Output() showPersonForm = new EventEmitter<any>();
  @Output() sendTopPage = new EventEmitter<any>();
  @Input() eventInfo: any;
  public deviceHost = DEVICE_IMG_HOST;
  public customerHistory: any[] = [];
  public noText = REPLACEMENT.noText;
  @ViewChild('topPage') topPage: ElementRef;
  public count: number = 0;
  public showBackToTop = false;

  constructor(private apiService: ApiService, private router: Router) {}

  ngOnInit(): void {
    this.scrollTopPage();
  }

  ngAfterViewInit() {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes['eventInfo'] && changes['eventInfo'].currentValue) {
      this.getCustomerHistory();
      this.scrollTopPage();
    }
  }

  public handleClose() {
    this.close.emit();
  }

  public getCustomerHistory() {
    let historyInclude = [
      {
        model: 't_cameragroup',
        include: [
          {
            model: 't_branch',
            include: [
              {
                model: 't_branch',
                include: [],
              },
            ],
          },
        ],
      },
    ];
    let historyQuery = {
      op: 'AND',
      val: [
        {
          model: 't_event_customer',
          col: 'faceuid',
          op: '=',
          val: this.eventInfo.faceuid,
        },
        {
          model: 't_cameragroup.t_branch.t_branch',
          col: 'code',
          op: '=',
          val: this.eventInfo.note.company_code,
        },
      ],
    };
    this.apiService
      .get<PagedItem<any>>(API.eventCustomers, {
        include: JSON.stringify(historyInclude),
        query: JSON.stringify(historyQuery),
        page: 1,
        itemsPerPage: 10,
        order: JSON.stringify([['createddate', 'DESC']]),
      })
      .subscribe((history) => {
        if (history) {
          this.customerHistory = history.rows;
        }
      });
  }

  public handleShowPersonForm() {
    this.showPersonForm.emit({
      forcedAvatar: this.deviceHost + this.eventInfo.url,
      faceuid: this.eventInfo.faceuid,
    });
  }

  changeImg(e, top) {
    top.src = this.deviceHost + e.url;
    this.scrollTopPage();
  }

  scrollTopPage() {
    if (this.topPage !== undefined) {
      this.topPage.nativeElement.scrollTop = 0;
      this.showBackToTop = false;
    }
  }

  backToTop() {
    if (
      this.topPage !== undefined &&
      this.topPage.nativeElement.scrollTop >= 1
    ) {
      this.showBackToTop = true;
    } else {
      this.showBackToTop = false;
    }
  }
}
