<div class="list">
    <div class="list-header">
      <div class="pt-4 title-header">{{name}}</div>
    </div>
    <div class="list-body p-0">
      <div class="list-content" *ngFor="let item of sideBarItem">
        <div class="list-content-item p-0" (click)="activateClass(item)" [ngClass]="{'active-analysis': item.active}">
          <div class="d-flex flex-row p-3 handle" routerLink="{{item.value}}">
            <div class="square-shape mr-3 ml-3"></div>
            <div class="title">{{item.title}}</div>
            <span *ngIf="item.active === true" class="primary-style position-absolute-right"><i
              class="fa fa-caret-right"></i></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  