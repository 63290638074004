<div class="d-flex flex-column full-width full-height" *ngIf="isShow">
  <div class="row">
    <app-person-search
      class="full-width"
      [personType]="manageType"
      (changeTab)="handleChangeTab($event)"
      (startSearch)="searchPerson($event)"
      (clickedBranch)="filterBranch($event)"
    ></app-person-search>
  </div>
  <div class="pt-3 pb-3">
    <button class="btn btn-primary" (click)="showEmptyForm()">Thêm</button>
  </div>
  <div
    class="d-flex flex-column flex-grow-1 slim-scroll hidden-scrollbar"
    style="height: 75vh"
  >
    <div class="grid-repeat-5 mt-1 h-auto">
      <div
        style="padding: 0 !important"
        class="box d-flex flex-column handle"
        *ngFor="let person of personList"
        (click)="showPersonDetail(person.id)"
        [ngClass]="{ inactive: person.isactive == false }"
      >
        <img
          style="margin: 0 !important; height: 80%"
          #img
          class=""
          style="
            max-width: 200px;
            object-fit: contain;
            margin: auto;
            max-height: 300px;
            width: 100%;
          "
          [src]="apiImgHost + person.img"
          onerror="this.src='assets/image/no-avatar.png'"
          alt=""
        />
        <div class="pt-3 pl-3">
          <div class="title font-weight-bold">{{ person.name }}</div>
          <div class="sub-title sub-style">
            {{ person.type ? person.type.name : noText }}
          </div>
          <!-- <div class="sub-title pb-3">{{person.code}}</div> -->
          <div class="title">
            {{ person.t_branch ? person.t_branch.name : noText }}
          </div>
          <div class="sub-title sub-style">
            {{ person.t_empteam ? person.t_empteam.name : noText }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-pagination
    [page]="currentPage"
    [itemsPerPage]="personsPerPage"
    [total]="noPerson"
    (clickedPage)="changePage($event)"
  ></app-pagination>
  <app-person-detail
    [personType]="manageType"
    [isAddNew]="isAddForm"
    [personId]="selectedPersonId"
    [isShow]="showDetail"
    (closeForm)="hideDetailForm($event)"
    (updatedDetail)="getPerson()"
  ></app-person-detail>
</div>
