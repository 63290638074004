<div class="list">
  <div class="list-header">
    <div class="pt-4 title-header">Phân tích</div>
  </div>
  <div class="list-body p-0">
    <div class="list-content" *ngFor="let item of sideBarAnalysis">
      <div class="list-content-item p-0" (click)="activateClass(item)" [ngClass]="{'active-analysis': item.active}" [routerLink]="['/analysis']" [fragment]="item.title" routerLinkActive="router-link-active" >
        <div class="d-flex flex-row p-3 handle">
          <div class="square-shape mr-3 ml-3"></div>
          <div class="title" style="line-height: 30px">{{item.title}}</div>
          <span *ngIf="item.active === true" class="primary-style position-absolute-right" style="line-height: 32px;"><i
            class="fa fa-caret-right"></i></span>
        </div>
      </div>
    </div>
  </div>
</div>
