import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import io from 'socket.io-client';
import { API, SOCKET_EVENT, SOCKET_HOST } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket;
  public newEventStatObserver = new Subject<any>();
  public newDeviceStatusObserver = new Subject<any>();
  public newEventObserver = new Subject<any>();
  public newPeerMsgObserver = new Subject<any>();
  public newRealtimeEventObserver = new Subject<any>();
  public newMonitorObserver = new Subject<any>();
  constructor() {
    this.socket = io(SOCKET_HOST, { path: API.socketPath });
    this.socket.on('connect', () => {
      // this.socket.emit("SOCKET_EVENT.auth", {token: notifyToken});
      console.log('Connected to socket!');
      this.socket.emit(SOCKET_EVENT.joinGroup, 'bireport');
      this.socket.on(SOCKET_EVENT.sendRealtimeData, (data) => {
        this.newEventStatObserver.next(data);
      });
      this.socket.on(SOCKET_EVENT.sendDeviceStatus, (data) => {
        this.newDeviceStatusObserver.next(data);
      });
      this.socket.on(SOCKET_EVENT.sendNewEventCustomer, (data) => {
        this.newEventObserver.next(data);
      });
      this.socket.on(SOCKET_EVENT.sendRealtimeEvent, (data) => {
        this.newRealtimeEventObserver.next(data);
      });
      this.socket.on(SOCKET_EVENT.sendMonitorEvent, (timestamp, imgBytes) => {
        this.newMonitorObserver.next({ timestamp, imgBytes });
      });
      this.socket.on(SOCKET_EVENT.informConnection, (msg) => {
        // console.log('socket message: ', msg);
      });
    });
  }

  public onNewEventStat() {
    return this.newEventStatObserver.asObservable();
  }

  public onNewDeviceStatus() {
    return this.newDeviceStatusObserver.asObservable();
  }

  public onNewEvent() {
    return this.newEventObserver.asObservable();
  }

  public getEventStat(groupid: string) {
    this.socket.emit(SOCKET_EVENT.getRealtimeData, groupid);
  }

  public getDeviceStatus(deviceid: string) {
    this.socket.emit(SOCKET_EVENT.getDeviceStatus, deviceid);
  }

  public onNewRealtimeEvent() {
    return this.newRealtimeEventObserver.asObservable();
  }

  public getMonitorEvent(cameraCode: string) {
    this.socket.emit(SOCKET_EVENT.joinGroup, `monitor/${cameraCode}`);
    return this.newMonitorObserver.asObservable();
  }

  public closeMonitor(cameraCode: string) {
    this.socket.emit(SOCKET_EVENT.leaveGroup, `monitor/${cameraCode}`);
  }
}
