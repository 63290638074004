import { Injectable } from '@angular/core';
import { SocketService } from './socket.service';
import { ApiService } from './api.service';
import { API } from 'src/environments/environment';
import { Camera } from '../models/camera';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebrtcService {
  private configuration = {
    iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
  };
  public peerConnection: RTCPeerConnection;
  private firebaseToken;
  public deviceInfo;
  public icecandidate;
  public trackObserverable = new Subject<any>();
  constructor(
    private socketService: SocketService,
    private apiService: ApiService
  ) {}

  public async start(device) {
    this.peerConnection = new RTCPeerConnection(this.configuration);

    // Listen for local ICE candidates on the local RTCPeerConnection
    this.peerConnection.addEventListener('icecandidate', (event) => {
      if (event.candidate) {
        this.icecandidate = event.candidate;
        this.sendFirebaseMsg(
          this.deviceInfo.firebase,
          JSON.stringify({ candidate: JSON.stringify(event.candidate) })
        );
      }
    });
    // Listen for connectionstatechange on the local RTCPeerConnection
    this.peerConnection.addEventListener('connectionstatechange', (event) => {
      // if (this.peerConnection.connectionState === 'connected') {
      // Peers connected!
      // }
    });

    // Listen for track on the local RTCPeerConnection to get video stream
    this.peerConnection.addEventListener('track', (event) => {
      this.trackObserverable.next(event.streams[0]);
    });

    //call API to get device firebase id and answer
    this.getDeviceInfo(device);

    //set offer as local
    this.setRemoteOffer();

    //create answer
    const answer = await this.peerConnection.createAnswer();
    this.peerConnection.setLocalDescription(answer);

    //send offer through firebase to device by device's firebase id
    this.sendFirebaseMsg(
      this.deviceInfo.firebase,
      JSON.stringify({ answer: JSON.stringify(answer) })
    );
  }

  public getDeviceInfo(device) {
    this.deviceInfo = JSON.parse(device.info);
  }

  public sendFirebaseMsg(deviceToken, msg) {
    this.apiService
      .post(API.firebase + '/' + deviceToken, msg)
      .subscribe((result) => {
        if (result) {
        }
      });
  }

  public async setRemoteOffer() {
    const remoteDesc = new RTCSessionDescription(this.deviceInfo.offer);
    await this.peerConnection.setRemoteDescription(remoteDesc);
  }

  public async addIceCandidate(icecandidate) {
    this.peerConnection.addIceCandidate(icecandidate);
  }

  public onTrackChange() {
    return this.trackObserverable.asObservable();
  }
}
