import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { SocketService } from 'src/app/services/socket.service';
import {
  API,
  DEVICE_IMG_HOST,
  REPLACEMENT,
} from 'src/environments/environment';
import { Option } from 'src/app/models/option';

@Component({
  selector: 'app-monitor-events',
  templateUrl: './monitor-events.component.html',
  styleUrls: ['./monitor-events.component.scss'],

  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('.8s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ],
})
export class MonitorEventsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedMonitor: any;
  public events: any[];
  public limit = 20;
  public page = 1;
  public imgUri = DEVICE_IMG_HOST;
  public noData = REPLACEMENT.noData;
  public eventSubscription: Subscription;
  public customerTypeList: Option[];

  constructor(
    private apiService: ApiService,
    private socketService: SocketService
  ) {}

  ngOnInit(): void {
    let currentEvent = null;
    this.eventSubscription = this.socketService
      .onNewEvent()
      .subscribe((data) => {
        if (data.cameracode == this.selectedMonitor.code) {
          if (!currentEvent || (currentEvent && currentEvent.id !== data.id)) {
            currentEvent = data;
            let content = JSON.parse(data.note);
            const customerType = this.customerTypeList.find(
              (type) => type.code === content.person_type
            );
            const newEvent = {
              url: content.url,
              t_customer: {
                name: content.person.name,
              },
              t_option: {
                code: content.person_type,
                name: customerType ? customerType.name : '',
              },
              createddate: content.created_timestamp,
            };
            this.events = [newEvent, ...this.events];
            if (this.events.length > this.limit) {
              this.events.pop();
            }
          }
        }
      });
    this.getCustomerTypes();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes['selectedMonitor'] && this.selectedMonitor) {
      this.getEvents();
    }
  }

  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
      this.eventSubscription = null;
    }
  }

  public getEvents() {
    const eventInclude = [
      {
        model: 't_customer',
        include: [{ model: 't_option', as: 'cust_gender', include: [] }],
      },
      { model: 't_option', include: [] },
    ];
    const eventQuery = {
      op: 'AND',
      val: [
        {
          model: 't_event_customer',
          col: 'cameracode',
          op: '=',
          val: this.selectedMonitor.code,
        },
        {
          model: 't_option',
          col: 'code',
          op: 'not',
          val: 'FREQ',
        },
      ],
    };
    this.apiService
      .get<any>(API.eventCustomers, {
        include: JSON.stringify(eventInclude),
        query: JSON.stringify(eventQuery),
        itemsPerPage: this.limit,
        page: this.page,
      })
      .subscribe((events) => {
        this.events = events.rows;
      });
  }

  public getCustomerTypes() {
    let typeInclude = [
      {
        model: 't_optiongroup',
        include: [],
      },
    ];
    let typeQuery = {
      op: 'AND',
      val: [
        {
          model: 't_optiongroup',
          col: 'code',
          op: '=',
          val: 'customertypeoptionid',
        },
      ],
    };
    this.apiService
      .get<Option[]>(API.options, {
        include: JSON.stringify(typeInclude),
        query: JSON.stringify(typeQuery),
      })
      .subscribe((types) => {
        if (types) {
          types.map((type) => (type.val = JSON.parse(type.val)));
          this.customerTypeList = types.filter(
            (type) => type.val.is_identify == true
          );
        }
      });
  }
}
