<div class="box p-3 mt-4 full-width">
  <div class="box-header title font-weight-bold">Sự kiện</div>
  <div class="box-body mt-4">
    <table class="table table-striped">
      <thead>
        <tr class="table-secondary">
          <th scope="col">
            <div class="sortable-col">
              <div class="col-label">Thời gian</div>
              <div class="sort-icons-container">
                <i
                  class="fa fa-lg fa-caret-up handle"
                  (click)="sortEventTable('time', 'ASC')"
                ></i>
                <i
                  class="fa fa-lg fa-caret-down handle"
                  (click)="sortEventTable('time', 'DESC')"
                ></i>
              </div>
            </div>
          </th>
          <th scope="col">
            <div class="sortable-col">
              <div class="col-label">Cơ quan</div>
              <div class="sort-icons-container">
                <i
                  class="fa fa-lg fa-caret-up handle"
                  (click)="sortEventTable('place', 'ASC')"
                ></i>
                <i
                  class="fa fa-lg fa-caret-down handle"
                  (click)="sortEventTable('place', 'DESC')"
                ></i>
              </div>
            </div>
          </th>
          <th scope="col">
            <div class="sortable-col">
              <div class="col-label">Khu vực</div>
              <div class="sort-icons-container">
                <i
                  class="fa fa-lg fa-caret-up handle"
                  (click)="sortEventTable('stall', 'ASC')"
                ></i>
                <i
                  class="fa fa-lg fa-caret-down handle"
                  (click)="sortEventTable('stall', 'DESC')"
                ></i>
              </div>
            </div>
          </th>
          <th scope="col">
            <div class="sortable-col">
              <div class="col-label">Đối tượng</div>
              <div class="sort-icons-container">
                <i
                  class="fa fa-lg fa-caret-up handle"
                  (click)="sortEventTable('custno', 'ASC')"
                ></i>
                <i
                  class="fa fa-lg fa-caret-down handle"
                  (click)="sortEventTable('custno', 'DESC')"
                ></i>
              </div>
            </div>
          </th>
          <th scope="col">
            <div class="sortable-col">
              <div class="col-label">Lảng vảng</div>
              <div class="sort-icons-container">
                <i
                  class="fa fa-lg fa-caret-up handle"
                  (click)="sortEventTable('interest', 'ASC')"
                ></i>
                <i
                  class="fa fa-lg fa-caret-down handle"
                  (click)="sortEventTable('interest', 'DESC')"
                ></i>
              </div>
            </div>
          </th>
          <th scope="col">Thời gian xem TB(giây)</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let event of eventShowList">
          <td class="multi-data-cell">
            <div>{{ event.hourtime | formatDate }}</div>
            <div class="sub-data">{{ event.daytime | date: "dd/MM/yyyy" }}</div>
          </td>
          <td>{{ event.cameragroupid != null ? event.branch : noText }}</td>
          <td>
            {{ event.cameragroupid != null ? event.cameragroup : noText }}
          </td>
          <td>{{ event.custno ? event.custno : noStatistic }}</td>
          <td class="multi-data-cell">
            <div>
              {{
                event.interestedcustno ? event.interestedcustno : noStatistic
              }}
            </div>
            <div class="sub-data">
              {{
                event.custno
                  ? ((event.interestedcustno / event.custno) * 100
                    | number: "1.0-1")
                  : noStatistic
              }}%
            </div>
          </td>
          <td>
            {{
              event.sumwatchtime
                ? (event.sumwatchtime / event.custno | number: "1.0-0")
                : noStatistic
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <app-pagination
    [page]="currentPage"
    [itemsPerPage]="eventsPerPage"
    [total]="noEvent"
    (clickedPage)="changePage($event)"
  ></app-pagination>
</div>
