<div
  class="slim-scroll hidden-scrollbar"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()"
  [scrollWindow]="false"
>
  <div class="row" style="position: sticky; top: 0">
    <app-evaluate-search
      class="full-width"
      [allowAll]="false"
      (clickedShowModal)="handleClickedShowModal($event)"
      (clickedBranch)="handleClickedBranch($event)"
      (clickedTimeOption)="handleClickedTimeOption($event)"
      (clickedStall)="handleClickedStall($event)"
      (selectedImg)="handleSelectImg($event)"
      (clickedGender)="handleClickedGender($event)"
      (clickedAge)="handleClickedAge($event)"
      (changedSlider)="handleChangedThreshold($event)"
      [showSlider]="true"
      [showAgeSelection]="true"
      [showGenderSelection]="true"
      [showEle]="showElement"
      [showPopup]="showModalVar"
      [hiddenSearchBar]="true"
      [showFromDate]="true"
      [showToDate]="true"
      (changedFromDate)="handleFromDate($event)"
      (changedToDate)="handleToDate($event)"
      (changeCustomer)="handleSearchCustomer($event)"
    ></app-evaluate-search>
    <span
      style="
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #f0f2f5;
      "
    >
      Tổng số khuôn mặt tìm được:
      <span style="font-weight: bold">{{ totalEvent }}</span> khuôn mặt
    </span>
  </div>
  <div class="d-flex flex-column transition" style="flex: 1 1 auto; height: 0">
    <div
      style="
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 2rem;
      "
    >
      <div
        class="box d-flex flex-column"
        style="padding: 0 !important; border-radius: 10px"
        *ngFor="let event of listEvent"
        [ngClass]="{
          'new-event':
            latestAnalyzeHour &&
            event.hour >= latestAnalyzeHour &&
            event.daytime == today
        }"
      >
        <div
          class="d-flex"
          style="
            background-color: #000000;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            height: 144px;
            text-align: center;
            justify-content: center;
          "
        >
          <img
            #img
            draggable="true"
            class="event-img handle"
            (drag)="drag()"
            (dragend)="dragLeaveEle(); dragLeaveModal()"
            src="{{ deviceHost + event.url }}"
            alt=""
            (error)="onImgError($event)"
            (click)="handleClickedShowModal(img)"
          />
        </div>
        <div class="mt-2 mb-2" style="padding-left: 12px; padding-right: 12px">
          <div class="pb-2">
            <div
              *ngIf="!userInfo.branchid"
              class="sub-title handle sub-style"
              (click)="handleClickFaceuid(event.faceuids)"
            >
              <span># {{ event.itemid }}_{{ event.age }}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="title">
                <b>{{ event.camera.name }}</b>
              </div>
              <a [href]="'map?camera=' + event.device + '&image=' + event.url">
                <button type="button" class="btn btn-sm btn-light">
                  <i class="fa fa-map-marker"></i>
                </button>
              </a>
            </div>
            <div class="sub-title sub-style" style="margin-top: 8px">
              {{ event.timestamp | date: "HH:mm:ss, dd-MM-yyyy" }}
            </div>
            <div
              class="sub-title"
              style="display: flex; gap: 8px; flex-wrap: wrap"
            >
              <div class="event-tag" style="width: 24px; height: 24px">
                <i class="fa fa-xs fa-mars" *ngIf="event.gender == 'M'"></i>
                <i class="fa fa-xs fa-venus" *ngIf="event.gender == 'F'"></i>
              </div>
              <div
                class="event-tag"
                style="width: 24px; height: 24px"
                *ngIf="event.ismasked == true || event.ismasked == 'true'"
              >
                <img
                  style="max-height: 100%; max-width: 100%; object-fit: contain"
                  src="assets/image/mask.png"
                />
              </div>
              <div class="pl-2 pr-2 event-tag">
                <i class="fa fa-xs fa-eye pr-1"></i>
                <span>{{ event.duration }}s</span>
              </div>
              <div class="pl-2 pr-2 event-tag" style="height: 24px">
                <span>{{ event.ageRange ? event.ageRange : event.age }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div>
            <div class="sub-title" style="display: grid; grid-template-columns: 1fr 5fr; align-items: baseline;">
              <i class="fa fa-xs fa-hourglass pr-2"></i>
              <div class="d-flex" style="justify-content: space-between;">
                <span>Thời gian quan sát:</span>
                <span>{{event.duration}}s</span>
              </div>
            </div>
          </div>
          <div class="sub-title" style="display: grid; grid-template-columns: 1fr 5fr">
            <span></span>{{event.created_timestamp | date: 'dd-MM-yyyy HH:mm:ss'}}</div>
        </div>
      </div> -->
      <ngx-spinner
        bdColor="rgba(255,255,255,0.4)"
        size="medium"
        color="#000"
        type="ball-spin"
        [fullScreen]="false"
        [name]="spinnerName"
      >
      </ngx-spinner>
    </div>
    <div *ngIf="listEvent.length == 0" class="title" style="text-align: center">
      Không có dữ liệu
    </div>
    <div *ngIf="showModal === true" id="modal-container">
      <div class="modal-background">
        <div class="modal w-auto">
          <img class="h-100 w-auto" src="{{ zoomImage }}" alt="" />
          <!--          <span class="close-modal" (click)="closeModal()"><i class="fa fa-close"></i></span>-->
        </div>
      </div>
    </div>
    <div *ngIf="showFaceuids === true" id="modal-container">
      <div class="modal-background">
        <div
          class="modal w-auto p-1"
          style="height: 80% !important; overflow: auto"
        >
          <!-- <div>{{clickedFaceuids}}</div> -->
          <!-- <div class="row">
            <div class="col-6">Faceuid</div>
            <div class="col-6">Dist</div>
          </div>
          <div class="row" *ngFor="let object of clickedFaceuids">
            <div class="col-6">{{object.face_uid}}</div>
            <div class="col-6">{{object.dist}}</div>
          </div> -->
          <table class="table table-striped">
            <thead>
              <tr class="table-secondary">
                <th scope="col">Faceuid</th>
                <th scope="col">Dist</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let object of clickedFaceuids">
                <td>{{ object.face_uid }}</td>
                <td>{{ object.dist }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
