import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
} from '@angular/core';
import { REPLACEMENT } from 'src/environments/environment';

@Component({
  selector: 'app-realtime-table-event',
  templateUrl: './realtime-table-event.component.html',
  styleUrls: ['./realtime-table-event.component.scss'],
})
export class RealtimeTableEventComponent implements OnInit, OnChanges {
  @Input() eventData = [];
  public noStatistic = REPLACEMENT.noStatistic;
  public noText = REPLACEMENT.noText;
  public eventShowList = [];
  public eventsPerPage = 10;
  public currentPage: number = 1;
  public noEvent: number = 0;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes['eventData'] && changes['eventData'].currentValue) {
      this.formatEventData();
    }
  }

  public formatEventData() {
    this.eventData.map((row, idx) => {
      this.eventData[idx].custno = +this.eventData[idx].custno;
      this.eventData[idx].nam = +this.eventData[idx].nam;
      this.eventData[idx].nu = +this.eventData[idx].nu;
      this.eventData[idx].tre_em = +this.eventData[idx].tre_em;
      this.eventData[idx].thanh_nien = +this.eventData[idx].thanh_nien;
      this.eventData[idx].trung_nien = +this.eventData[idx].trung_nien;
      this.eventData[idx].cao_tuoi = +this.eventData[idx].cao_tuoi;
    });
    this.noEvent = this.eventData.length;
    this.changePage(1);
  }

  changePage(page: number) {
    this.currentPage = page;
    let nextBeginItem = (this.currentPage - 1) * this.eventsPerPage;
    this.eventShowList = this.eventData.slice(
      nextBeginItem,
      nextBeginItem + this.eventsPerPage
    );
  }

  public sortEventTable(type: string, order: string) {
    this.eventShowList = this.sortArrayObject(
      this.eventShowList,
      [[type]],
      order
    );
  }

  public sortArrayObject(arr: any[], attr: string[][], order: string) {
    let result = [...arr];
    for (let i = 0; i < attr.length; i++) {
      let sortAttr = attr[i];
      result.sort((item1, item2) => {
        let value1 = this.getItemAttr(item1, sortAttr);
        let value2 = this.getItemAttr(item2, sortAttr);
        if (value1 > value2) {
          return order == 'ASC' ? 1 : -1;
        }
        if (value1 < value2) return order == 'ASC' ? -1 : 1;
        return 0;
      });
    }
    return result;
  }

  public getItemAttr(item: any, attr: string[]) {
    let result = item;
    for (let i = 0; i < attr.length; i++) {
      result = result[attr[i]];
    }
    return result;
  }
}
