<div class="box full-width">
  <div class="box-header row">
    <div class="pt-3 title col font-weight-bold">Đối tượng</div>
    <!-- <ul class="pt-2 nav justify-content-end col">
      <li class="nav-item title" [ngClass]="{'lock-item': selectedTab !=='day'}">
        <div class="nav-link" [ngClass]="{'active': selectedTab == 'day'}"
             (click)="handleSelectTab('day')">Ngày
        </div>
      </li>
      <li class="nav-item title" [ngClass]="{'lock-item': selectedTab !=='week'}">
        <div class="nav-link"
             [ngClass]="{'active': selectedTab == 'week', 'handle': selectedTab != 'week',
              'disabled': selectedTime && !selectedTime.week
              }"
             (click)="handleSelectTab('week')">Tuần
        </div>
      </li>
      <li class="nav-item title" [ngClass]="{'lock-item': selectedTab !=='month'}">
        <div class="nav-link"
             [ngClass]="{'active': selectedTab == 'month', 'handle': selectedTab != 'month',
              'disabled': selectedTime && !selectedTime.month}"
             (click)="handleSelectTab('month')">Tháng
        </div>
      </li>
    </ul> -->
  </div>
  <div class="box-body tab-content">
    <div id="day" class="tab-pane chart-container active">
      <ngx-charts-bar-vertical-stacked
        [scheme]="colorScheme"
        [results]="chartData"
        [barPadding]="3"
        [xAxis]="true"
        [yAxis]="true"
      >
        <ng-template #tooltipTemplate let-model="model">
          <div *ngIf="model">
            <span style="font-weight: bold"
              >{{ xAxisUnit }} {{ model.series ? model.series : 0 }}</span
            >
            <div *ngIf="getColumnData(model.series); let data">
              <a>{{ interestedcustnoLabel }}: {{ data[0].value }}</a
              ><br />
              <a>{{ custnoLabel }}: {{ data[0].value + data[1].value }}</a>
            </div>
          </div>
          <!-- <p>{{model|json}}</p> -->
        </ng-template>
      </ngx-charts-bar-vertical-stacked>
    </div>
    <!-- <div id="week" class="tab-pane chart-container"
         [ngClass]="{'active': selectedTab == 'week', 'fade': selectedTab != 'week'}">
      <ngx-charts-bar-vertical-stacked
        [scheme]="colorScheme"
        [results]="chartData"
        [barPadding]="3"
        [xAxis]="true"
        [yAxis]="true">
      </ngx-charts-bar-vertical-stacked>
    </div>
    <div id="month" class="tab-pane chart-container"
         [ngClass]="{'active': selectedTab == 'month', 'fade': selectedTab != 'month'}">
      <ngx-charts-bar-vertical-stacked
        [scheme]="colorScheme"
        [results]="chartData"
        [barPadding]="3"
        [xAxis]="true"
        [yAxis]="true">
      </ngx-charts-bar-vertical-stacked>
    </div> -->
    <div
      class="p-4"
      style="
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 20px;
      "
    >
      <div class="info-box d-flex flex-row">
        <span class="info-box-icon background-primary-style">
          <i class="fa fa-users default-style"></i>
        </span>
        <div class="info-box-content">
          <div class="sub-title font-weight-bold">Đối tượng</div>
          <div class="l-title font-weight-bold">{{ statisticData.custno }}</div>
        </div>
      </div>
      <div class="info-box d-flex flex-row">
        <span class="info-box-icon background-danger-style">
          <i class="fa fa-hourglass default-style"></i>
        </span>
        <div class="info-box-content">
          <div class="sub-title font-weight-bold">Thời gian xuất hiện</div>
          <div class="l-title font-weight-bold">
            {{
              statisticData.custno
                ? (statisticData.watchtime / statisticData.custno
                  | number: "1.0-0")
                : noStatistic
            }}s
          </div>
        </div>
      </div>
      <!-- <div class="info-box d-flex flex-row">
        <span class="info-box-icon background-success-style">
          <i class="fa fa-eye default-style"></i>
        </span>
        <div class="info-box-content">
          <div class="sub-title font-weight-bold">Lượt quan tâm</div>
          <div class="l-title font-weight-bold">
            {{ statisticData.interest }}
          </div>
        </div>
      </div>
      <div class="info-box info-box d-flex flex-row">
        <span class="info-box-icon background-warning-style">
          <i class="fa fa-percent default-style"></i>
        </span>
        <div class="info-box-content">
          <div class="sub-title font-weight-bold">Tỉ lệ quan tâm</div>
          <div class="l-title font-weight-bold">
            {{
              statisticData.custno
                ? ((statisticData.interest / statisticData.custno) * 100
                  | number: "1.0-1")
                : noStatistic
            }}
            %
          </div>
        </div>
      </div>
      <div class="info-box d-flex flex-row">
        <span class="info-box-icon" style="background-color: cornflowerblue">
          <i class="fa fa-hourglass-half default-style"></i>
        </span>
        <div class="info-box-content">
          <div class="sub-title font-weight-bold">Quan tâm trung bình</div>
          <div class="l-title font-weight-bold">
            {{
              statisticData.interest
                ? (statisticData.interestedwatchtime / statisticData.interest
                  | number: "1.0-0")
                : noStatistic
            }}s
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
