import { Component, OnInit } from '@angular/core';

interface SidebarItem {
  Label: string;
  Value: 'user' | 'role';
}

@Component({
  selector: 'app-manage-role',
  templateUrl: './manage-role.component.html',
  styleUrls: ['./manage-role.component.scss'],
})
export class ManageRoleComponent implements OnInit {
  public readonly SIDEBAR_ITEM: SidebarItem[] = [
    {
      Label: 'Quyền người dùng',
      Value: 'user',
    },
    {
      Label: 'Nhóm quyền',
      Value: 'role',
    },
  ];
  public selectedSidebarItem: SidebarItem = this.SIDEBAR_ITEM[0];
  constructor() {}

  ngOnInit(): void {}

  public onClickSidebarItem(sidebarItem: SidebarItem): void {
    this.selectedSidebarItem = sidebarItem;
  }
}
