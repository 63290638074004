import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';
import { Branch } from 'src/app/models/branch';
import { Cameragroup } from 'src/app/models/cameragroup';
import { SettingOption } from 'src/app/models/setting-option';
import { Option } from 'src/app/models/option';
import { ApiService } from 'src/app/services/api.service';
import {
  API,
  API_IMG_HOST,
  FUNC,
  REPLACEMENT,
} from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { GlobalVariablesService } from '../../../services/variables/global-variables.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Options } from '@angular-slider/ngx-slider';
import { EventAttribute } from 'src/app/models/event-attribute';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import * as moment from 'moment';
import { AlertService } from 'src/app/services/alert.service';
import { Customer } from 'src/app/models/customer';
import { PagedItem } from 'src/app/models/paged-item';
import { DelayInputService } from 'src/app/services/delay-input.service';

@Component({
  selector: 'app-evaluate-search',
  templateUrl: './evaluate-search.component.html',
  styleUrls: ['./evaluate-search.component.scss'],
})
export class EvaluateSearchComponent implements OnInit, OnChanges {
  @Input() hiddenSearchBar: boolean;
  @Input() showSlider: boolean = false;
  @Input() showAgeSelection = false;
  @Input() showGenderSelection = false;
  @Input() maxTimeValue = null;
  @Input() showPointSlider = false;
  @Input() showTimeOption = true;
  @Output() clickedBranch: EventEmitter<Branch> = new EventEmitter<Branch>();
  @Output() clickedStall: EventEmitter<Cameragroup> =
    new EventEmitter<Cameragroup>();
  @Output() clickedTimeOption: EventEmitter<SettingOption> =
    new EventEmitter<SettingOption>();
  @Output() clickedTimeTab: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectedImg: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickedGender: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickedAge: EventEmitter<any> = new EventEmitter<any>();
  // @ViewChild('element') showElement: ElementRef<HTMLInputElement>;
  @Output() changedSlider: EventEmitter<number> = new EventEmitter<number>();
  @Output() clickedShowModal: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() changedMaxSliderValue: EventEmitter<number> =
    new EventEmitter<number>();
  public branchList: Branch[] = [];
  public stallList: Cameragroup[] = [];
  public chosenBranch: Branch;
  public chosenStall: Cameragroup;
  public timeOptions: SettingOption[] = [];
  public chosenTime: SettingOption;
  public isShowBranchOption: boolean = false;
  public isShowCameraGroupOption: boolean = false;
  public isShowTimeOption: boolean = false;
  public isShowGenderOption: boolean = false;
  public isShowAgeOption: boolean = false;
  public noText = REPLACEMENT.noText;
  private branchParam = 0;
  private stallParam = 0;
  public chosenBranchChildren = [];
  public isFocus = false;
  public isRangeSlider = false;
  public textSearch: string = '';
  public imageUrl = [];
  public ageOptions: EventAttribute[] = [
    {
      id: 0,
      code: '',
      name: 'Tất cả',
      info: 'all age',
      op: '',
      prop: 'age',
      val: null,
      valmax: null,
    },
  ];
  public genderOptions: EventAttribute[] = [
    {
      id: 0,
      code: '',
      name: 'Tất cả',
      info: 'all gender',
      op: '',
      prop: 'gender',
      val: null,
      valmax: null,
    },
  ];
  public chosenGender;
  public chosenAge;
  public showImg: boolean = false;
  @Input() showModal: boolean;
  @Input() showEle: boolean;
  public srcImage: string;
  @Input() allowAll = true;
  @Input() showPopup: boolean = false;
  public sliderValue: number = 100;
  @Input() maxSliderValue: number = 50;
  @Input() showFromDate: boolean = false;
  @Input() showToDate: boolean = false;
  @Input() fromDateLabel: string = 'Từ';
  @Input() toDateLabel: string = 'Đến';
  @Output() changedFromDate: EventEmitter<string> = new EventEmitter<string>();
  @Output() changedToDate: EventEmitter<string> = new EventEmitter<string>();
  @Input() upperBound: number = 100;
  @Input() lowerBound: number = 1;
  public fromDate: IMyDateModel;
  public toDate: IMyDateModel;
  public dateRange;
  public locale = 'vi';
  public dateFormat = 'dd-mm-yyyy';
  public datePickerOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: this.dateFormat,
    disableSince: {
      year: moment().year(),
      month: moment().month() + 1,
      day: moment().date() + 1,
    },
    // other options...
  };
  public options: Options = {
    floor: 0,
    ceil: 100,
    autoHideLimitLabels: false,
    hidePointerLabels: true,
    translate: (value: number) => {
      if (value == 0) return 'Thấp';
      if (value == 100) return 'Cao';
      return '';
    },
  };
  public maxSliderOptions: Options = {
    floor: 0,
    ceil: 1,
    autoHideLimitLabels: true,
    hideLimitLabels: true,
    hidePointerLabels: true,
    disabled: true,
  };
  public customerList = [];
  public chosenCustomer = null;
  public showCustomerDropdown = false;
  public apiImgHost = API_IMG_HOST;
  @Output() changeCustomer = new EventEmitter();

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private globalService: GlobalVariablesService,
    private sanitizer: DomSanitizer,
    private alertService: AlertService,
    private delayInput: DelayInputService
  ) {}

  ngOnInit(): void {
    let routeBranchParam = this.route.snapshot.queryParamMap.get('branch');
    if (routeBranchParam) {
      this.branchParam = Number(routeBranchParam);
    }
    let routeStallParam = this.route.snapshot.queryParamMap.get('stall');
    if (routeStallParam) {
      this.stallParam = Number(routeStallParam);
    }
    if (this.allowAll)
      this.stallList = [
        {
          id: 0,
          code: '',
          name: 'Tất cả khu vực',
          info: null,
          branchid: null,
          deactiveyn: 'F',
          kpi_custno: 0,
          kpi_sumwatchtime: 0,
          cameratypeoptionid: 0,
        },
      ];
    this.getTimeOptions();
    if (this.showGenderSelection) this.getGenderOptions();
    if (this.showAgeSelection) this.getAgeOptions();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (this.showPointSlider && changes['maxSliderValue']) {
      // let noStep = 5;
      // let step = Math.floor(maxVal / noStep);
      // if (step > 0) {
      //   if (maxVal % step != 0) {
      //     maxVal = Math.floor(this.maxSliderValue/step) * step;
      //   }
      //   if (maxVal == 0) maxVal = 1;
      //   this.maxSliderOptions = {
      //     floor: 1,
      //     ceil: maxVal,
      //     autoHideLimitLabels: false,
      //     // step: step,
      //     showTicksValues: true,
      //     hidePointerLabels: true,
      //   }
      // }
      // else {
      //   this.maxSliderOptions = {
      //     floor: 1,
      //     ceil: 100,
      //     disabled: true,
      //     hideLimitLabels: true,
      //     hidePointerLabels: true,
      //   }
      // }
      if (this.upperBound > 0 && this.lowerBound > 0) {
        this.maxSliderOptions = {
          floor: this.lowerBound,
          ceil: this.upperBound,
          autoHideLimitLabels: true,
        };
      } else {
        this.maxSliderOptions = {
          floor: 0,
          ceil: 1,
          autoHideLimitLabels: true,
          hideLimitLabels: true,
          hidePointerLabels: true,
          disabled: true,
        };
      }
    }

    if (changes['showFromDate'] && changes['showFromDate'].currentValue) {
      this.fromDate = { isRange: false, singleDate: { jsDate: new Date() } };
    }

    if (changes['showToDate'] && changes['showToDate'].currentValue) {
      this.toDate = { isRange: false, singleDate: { jsDate: new Date() } };
    }
  }

  public handleClickedShowModal(image) {
    this.clickedShowModal.emit(image);
  }
  public getChosenBranchChildren() {
    this.apiService
      .get<any[]>(API.view + FUNC.getBranchChildren, {
        parent: this.chosenBranch.id,
      })
      .subscribe((branches) => {
        if (branches) {
          this.chosenBranchChildren = branches.map((branch) => branch.id);
          this.getStall();
        }
      });
  }

  public handleClickedBranch(selectedBranch: Branch) {
    this.isShowBranchOption = false;
    this.chosenBranch = selectedBranch;
    this.clickedBranch.emit(selectedBranch);
    this.getChosenBranchChildren();
  }

  public handleClickedStall(selectedStall: Cameragroup) {
    this.chosenStall = selectedStall;
    this.clickedStall.emit(selectedStall);
  }

  public handleSelectList(type: string) {
    let selectList = [
      'isShowBranchOption',
      'isShowCameraGroupOption',
      'isShowTimeOption',
      'isShowGenderOption',
      'isShowAgeOption',
    ];
    for (let i = 0; i < selectList.length; i++) {
      if (selectList[i] == type) {
        this[type] = !this[type];
      } else {
        this[selectList[i]] = false;
      }
    }
  }

  public handleSelectTime(selectedOption: SettingOption) {
    if (selectedOption.name == 'Hôm nay') {
      if (this.showFromDate)
        this.fromDate = { isRange: false, singleDate: { jsDate: new Date() } };
      if (this.showToDate)
        this.toDate = { isRange: false, singleDate: { jsDate: new Date() } };
    } else {
      if (this.showFromDate) {
        let newDate = moment(this.toDate.singleDate.jsDate).subtract(
          +selectedOption.value - 1,
          'days'
        );
        if (newDate.isValid())
          this.fromDate = {
            isRange: false,
            singleDate: { jsDate: newDate.toDate() },
          };
      }
    }
    this.chosenTime = selectedOption;
    this.clickedTimeOption.emit(selectedOption);
  }

  public getBranches() {
    this.chosenBranch = this.globalService.globalBranch;
    let branchQuery = {
      op: 'AND',
      val: [
        {
          model: 't_branch',
          col: 'parentid',
          op: '!=',
          val: null,
        },
      ],
    };
    this.apiService
      .get<Branch[]>(API.branches, { query: JSON.stringify(branchQuery) })
      .subscribe((branches) => {
        if (branches) {
          // this.formatBranchList(branches);
          this.branchList = branches;
          let selectedBranch = branches.find(
            (branch) => branch.id == this.branchParam
          );
          if (selectedBranch) {
            this.handleClickedBranch(selectedBranch);
          } else {
            if (this.chosenBranch === undefined) {
              this.handleClickedBranch(this.branchList[0]);
            } else {
              this.handleClickedBranch(this.chosenBranch);
            }
          }
        }
      });
  }

  public formatBranchList(branches: Branch[]) {
    let tempList = [];
    branches.map((branch) => {
      branch.t_branches = this.getBranchChildren(branch, branches);
      if (!branch.parentid) {
        tempList.push(branch);
      }
    });
    this.branchList = [...this.branchList, ...tempList];
  }

  public getBranchChildren(parent: Branch, branchList: Branch[]) {
    let children = [];
    branchList.map((branch, idx) => {
      if (branch.parentid == parent.id) {
        children.push(branch);
        // branchList.splice(idx, 1);
      }
    });
    return children;
  }

  public getStall() {
    if (this.allowAll) this.stallList.length = 1;
    else this.stallList.length = 0;
    let stallQuery = {
      op: 'AND',
      val: [
        {
          model: 't_cameragroup',
          col: 'branchid',
          op: 'in',
          val: this.chosenBranchChildren,
        },
      ],
    };
    this.apiService
      .get<Cameragroup[]>(API.cameraGroup, {
        query: JSON.stringify(stallQuery),
      })
      .subscribe((stalls) => {
        if (stalls) {
          this.stallList = [...this.stallList, ...stalls];
          let selectedStall = stalls.find(
            (stall) => stall.id == this.stallParam
          );
          if (selectedStall) {
            this.handleClickedStall(selectedStall);
          } else {
            this.handleClickedStall(this.stallList[0]);
          }
        }
      });
  }

  public getTimeOptions() {
    this.chosenTime = this.globalService.globalTime;
    this.timeOptions.length = 0;
    const optionInclude = [
      {
        model: 't_optiongroup',
        include: [],
      },
    ];
    const optionQuery = {
      op: 'AND',
      val: [
        {
          model: 't_optiongroup',
          col: 'code',
          op: '=',
          val: 'timeoptionid',
        },
      ],
    };
    this.apiService
      .get<Option[]>(API.options, {
        include: JSON.stringify(optionInclude),
        query: JSON.stringify(optionQuery),
        order: JSON.stringify([['id', 'ASC']]),
      })
      .subscribe((options) => {
        if (options) {
          options.map((option) => {
            let optionVal = JSON.parse(option.val);
            if (this.maxTimeValue && optionVal.value <= this.maxTimeValue) {
              let timeOption: SettingOption = {
                name: option.name,
                value: optionVal.value,
                day: optionVal.day,
                week: optionVal.week,
                month: optionVal.month,
              };
              this.timeOptions.push(timeOption);
            } else if (!this.maxTimeValue) {
              let timeOption: SettingOption = {
                name: option.name,
                value: optionVal.value,
                day: optionVal.day,
                week: optionVal.week,
                month: optionVal.month,
              };
              this.timeOptions.push(timeOption);
            }
          });
          if (this.chosenTime === undefined) {
            this.handleSelectTime(this.timeOptions[0]);
          } else {
            this.handleSelectTime(this.chosenTime);
          }
        }
        this.getBranches();
      });
  }

  public search() {
    // this.textSearchChange.emit(this.textSearch);
  }

  public resetSearch() {
    this.textSearch = '';
    this.search();
  }

  closeImg(e, index) {
    this.imageUrl.map((item, i) => {
      if (index === i) {
        this.imageUrl.splice(i, 1);
        this.showModal = false;
      }
    });
    this.selectedImg.emit(null);
    if (this.imageUrl.length == 0) {
      this.showImg = false;
    }
  }

  findImage() {
    this.imageUrl = [];
    this.imageUrl.push(this.srcImage);
    this.selectedImg.emit(this.srcImage);
    this.showImg = true;
    this.showModal = false;
    this.srcImage = '';
  }

  dropImage(e: any[]) {
    this.imageUrl = e;
    this.showImg = true;
    this.showModal = false;
    this.selectedImg.emit(e[0]);
  }

  chooseFile(files) {
    this.imageUrl = [];
    const url = URL.createObjectURL(files[0]);
    const sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    const object = { file: files[0], url: sanitizedUrl };
    this.imageUrl.push(object);
    this.showImg = true;
    this.showPopup = false;
    this.selectedImg.emit(object);
  }

  public onDrag(ev: DragEvent) {
    // console.log(ev)
  }

  // @HostListener('document:click', ['$event'])
  // public clickOutside(e) {
  //   console.log(e);
  //   if (!this.element.nativeElement.contains(e.target)) {
  //     this.closePopup()
  //   }
  // }

  public openPopup() {
    this.showPopup = this.showPopup !== true;
    this.showCustomerDropdown = false;
  }

  public closePopup() {
    this.showModal = false;
    this.isShowBranchOption = false;
    this.isShowCameraGroupOption = false;
    this.isShowTimeOption = false;
  }

  public handleChangeSlider() {
    this.changedSlider.emit(this.sliderValue);
  }

  public getAgeOptions() {
    this.ageOptions.length = 1;
    let ageQuery = {
      op: 'AND',
      val: [
        {
          model: 't_eventattribute',
          col: 'prop',
          op: '=',
          val: 'age',
        },
      ],
    };
    let order = [['id', 'asc']];
    this.apiService
      .get<any[]>(API.eventAttributes, {
        query: JSON.stringify(ageQuery),
        order: JSON.stringify(order),
      })
      .subscribe((ageOptions) => {
        if (ageOptions) {
          this.ageOptions = [...this.ageOptions, ...ageOptions];
          let routeAgeParam = this.route.snapshot.queryParamMap.get('age');
          let chosenAge = null;
          if (routeAgeParam) {
            chosenAge = this.ageOptions.find(
              (option) => option.code == routeAgeParam
            );
          }
          if (chosenAge) this.handleSelectAge(chosenAge);
          else this.handleSelectAge(this.ageOptions[0]);
        }
      });
  }

  public getGenderOptions() {
    this.genderOptions.length = 1;
    let genderQuery = {
      op: 'AND',
      val: [
        {
          model: 't_eventattribute',
          col: 'prop',
          op: '=',
          val: 'gender',
        },
      ],
    };
    let order = [['id', 'asc']];
    this.apiService
      .get<any[]>(API.eventAttributes, {
        query: JSON.stringify(genderQuery),
        order: JSON.stringify(order),
      })
      .subscribe((genderOptions) => {
        if (genderOptions) {
          this.genderOptions = [...this.genderOptions, ...genderOptions];
          let routeGenderParam =
            this.route.snapshot.queryParamMap.get('gender');
          let chosenGender = null;
          if (routeGenderParam) {
            chosenGender = this.genderOptions.find(
              (option) => option.code == routeGenderParam
            );
          }
          if (chosenGender) this.handleSelectGender(chosenGender);
          else this.handleSelectGender(this.genderOptions[0]);
        }
      });
  }

  public handleSelectAge(selectedAge: any) {
    this.chosenAge = selectedAge;
    this.clickedAge.emit(this.chosenAge);
  }

  public handleSelectGender(selectedGender: any) {
    this.chosenGender = selectedGender;
    this.clickedGender.emit(this.chosenGender);
  }

  public handleMaxChangeSlider() {
    this.changedMaxSliderValue.emit(this.maxSliderValue);
  }

  public handleFromDateChange(newDate) {
    this.changedFromDate.emit(newDate.singleDate.formatted);
  }

  public handleToDateChange(newDate) {
    this.changedToDate.emit(newDate.singleDate.formatted);
  }

  public findCustomerByName(textSearch: string) {
    this.textSearch = textSearch;
    this.delayInput.delay(
      '#text_search_customer',
      () => this.getCustomers(),
      300
    );
  }

  public getCustomers() {
    const customerInclude = [
      {
        model: 't_branch',
        include: [],
      },
      {
        model: 't_option',
        as: 'type',
        include: [],
      },
    ];
    const customerQuery = {
      op: 'AND',
      val: [
        {
          model: 't_customer',
          col: 'name',
          op: 'ilike',
          val: `%${this.textSearch}%`,
        },
      ],
    };
    this.apiService
      .get<PagedItem<Customer>>(API.customers, {
        include: JSON.stringify(customerInclude),
        query: JSON.stringify(customerQuery),
        page: 1,
        itemsPerPage: 20,
      })
      .subscribe((data) => {
        if (data) {
          this.customerList = data.rows;
          this.showCustomerDropdown = true;
          // if (data.rows.length > 0) {
          //   this.chosenCustomer = data.rows[0];
          // }
          // this.changeCustomer.emit(data.rows[0]);
        }
      });
  }

  public handleSelectCustomer(selectedCustomer: Customer) {
    this.chosenCustomer = selectedCustomer;
    this.showCustomerDropdown = false;
    this.changeCustomer.emit(selectedCustomer);
  }

  public clearCustomer() {
    this.chosenCustomer = null;
    this.showCustomerDropdown = false;
    this.changeCustomer.emit(null);
  }
}
