<div class="position-relative">
  <div class="form-modal d-flex flex-column">
    <div class="full-width full-height row" *ngIf="selectedTab == 'info'">
      <div class="info flex-grow-1" *ngIf="itemType">
        <ng-container *ngIf="itemType == 'branch'">
          <app-branch-detail
            [selectedBranchId]="itemId"
            [parentId]="currentBranchId"
            (changedBranch)="handleChangedItem($event)"
          ></app-branch-detail>
        </ng-container>
        <ng-container *ngIf="itemType == 'cameragroup'">
          <app-cameragroup-detail
            [chosenTab]="selectedTab"
            [cameraId]="selectedCameraId"
            [selectedCameragroupId]="itemId"
            [parentId]="currentBranchId"
            (changedCameragroup)="handleChangedItem($event)"
          ></app-cameragroup-detail>
        </ng-container>
      </div>
      <div class="mt-2 d-flex full-width" *ngIf="itemType == 'branch'">
        <button
          type="button"
          class="mr-2 btn btn-sm btn-secondary full-width"
          (click)="newBranchForm()"
        >
          Thêm cơ quan
        </button>
        <button
          type="button"
          class="btn btn-sm btn-secondary full-width"
          (click)="newCameragroupForm()"
        >
          Thêm khu vực
        </button>
      </div>
    </div>
  </div>
</div>
