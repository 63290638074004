import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Branch } from 'src/app/models/branch';
import { Cameragroup } from 'src/app/models/cameragroup';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { API, FUNC } from 'src/environments/environment';

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.scss'],
})
export class ReportDetailComponent implements OnInit {
  @Output() submitReportParam: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportReport: EventEmitter<any> = new EventEmitter<any>();
  public selectedBranch: Branch;
  public branchList: Branch[] = [];
  public frmObject = new FormGroup({
    branchid: new FormControl(null),
    cameragroups: new FormControl(null),
    startTime: new FormControl(null),
    endTime: new FormControl(null),
  });
  public currentUserInfo;
  public userBranchChildren = [];
  public formatedBranchList = [];
  public isShowBranchOption = false;
  public datePickerOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mm-yyyy',
    disableSince: {
      year: moment().year(),
      month: moment().month() + 1,
      day: moment().date() + 1,
    },
    // other options...
  };
  public locale = 'vi';
  @Input() exportable = false;
  public selectedCameragroups: Cameragroup[] = [];
  public cameragroupList: Cameragroup[] = [];
  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Tất cả khu vực',
    unSelectAllText: 'Bỏ chọn tất cả',
    // itemsShowLimit: 5,
  };
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.currentUserInfo = this.authService.getUserInfo();
    if (this.currentUserInfo.branchid) this.getBranchChildren();
    else this.getBranch();
  }

  public getBranch() {
    this.branchList.length = 0;
    let branchQuery = {
      op: 'AND',
      val: [],
    };
    if (this.userBranchChildren.length > 0) {
      branchQuery.val.push({
        model: 't_branch',
        col: 'id',
        op: 'in',
        val: this.userBranchChildren,
      });
    }
    let order = [['id', 'asc']];
    this.apiService
      .get<Branch[]>(API.branches, {
        query: JSON.stringify(branchQuery),
        order: JSON.stringify(order),
      })
      .subscribe((branches) => {
        if (branches) {
          this.branchList = branches;
          this.formatedBranchList.length = 0;
          this.formatBranchList(branches);
          this.handleSelectBranch(this.branchList[0]);
        }
      });
  }

  public getBranchChildren() {
    this.apiService
      .get<any>(API.view + FUNC.getBranchChildren, {
        parent: this.currentUserInfo.branchid,
      })
      .subscribe((branches) => {
        if (branches) {
          this.userBranchChildren = branches.map((branch) => branch.id);
          this.getBranch();
        }
      });
  }

  public getBranchChildrenFromLocal(parent: Branch, branchList: Branch[]) {
    let children = [];
    branchList.map((branch, idx) => {
      if (branch.parentid == parent.id) {
        children.push(branch);
      }
    });
    return children;
  }

  public formatBranchList(branches: Branch[]) {
    let tempList = [];
    branches.map((branch) => {
      branch.t_branches = this.getBranchChildrenFromLocal(branch, branches);
      if (!branch.parentid) {
        tempList.push(branch);
      }
    });
    this.formatedBranchList = [...this.formatedBranchList, ...tempList];
  }

  public onClickBranch() {
    this.isShowBranchOption = !this.isShowBranchOption;
  }

  public handleSelectBranch(selectedBranch) {
    this.frmObject.patchValue({
      branchid: selectedBranch.id,
    });
    this.selectedBranch = this.branchList.find(
      (branch) => branch.id == selectedBranch.id
    );
    this.isShowBranchOption = false;
    this.getCameragroups();
  }

  public onSubmit() {
    if (this.frmObject.invalid || this.selectedCameragroups.length == 0)
      this.alertService.informError('Xin hãy điền đầy đủ thông tin');
    else {
      this.frmObject.patchValue({
        cameragroups: this.selectedCameragroups,
      });
      this.submitReportParam.emit(this.frmObject.value);
    }
  }

  public onExport() {
    if (this.exportable) this.exportReport.emit(true);
    else
      this.alertService.informError(
        'Chưa có dữ liệu báo cáo. Xin hãy xem báo cáo trước!'
      );
  }

  public getCameragroups() {
    this.selectedCameragroups = [];
    this.cameragroupList.length = 0;
    let cameragroupQuery = {
      op: 'AND',
      val: [],
    };
    if (
      this.selectedBranch.t_branches &&
      this.selectedBranch.t_branches.length > 0
    ) {
      cameragroupQuery.val.push({
        model: 't_cameragroup',
        col: 'branchid',
        op: 'in',
        val: this.selectedBranch.t_branches.map((branch) => branch.id),
      });
    } else {
      cameragroupQuery.val.push({
        model: 't_cameragroup',
        col: 'branchid',
        op: '=',
        val: this.selectedBranch.id,
      });
    }
    let order = [['id', 'asc']];
    this.apiService
      .get<Cameragroup[]>(API.cameraGroup, {
        query: JSON.stringify(cameragroupQuery),
        order: JSON.stringify(order),
      })
      .subscribe((cameragroups) => {
        if (cameragroups) {
          this.cameragroupList = cameragroups;
        }
      });
  }
}
