<div
  class="p-2 mt-2 camera-info"
  style="background-color: #ffffff; height: 15vh; overflow: auto"
>
  <div class="location-icon">
    <img src="assets/image/camera.PNG" alt="camera-icon" />
  </div>
  <div>
    <span class="title-header">{{
      selectedMonitor ? selectedMonitor.name : noText
    }}</span
    ><br />
    <span class="sub-title">{{
      selectedMonitor && selectedMonitor.t_cameragroup
        ? selectedMonitor.t_cameragroup.name
        : noText
    }}</span>
  </div>
</div>
