<nav class="navbar navbar-expand-lg navbar-light background-default-style">
  <div style="padding: 10px 10px 10px 15px">
    <span class="mr-2">
      <img class="pb-2" src="assets/image/logo.png" alt="" />
    </span>
    <!--    <span class="mr-2">-->
    <!--      <img *ngIf="changeICon === true" class="pb-2" src="assets/image/changelogo.PNG" alt="">-->
    <!--    </span>-->
    <a
      style="font-size: 24px"
      class="navbar-brand regular"
      *ngIf="changeICon === false"
      >Giám sát khuôn mặt</a
    >
    <a style="color: #2a92dc" class="navbar-brand" *ngIf="changeICon === true"
      >AN NINH VÀ GIAO THÔNG</a
    >
    <!--    <span class="tris-title-color"><i class="fa " [ngClass]="changeICon?'fa-caret-up':'fa-caret-down'"></i></span>-->
  </div>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNav"
    aria-controls="navbarNav"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div
    class="collapse navbar-collapse custom-navbar full-height"
    id="navbarNav"
  >
    <ul class="navbar-nav full-height">
      <li class="nav-item regular" routerLinkActive="active">
        <a
          style="font-size: 16px"
          class="nav-link regular"
          routerLink="/analysis"
          >PHÂN TÍCH</a
        >
      </li>
      <!-- <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
        <a class="nav-link" routerLink="/cctv">GIÁM SÁT</a>
      </li> -->
      <li class="nav-item regular" routerLinkActive="active">
        <a style="font-size: 16px" class="nav-link regular" routerLink="/image"
          >HÌNH ẢNH</a
        >
      </li>
      <!-- <li class="nav-item regular" routerLinkActive="active">
        <a
          style="font-size: 16px"
          class="nav-link regular"
          routerLink="/heatmap"
          >HEATMAP</a
        >
      </li> -->
      <li class="nav-item regular" routerLinkActive="active">
        <a
          style="font-size: 16px"
          class="nav-link regular"
          routerLink="/monitor"
          >GIÁM SÁT</a
        >
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a style="font-size: 16px" class="nav-link regular" routerLink="/map"
          >BẢN ĐỒ <span class="sr-only">(current)</span></a
        >
      </li>
      <li class="nav-item regular" routerLinkActive="active">
        <a style="font-size: 16px" class="nav-link regular" routerLink="/manage"
          >QUẢN LÍ</a
        >
      </li>
    </ul>
  </div>
  <div class="position-absolute" style="right: 0; top: 20px">
    <div class="position-relative">
      <div class="nav-bar-user d-flex" style="flex: 1 auto">
        <div class="handle position-relative">
          <div
            class="parent notification-icon"
            [ngClass]="isShowNotifies ? 'background-shadow' : '#F8F9FA'"
            (click)="clickedNotifyMenu()"
          >
            <!-- <i class="fa fa-user p-1"></i> -->
            <div style="position: relative">
              <div class="notification" *ngIf="noNewNotifies > 0">
                {{
                  noNewNotifies > maxNotification
                    ? maxNotification + "+"
                    : noNewNotifies
                }}
              </div>
              <span><i class="fa fa-bell"></i></span>
            </div>
          </div>
          <div
            class="top-level-menu notification-list mt-2"
            [hidden]="!isShowNotifies"
            infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            (scrolled)="onScroll()"
            [scrollWindow]="false"
          >
            <div
              class="title-menu p-3"
              *ngFor="let notify of notifyList"
              (click)="updateNotifyState(notify)"
              [routerLink]="['/analysis']"
              [queryParams]="{
                event: notify.eventid,
                tab: notify.person_type,
                branch: notify.branchid
              }"
              [fragment]="'Nhận dạng'"
              routerLinkActive="router-link-active"
            >
              <div class="notify-item" *ngIf="notify">
                <div>
                  <img
                    class="user-avatar-icon"
                    style="width: 50px; max-height: 50px"
                    src="{{ deviceHost + notify.url }}"
                  />
                </div>
                <div>
                  <div class="title">
                    {{ notify.person ? notify.person.name : freqGuess }}
                  </div>
                  <div class="sub-title">
                    {{
                      notify.personType ? notify.personType : notify.person_type
                    }}
                  </div>
                  <div class="sub-title">
                    {{ notify.created_timestamp | date: "dd-MM-yyyy HH:mm:ss" }}
                  </div>
                </div>
                <div class="new-notify">
                  <i class="fa fa-circle" *ngIf="notify.isNew"></i>
                </div>
              </div>
            </div>
            <ngx-spinner
              bdColor="black"
              size="small"
              color="#fff"
              type="ball-pulse-sync"
              [fullScreen]="false"
            >
              <p style="font-size: 20px; color: black">Loading...</p>
            </ngx-spinner>
          </div>
        </div>
        <div class="handle position-relative">
          <div
            class="parent"
            style="
              border: none;
              background: none;
              min-width: 150px;
              width: max-content !important;
              text-align: right;
            "
            [ngClass]="isShowUserMenu ? 'background-shadow' : '#F8F9FA'"
          >
            <!-- <i class="fa fa-user p-1"></i> -->
            <div (click)="clickedUserMenu()">
              <img class="user-avatar-icon" src="{{ avatarSrc }}" />
              <span>{{ userInfo.name }}</span>
              <span class="ml-3"
                ><i
                  class="fa"
                  [ngClass]="{
                    'fa-caret-down': !isShowUserMenu,
                    'fa-caret-up': isShowUserMenu
                  }"
                ></i
              ></span>
            </div>
          </div>
          <ul
            class="top-level-menu mt-1"
            style="top: 30px; right: 0; width: 100% !important"
            [hidden]="!isShowUserMenu"
          >
            <li class="title-menu">
              <a class="p-2" style="text-align: center" (click)="logout()"
                ><i class="fa fa-sign-out pr-2" aria-hidden="true"></i>Đăng
                xuất</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>

<!--<ngx-spinner-->
<!--  bdOpacity = 0.4-->
<!--  bdColor = ""-->
<!--  size = "default"-->
<!--  color = "rgba(0,0,0,0.4)"-->
<!--  type = "ball-pulse-sync"-->
<!--  [fullScreen] = "true"-->
<!--&gt;-->
<!--</ngx-spinner>-->
