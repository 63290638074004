import {Component, OnInit} from '@angular/core';
import { Camera } from 'src/app/models/camera';
import { Cameragroup } from 'src/app/models/cameragroup';
import { REPLACEMENT } from 'src/environments/environment';

@Component({
  selector: 'app-cctv-layout',
  templateUrl: './cctv-layout.component.html',
  styleUrls: ['./cctv-layout.component.scss']
})
export class CctvLayoutComponent implements OnInit {
  public isShowSideBar: any = false;
  public chosenCamera: Camera;
  public chosenCameraGroup: Cameragroup;
  public currentDeviceImg: string = REPLACEMENT.noDeviceImg;

  constructor() {
  }

  ngOnInit(): void {
  }

  public handleShowSideBar() {
    this.isShowSideBar = this.isShowSideBar !== true;
  }

  public handleClickedCamera(camera: Camera) {
    this.chosenCamera = camera;
  }

  public handleClickedCameraGroup(cameraGroup: Cameragroup) {
    // this.chosenCamera = camera;
    this.chosenCameraGroup = cameraGroup;
  }
}
