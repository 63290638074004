<div class="p-2 mt-2 event-container" style="background-color: #ffffff">
  <span class="title-header mb-3">Sự kiện</span>
  <ng-container *ngIf="!selectedMonitor">{{ noData }}</ng-container>
  <ng-container *ngIf="selectedMonitor"></ng-container>
  <div class="event-item m-2" *ngFor="let event of events" @slideInOut>
    <div class="img-container">
      <img class="event-image" [src]="imgUri + event.url" />
    </div>
    <div class="p-1">
      <span>{{ event.t_customer.name }}</span
      ><br />
      <span
        class="sub-title event-type"
        [ngClass]="{
          'event-type__vip': event.t_option.code == 'VIP',
          'event-type__blk': event.t_option.code == 'BLK',
          'event-type__id': event.t_option.code == 'IDPERSON'
        }"
        >{{ event.t_option.name }}</span
      ><br />
      <span class="sub-title sub-style">{{
        event.createddate | date: "dd-MM-yyyy HH:mm:ss"
      }}</span>
    </div>
  </div>
</div>
